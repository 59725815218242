import {RouterModule, Routes} from '@angular/router';
import {ThemingComponent} from './components/theming.component';
import {permission} from "./settings-theme.permission";

const APP_ROUTES: Routes = [
  {
    path: 'module/settings/theming',
    component: ThemingComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: true,
      needsPermissions: [
        permission.MYMEDAX_SETTING_CREATE,
        permission.MYMEDAX_SETTING_READ,
        permission.MYMEDAX_SETTING_UPDATE,
        permission.MYMEDAX_SETTING_DELETE
      ],
      needsConfig: [
        'feature.patient.app.autoTheming',
      ]
    }
  }
];

export const routing = RouterModule.forRoot(APP_ROUTES);
