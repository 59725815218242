import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Translatable} from '@ngmedax/translation';
import {DragulaService} from 'ng2-dragula';
import {TRANSLATION_DEFAULT_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';
import {PdfForm} from '../../../../types';
import {PdfFormService} from '../../services/pdf-form.service';
declare const $: any;


// hack to inject decorator declarations. must occur before class declaration!
export interface PdfFormSelectComponent extends Translatable {}

@Component({
  selector: 'app-pdf-form-select',
  templateUrl: './pdf-form-select.component.html',
  styleUrls: ['./pdf-form-select.component.css'],
  encapsulation: ViewEncapsulation.None
})
@Translatable({scope: TRANSLATION_DEFAULT_SCOPE, keys: KEYS})
export class PdfFormSelectComponent implements OnInit {
  /**
   * Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Available forms
   * @type {PdfForm[]} forms
   */
  public forms: PdfForm[] = [];

  /**
   * Currently selected form
   * @type {string} choice
   */
  public choice: string = null;

  /**
   * Options for select2
   */
  public options: any = {width: '100%', placeholder: '...',};

  /**
   * Mapping of form ids to form titles
   * @type {{[id: string]: Translation}}
   */
  public formTitles: {[id: string]: string} = {};

  /**
   * Currently selected form ids. You can use this for two-way data binding
   * @type {string[]} selected
   */
  @Input() public selected: string[] = [];

  /**
   * Event emitter for when selected form ids changed. You can use this for two-way data binding
   * @type {EventEmitter<string[]>} selectedChange
   */
  @Output() public selectedChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  /**
   * Injects dependencies
   * Loads forms
   */
  public constructor(
    private pdfFormService: PdfFormService,
    private dragula: DragulaService,
  ) {
    /**
     * Bugfix for dragula: Deletes previous elements bag on progressive page reloads
     */
    for (const bag of ['pdf-form-list-bag']) {
      this.dragula.find(bag) && this.dragula.destroy(bag);
    }

    // only allow dragging via elements that contain the class: qa-handle-control
    this.dragula.createGroup('pdf-form-list-bag', {
      moves: (el, container, handle) => handle.classList.contains('qa-handle-control')
    });

    this.pdfFormService.loadPdfForms()
      .then(forms => {
        this.forms = forms.rows;
        this.forms.forEach(f => this.formTitles[f.uid] = f.name);
      })
      .catch(error => {
        console.error(error);
        alert(this._(KEYS.DEFAULT.ERROR_LOADING_PDF_FORMS));
      });
  }

  public ngOnInit() {
    !this.selected && (this.selected = []);
  }

  /**
   * Handler for when form added to list
   *
   * @param {string} id
   */
  public onAddPdfForm(id: string) {
    this.selected.indexOf(id) === -1 && this.selected.push(id);
    this.selectedChange.emit(this.selected);
    this.choice = null;
  }

  /**
   * Handler for when form position changes in list
   *
   * @param {number} currentPosition
   * @param {number} newPosition
   */
  public onPositionChange(currentPosition: number, newPosition: number) {
    // early bailout when changing positions does not make sense
    if (currentPosition >= (this.selected.length) || newPosition < 0) {
      return;
    }

    // reorder answers by current and new position
    this.selected.splice(newPosition, 0, this.selected.splice(currentPosition, 1)[0]);
    this.selectedChange.emit(this.selected);
  }

  /**
   * Handler for when form is deleted from list
   *
   * @param {number} position
   */
  public onDelete(position: number) {
    this.selected.splice(position, 1);
    this.selectedChange.emit(this.selected);
  }
}
