import {Component, Input, ChangeDetectorRef, Optional, OnInit, ViewEncapsulation} from '@angular/core';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {QuestionnaireEditorService} from '../../../services/questionnaire-editor.service';
import {QuestionnaireStateService} from '../../../services/questionnaire-state.service';
import {QuestionnaireConditionService} from '../../../services/questionnaire-condition.service';
import {TRANSLATION_EDITOR_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';


// hack to inject decorator declarations. must occur before class declaration!
export interface QuestionTypeNetDiagramComponent extends Translatable {}

@Component({
  selector: 'app-qa-question-net-diagram',
  templateUrl: './question-type-net-diagram.component.html',
  styleUrls: ['./question-type-net-diagram.component.css'],
  encapsulation: ViewEncapsulation.None
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class QuestionTypeNetDiagramComponent implements OnInit {
  @Input() question: Questionnaire.Container;

  /**
   * Locale for questionnaire. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi lang support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Default option for select2 component
   */
  public select2Opts = {width: '200px', placeholder: '...'};

  /**
   * List of available variables
   */
  public variables: string[] = [];

  /**
   * Input steps
   * @type {string}
   */
  public steps = '1';

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    private questionnaireEditor: QuestionnaireEditorService,
    private conditions: QuestionnaireConditionService,
    private state: QuestionnaireStateService,
    private editor: QuestionnaireEditorService,
    private ref: ChangeDetectorRef,
  ) {
  }

  public ngOnInit() {
    const variables = [];
    const select = this.conditions.getSelectData(false);

    for (const variable of select.variables) {
      const container = this.conditions.getContainerForVariable(variable);
      container && container.format === 'numeric' && variables.push(variable);
    }

    this.question && this.question.options && !this.question.options.steps && (this.question.options.steps = 10);

    this.variables = variables;
    this.onUpdateSteps();
  }

  /**
   * Updates input steps
   */
  public onUpdateSteps() {
    this.steps = (this.question.options.steps + '')
      .replace(/0*\d/g,'0')
      .replace(/[0-9]$/, '1');
  }

  /**
   * Triggers the "entry position change" event in order to push an entry
   * to the given position
   *
   * @param {number} currentPosition
   * @param {number} newPosition
   */
  public onPositionChange(currentPosition: number, newPosition: number) {
    const entries = this.question.options.netDiagram[0].entries;

    // early bailout when changing positions does not make sense
    if (currentPosition >= (entries.length) || newPosition < 0) {
      return;
    }

    // reorder entries by current and new position
    entries.splice(newPosition, 0, entries.splice(currentPosition, 1)[0]);
    this.triggerChangeDetection();
  }

  public onAddEntry() {
    this.question.options.netDiagram[0].entries.push({
      label: {[this.locale]: ''},
      variable: '',
    });

    this.triggerChangeDetection();
  }

  public onDeleteEntry(position: number) {
    this.questionnaireEditor.confirmDelete(() => {
      this.question.options.netDiagram[0].entries.splice(position, 1);
      this.triggerChangeDetection();
    });
  }

  /**
   * Triggers change detection
   */
  public triggerChangeDetection() {
    this.ref.detectChanges();
    this.ref.markForCheck();
  }
}
