import {Component} from '@angular/core';
import {TRANSLATION_IMPRESS_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';
import {Translatable} from '@ngmedax/translation';


// hack to inject decorator declarations. must occur before class declaration!
export interface ImprintComponent extends Translatable {}

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.css']
})
@Translatable({scope: TRANSLATION_IMPRESS_SCOPE, keys: KEYS})
export class ImprintComponent {
}
