import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClickOutsideModule} from 'ng-click-outside';

import {GlobalStyleComponent} from './components/global-style/global-style.component';
import {LocaleSelectComponent} from './components/translate/locale-select.component';
import {LayoutHomeComponent} from './layout-home.component';
import {LayoutComponent} from './components/layout/layout.component';
import {LayoutService} from './layout.service';
import {ShowMenuGuard} from './show-menu.guard';
import {CoreGuard} from './core.guard';
import {RedirectGuard} from './redirect.guard';
import {routing} from './layout.routing';
import {Select2Module} from '@ngmedax/select2';
import {EventService} from "./event.service";


@NgModule({
  imports: [
    CommonModule,
    ClickOutsideModule,
    Select2Module,
    routing
  ],
  declarations: [
    GlobalStyleComponent,
    LocaleSelectComponent,
    LayoutHomeComponent,
    LayoutComponent
  ],
  exports: [LayoutComponent],
  providers: [
    LayoutService,
    EventService,
    ShowMenuGuard,
    {
      provide: 'CoreGuard',
      useClass: CoreGuard
    },
    {
      provide: 'RedirectGuard',
      useClass: RedirectGuard
    }
  ]
})
export class LayoutModule {
  public constructor(
    showMenuGuard: ShowMenuGuard,
    layoutService: LayoutService,
  ) {
    layoutService.registerGuard(showMenuGuard, 10000);
  }
}
