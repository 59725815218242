import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'striptags',
})
export class StriptagsPipe implements PipeTransform {
  /**
   * Removes html tags from value
   *
   * @param {string} value
   * @returns {string}
   */
  transform(value: string) {
    if (value) {
      return value.replace(/<\/?[^>]+>/gi, '');
    }
  }
}
