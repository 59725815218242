import {RouterModule, Routes} from '@angular/router';
import {LicenseComponent} from './components/license.component';

const APP_ROUTES: Routes = [
  {
    path: 'module/settings/license',
    component: LicenseComponent,
    canActivate: ['CoreGuard'],
    data: {needsLogin: false}
  }
];

export const routing = RouterModule.forRoot(APP_ROUTES);
