import {RouterModule, Routes} from '@angular/router';
import {FiltergroupGridComponent} from './components/grid/filtergroup-grid.component';
import {permission} from './filtergroup-grid.permission';

const APP_ROUTES: Routes = [
  {
    path: 'module/questionnaire/filtergroups',
    component: FiltergroupGridComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: true,
      needsPermissions: [
        permission.MYMEDAX_FILTERGROUP_CREATE,
        permission.MYMEDAX_FILTERGROUP_READ,
        permission.MYMEDAX_FILTERGROUP_UPDATE,
        permission.MYMEDAX_FILTERGROUP_DELETE
      ]
    }
  }
];

export const routing = RouterModule.forRoot(APP_ROUTES);
