declare const $: any;
declare const document: any;

export const dragulaScroll = function() {
  // use document and window as container
  let container = $(document);
  let win = $(window);

  /**
   * When the editor is maximized, we have to use the editor element instead of the document element,
   * as it is set to overlay the document. Thus scrolling the document would not really make any sense ;p
   */
  if ($('.qa-editor.maximized').length) {
    container = $('.qa-editor.maximized');
    win = $('.qa-editor.maximized');
  }

  /**
   * Registers a handler for the "mouse move" event. Scrolls the editor by the mouse position
   */
  $(document).mousemove(function (e) {
    const height = win.height();
    const distance = (e.clientY - height / 2) * 0.1;
    container.scrollTop(distance + container.scrollTop());
  });
};

export const dragulaScrollStop = function() {
  $(document).unbind('mousemove');
};
