import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {QRCodeModule} from 'angularx-qrcode';

import {LayoutService} from '@ngmedax/layout';
import {Select2Module} from '@ngmedax/select2';

import {routing} from './device.routing';
import {NAVIGATION} from './device.navigation';
import {DeviceGridComponent} from './components/device-grid.component';
import {DeviceService} from './services/device.service';
import {DeviceFormComponent} from './components/device-form.component';
import {AuthUriService} from './services/auth-uri.service';
import {InitDeviceQrCodeComponent} from './components/init-device-qr-code.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    Select2Module,
    QRCodeModule,
    NgbModule,
    routing
  ],
  declarations: [
    DeviceGridComponent,
    DeviceFormComponent,
    InitDeviceQrCodeComponent,
  ],
  exports: [
    InitDeviceQrCodeComponent
  ],
  providers: [
    AuthUriService,
    DeviceService
  ]
})
export class DeviceModule {
  public constructor(private layoutService: LayoutService) {
    layoutService.addMenuEntry(NAVIGATION);
  }
}
