import {Component, Input, Optional} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {TRANSLATION_EDITOR_SCOPE} from '../../../../../constants';
import {KEYS} from '../../../../../translation-keys';


// hack to inject decorator declarations. must occur before class declaration!
export interface VariablesModalComponent extends Translatable {}

@Component({
  selector: 'app-qa-variables-modal',
  templateUrl: './variables-modal.component.html'
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class VariablesModalComponent {
  @Input() container: Questionnaire.Container;
  @Input() disabledScopes: {[scopeName: string]: true} = {};

  constructor(
    @Optional() private translationService: TranslationService,
    public activeModal: NgbActiveModal
  ) {
  }
}
