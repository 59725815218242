<form [formGroup]="form" class="mt-1">
  <ng-template #tipContent let-tooltipMsg="tooltipMsg">{{tooltipMsg}}</ng-template>
  <div class="btn-toolbar justify-content-between" *ngFor="let externVarName of getExternVariableNames(false)">
    <div class="input-group mt-1 mb-1" [ngClass]="{'dirty-entry': form.get(externVarName).dirty}" padding>

      <div class="input-group">
        <span class="input-group-addon">Name</span>
        <input
          [value]="externVarName"
          id="{{externVarName}}"
          name="{{externVarName}}"
          [formControlName]='externVarName'
          [ngbTooltip]="tipContent" triggers="manual" #tt="ngbTooltip"
          (change)="onCheckForTooltip(tt, form.get(externVarName))"
          (keyup)="onCheckForTooltip(tt, form.get(externVarName))"
          class="form-control"
          #externInputEl
        >
      </div>

      <div class="input-group ml-2">
        <span class="input-group-addon height-fix">{{_(KEYS.EDITOR.QUESTIONNAIRE_TYPE)}}</span>
        <select class="form-control qa-custom-select" [formControlName]='externVarName + "Format"' (change)="onResetDefault(externVarName)"
        >
          <option value="text">{{_(KEYS.EDITOR.TYPE_STRING)}}</option>
          <option value="numeric">{{_(KEYS.EDITOR.TYPE_NUMERIC)}}</option>
          <option value="date">{{_(KEYS.EDITOR.TYPE_DATE)}}</option>
        </select>
      </div>

      <div class="input-group ml-2">
        <span class="input-group-addon">Default</span>
        <input
          [type]="getInputType(externVarName)"
          [formControlName]='externVarName + "Default"'
          [ngbTooltip]="tipContent" triggers="manual" #ttd="ngbTooltip"
          (change)="onCheckForTooltip(ttd, form.get(externVarName + 'Default'))"
          (keyup)="onCheckForTooltip(ttd, form.get(externVarName + 'Default'))"
          class="form-control default-value"
        >
      </div>

      <button
        class="btn btn-success ml-2"
        [disabled]="(form.get(externVarName).errors || form.get(externVarName + 'Default').errors || !form.dirty)  ? true : null"
        (click)="onEdit(externInputEl.value, externVarName)"
      >
        <i class="fa fa-save"></i>
      </button>
      <button class="btn btn-primary ml-1" (click)="onUndo(externVarName)">
        <i class="fa fa-undo"></i>
      </button>
      <button class="btn btn-danger ml-1" (click)="onRemove(externVarName)">
        <i class="fa fa-trash"></i>
      </button>
    </div>
  </div>
</form>

<div class="input-group mt-2 mb-2">
  <span class="input-group-addon">{{_(KEYS.EDITOR.ADD_EXTERN_VARIABLE)}}</span>
  <button
    class="btn input-group-addon add-btn btn-success add-btn"
    (click)="onAdd()">
    <i class="fa fa-plus"></i>
  </button>
</div>
