import {CommonModule} from '@angular/common';
import {NgModule, Optional} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {NavigationEnd, Router} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {LayoutService} from '@ngmedax/layout';
import {ConfigService} from '@ngmedax/config';
import {ErrorService} from '@ngmedax/error';

import {configKeys} from './questionnaire-group.config-keys';
import {routing} from './questionnaire-group.routing';
import {NAVIGATION} from './questionnaire-group.navigation';
import {TruncatePipe} from './pipes/truncate.pipe';
import {DatexPipe} from './pipes/datex.pipe';
import {QuestionnaireGroupService} from './services/questionnaire-group.service';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {TRANSLATION_GRID_SCOPE} from '../../constants';
import {KEYS} from '../../translation-keys';
import {filter} from 'rxjs/operators';
import {QuestionnaireGroupGridComponent} from './components/grid/questionnaire-group-grid';
import {QuestionnaireGroupCrudComponent} from './components/crud/questionnaire-group-crud.component';
import {QuestionnaireSelectModule} from '@ngmedax/questionnaire-select';


// hack to inject decorator declarations. must occur before class declaration!
export interface QuestionnaireGroupModule extends Translatable {}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    QuestionnaireSelectModule,
    routing
  ],
  declarations: [
    QuestionnaireGroupGridComponent,
    QuestionnaireGroupCrudComponent,
    TruncatePipe,
    DatexPipe
  ],
  exports: [],
  entryComponents: [
    QuestionnaireGroupCrudComponent
  ],
  providers: [
    QuestionnaireGroupService
  ]
})
@Translatable({scope: TRANSLATION_GRID_SCOPE, keys: KEYS})
export class QuestionnaireGroupModule {
  public constructor(
    private router: Router,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private errorService: ErrorService,
    @Optional() private translationService: TranslationService,
  ) {
    layoutService.addMenuEntry(NAVIGATION);

    // subscribe to navigation end event
    const subscription = router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const groupApiUri = this.configService.get(configKeys.QUESTIONNAIRE_GROUP_URI_CONFIG_KEY);

        if (!groupApiUri) {
          // unsubscribe to prevent redirect loop
          subscription.unsubscribe();

          this.showErrorMessage();
        }
      });
  }

  /**
   * Adds a "questionnaire group api url not found" message to the error service and redirects to the error page.
   */
  private showErrorMessage() {
    // uri for the error message
    const uri = 'questionnaire-group-api-uri-not-configured';

    // title that we will see on the error page
    const title = this._(KEYS.GRID.CONFIG_ERROR);

    // message that we will see on the error page
    const message = this._(KEYS.GRID.ERROR_MISSING_MANDATORY_CONFIG) + '<br/><br/>';

    // example config object that we will se on the error page
    const exampleObject = {
      'apis': {
        'questionnaire': {
          'group': {
            'uri': 'https://some.tld/api'
          }
        }
      }
    };

    // add error message and redirect to error page
    // note: error service will only redirect when we are not already on this error page
    this.errorService
      .addErrorMessage(uri, title, message, exampleObject)
      .navigateToError(uri);
  }
}
