import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PatientModule} from '@ngmedax/patient';

import {routing} from './web-allocator.routing';
import {PatientCrudComponent} from './components/patient-crud.component';
import {NavigationStart, Router} from '@angular/router';
import {WebAllocatorGuard} from './guard/web-allocator.guard';
import {LayoutService} from '../../../layout';
import {filter} from 'rxjs/operators';


declare const $: any;

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PatientModule,
    NgbModule,
    routing
  ],
  declarations: [
    PatientCrudComponent
  ],
  exports: [],
  providers: [
    WebAllocatorGuard
  ]
})
export class WebAllocatorModule {
  public constructor(
    webAllocatorGuard: WebAllocatorGuard,
    layoutService: LayoutService,
    router: Router
  ) {
    // subscribe to navigation end event
    router.events
      .pipe(<any>filter((event:Event) => event instanceof NavigationStart))
      .subscribe(() => {
        layoutService.registerGuard(webAllocatorGuard, 500);

        /**
         * Hack to hide header and navigation on web allocator module. Not pretty but it works
         */
        if (location.href.match('/module/web-allocator/') && typeof $ === 'function') {
          $('#sidebar-wrapper').hide();
          $('.content-header').hide();
          $('.header').hide();

          setTimeout(() => {
            $('#sidebar-wrapper').show();
            $('.content-header').show();
            $('.header').show();
          }, 2000);
        }
      });
  }
}
