<div class="qa-matrix-options">
  <div class="qa-matrix-x card">
    <div class="card-body">
      <div class="card-title">
        <h5>
          <img src="/assets/images/insert-row.svg" class="qa-insert-row">
          &nbsp;{{_(KEYS.EDITOR.ROWS)}}
        </h5>
      </div>

      <div *ngFor="let element of rows; let position = index;">
        <div class="input-group mb-1">
          <button class="btn btn-success qa-input-position mr-1">
            {{position + 1}}
          </button>

          <div class="btn-group ml-1 mr-2">
            <button class="btn btn-primary" (click)="onRowPositionChange(position, position - 1)">
              <i class="fa fa-arrow-up"></i>
            </button>

            <button class="btn btn-primary" (click)="onRowPositionChange(position, position + 1)">
              <i class="fa fa-arrow-down"></i>
            </button>
          </div>

          <ng-container *ngIf="isScaleMode">
            <div class="qa-fake-input scale-mode">
              <div class="qa-mt-04" [froalaEditor]="answerEditorOptions" [(froalaModel)]="element.title[locale]" (blur)="updateElement()"></div>
            </div>
            <div class="qa-fake-input scale-mode">
              <div class="qa-mt-04" [froalaEditor]="answerEditorOptions" [(froalaModel)]="element.appendix[locale]" (blur)="updateElement()"></div>
            </div>
          </ng-container>

          <ng-container *ngIf="!isScaleMode">
            <div class="qa-fake-input">
              <div class="qa-mt-04" [froalaEditor]="answerEditorOptions" [(froalaModel)]="element.title[locale]" (blur)="updateElement()"></div>
            </div>
          </ng-container>

          <button
            class="btn btn-primary ml-2"
            ngbTooltip="{{_(KEYS.EDITOR.VARIABLES)}}"
            (click)="onVariablesModal(rows[position])"
          >
            <i class="fa fa-code"></i>
          </button>

          <button class="btn btn-danger ml-1" (click)="onDeleteRow(position)">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>

      <div class="btn-group mt-2">
        <button class="btn btn-sm btn-success" (click)="onAddRow()">
          <i class="fa fa-plus"></i> {{_(KEYS.EDITOR.ADD_ROW)}}
        </button>
      </div>
    </div>
  </div>

  <div class="qa-matrix-y card">
    <div class="card-body">
      <div class="card-title">
        <h5>
          <h5>
            <img src="/assets/images/insert-column.svg" class="qa-insert-column">
            &nbsp;{{_(KEYS.EDITOR.COLUMNS)}}
          </h5>
        </h5>
      </div>

      <div *ngFor="let element of cols; let position = index;">
        <div class="input-group mb-1">
          <button class="btn btn-success qa-input-position mr-1">
            {{position + 1}}
          </button>

          <div class="btn-group ml-1 mr-2">
            <button class="btn btn-primary" (click)="onColPositionChange(position, position - 1)">
              <i class="fa fa-arrow-up"></i>
            </button>

            <button class="btn btn-primary" (click)="onColPositionChange(position, position + 1)">
              <i class="fa fa-arrow-down"></i>
            </button>
          </div>

          <div class="qa-fake-input">
            <div class="qa-mt-04" [froalaEditor]="answerEditorOptions" [(froalaModel)]="element.title[locale]" (blur)="updateElement()"></div>
          </div>

          <button class="btn btn-danger ml-1" (click)="onDeleteColumn(position)">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>

      <div class="btn-group mt-2">
        <button class="btn btn-sm btn-success" (click)="onAddColumn()">
          <i class="fa fa-plus"></i> {{_(KEYS.EDITOR.ADD_COLUMN)}}
        </button>
      </div>
    </div>
  </div>

  <div class="qa-matrix-display-type input-group">
    <span class="input-group-addon">{{_(KEYS.EDITOR.ANSWERS_PER_ROW)}}</span>
    <select class="form-control qa-custom-select" [(ngModel)]="question.displayType">
      <option value="radio">{{_(KEYS.EDITOR.SINGLE_CHOICE)}}</option>
      <option value="checkbox">{{_(KEYS.EDITOR.MULTIPLE_CHOICE)}}</option>
    </select>
  </div>

  <div class="qa-matrix-preview card" *ngIf="cols.length && rows.length">
    <div class="card-body">
      <div class="card-title">
        <h5>
          <h5>
            <img src="/assets/images/table.svg" class="qa-insert-column">
            &nbsp;{{_(KEYS.EDITOR.PREVIEW)}}
          </h5>
        </h5>
      </div>

      <div style="overflow-x: auto;">
        <table class="table">
          <thead>
          <tr>
            <th></th>
            <ng-container *ngFor="let element of cols; let posCol = index;">
              <th *ngIf="element.title[locale]; else qaColPlaceholder">{{element.title[locale] | striptags | htmldecode}}</th>
              <ng-template #qaColPlaceholder>
                <th class="qa-color-gray">{{_(KEYS.EDITOR.COLUMN)}} {{posCol + 1}}</th>
              </ng-template>
            </ng-container>
          </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let row of this.question.elements; let posRow = index;">
              <tr>
                <th *ngIf="row.title[locale]; else qaRowPlaceholder">{{row.title[locale] | striptags | htmldecode}}</th>
                <ng-template #qaRowPlaceholder>
                  <th class="qa-color-gray">{{_(KEYS.EDITOR.ROW)}} {{posRow + 1}}</th>
                </ng-template>
                <ng-container *ngFor="let col of row.elements;">
                  <td>
                    <button
                      class="btn btn-sm btn-sx btn-primary mr-1"
                      ngbTooltip="{{_(KEYS.EDITOR.VARIABLES)}}"
                      (click)="onVariablesModal(col, {inline: false, upload: true})"
                    >
                      <i class="fa fa-code"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-sx btn-primary"
                      ngbTooltip="{{_(KEYS.EDITOR.SCORING)}}"
                      (click)="onScoringModal(col)"
                    >
                      <i class="fa fa-sort-numeric-asc"></i>
                    </button>
                  </td>
                </ng-container>
                <td *ngIf="row.appendix && row.appendix[locale]">{{row.appendix[locale] | striptags | htmldecode}}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
