import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {NavigationEnd, Router} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {LayoutModule, LayoutService} from '@ngmedax/layout';
import {ConfigService} from '@ngmedax/config';
import {ErrorService} from '@ngmedax/error';

import {routing} from './filtergroup-grid.routing';
import {NAVIGATION} from './filtergroup-grid.navigation';
import {FiltergroupGridComponent} from './components/grid/filtergroup-grid.component';
import {FiltergroupGridService} from './services/filtergroup-grid.service';
import {AuthUriService} from './services/auth-uri.service';
import {HttpClientModule} from '@angular/common/http';
import {filter} from 'rxjs/operators';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    LayoutModule,
    routing
  ],
  declarations: [
    FiltergroupGridComponent
  ],
  exports: [],
  providers: [
    FiltergroupGridService,
    AuthUriService
  ]
})
export class FiltergroupGridModule {
  public constructor(
    private router: Router,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private errorService: ErrorService
  ) {
    layoutService.addMenuEntry(NAVIGATION);

    // subscribe to navigation end event
    const subscription = router.events
      .pipe(<any>filter((event:Event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const filtergroupApiUri = this.configService.get('apis.filtergroup.uri');

        if (!filtergroupApiUri) {
          // unsubscribe to prevent redirect loop
          subscription.unsubscribe();
          this.showErrorMessage();
        }
      });
  }

  /**
   * Adds a "filtergroup api url not found" message to the error service and redirects to the error page.
   */
  private showErrorMessage() {
    // uri for the error message
    const uri = 'filtergroup-api-uri-not-configured';

    // title that we will see on the error page
    const title = 'Konfigurationsfehler';

    // message that we will see on the error page
    const message =
      `Die Konfiguration enthält keine API URL zum Abrufen von Fachgruppen.
      Bitte konfigurieren sie diese in der Konfigurationsdatei:
      <b>/assets/config/config.json</b><br/><br/>`;

    // example config object that we will se on the error page
    const exampleObject = {
      'apis': {
        'filtergroup': {
          'api': 'https://some.tld/api'
        }
      }
    };

    // add error message and redirect to error page
    // note: error service will only redirect when we are not already on this error page
    this.errorService
      .addErrorMessage(uri, title, message, exampleObject)
      .navigateToError(uri);
  }
}
