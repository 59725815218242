import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {Translatable} from '@ngmedax/translation';

import {PdfFormService} from './services/pdf-form.service';
import {TRANSLATION_DEFAULT_SCOPE} from '../../constants';
import {KEYS} from '../../translation-keys';
import {PdfFormSelectComponent} from './components/select/pdf-form-select.component';
import {Select2Module} from '@ngmedax/select2';
import {DragulaModule} from 'ng2-dragula';

export interface PdfFormSelectModule extends Translatable {};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    Select2Module,
    DragulaModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModule
  ],
  declarations: [
    PdfFormSelectComponent,
  ],
  exports: [
    PdfFormSelectComponent
  ],
  entryComponents: [
  ],
  providers: [
    PdfFormService
  ]
})

@Translatable({scope: TRANSLATION_DEFAULT_SCOPE, keys: KEYS})
export class PdfFormSelectModule {
  constructor() {
  }
}
