import {ChangeDetectorRef, Component, OnInit, Optional} from '@angular/core';
import {DATE_FORMAT_YMD, DATE_FORMAT_YMD_HM, Translatable, TranslationService} from '@ngmedax/translation';
import {PermissionService} from '@ngmedax/permission';
import {LayoutService, EventService} from '@ngmedax/layout';
import {MediaCenterService} from '@ngmedax/asset';

import {SurveyGridService} from '../services/survey-grid.service';
import {TRANSLATION_GRID_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';
import {Survey} from '../../../types';
import {DateFormatService} from '../../../../translation';
import {ConfigService} from '@ngmedax/config';
import {SurveyHistoryComponent} from './survey-history.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as pako from 'pako';
import {Route, Router} from '@angular/router';
import {permission} from '../survey-grid.permission';


// hack to inject decorator declarations. must occur before class declaration!
export interface SurveyGridComponent extends Translatable {}

@Component({
  selector: 'app-survey-grid',
  templateUrl: './survey-grid.component.html',
  styleUrls: ['./survey-grid.component.css'],
})
@Translatable({scope: TRANSLATION_GRID_SCOPE, keys: KEYS})
export class SurveyGridComponent implements OnInit {
  public isSearchCollapsed = false;
  public isGridCollapsed = false;
  public gridPageNumber = 1;
  public displayPerPage = 25;
  public surveys: Survey[] = [];
  public canEditPatient = false;
  public filter: {patient?: string, status?: string} = {patient: '', status: ''};
  public total = 0;

  /**
   * Locale for surveys. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Timeout for filter change event
   */
  private filterChangeTimeout: any = null;

  /**
   * Timeout for show preloader event
   */
  private showPreloaderTimeout: any = null;

  /**
   * Assets base uri
   */
  private assetsBaseUri = '';

  /**
   * Mobile frontend uri
   */
  private mobileFrontendUri: string;

  /**
   * Mobile api uri
   */
  private mobileApiUri: string;

  /**
   * List of all routes
   */
  private routeList: {[url: string]: Route} = {};

  /**
   * date format to use in grid
   * @type {string}
   */
  public get dateFormat() {
    const format = DATE_FORMAT_YMD_HM.replace(/YYYY/, 'YY');
    return this.getDateFormat(format);
  };

  /**
   * Loads surveys
   */
  public ngOnInit() {
    this.layoutService.showPreloader();
    this.loadSurveys();

    if (this.permissionService) {
      const routeData = {
        needsLogin: true,
        needsPermissions: [
          permission.MYMEDAX_PATIENT_UPDATE,
          permission.MYMEDAX_PATIENT_QUESTIONNAIRE_READ,
          permission.MYMEDAX_PATIENT_QUESTIONNAIRE_ASSIGN,
          permission.MYMEDAX_PATIENT_QUESTIONNAIRE_UNASSIGN
        ]
      };

      const result = this.permissionService.isAllowedByRouteData(routeData);
      this.canEditPatient = !!result.allowed;
    }
  }

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private permissionService: PermissionService,
    @Optional() private translationService: TranslationService,
    @Optional() private dateFormatService: DateFormatService,
    @Optional() private mediaCenterService: MediaCenterService,
    private layoutService: LayoutService,
    private eventService: EventService,
    private surveyGridService: SurveyGridService,
    private ref: ChangeDetectorRef,
    private configService: ConfigService,
    private router: Router,
    private modal: NgbModal
  ) {
    this.mobileApiUri = this.configService.get('apis.mobile.uri');
    this.mobileFrontendUri = this.configService.get('mobile.frontend.uri') || '/app';
    this.assetsBaseUri = this.configService.get('apis.submission.assets.cdn');

    for (const routeConfig of router.config) {
      this.routeList[routeConfig.path] = routeConfig;
    }
  }

  /**
   * Event handler for when paging changes. Triggers change detection and loads surveys
   */
  public onPagingChange() {
    // trigger change detection
    this.ref.detectChanges();
    this.ref.markForCheck();

    this.loadSurveys();
  }

  /**
   * Event handler for when filter changes. Loads surveys
   */
  public onFilterChange() {
    clearTimeout(this.filterChangeTimeout);
    this.filterChangeTimeout = setTimeout(() => this.loadSurveys(), 1000);
  }

  /**
   * Downloads export file by given file name
   *
   * @param {string} exportFileName
   */
  public onDownload(exportFileName: string) {
    this.assetsBaseUri && window.open(`${this.assetsBaseUri}/${exportFileName}`, '_blank');
  }

  /**
   * Opens given link
   *
   * @param {string} link
   */
  public onOpenLink(link: string) {
    link && window.open(link, '_blank');
  }

  /**
   * Opens media center modal for submission assets
   *
   * @param {{bucketId: string}} submission
   */
  public onOpenMediaCenterModal(submission: {bucketId?: string}) {
    this.mediaCenterService && this.mediaCenterService.openModal(
      {bucketId: submission.bucketId, disableUpload: true, enableZipDownload: true});
  }

  /**
   * Opens survey history
   *
   * @param {Survey} survey
   */
  public onOpenHistory(survey: Survey) {
    const modalRef = this.modal.open(SurveyHistoryComponent, <any>{size: 'fullscreen', backdrop: 'static'});
    survey && modalRef.componentInstance.load(survey.id);
  }

  public onEditPatient(survey: Survey) {
    if (!this.canEditPatient || !survey || !survey.client || !survey.client.uid) {
      return;
    }

    this.eventService.emitEvent({type: 'openPatientCrud', payload: survey.client.uid});
  }

  /**
   * Event handler for resubmitting a survey
   *
   * @param {Survey} survey
   */
  public async onResubmit(survey: Survey) {
    const shouldResubmit = await <any>confirm(this._(KEYS.GRID.CONFIRM_RESUBMIT));

    if (!shouldResubmit) {
      return;
    }

    try {
      this.layoutService.showPreloader();
      await this.surveyGridService.resubmitSurvey(survey.id);
      this.layoutService.hidePreloader();
      this.onPagingChange();
      alert(this._(KEYS.GRID.SUCCESS_RESUBMITTING_SURVEY));
    } catch (error) {
      this.layoutService.hidePreloader();
      alert(this._(KEYS.GRID.ERROR_RESUBMITTING_SURVEY));
      console.error(error);
    }
  }

  public getMobileFrontendContinueLink(survey: Survey) {
    if (!survey) {
      return;
    }

    if (survey.appDataLink) {
      return survey.appDataLink;
    }

    if (<any>survey.status !== 'saved') {
      return '#';
    }

    const encodeUri = false;
    const appDataId = survey.appDataId;
    const mobileFrontendUri = this.mobileFrontendUri;
    const queryString = `appDataId=${appDataId}&locale=${this.locale}&serverUrl=${this.mobileApiUri}`;
    let baseUrl = (mobileFrontendUri.match(/\/$/) ? mobileFrontendUri : mobileFrontendUri + '/');
    !baseUrl.match(/^htt/) && (baseUrl = `${window.location.protocol}//${window.location.host}${baseUrl}`);

    const encode = encodeUri ? encodeURIComponent : (u) => u;

    if (window && window.btoa) {
      try {
        const enc = queryString.split('').reduce((all, str) => all += String.fromCharCode(23 ^ str.charCodeAt(0)), '');
        const gzipped = pako.deflate(enc, {to: 'string'});
        const base64 = window.btoa(gzipped);
        return encode(`${baseUrl}?c=${base64}`);
      } catch (error) {
        // we can ignore this
      }
    }

    const url = encode(`${baseUrl}?${queryString}`);
    survey.appDataLink = url;
    return url;
  }

  public getMobileFrontendRestartLink(survey: Survey): string {
    const encodeUri = false;
    const mobileApiUri = this.mobileApiUri;
    const mobileFrontendUri = this.mobileFrontendUri;

    const pairCode = `mymedax|serverurl|${mobileApiUri}`;
    const assignCode = `mymedax|patient|${survey.uid}`;

    let baseUrl = (mobileFrontendUri.match(/\/$/) ? mobileFrontendUri : mobileFrontendUri + '/');
    !baseUrl.match(/^htt/) && (baseUrl = `${window.location.protocol}//${window.location.host}${baseUrl}`);
    const queryString = `autoPairMode=true&pairCode=${pairCode}&assignCode=${assignCode}&locale=${this.locale}`;

    const encode = encodeUri ? encodeURIComponent : (u) => u;

    if (window && window.btoa) {
      try {
        const enc = queryString.split('').reduce((all, str) => all += String.fromCharCode(23 ^ str.charCodeAt(0)), '');
        const gzipped = pako.deflate(enc, {to: 'string'});
        const base64 = window.btoa(gzipped);
        return encode(`${baseUrl}?c=${base64}`);
      } catch (error) {
        // we can ignore this
      }
    }

    return encode(`${baseUrl}?${queryString}`);
  }

  /**
   * Returns surveys
   *
   * @returns {Array}
   */
  public getSurveys(): Survey[] {
    return this.surveys;
  }

  /**
   * Converts mysql date to local ymd date
   *
   * @param {string} date
   * @returns {string}
   */
  public fromMySQLDate(date): string {
    return this.dateFormatService && date ? this.dateFormatService.fromMySqlDate(date, this.getDateFormat(DATE_FORMAT_YMD)) : date;
  }

  /**
   * Returns true if a survey is expired
   * @param survey
   */
  public isSurveyExpired(survey: Survey) {
    const now = <any>new Date().toJSON();
    const isDone = survey.status === 'signed' || survey.status === 'submitted';
    const isExpired = survey.validUntil && survey.validUntil < now;

    if (isDone) {
      return false;
    }

    return isExpired;
  }

  /**
   * Returns true if submission has valid exports
   */
  public hasValidExports(submission: any) {
    const exports = submission.exports;
    const isArray = Array.isArray(exports);
    const hasElements = isArray && exports.length > 0;
    const hasObjects = hasElements && typeof exports[0] === 'object';
    const hasExpectedFormat = hasObjects && !!exports[0].type && !!exports[0].fileName;

    return hasExpectedFormat;
  }

  private loadSurveys() {
    this.showPreloader();

    const filter = this.getQueryFilter();
    const opts = this.getQueryOpts();

    this.surveyGridService.loadSurveys(filter, opts)
      .then((result) => {
        this.surveys = result.rows;
        this.total = result.total;
        this.hidePreloader();
      })
      .catch(error => {
        this.hidePreloader();
        alert(this._(KEYS.GRID.ERROR_LOADING_SURVEYS));
        console.log(error);
      });
  }

  /**
   * Returns query filter by filter object
   */
  private getQueryFilter() {
    const filter: any = {};
    const like = (value) => ({ '$regex': value, '$options' : 'i' });
    const equal = (value) => ({ '$eq': value});

    this.filter.status && (filter.status = equal(this.filter.status));

    if (this.filter.patient) {
      const likeValue = like(this.filter.patient);
      filter['$or'] = ['firstName', 'lastName', 'birthDate', 'customerNr', 'caseNr', 'pseudoId', 'location', 'status', 'address']
        .map(key => ({[`client.${key}`]: likeValue}));
    }

    return filter;
  }

  /**
   * Returns query opts
   */
  private getQueryOpts() {
    return {limit: this.displayPerPage, offset: (this.gridPageNumber - 1) * this.displayPerPage}
  }

  /**
   * Shows preloader after 200ms
   */
  private showPreloader() {
    this.showPreloaderTimeout = setTimeout(() => this.layoutService.showPreloader(), 200);
  }

  /**
   * Hides preloader
   */
  private hidePreloader() {
    clearTimeout(this.showPreloaderTimeout);
    this.layoutService.hidePreloader();
  }

  /**
   * Finds and returns route data by given uri. Returns empty object if no route data found
   *
   * @returns {any}
   */
  private getRouteData(uri: string) {
    uri = uri.replace(/^\//, '');
    const route = this.routeList[uri];
    return (route && route.data) ? route : {};
  }
}
