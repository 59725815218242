import {Injectable} from '@angular/core';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import * as sha from 'js-sha256';

@Injectable()
export class QuestionnairePathHashService {

  /**
   * Recursively creates and adds path hashes to elements of given questionnaire. Also builds
   * questionnaire path hash map and returns it
   *
   * @param {Questionnaire | Questionnaire.Container} element
   * @param {Questionnaire.Accumulated.QuestionnairePathHashMap} pathHashMap
   * @param {string} path
   * @returns {Questionnaire.Accumulated.QuestionnairePathHashMap}
   */
  public addPathHashes(
    element: Questionnaire | Questionnaire.Container,
    pathHashMap: Questionnaire.Accumulated.QuestionnairePathHashMap = {},
    path: string = null
  ): Questionnaire.Accumulated.QuestionnairePathHashMap {

    if ((<Questionnaire>element).questions) {
      const questionnaire = <Questionnaire>element;

      for (const question of questionnaire.questions) {
        this.addPathHashes(question, pathHashMap);
      }

      return pathHashMap;
    }

    element = <Questionnaire.Container>element;

    if (!element.omitContainer) {
      path = path ? `${path}.${element.id}` : element.id;
    }

    // build and add path hash
    element.pathHash = path ? sha.sha256(path) : null;

    // for debugging only
    element.path = path;

    if (!element.omitContainer) {
      // add element to path hash map
      pathHashMap[element.pathHash] = element;
    }

    if (element.elements) {
      for (const subElement of element.elements) {
        subElement.parentHash = element.pathHash || element.parentHash;
        this.addPathHashes(subElement, pathHashMap, path);
      }
    }

    return pathHashMap;
  }
}
