import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ConfigService} from '@ngmedax/config';
import {RegistryService} from '@ngmedax/registry';
import {Patient, Questionnaire} from '../../../types';
import {configKeys} from '../patient.config-keys';


@Injectable()
export class PatientService {
  /**
   * Locale for patients. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  public constructor(
    private registryService: RegistryService,
    private configService: ConfigService,
    private http: HttpClient
  ) {
  }

  /**
   * Loads patients
   *
   * @param filter: any
   * @returns {Promise<Patient[]>}
   */
  public loadPatients(filter?: Patient.Filter, opts?: Patient.Opts): Promise<{rows: Patient[], total: number}> {
    const query: any = {filter: JSON.stringify(filter) || '{}', opts: JSON.stringify(opts) || '{}'};
    const basePath = `${this.getPatientApiUrl()}?${decodeURI(new URLSearchParams(query).toString())}`;

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers: this.getAuthHeaders()})
        .subscribe((patient: any) => {
            const patients = patient.patient || patient.rows || patient || [];
            const total = patient.total || 0;

            // TODO: sorting should be done on server side!
            if (Object.prototype.toString.call(patients) === '[object Array]') {
              patients.sort((a , b) => (`${a.lastName} ${a.firstName}` < `${b.lastName} ${b.firstName}`) ? -1 : 1);
            }

            resolve({rows: patients, total});
          },
          error => {
            reject(error);
          });
    });
  }

  /**
   * Helper method to load patient by id.
   *
   * @param {string} patientId
   * @returns {Promise<Patient>}
   */
  public loadPatient(patientId: string): Promise<Patient> {
    const basePath = this.getPatientApiUrl(`/${patientId}`);

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers: this.getAuthHeaders()})
        .subscribe(
          (patient: any) => resolve(patient.patient || patient || []),
          error => reject(error)
        );
    });
  }

  /**
   * Creates or updates a given patient
   *
   * @param {Patient} patient
   * @returns {Promise<Patient>}
   */
  public savePatient(patient: Patient): Promise<Patient> {
    if (patient.uid) {
      return this.updatePatient(patient);
    }

    return this.createPatient(patient);
  }

  /**
   * Updates the patient
   *
   * @param {Patient} patient
   * @returns {Promise<Patient>}
   */
  public createPatient(patient: Patient): Promise<Patient> {
    const basePath = this.getPatientApiUrl();

    return new Promise((resolve, reject) => {
      this.http.post(basePath, patient, {headers: this.getAuthHeaders()})
        .subscribe(
          (response: any) => resolve(<Patient>(response.client || response.patient || response)),
          error => reject(error));
    });
  }

  /**
   * Updates the patient
   *
   * @param {Patient} patient
   * @returns {Promise<Patient>}
   */
  public updatePatient(patient: Patient): Promise<Patient> {
    const basePath = this.getPatientApiUrl(`/${patient.uid}`);

    return new Promise((resolve, reject) => {
      this.http.put(basePath, patient, {headers: this.getAuthHeaders()})
        .subscribe(
          () => resolve(patient),
          error => reject(error));
    });
  }

  /**
   * Deletes a patient
   *
   * @param {Patient} patient
   * @returns {Promise<any>}
   */
  public deletePatient(patient: Patient): Promise<any> {
    const basePath = this.getPatientApiUrl(`/${patient.uid}`);

    return new Promise<void>((resolve, reject) => {
      this.http.delete(basePath, {headers: this.getAuthHeaders()})
        .subscribe(
          () => resolve(),
          error => reject(error));
    });
  }

  /**
   * Assigns questionnaires to patient
   * Questionnaires must be an array of questionnaire id's
   *
   * @param {Patient} patient
   * @param {string[]} questionnaires
   */
  public assignQuestionnaires(patient: Patient, questionnaires: string[]): Promise<Questionnaire[]> {
    const basePath = this.getPatientApiUrl(`/${patient.uid}/questionnaire`);

    return new Promise((resolve, reject) => {
      this.http.post(basePath, questionnaires, {headers: this.getAuthHeaders()})
        .subscribe((response: any) => {
          resolve(response.questionnaire || response.rows || response);
        },
        error => {
          reject(error);
        });
    });
  }

  /**
   * Method to load questionnaires.
   *
   * @returns {Promise<Questionnaire[]>}
   */
  public loadQuestionnaires(): Promise<Questionnaire[]> {
    const basePath = this.getQuestionnaireApiUrl() + '?order=meta.title.de_DE&active=true';

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers: this.getAuthHeaders()})
        .subscribe((response: any) => {
            const questionnaires = response.questionnaire || response.rows || response;
            resolve(questionnaires);
          },
          error => {
            reject(error);
          });
    });
  }

  /**
   * Method to check if survey mail is configured
   *
   * @returns {Promise<boolean>}
   */
  public isSurveyMailConfigured(): Promise<boolean> {
    const basePath = this.getSurveyMailApiUrl('/configured');

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers: this.getAuthHeaders()})
        .subscribe((response: any) => resolve(response.success || false), error => reject(error));
    });
  }

  /**
   * Fetches number of submitted surveys for current month
   *
   * @returs {Promise<number>}
   */
  public async getNumSubmittedSurveysForCurrentMonth(): Promise<number> {
    const url = this.getPatientApiUrl('/survey/submitted/count');

    return new Promise<number>((resolve, reject) => {
      this.http.get(url, {headers: this.getAuthHeaders()}).subscribe(
        (response: {count: number}) => resolve(response.count || 0),
        error => reject(error)
      );
    });
  }

  /**
   * Returns api url for questionnaire
   *
   * @param {string} suffix
   * @returns {string}
   */
  private getQuestionnaireApiUrl(suffix: string = null): string {
    return this.buildUrl(configKeys.QUESTIONNAIRE_URI_CONFIG_KEY, suffix);
  }

  /**
   * Returns api url for patient
   *
   * @param {string} suffix
   * @returns {string}
   */
  private getPatientApiUrl(suffix: string = null): string {
    return this.buildUrl(configKeys.PATIENT_URI_CONFIG_KEY, suffix);
  }

  /**
   * Returns api url for survey mail
   *
   * @param {string} suffix
   * @returns {string}
   */
  private getSurveyMailApiUrl(suffix: string = null): string {
    return this.buildUrl(configKeys.SURVEY_MAIL_URI_CONFIG_KEY, suffix);
  }

  /**
   * Returns url for config key. Adds auth information to url path when api is not deprecated
   *
   * @param {string} configKey
   * @returns {any}
   */
  private buildUrl(configKey: string, suffix = null) {
    let uri = this.configService.get(configKey);

    if (suffix) {
      uri = `${uri}${suffix}`;
    }

    return uri;
  }

  /**
   * Returns auth headers by auth token and tenant id
   */
  private getAuthHeaders(): any {
    const headers: any = {};
    const authToken = this.registryService.get(configKeys.SESSION_AUTH_TOKEN);
    const tenantId = this.registryService.get(configKeys.SESSION_TENANT_ID);

    if (authToken) {
      headers['X-Api-Token'] = authToken;
    }

    if (tenantId) {
      headers['X-Api-TenantId'] = `${tenantId}`;
    }

    return headers;
  }
}
