import {Injectable, Optional} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {DATE_FORMAT_YMD, DateFormatService, TranslationService, Translatable} from '@ngmedax/translation';
import {PatientService} from '@ngmedax/patient';
import {TRANSLATION_CRUD_SCOPE} from '../../constants';

// hack to inject decorator declarations. must occur before class declaration!
export interface WebAllocatorGuard extends Translatable {}

@Injectable()
@Translatable({scope: TRANSLATION_CRUD_SCOPE})
export class WebAllocatorGuard implements CanActivate {

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    @Optional() private dateFormatService: DateFormatService,
    private patientService: PatientService,
    private router: Router
  ) {
  }

  /**
   * Returns ymd date format. E.g: MM/DD/YYYY
   *
   * @returns {string}
   */
  public getLocalDateFormat(): string {
    return this.getDateFormat(DATE_FORMAT_YMD);
  }

  /**
   * Converts local ymd date to mysql date format
   *
   * @param {string} date
   * @returns {string}
   */
  public toMySqlDate(date: string): string {
    return this.dateFormatService ? this.dateFormatService.toMySqlDate(date, this.getLocalDateFormat()) : date;
  }

  /**
   * Detects if a patient sent by query param was already created before.
   * In such a case, the guard will redirect to a route, which loads the already
   * created patient. This way we will make sure, that we don't create duplicates!
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Promise<boolean>}
   */
  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const url = '/' + route.url.join('/');

    if (!url.match('/module/web-allocator/crud')) {
      return true;
    }

    // no query params and/or no auth token? nothing to do!
    if (!route.queryParams || !route.params || !route.params.authToken) {
      return true;
    }

    // patient id given? nothing to do!
    if (route.params.id) {
      return true;
    }

    const authToken = route.params.authToken;
    const {firstName, lastName, customerNr} = route.queryParams;
    let {birthDate} = route.queryParams;

    // mandatory patient props missing? can't search for a matching patient
    if (!firstName || !lastName || !birthDate || !customerNr) {
      return true;
    }

    // not a mysql date format (YYYY-MM-DD) ?
    if (!birthDate.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/)) {
      // assume date in current locale and convert to mysql date
      birthDate = this.toMySqlDate(birthDate);
    }

    try {
      const result = await this.patientService.loadPatients({firstName, lastName, birthDate, customerNr});
      const patients = result.rows;

      // no patients found? then there's nothing more to do
      if (!patients || !patients.length || typeof patients[0] !== 'object' || !patients[0].uid) {
        return true;
      }

      // more than one patient found? we don't know which one to choose!
      if (patients.length > 1) {
        console.error(
          `web-allocator.guard: Found ${patients.length} patients by given query params. Unable to redirect to unique patient!`
        );
        return true;
      }

      const patient = patients[0];

      // found patient does not match patient props from query params? server seems to not support filtering!
      if (patient.firstName !== firstName || patient.lastName !== lastName || patient.customerNr !== customerNr) {
        console.error(
          'web-allocator.guard: Found a patient, but its data does not match. ' +
          'Api server seems not to support filtering. Please update api server!'
        );
        return true;
      }

      const navRoute = [
        'module',
        'web-allocator',
        'crud',
        patients[0].uid,
        authToken
      ];

      this.router.navigate(navRoute, {queryParams: route.queryParams}).then().catch();
      return false;
    } catch (error) {
      console.error(error);
    }

    return true;
  }
}
