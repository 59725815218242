import {VariableMapper} from '../../../types';
import {Injectable} from "@angular/core";

@Injectable()
export class VariableMapperService {
  private mappings: VariableMapper.Mappings;

  constructor(mappings: VariableMapper.Mappings) {
    this.mappings = mappings;
  }

  public generateTargetVariables(sourceVariables: {[key: string]: any}): VariableMapper.Output {
    const output: VariableMapper.Output = {};

    Object.entries(this.mappings).forEach(([key, mapping]) => {
      const value = sourceVariables[key];
      const targetVariables = this.generateTargetVariablesForMapping(mapping.mapping, value);
      Object.assign(output, targetVariables);
    });

    return output;
  }

  public generateSourceVariables(targetVariables: {[key: string]: any}): VariableMapper.Output {
    const output: VariableMapper.Output = {};

    Object.entries(targetVariables).forEach(([targetKey, targetValue]) => {
      Object.entries(this.mappings).forEach(([sourceKey, mapping]) => {
        const mappingObj = mapping.mapping.find(map => map.name === targetKey);
        if (mappingObj) {
          let sourceValue: any = null;
          if (mappingObj.if !== undefined) {
            // if mappingObj has 'if' set, find the corresponding value and set sourceValue
            const matchingCondition = mappingObj.if.find(cond => cond.value === targetValue);
            if (matchingCondition) {
              sourceValue = matchingCondition.set;
            } else if (mappingObj.else !== undefined) {
              // if 'if' is not satisfied, and 'else' is set, use 'else' as the source value
              sourceValue = mappingObj.else.set;
            }
          } else {
            // if 'if' is not set, set sourceValue to the targetValue
            sourceValue = targetValue;
          }
          if (sourceValue !== null) {
            output[sourceKey] = sourceValue;
          }
        }
      });
    });

    return output;
  }

  private generateTargetVariablesForMapping(mapping: VariableMapper.MappingObject[], value: any): VariableMapper.Output {
    const output: VariableMapper.Output = {};

    for (const map of mapping) {
      const {name, if: ifs, else: els} = map;
      if (ifs) {
        const matchedIf = ifs.find(cond => cond.set === value);
        if (matchedIf) {
          output[name] = matchedIf.value;
        } else if (els !== undefined) {
          output[name] = els.value;
        }
      } else {
        value && (output[name] = value);
      }
    }

    return output;
  }
}
