import {Component, Input, ChangeDetectorRef, Optional} from '@angular/core';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {MediaCenter} from '@ngmedax/asset';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {QuestionnaireEditorService} from '../../../services/questionnaire-editor.service';
import {QuestionnaireStateService} from '../../../services/questionnaire-state.service';
import {QuestionnaireMediaCenterService} from '../../../services/questionnaire-media-center.service';
import {TRANSLATION_EDITOR_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';


// hack to inject decorator declarations. must occur before class declaration!
export interface QuestionTypeCanvasComponent extends Translatable {}

@Component({
  selector: 'app-qa-question-canvas',
  templateUrl: './question-type-canvas.component.html',
  styleUrls: ['./question-type-canvas.component.css']
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class QuestionTypeCanvasComponent {
  @Input() question: Questionnaire.Container;

  /**
   * Locale for questionnaire. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    private mediaCenter: QuestionnaireMediaCenterService,
    private state: QuestionnaireStateService,
    private editor: QuestionnaireEditorService,
    private ref: ChangeDetectorRef,
  ) {
  }

  /**
   * Deleted bg image object of this question
   */
  public onDeleteImage() {
    if (this.question.options.bgImage) {
      delete(this.question.options.bgImage);
    }
  }

  /**
   * Opens media center modal
   */
  public onOpenMediaCenterModal() {
    const mediaType = 'images';

    const locale = this.locale;
    const questionnaireId =  this.state.getQuestionnaire().id;
    const maintainAspectRatio = true;
    const aspectRatio = 4 / 3;

    const callback = (mediaCenterAsset: MediaCenter.Asset) => {
      !this.question.options.bgImage && (this.question.options.bgImage = {});
      const bgImage = this.question.options.bgImage;

      let image = mediaCenterAsset.name;

      if (mediaCenterAsset.options && mediaCenterAsset.options.croppedImage) {
        image = mediaCenterAsset.options.croppedImage;
      }

      const isDataUrl = image.match(/^data:image/);

      if (isDataUrl) {
        bgImage[this.locale] = image;
        this.ref.detectChanges();
        this.ref.markForCheck();
        return;
      }

      mediaCenterAsset.name = image;
      this.mediaCenter.getImageData(mediaCenterAsset, questionnaireId)
        .then((result) => {
          bgImage[this.locale] = result.dataUrl;
          this.ref.detectChanges();
          this.ref.markForCheck();
        })
        .catch(error => {
          alert(this._(KEYS.EDITOR.ERROR_TRANSFERRING_IMAGE));
          console.error(error);
        });
    };

    this.mediaCenter.openMediaCenter({
      questionnaireId, mediaType, locale, containerAsset: null, callback, maintainAspectRatio, aspectRatio
    });
  }
}
