import {Injectable} from '@angular/core';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {ValueService} from '@ngmedax/value';


/**
 * Service that provides methods to get questionnaire attributes
 */
@Injectable()
export class QuestionnaireAttributeService {

  /**
   * Injects dependencies
   * 
   * @param value
   */
  public constructor(private value: ValueService) {
  }

  public getQuestionPathHashes(
    element: Questionnaire | Questionnaire.Container,
    questionPathHashes: Questionnaire.Accumulated.QuestionnaireQuestionPathHashMap = {},
    currentPageNumber = 0
  ): Questionnaire.Accumulated.QuestionnaireQuestionPathHashMap {
    // given element could be a questionnaire
    if ((<Questionnaire>element).questions) {
      const questionnaire = <Questionnaire>element;

      // get number of questions for each container
      for (const container of questionnaire.questions) {
        questionPathHashes = this.getQuestionPathHashes(
          container,
          questionPathHashes,
          currentPageNumber++
        );
      }

      return questionPathHashes;
    }

    // still here? element is a container
    const container = <Questionnaire.Container>element;

    // container is not a question
    if (!this.isQuestion(container)) {
      // but it could contain sub containers with questions
      if (container.elements) {
        // get number of questions for all sub containers
        for (const subContainer of container.elements) {
          questionPathHashes = this.getQuestionPathHashes(
            subContainer,
            questionPathHashes,
            currentPageNumber
          );
        }
      }
      return questionPathHashes;
    }

    questionPathHashes[container.pathHash] = {
      isOnPageNumber: currentPageNumber,
      isMandatory: this.isMandatory(container)
    };

    return questionPathHashes;
  }

  /**
   * Returns true if given container is an answerable question
   *
   * @param {Questionnaire.Container} container
   * @returns {boolean}
   */
  public isQuestion(container: Questionnaire.Container): boolean {
    // some display types to ignore
    const displayTypesToIgnore = {
      [Questionnaire.Container.DisplayType.GROUP]: true,
    };

    // some container formats to ignore
    const formatsToIgnore = {
      [Questionnaire.Container.Format.PAGE_BREAK]: true,
      [Questionnaire.Container.Format.INFO]: true
    };

    const isDisplayTypeToIgnore = displayTypesToIgnore[container.displayType];
    const isContainerFormatToIgnore = formatsToIgnore[container.format];
    const isActive = this.isActive(container);

    return (!isDisplayTypeToIgnore && !isContainerFormatToIgnore && isActive);
  }

  /**
   * Returns true if given container is mandatory
   *
   * @param {Questionnaire.Container} container
   * @returns {boolean}
   */
  public isMandatory(container: Questionnaire.Container): boolean {
    return !!this.value.get(container, ['options', 'mandatory']);
  }

  /**
   * Returns true if given container is active
   *
   * @param {Questionnaire.Container} container
   * @returns {boolean}
   */
  public isActive(container: Questionnaire.Container): boolean {
    return !!this.value.get(container, ['options', 'active']);
  }

  /**
   * Returns true if given container has conditions
   *
   * @param {Questionnaire.Container} container
   * @returns {boolean}
   */
  public hasConditions(container: Questionnaire.Container): boolean {
    const hasConditions = !!this.value.get(container, ['conditions', 'show']);

    if (!hasConditions) {
      return false;
    }

    const conditions = this.value.isArray(container.conditions.show) ?
      <string[]>container.conditions.show : [<string>container.conditions.show];

    return !!conditions.length;
  }
}
