import {Component, Optional} from '@angular/core';
import {Settings} from '../../../types';
import {SettingsService} from '../services/settings.service';
import {TRANSLATION_SETTINGS_SCOPE} from '../../../constants';
import {Translatable, TranslationService} from '../../../../translation';
import {KEYS} from '../../../translation-keys';


// hack to inject decorator declarations. must occur before class declaration!
export interface SettingsComponent extends Translatable {}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
@Translatable({scope: TRANSLATION_SETTINGS_SCOPE, keys: KEYS})
export class SettingsComponent {
  public settings: Settings = {
    deleteClientsInDb: 0,
    deleteClientsOnDisk: 0,
    deleteSurveys: 40
  };

  public timePeriods = [
    {variables: {}, key: KEYS.SETTINGS.NEVER, value: 0},
    {variables: {}, key: KEYS.SETTINGS.ONE_DAY, value: 1},
    {variables: {num: 2}, key: KEYS.SETTINGS.NUM_DAYS, value: 2},
    {variables: {num: 3}, key: KEYS.SETTINGS.NUM_DAYS, value: 3},
    {variables: {num: 4}, key: KEYS.SETTINGS.NUM_DAYS, value: 4},
    {variables: {num: 5}, key: KEYS.SETTINGS.NUM_DAYS, value: 5},
    {variables: {num: 6}, key: KEYS.SETTINGS.NUM_DAYS, value: 6},
    {variables: {}, key: KEYS.SETTINGS.ONE_WEEK, value: 7},
    {variables: {num: 2}, key: KEYS.SETTINGS.NUM_WEEKS, value: 14},
    {variables: {num: 3}, key: KEYS.SETTINGS.NUM_WEEKS, value: 21},
    {variables: {}, key: KEYS.SETTINGS.ONE_MONTH, value: 30},
  ];

  public surveyTimePeriods = [
    {variables: {num: -1}, key: KEYS.SETTINGS.NEVER, value: -1},
    {variables: {num: 40}, key: KEYS.SETTINGS.NUM_DAYS, value: 40},
    {variables: {num: 3}, key: KEYS.SETTINGS.NUM_MONTHS, value: 30 * 3},
    {variables: {num: 6}, key: KEYS.SETTINGS.NUM_MONTHS, value: 30 * 6},
    {variables: {num: 12}, key: KEYS.SETTINGS.NUM_MONTHS, value: 30 * 12},
  ];

  constructor(
    @Optional() private translationService: TranslationService,
    private sets: SettingsService
  ) {
    sets.getSettings().then((mySettings: any) => {
      mySettings && (this.settings = mySettings);
    });
  }

  /**
   * Locale for questionnaires. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  public saveSettings() {
    this.sets
      .saveSettings(this.settings)
      .then(() => {
        alert(this._(KEYS.SETTINGS.SUCCESSFULLY_SAVED_SETTINGS));
      })
      .catch(error => {
        console.error(error);
        alert(this._(KEYS.SETTINGS.ERROR_SAVING_SETTINGS));
      });
  }
}
