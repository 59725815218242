import {Injectable} from '@angular/core';
import {PasswordOptions} from '../../../types';

@Injectable()
export class PasswordGeneratorService {

  /**
   * Generates and returns password by given options
   *
   * @param {PasswordOptions} opts
   * @returns {string}
   */
  public generate(opts?: PasswordOptions) {
    !opts && (opts = {});
    const length = opts.length || 8;
    const minUpper = opts.minUpper || 1;
    const minLower = opts.minLower || 0;
    const minNumber = opts.minNumber || -1;
    const minSpecial = opts.minSpecial || -1;

    const genArray = (length) => Array.from(Array(length).keys());

    let chars = String.fromCharCode(...genArray(127)).slice(33), //chars
      A2Z = String.fromCharCode(...genArray(91)).slice(65), //A-Z
      a2z = String.fromCharCode(...genArray(123)).slice(97), //a-z
      zero2nine = String.fromCharCode(...genArray(58)).slice(48), //0-9
      specials = chars.replace(/\w/g, ''); //special chars

    let minRequired = minSpecial + minUpper + minLower + minNumber;

    let rs = [].concat(
      Array.from({length: minSpecial ? minSpecial : 0}, () => specials[Math.floor(Math.random() * specials.length)]),
      Array.from({length: minUpper ? minUpper : 0}, () => A2Z[Math.floor(Math.random() * A2Z.length)]),
      Array.from({length: minLower ? minLower : 0}, () => a2z[Math.floor(Math.random() * a2z.length)]),
      Array.from({length: minNumber ? minNumber : 0}, () => zero2nine[Math.floor(Math.random() * zero2nine.length)]),
      Array.from({length: Math.max(length, minRequired) - (minRequired ? minRequired : 0)},
        () => a2z[Math.floor(Math.random() * a2z.length)])
    );

    rs.sort(() => Math.random() - 0.5);
    return rs
      .join('')
      .replace(/\\/, '')
      .replace(/\$/g, '!')
      .replace(/("|')/g, '^');
  }
}
