<div class="qa-io-overlay" *ngIf="visible">
  <div class="card bg-light mb-3">
    <div class="card-header">
      <i class="fa fa-retweet"></i> {{_(KEYS.EDITOR.QUESTIONNAIRE_JSON)}}
      <button class="close" (click)="hide()">
        <i class="fa fa-close"></i>
      </button>
    </div>
    <div class="card-body">
      <textarea #qaIO></textarea>
    </div>
    <div class="card-footer">
      <button class="btn btn-success" (click)="importJSON(qaIO.value)">
        <i class="fa fa-upload"></i> {{_(KEYS.EDITOR.IMPORT)}}
      </button>
      <button class="btn btn-success" (click)="exportJSON(qaIO)">
        <i class="fa fa-download"></i> {{_(KEYS.EDITOR.EXPORT)}}
      </button>

      <div class="float-right">
        <div ngbDropdown>
          <button class="btn btn-danger" ngbDropdownToggle>
            <i class="fa fa-code-fork"></i>
            {{_(KEYS.EDITOR.DEBUGGING)}}
          </button>
          <div ngbDropdownMenu>
            <button class="dropdown-item qa-cursor-pointer" (click)="exportJSON(qaIO, true)">
              <i class="fa fa-file-code-o"></i>
              {{_(KEYS.EDITOR.UNPACKED_EXPORT)}}
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
