import {TranslationService} from '../../..';
import {ConfigService} from '@ngmedax/config';

export function LocalesLoader(translation: TranslationService, config: ConfigService) {
  return async () => {
    await new Promise<void>(resolve => {
      const msg = 'locales loader: failed because config was still not loaded after 10 seconds';
      const ref = setInterval(() => config.isLoaded() && resolve(), 100);
      // @ts-ignore
      setTimeout(() => clearInterval(ref) && console.error(msg) && resolve(), 10000);
    });
    await translation.loadLocales();
    await translation.setActiveLocale();
  };
}
