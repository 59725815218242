import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ImageCropperModule} from '@ngmedax/image-cropper';

import {AuthService} from './services/auth.service';
import {AssetApiService} from './services/asset-api.service';
import {MediaCenterComponent} from './components/media-center/media-center.component';
import {MediaCenterService} from './services/media-center.service';
import {TruncatePipe} from './pipes/truncate.pipe';
import {DatexPipe} from './pipes/datex.pipe';
import {ImageService} from './services/image.service';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ImageCropperModule
  ],
  declarations: [
    MediaCenterComponent,
    TruncatePipe,
    DatexPipe
  ],
  exports: [
  ],
  entryComponents: [
    MediaCenterComponent
  ],
  providers: [
    AuthService,
    ImageService,
    AssetApiService,
    MediaCenterService
  ]
})
export class AssetModule {
}
