import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, Optional} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {Translatable, TranslationEventService, TranslationService} from '@ngmedax/translation';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {ConfigService} from '@ngmedax/config';
import {PdfFormService} from '@ngmedax/pdf-form';
import {ValueService} from '@ngmedax/value';

import {duplicateVariableNameValidator} from './validator/duplicate-variable-name.validator';
import {QuestionnaireVariablesService} from '../../../services/questionnaire-variables.service';
import {QuestionnaireStateService} from '../../../services/questionnaire-state.service';
import {DomHelperService} from '../../../services/dom-helper.service';
import {QuestionnaireEditor} from '../../../../types';
import {TRANSLATION_EDITOR_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';



// hack to inject decorator declarations. must occur before class declaration!
export interface VariablesComponent extends Translatable {}

@Component({
  selector: 'app-qa-variables',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './variables.component.html',
  styleUrls: ['./variables.component.css']
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class VariablesComponent implements OnInit {
  @Input() container: Questionnaire.Container;
  @Input() disabledScopes: {[scopeName: string]: boolean} = {};

  /**
   * Locale for questionnaire. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Scope names for questionnaire variables
   *
   * @type {string[]}
   */
  public scopeNames = [];

  /**
   * Variable scopes configuration
   *
   * @type {QuestionnaireEditor.VariableScopes}
   */
  public variableScopes: QuestionnaireEditor.VariableScopes = null;

  /**
   * Form for variables
   *
   * @type {FormGroup}
   */
  public variablesForm: FormGroup;

  /**
   * Reference to current questionnaire
   *
   * @type {Questionnaire}
   */
  private questionnaire: Questionnaire;

  /**
   * Form validation error messages
   */
  private formErrors: {[errorName: string]: string} = {};

  /**
   * Storage for tooltips
   */
  private tooltips: {[scope: string]: NgbTooltip} = {};

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    @Optional() private translationEvents: TranslationEventService,
    @Optional() private pdfFormService: PdfFormService,
    private questionnaireVariables: QuestionnaireVariablesService,
    private questionnaireState: QuestionnaireStateService,
    private config: ConfigService,
    private value: ValueService,
    private formBuilder: FormBuilder,
    private domHelper: DomHelperService,
    private ref: ChangeDetectorRef
  ) {
    this.updateFormErrors();
    this.translationEvents && this.translationEvents.onLocaleChanged().subscribe(() => this.updateFormErrors());
  }

  /**
   * Initializes conditions
   */
  public ngOnInit() {
    this.questionnaire = this.questionnaireState.getQuestionnaire();
    this.scopeNames = this.questionnaireVariables.getVariableScopeNames();
    this.variableScopes = this.questionnaireVariables.getVariableScopes();

    if (!this.scopeNames || !this.scopeNames.length) {
      return;
    }

    this.initForm().then(() => this.populateForm());
  }

  /**
   * Saves variable mapping
   */
  public onSave(el: HTMLElement, formEl: AbstractControl, tooltip: NgbTooltip, scope: string) {
    const pathHash = this.container.pathHash;
    const variableName = formEl.value;
    const ctrlName = this.getFormControlNameForReturnValue(scope);
    const returnValue = this.variablesForm.get(ctrlName) ? this.variablesForm.get(ctrlName).value : '';
    const options = this.variableScopes[scope].allowReturnValue && returnValue ?
      {returnValue: {[this.locale]: returnValue}} : {};

    tooltip && tooltip.isOpen() && tooltip.close();

    this.runValidationOnFormEl(formEl);
    this.onCheckForTooltip(this.tooltips[scope], formEl, scope);

    if (formEl.errors) {
      return;
    }

    this.questionnaireVariables.addVariableMapping(scope, variableName, pathHash, options);
    this.questionnaireState.onUpdateElement.emit({element: null, position: null});
    formEl.reset(variableName);
    this.onClean(el);
  }

  /**
   * Resets variable mapping
   */
  public onReset(el: HTMLElement, formEl: AbstractControl, scope: string) {
    const pathHash = this.container.pathHash;
    const ctrlName = this.getFormControlNameForReturnValue(scope);
    const previousVariableName = this.questionnaireVariables.getMappedVariableName(scope, pathHash);
    const options = this.questionnaireVariables.getMappedVariableOptions(scope, pathHash, previousVariableName);
    const tooltip = this.tooltips[scope];

    this.variablesForm.get(ctrlName) && this.variablesForm.get(ctrlName).setValue('');

    if (options && options.returnValue && options.returnValue[this.locale]) {
      const returnValue = options.returnValue[this.locale];
      this.variablesForm.get(ctrlName) && this.variablesForm.get(ctrlName).setValue(returnValue);
    }

    tooltip && tooltip.isOpen() && tooltip.close();
    formEl.reset(previousVariableName);
    this.onClean(el);
  }

  /**
   * Deletes variable mapping
   */
  public onDelete(el: HTMLElement, formEl: AbstractControl, scope: string) {
    const ctrlName = this.getFormControlNameForReturnValue(scope);
    const pathHash = this.container.pathHash;
    const variableName = formEl.value;
    const tooltip = this.tooltips[scope];

    this.questionnaireVariables.deleteVariableMapping(scope, variableName, pathHash);
    this.questionnaireState.onUpdateElement.emit({element: null, position: null});

    tooltip && tooltip.isOpen() && tooltip.close();
    this.variablesForm.get(ctrlName) && this.variablesForm.get(ctrlName).setValue('');
    formEl.reset('');
    this.onClean(el);
  }

  /**
   * Marks an element as dirty
   */
  public onDirty(el: HTMLElement, formEl: AbstractControl = null) {
    this.domHelper.addClasses(el, ['qa-tr-dirty']);
    formEl && formEl.markAsDirty();
  }

  /**
   * Marks an element as clean
   */
  public onClean(el: HTMLElement) {
    this.domHelper.removeClasses(el, ['qa-tr-dirty']);
    this.domHelper.addClasses(el, ['qa-tr-clean', 'fadeIn', 'animated']);
    setTimeout(() => this.domHelper.removeClasses(el, ['qa-tr-clean', 'fadeIn', 'animated']), 600);
  }

  /**
   * Shows a tooltip over given form element when form value is invalid
   */
  public onCheckForTooltip(tooltip: NgbTooltip, formEl: AbstractControl, scope: string) {
    tooltip && (this.tooltips[scope] = tooltip);

    if (formEl.dirty && formEl.errors) {
      const errorMsg = this.getFirstErrorMessage(formEl.errors);
      errorMsg && tooltip.open({tooltipMsg: errorMsg});
      return;
    }

    tooltip && tooltip.isOpen() && tooltip.close();
  }

  public isDisabledScope(scope: string) {
    if (!this.disabledScopes) {
      return false;
    }

    if (this.disabledScopes[scope]) {
      return true;
    }

    if (!!this.disabledScopes['pdf-form'] && scope.match(/^pdf-form/)) {
      return true;
    }

    return false;
  }

  /**
   * Returns keys of given object
   */
  public getObjectKeys(obj: any): string[] {
    return typeof obj !== 'object' ? [] : Object.keys(obj);
  }

  /**
   * Initializes variables form
   */
  private async initForm() {
    const pathHash = this.container.pathHash;
    const variables = this.questionnaire.meta.options.variables;
    const varNameValidator = Validators.pattern(/^[a-zA-Z0-9_]{3,50}$/);
    const dynFormGroup = {};

    const pdfFormScopes = await this.questionnaireVariables.getPdfFormScopes();
    Object.assign(this.variableScopes, pdfFormScopes);
    this.scopeNames = [...this.scopeNames, ...Object.keys(pdfFormScopes)];

    Object.keys(pdfFormScopes).forEach((scopeName) => {
      !this.questionnaire.meta.options.variables[scopeName] && (this.questionnaire.meta.options.variables[scopeName] = {});
    });

    for (const scope of Object.keys(this.variableScopes)) {
      if (this.disabledScopes && this.isDisabledScope(scope)) {
        continue;
      }

      const validators = [];

      const variableScope = this.variableScopes[scope];

      if (!variableScope.skipValidation) {
        validators.push(varNameValidator);
      }

      if (!variableScope.allowAccumulation) {
        validators.push(duplicateVariableNameValidator(scope, pathHash, variables));
      }

      dynFormGroup[scope] = this.formBuilder.control(null, validators);

      // add form element for return value, if allowed by scope
      if (variableScope.allowReturnValue) {
        const ctrlName = this.getFormControlNameForReturnValue(scope);
        dynFormGroup[ctrlName] = this.formBuilder.control(null);
      }
    }

    this.variablesForm = this.formBuilder.group(dynFormGroup);
  }

  /**
   * Populates variables form by current questionnaire
   */
  private populateForm() {
    const pathHash = this.container.pathHash;
    const variables: Questionnaire.Options.Variables = this.questionnaire.meta.options.variables;

    for (const scope of Object.keys(variables)) {
      const variableName = this.questionnaireVariables.getMappedVariableName(scope, pathHash);
      const options = this.questionnaireVariables.getMappedVariableOptions(scope, pathHash, variableName);

      if (!variableName) {
        continue;
      }

      // options has a return value? set related form field...
      if (options && options.returnValue && options.returnValue[this.locale]) {
        const returnValue = options.returnValue[this.locale];
        const ctrlName = this.getFormControlNameForReturnValue(scope);
        this.variablesForm.get(ctrlName) && this.variablesForm.get(ctrlName).setValue(returnValue);
      }

      this.variablesForm.get(scope) && this.variablesForm.get(scope).setValue(variableName);
    }

    this.ref.markForCheck();
    this.ref.detectChanges();
  }

  /**
   * Returns first error message for given errors array
   */
  private getFirstErrorMessage(errors: {[errorName: string]: string}): string {
    for (const errorName of Object.keys(errors)) {
      if (this.formErrors[errorName]) {
        return this.formErrors[errorName];
      }
    }
  }

  /**
   * Returns form control name for return value
   *
   * @param scope
   * @returns string
   */
  private getFormControlNameForReturnValue(scope: string) {
    return `${scope}-return-value`;
  }

  /**
   * Runs validation on given form element
   *
   * @param formEl
   */
  private runValidationOnFormEl(formEl: AbstractControl) {
    formEl.updateValueAndValidity();
    this.ref.markForCheck();
    this.ref.detectChanges();
  }

  private updateFormErrors() {
    this.formErrors = {
      pattern: this._(KEYS.EDITOR.INVALID_VAR_NAME_TEXT),
      duplicateVariableName: this._(KEYS.EDITOR.DUPLICATE_VAR_NAME_TEXT)
    };
  }
}
