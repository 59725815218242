import {RouterModule, Routes} from '@angular/router';
import {permission} from './pdf-form.permission';
import {PdfFormGridComponent} from './components/grid/pdf-form-grid.component';

const APP_ROUTES: Routes = [
  {
    path: 'module/pdf-form/grid',
    component: PdfFormGridComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: true,
      needsPermissions: [
        permission.MYMEDAX_QUESTIONNAIRE_READ,
        permission.MYMEDAX_QUESTIONNAIRE_CREATE,
        permission.MYMEDAX_QUESTIONNAIRE_UPDATE,
        permission.MYMEDAX_QUESTIONNAIRE_DELETE
      ],
      needsConfig: [
        'feature.pdf.forms'
      ]
    }
  },
];

export const routing = RouterModule.forRoot(APP_ROUTES);
