import {Injectable, Optional} from '@angular/core';
import {LoginService} from '@ngmedax/login';
import {ConfigService} from '@ngmedax/config';
import {RegistryService} from '@ngmedax/registry';


@Injectable()
export class AuthUriService {

  public constructor(
    private registryService: RegistryService,
    private configService: ConfigService
  ) {
  }

  /**
   * Builds url by value of given config key and suffix
   *
   * @param {string} configKey
   * @returns {any}
   */
  public buildUrl(configKey: string, suffix = null) {
    let baseUri = this.configService.get(configKey);

    if (suffix) {
      baseUri = `${baseUri}${suffix}`;
    }

    return baseUri;
  }

  /**
   * Returns auth headers by auth token and tenant id
   */
  public getAuthHeaders(): any {
    const headers: any = {};
    const authToken = this.registryService.get('session.authToken');
    const tenantId = this.registryService.get('session.tenantId');

    if (authToken) {
      headers['X-Api-Token'] = authToken;
    }

    if (tenantId) {
      headers['X-Api-TenantId'] = `${tenantId}`;
    }

    return headers;
  }

  /**
   * Deprecated. Now sent via header
   * Returns auth uri part: e.g: /:token/:tenant
   *
   * Returns empty string if login not set. Also returns
   * empty string if no user returned by login service.
   *
   * @deprecated
   * @param {LoginService} loginService
   * @param {boolean} withTenantId
   * @returns {string}
   */
  public getAuthUri(
    @Optional() loginService: LoginService,
    withTenantId = false
  ): string {
    return '';
  }

  /**
   * Deprecated. Now sent via header
   * Returns url with auth by given params
   *
   * @deprecated
   * @param {LoginService} loginService
   * @param {string} baseConfigKey
   * @param {string} configKey
   * @param {any} suffix
   * @returns {string}
   */
  public getUrlWithAuth(
    @Optional() loginService: LoginService,
    baseConfigKey: string,
    configKey: string,
    suffix = null
  ) {
    let baseUri = this.configService.get(baseConfigKey)
      + this.getAuthUri(loginService)
      + this.configService.get(configKey);

    if (suffix) {
      baseUri = `${baseUri}${suffix}`;
    }

    return baseUri;
  }
}
