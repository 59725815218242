/**
 * Mapping for translation keys, so we don't have to use long text in source code. Please keep in mind, that the
 * german default translations are the keys, so don't change them, if you wan't to change the text.
 * Instead change the de_DE translations!
 */
export const KEYS = {
  GRID: {
    DELETE_PDF_FORM_QUESTION: 'Das folgende PDF Formular wirklich löschen?',
    SUCCESSFULLY_DELETED_PDF_FORM: 'Das PDF Formular wurde erfolgreich gelöscht.',
    ERROR_DELETING_PDF_FORM: 'Beim Löschen des PDF Formulars ist ein Fehler aufgetreten.',
    ERROR_LOADING_PDF_FORMS: 'Beim Laden der PDF Formulare ist ein Fehler aufgetreten.',
    PDF_FORMS: 'PDF Formulare',
    SEARCH: 'Suche',
    ID: 'Id',
    NAME: 'Name',
    FILE: 'Datei',
    FORM: 'Formular',
    OPTIONS: 'Optionen',
    CREATED_AT: 'Erstellt am',
    UPDATED_AT: 'Bearbeitet am',
    CHANGE: 'Bearbeiten',
    DELETE: 'Löschen',
    ENTRIES: 'Einträge',
    CREATE: 'Erstellen',
    SIGNATURES: 'Unterschriften'
  },
  CRUD: {
    PDF_FORM: 'PDF Formular',
    SAVE: 'Speichern',
    CANCEL: 'Abbrechen',
    NAME: 'Name',
    ENTER_NAME: 'Name eingeben',
    FILE: 'Datei',
    SELECT: 'Auswählen',
    FORM_FIELDS: 'Formularfelder',
    SIGNATURES: 'Unterschriften',
    TITLE: 'Titel',
    ADD: 'Hinzufügen',
    ERROR_SAVING_FORM: 'Beim Speichern des PDF Formulars ist ein Fehler aufgetreten',
    FOUND_NO_FORM_BY_ID: 'Es wurde kein PDF Formular mit folgender Id gefunden:',
    SUCCESSFULLY_SAVED_PDF_FORM: 'Das PDF Formular wurde erfolgreich gespeichert',
    SHOW_IN_SGN: 'Immer in SignoSign anzeigen'
  }
};

