<div class="panel-body">
    <div class="dataTables_wrapper no-footer">


        <div class="table-responsive">
            <table class="table table-bordered table-striped mb-none dataTable no-footer" role="grid" aria-describedby="datatable-default_info">
                <thead>
                <tr role="row">
                    <th style="width: 50px;"></th>
                    <th id="locale">Sprache</th>
                    <th id="language">Sprache</th>
                    <th style="width: 100px;" id="backend_language">Backend</th>
                    <th style="width: 100px;" id="survey_language">Fragebogen</th>
                    <th style="width: 200px;" id="sort_order">Sortierung</th>
                </tr>
                </thead>
                <tbody><tr id="de_DE">
                    <td style="text-align: center"></td>
                    <td>
                        de_DE                                <input type="hidden" name="row_locale" value="de_DE">
                    </td>
                    <td>
                        Deutsch                                <input type="hidden" name="row_language" value="language_de_DE">
                    </td>
                    <td>
                        ✓
                    </td>
                    <td>
                        ✓
                    </td>
                    <td>
                        <div class="input-group">
                                    <span class="input-group-addon">
                                        <i class="fa fa-sort" style="width:15px!important"></i>
                                    </span>
                            <input type="text" style="width: 100px;" name="sort_order" value="10" title="" data-placement="top" data-toggle="tooltip" data-original-title="Sortierung">
                        </div>
                    </td>
                </tr>


                </tbody>
            </table>
        </div>
      </div>
</div>