import {Router} from '@angular/router';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {LayoutService} from '@ngmedax/layout';

import {routing} from './imprint.routing';
import {NAVIGATION} from './imprint.navigation';
import {ImprintComponent} from './components/imprint.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    routing
  ],
  declarations: [
    ImprintComponent
  ],
  exports: [],
  providers: []
})
export class ImprintModule {
  public constructor(
    private router: Router,
    private layoutService: LayoutService,
  ) {
    layoutService.addMenuEntry(NAVIGATION);
  }

}
