import {RouterModule, Routes} from '@angular/router';
import {QuestionnaireGridComponent} from './components/grid/questionnaire-grid.component';
import {permission} from './questionnaire-grid.permission';

const APP_ROUTES: Routes = [
  {
    path: 'module/questionnaire/grid',
    component: QuestionnaireGridComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: true,
      needsPermissions: [
        permission.MYMEDAX_QUESTIONNAIRE_READ,
        permission.MYMEDAX_QUESTIONNAIRE_DELETE
      ]
    }
  }
];

export const routing = RouterModule.forRoot(APP_ROUTES);
