/**
 * Mapping for translation keys, so we don't have to use long text in source code. Please keep in mind, that the
 * german default translations are the keys, so don't change them, if you wan't to change the text.
 * Instead change the de_DE translations!
 */
export const KEYS = {
  GRID: {
    ERROR_MISSING_MANDATORY_CONFIG: 'Die Konfiguration enthält keine API URL zum Abrufen von Befragungen!',
    ERROR_LOADING_SURVEYS: 'Beim Laden der Befragungen ist ein Fehler aufgetreten!',
    RELATED_DATA_NOT_AVAILABLE: 'Verknüpfte Daten nicht vorhanden!',
    CONFIG_ERROR: 'Konfigurationsfehler',
    SURVEYS: 'Befragungen',
    PATIENT_ID: 'PatNr.',
    CASE_NR: 'FallNr.',
    PATIENT: 'Patient',
    QUESTIONNAIRES: 'Fragebögen',
    SUBMISSIONS: 'Abgaben',
    HISTORY: 'Historie',
    OPTIONS: 'Optionen',
    CONTINUE_SURVEY: 'Befragung fortsetzen',
    RESTART_SURVEY: 'Befragung neu starten',
    RESUBMIT_SURVEY: 'Befragung erneut abgeben',
    CONFIRM_RESUBMIT: 'Möchten Sie die Befragung wirklich erneut abgeben?',
    ERROR_RESUBMITTING_SURVEY: 'Beim erneuten Abgeben der Befragung ist ein Fehler aufgetreten!',
    SUCCESS_RESUBMITTING_SURVEY: 'Die Befragung wurde erfolgreich erneut abgegeben.',
    STATUS: 'Status',
    STATUS_CREATED: 'Erstellt',
    STATUS_ASSIGNED: 'Zugewiesen',
    STATUS_SAVED: 'Zwischengespeichert',
    STATUS_SUBMITTED: 'Abgegeben',
    STATUS_SIGNED: 'Unterschrieben',
    STATUS_CANCELED: 'Abgebrochen',
    STATUS_EXPIRED: 'Abgelaufen',
    CREATED_AT: 'Erstellt am',
    UPDATED_AT: 'Bearbeitet am',
    ENTRIES: 'Einträge',
    ASSETS: 'Dateien',
    MR: 'Herr',
    MS: 'Frau',
    ID: 'Id',
    PSEUDONYMOUS: 'Pseudonym',
    ANONYMOUS: 'Anonym',
    USER: 'Benutzer'
  },
  HISTORY: {
    SURVEY_HISTORY: 'Historie der Befragung'
  }
};

