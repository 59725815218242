import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';

@Component({
  selector: 'app-qa-extended-conditions',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './extended-conditions.component.html',
  styleUrls: ['./extended-conditions.component.css']

})
export class ExtendedConditionsComponent {
  @Input() question: Questionnaire.Container;
}
