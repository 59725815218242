import {Injectable, Optional} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Tenant, Role, FilterGroup} from '../../../types';
import {AuthUriService} from './auth-uri.service';
import {configKeys} from '../ums.config-keys';

import {ConfigService} from '@ngmedax/config';
import {LoginService} from '@ngmedax/login';


/**
 * Service to request data from the user management system
 */
@Injectable()
export class UmsService {

  /**
   * Injects dependencies
   *
   * @param {HttpClient} http
   * @param {ConfigService} configService
   * @param {AuthUriService} authUriService
   * @param {LoginService} loginService
   */
  public constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private authUriService: AuthUriService,
    @Optional() private loginService: LoginService) {
  }

  /**
   * Loads Filter groups
   *
   * @returns {Promise<FilterGroup[]>}
   */
  public loadFilterGroups(): Promise<FilterGroup[]> {
    const basePath = this.getFilterGroupApiUrl();

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers: this.authUriService.getAuthHeaders()})
        .subscribe((filterGroups: any) =>
          resolve(filterGroups.filterGroup || filterGroups.rows || filterGroups || []), error => reject(error)
        );
    });
  }

  /**
   * Loads Tenants
   *
   * @returns {Promise<Tenant[]>}
   */
  public loadTenants(): Promise<Tenant[]> {
    const basePath = this.getTenantApiUrl();

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers: this.authUriService.getAuthHeaders()})
        .subscribe((tenants: any) => resolve(tenants.rows || []), error => reject(error));
    });
  }

  /**
   * Loads Roles
   *
   * @returns {Promise<Role[]>}
   */
  public loadRoles(): Promise<Role[]> {
    const basePath = this.getRoleApiUrl();

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers: this.authUriService.getAuthHeaders()})
        .subscribe((roles: any) => resolve(roles.rows || []), error => reject(error));
    });
  }

  /**
   * Checks if given auth token is valid by asking the ums
   *
   * @param {string} authToken
   * @returns {Promise<boolean>}
   */
  public isValidAuthToken(authToken: string): Promise<boolean> {
    const basePath = this.getAuthTokenValidateUrl();
    return new Promise((resolve, reject) => {
      this.http.put(basePath, {authToken}, {headers: this.authUriService.getAuthHeaders()})
        .subscribe((response: any) => resolve(response.success || false), error => reject(error));
    });
  }

  /**
   * Preview mode? Used to enables bleeding edge features that are not visible on production!
   *
   * @returns {boolean}
   */
  public isPreviewMode(): boolean {
    return this.configService.get('previewMode') === true;
  }

  /**
   * Returns url for reset password captcha
   *
   * @returns {string}
   */
  public getResetPasswordCaptchaUrl(): string {
    return this.configService.get(configKeys.BASE_URI_CONFIG_KEY)
      + this.configService.get(configKeys.RESET_PASSWORD_CAPTCHA_KEY);
  }

  /**
   * Returns api url for filter group
   *
   * @returns {string}
   */
  private getFilterGroupApiUrl(suffix: string = null): string {
    return this.configService.get(configKeys.FILTER_GROUP_URI_CONFIG_KEY)
      + (suffix ? suffix : '');
  }

  /**
   * Returns api url for role
   *
   * @returns {string}
   */
  private getRoleApiUrl(suffix: string = null): string {
    return this.getUrl(configKeys.ROLE_URI_CONFIG_KEY, suffix);
  }

  /**
   * Returns api url for tenant
   *
   * @returns {string}
   */
  private getTenantApiUrl(suffix: string = null): string {
    return this.getUrl(configKeys.TENANT_URI_CONFIG_KEY, suffix);
  }

  /**
   * Returns api url for auth token validation
   * @param suffix
   * @returns {string}
   */
  private getAuthTokenValidateUrl(suffix: string = null): string {
    return this.getUrl(configKeys.AUTH_TOKEN_VALIDATE_KEY, suffix);
  }

  /**
   * Returns url with auth uri part by given config key
   *
   * @param {string} configKey
   * @param {any} suffix
   * @returns {string}
   */
  private getUrl(configKey: string, suffix = null) {
    const baseConfigKey = configKeys.BASE_URI_CONFIG_KEY;

    let baseUri = this.configService.get(baseConfigKey)
      + this.configService.get(configKey);

    if (suffix) {
      baseUri = `${baseUri}${suffix}`;
    }

    return baseUri;
  }
}
