export const NAVIGATION = {
  name: 'Befragungen',
  icon: 'fa-list-ul',
  path: '/module/survey/',
  children: [{
    name: 'Liste',
    path: '/module/survey/grid',
    icon: 'fa-table'
  }]
};
