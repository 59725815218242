export const NAVIGATION = {
  name: 'Fragebogen',
  icon: 'fa-id-card-o',
  path: '/module/questionnaire/',
  children: [
    {
      name: 'Ausgefüllt',
      icon: 'fa-check-circle',
      path: '/module/questionnaire/submission/grid',
    }
  ]
};
