export const configKeys = {
  /**
   * Config key for license manager uri
   * @type {string}
   */
  LICENSE_MANAGER_API_URI_CONFIG_KEY: 'apis.license.manager.uri',

  /**
   * Config key for session auth token
   * @type {string}
   */
  SESSION_AUTH_TOKEN: 'session.authToken',

  /**
   * Config key for session tenant id
   * @type {string}
   */
  SESSION_TENANT_ID: 'session.tenantId'
};
