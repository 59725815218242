export const NAVIGATION = {
  name: 'Einstellungen',
  icon: 'fa-cog',
  path: '/module/settings/',
  children: [{
    name: 'CI / Design',
    path: '/module/settings/theming',
    icon: 'fa-paint-brush'
  }]
};
