import {Injectable} from '@angular/core';
import {Asset} from '../../../types';
import {ConfigService} from '@ngmedax/config';


@Injectable()
export class ImageService {
  /**
   * Asset cdn base uri
   */
  private readonly baseUri: string;

  /**
   * Injects dependencies and sets asset cdn base uri
   *
   * @param config
   * @param modal
   */
  public constructor(
    private config: ConfigService,
  ) {
    this.baseUri = `${this.config.get('apis.asset.cdn') || ''}`;
  }

  /**
   * Returns image data for given Asset. Adds white color to image background
   * in order to prevent transparent layers to become black!
   *
   * @param file
   * @param bucketId: string
   */
  public getImageData(
    file: Asset.MediaCenter.GridFile,
    bucketId: string,
    transparentBackground: boolean = false
  ): Promise<{width: number, height: number, dataUrl: string}> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        const canvas = <HTMLCanvasElement>document.createElement('CANVAS');
        const ctx = canvas.getContext('2d');
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.fillStyle = transparentBackground? 'rgba(0,0,0,0)' : '#FFFFFF';
        ctx.fillRect(0, 0, img.width, img.height);
        ctx.drawImage(img, 0, 0);
        resolve({
          width: img.width,
          height: img.height,
          dataUrl: canvas.toDataURL(transparentBackground ? 'image/png' : 'image/jpeg')
        });
      };

      img.onerror = event => reject((<any>event).error);

      const src = `${this.baseUri}/${bucketId}/${file.name}`;
      img.src = src;

      if (img.complete || img.complete === undefined) {
        img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
        img.src = src;
      }
    });
  }
}
