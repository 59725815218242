import {Component, Input, Optional} from '@angular/core';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {TRANSLATION_EDITOR_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';
import {Translatable, TranslationService} from '@ngmedax/translation';


// hack to inject decorator declarations. must occur before class declaration!
export interface QuestionTypeSignatureComponent extends Translatable {}

@Component({
  selector: 'app-qa-question-signature',
  templateUrl: './question-type-signature.component.html',
  styleUrls: ['./question-type-signature.component.css', '../../../shared/reusable.css']
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class QuestionTypeSignatureComponent {
  @Input() question: Questionnaire.Container;

  /**
   * Inject dependencies
   */
  public constructor(@Optional() private translationService: TranslationService) {
  }
}
