<div class="maximized">
  <div class="row justify-content-center mt-5">
    <div class="col-12 text-center mt-5">
      <h4>{{_(KEYS.DEFAULT.ENTER_JUMP_IN_CODE)}}</h4>
    </div>
  </div>
  <div class="row justify-content-center mt-3">
    <div class="col-auto">
      <div class="d-flex">
        <input *ngFor="let field of codeFields; let i = index"
               type="text"
               class="form-control text-center mx-1"
               style="width: 50px;"
               maxlength="1"
               autocomplete="off"
               [id]="'code-' + i"
               [(ngModel)]="codeFields[i]"
               (paste)="onPaste($event, i)"
               (keydown)="onKeyDown($event, i)">
      </div>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-12 text-center mt-4">
      <b>{{_(KEYS.DEFAULT.ENTER_BIRTH_YEAR)}}</b>
    </div>
  </div>
  <div class="row justify-content-center mt-3">
    <div class="col-auto">
      <div class="d-flex">
        <input type="number"
               id="birthYear"
               class="form-control"
               [(ngModel)]="birthYear"
               placeholder="YYYY"
               min="1900"
               [max]="getDateFormat('YYYY')"
               (keydown)="onBirthYearKeyDown($event)"
               autocomplete="off">
      </div>
    </div>
  </div>


  <div class="row justify-content-center mt-4">
    <div class="col-auto">
      <button class="btn btn-primary"
              (click)="onSubmit()"
              [disabled]="!canSubmit()">
        {{_(KEYS.DEFAULT.START_SURVEY)}}
      </button>
    </div>
  </div>
</div>
