<div class="qa-number-options">
  <label class="min-label" for="min">{{_(KEYS.EDITOR.MIN)}}:</label>
  <input class="min-input form-control" type="number" id="min" [(ngModel)]="question.options.min">

  <label class="max-label" for="max">{{_(KEYS.EDITOR.MAX)}}:</label>
  <input class="max-input form-control" type="number" id="max" [(ngModel)]="question.options.max">

  <label class="steps-label" for="steps">{{_(KEYS.EDITOR.STEPS)}}:</label>

  <input
    class="steps-input form-control"
    type="number" id="steps"
    (change)="onUpdateSteps()"
    (keyup)="onUpdateSteps()"
    [step]="steps"
    [(ngModel)]="question.options.steps"
  >

  <label class="unit-label" for="unit">{{_(KEYS.EDITOR.UNIT)}}:</label>
  <input class="unit-input form-control" id="unit" placeholder="Bsp. Kg" [(ngModel)]="question.options.unit[locale]">

  <!-- no longer in use
  <label class="scale-label" for="steps">{{_(KEYS.EDITOR.SCALE)}}:</label>
  <input class="scale-input form-control" type="checkbox" id="scale" [(ngModel)]="question.options.drawScale">
  -->


  <label class="display-label" for="unit">{{_(KEYS.EDITOR.DISPLAY_TYPE)}}:</label>
  <select class="display-input form-control" [(ngModel)]="question.displayType">
    <option value="text">{{_(KEYS.EDITOR.INPUT_FIELD)}}</option>
    <!-- <option value="spinner">Spinner (Hoch/Runter Buttons)</option> -->
    <option value="slider">{{_(KEYS.EDITOR.SLIDER)}}</option>
    <!-- <option value="dropdown">Dropdown</option> -->
  </select>
</div>
