import {Component, EventEmitter, HostListener, Input, Optional, Output, ViewEncapsulation} from '@angular/core';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {FORMAT_TO_TEMPLATE_MAPPING, TEMPLATE_TO_ICON_MAPPING} from '../../../mappings';
import {TRANSLATION_EDITOR_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';


// hack to inject decorator declarations. must occur before class declaration!
export interface QuestionIndexComponent extends Translatable {}
declare const $: any;

@Component({
  selector: 'app-qa-question-index',
  templateUrl: './question-index.component.html',
  styleUrls: ['./question-index.component.css'],
  encapsulation: ViewEncapsulation.None
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class QuestionIndexComponent {
  @Output() done: EventEmitter<any> = new EventEmitter();
  @Input() questionnaire: Questionnaire;
  @Input() hideLabel = false;
  @Input() truncateAfter = 25;

  /**
   * Hardcoded locale
   */
  public locale = 'de_DE';

  /**
   * Select2 options
   */
  public options: any = {};

  /**
   * Element format to icon mapping
   */
  private iconMapping = TEMPLATE_TO_ICON_MAPPING;

  /**
   * Element format to template mapping
   */
  private formatMapping = FORMAT_TO_TEMPLATE_MAPPING;

  /**
   * Injects dependencies and initializes select2 options
   */
  public constructor(
    @Optional() private translationService: TranslationService
  ) {
    // adds matching icon on left side of element text
    const iconRenderer = (state) => {
      const elementFormat = state && state.id ? $(`option[value="${state.id}"]`).attr('data-format') : '';
      const templateName = elementFormat ? this.formatMapping[elementFormat] : '';
      const icon = templateName ? this.iconMapping[templateName] : '';

      if (icon) {
        return $(`<span><i class="fa fa-fw ${icon}"></i> ${state.text.trim()}</span>`);
      }

      return state.text;
    };

    this.options = {
      width: '100%',
      placeholder: '...',
      templateResult: iconRenderer,
      templateSelection: iconRenderer
    };
  }

  /**
   * Sadly, NgbPopover does not support auto hide on click outside, so we have to implement this.
   * When Ngb is updated to a version >= 3.0.0, we can use the "autoClose" option.
   *
   * @param targetElement
   */
  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    const popover = $('ngb-popover-window');
    const select2Dropdown = $('.select2-dropdown');
    const clickInside = popover.find(targetElement).length || select2Dropdown.find(targetElement).length;

    if (!clickInside) {
      $('ngb-popover-window').hide();
    }
  }

  /**
   * Jumps to a questionnaire element by selected element id
   * @param elementId
   */
  public onJumpToElement(elementId: string) {
    this.done.emit();

    const element = $(`#${elementId}`);

    if (!element.length) {
      return;
    }

    const posX = element.offset().top - 110;
    $('html,body').animate({scrollTop: posX}, 400);
  }
}
