import {RouterModule, Routes} from '@angular/router';
import {DeviceGridComponent} from './components/device-grid.component';
import {DeviceFormComponent} from './components/device-form.component';
import {permission} from './device.permission';

const APP_ROUTES: Routes = [
  {
    path: 'module/device/grid',
    component: DeviceGridComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: true,
      needsPermissions: [
        permission.MYMEDAX_DEVICE_READ,
        permission.MYMEDAX_DEVICE_DELETE
      ]
    }
  },
  {
    path: 'module/device/create',
    component: DeviceFormComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: true,
      needsPermissions: [
        permission.MYMEDAX_DEVICE_CREATE,
        permission.MYMEDAX_DEVICE_READ,
        permission.MYMEDAX_DEVICE_UPDATE
      ]
    }
  }
];

export const routing = RouterModule.forRoot(APP_ROUTES);
