import {RouterModule, Routes} from '@angular/router';
import {permission} from './questionnaire-group.permission';
import {QuestionnaireGroupGridComponent} from './components/grid/questionnaire-group-grid';

const APP_ROUTES: Routes = [
  {
    path: 'module/questionnaire/groups',
    component: QuestionnaireGroupGridComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: true,
      needsPermissions: [
        permission.MYMEDAX_QUESTIONNAIRE_CREATE,
        permission.MYMEDAX_QUESTIONNAIRE_READ,
        permission.MYMEDAX_QUESTIONNAIRE_UPDATE,
        permission.MYMEDAX_QUESTIONNAIRE_DELETE
      ]
    }
  }
];

export const routing = RouterModule.forRoot(APP_ROUTES);
