<div class="modal-header">
  <h4 class="modal-title">{{_(KEYS.HISTORY.SURVEY_HISTORY)}}</h4>
  <button type="button" class="close" (click)="activeModal.dismiss()">
    <i class="fa fa-close"></i>
  </button>
</div>
<div class="modal-body qa-pdf-form-crud animated fadeIn">
  <form class="history-log">
    <ng-container *ngFor="let entry of history">
      <span>{{entry.createdAt | datex:dateFormat}}: <i class="fa {{getIcon(entry)}}"></i> {{getText(entry)}}</span><br>
    </ng-container>

    <ng-container *ngIf="noEntries">
      {{_('Keine Einträge vorhanden...')}}
    </ng-container>
  </form>
</div>
<div class="modal-footer">
</div>
