<div class="modal-header">
  <h4 class="modal-title">{{_(KEYS.CRUD.SIGNATURES)}}</h4>
  <button type="button" class="close" (click)="activeModal.dismiss()">
    <i class="fa fa-close"></i>
  </button>
</div>
<div class="modal-body qa-pdf-form-crud animated fadeIn">
  <div *ngIf="pageImg && !editMode" style="position: relative;">
    <img [src]="pageImg"  class="page-image"/>

    <ng-container *ngIf="pdfForm && pdfForm.signatures">
      <ng-container *ngFor="let signature of pdfForm.signatures; let pos = index;">
        <div [ngStyle]="getBoxStyle(signature)" class="qa-signature-box" (click)="onEdit(pos)" *ngIf="pageNumber === signature.page">
          <section *ngIf="signature.title && signature.title[locale]"><p>{{signature.title[locale]}}</p></section>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <image-cropper
    #imageCropper
    *ngIf="pageImg && editMode"
    [autoCrop]="false"
    [aspectRatio]="1"
    [maintainAspectRatio]="false"
    [imageBase64]="pageImg"
    (imageLoaded)="onImageLoaded()"
    (imageCropped)="onImageCropped($event)"
    format="jpeg"
  ></image-cropper>

  <div class="input-group qa-signature-title mt-1">
    <span class="input-group-addon">{{_(KEYS.CRUD.TITLE)}}:</span>
    <input class="form-control" [(ngModel)]="signatureTitle">
    <button type="button" class="btn btn-success ml-1" (click)="onAdd()" [attr.disabled]="editMode ? true: null">
      <i class="fa fa-plus"></i>
    </button>
    <button type="button" class="btn btn-danger ml-1" (click)="onDelete()" [attr.disabled]="!editMode ? true: null">
      <i class="fa fa-trash"></i>
    </button>
    <button type="button" class="btn btn-primary ml-1" (click)="onDone()" [attr.disabled]="(!editMode || !signatureTitle) ? true: null">
      <i class="fa fa-check"></i>
    </button>
  </div>

  <div class="input-group qa-signature-title mt-1">
    <button type="button" class="btn btn-success mr-5" [attr.disabled]="pageNumber <= 1 ? true: null" (click)="loadPage(pageNumber -1)">
      <i class="fa fa-arrow-left"></i>
    </button>

    <span class="qa-page-info">Seite {{pageNumber}} von {{pageCount}}</span>

    <button type="button" class="btn btn-success ml-5" [attr.disabled]="pageNumber >= pageCount ? true: null" (click)="loadPage(pageNumber + 1)">
      <i class="fa fa-arrow-right"></i>
    </button>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="onCancel()">
    <i class="fa fa-close"></i>
    {{_(KEYS.CRUD.CANCEL)}}
  </button>
  <button type="button" class="btn btn-primary" (click)="onSave()">
    <i class="fa fa-save"></i>
    {{_(KEYS.CRUD.SAVE)}}
  </button>
</div>
