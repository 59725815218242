import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '@ngmedax/config';
import {AuthUriService} from './auth-uri.service';
import {configKeys} from '../device.config-keys';
import {Device} from '../../../types';


@Injectable()
export class DeviceService {
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private authUriService: AuthUriService) {
  }

  /**
   * Lists all devices in current tenant
   *
   * @returns {Promise<any>}
   */
  public getDevices(filter?: Device.Filter, opts?: any) {
    const query: any = {filter: JSON.stringify(filter) || '{}', opts: JSON.stringify(opts) || '{}'};
    return new Promise((resolve, reject) => {
      const basePath = `${this.authUriService.buildUrl(configKeys.DEVICE_URI_CONFIG_KEY)}?${decodeURI(new URLSearchParams(query).toString())}`;

      this.http
        .get(basePath, {headers: this.authUriService.getAuthHeaders()})
        .subscribe(
          (data: any) => resolve(data.device || data.rows || data),
          (err) => reject(err)
        );
    });
  }

  /**
   * Loads patients
   *
   * @param filter: any
   * @returns {Promise<Patient[]>}
   */
  public getClients(): Promise<Device.Client[]> {
    const basePath = this.authUriService.buildUrl(configKeys.PATIENT_URI_CONFIG_KEY);

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers: this.authUriService.getAuthHeaders()})
        .subscribe((client: any) => {
            const clients = client.patient || client.rows || client || [];

            // TODO: sorting should be done on server side!
            if (Object.prototype.toString.call(clients) === '[object Array]') {
              clients.sort((a , b) => (`${a.lastName} ${a.firstName}` < `${b.lastName} ${b.firstName}`) ? -1 : 1);
            }

            resolve(clients);
          },
          error => {
            resolve([])
          });
    });
  }

  /**
   * Removes device from backend / database
   *
   * @param {Device} device
   * @returns {Promise<any>}
   */
  public removeDevice(device: Device) {
    return new Promise<void>((resolve, reject) => {
      const apiUrl = this.authUriService.buildUrl(
        configKeys.DEVICE_URI_CONFIG_KEY,
        '/' + device.uid
      );

      this.http
        .delete(apiUrl, {headers: this.authUriService.getAuthHeaders()})
        .subscribe(
          () => resolve(),
          (err) => reject(err)
        );
    });
  }

  /**
   * Returns configured qr url
   *
   * @returns {string}
   */
  public getQrUrl() {
    const apiUrl = this.configService.get(configKeys.MOBILE_URI_CONFIG_KEY);
    const qrString = 'mymedax|serverurl|' + apiUrl;
    return qrString;
  }
}
