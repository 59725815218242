<form [formGroup]="form" class="mt-1">
  <ng-template #tipContent let-tooltipMsg="tooltipMsg">{{tooltipMsg}}</ng-template>
  <div class="btn-toolbar justify-content-between" *ngFor="let scoringVarName of getScoringVariableNames(true)">
    <div class="input-group" [ngClass]="{'dirty-entry': form.get(scoringVarName).dirty}" padding>
      <input
        [value]="scoringVarName"
        id="{{scoringVarName}}"
        name="{{scoringVarName}}"
        [formControlName]='scoringVarName'
        [ngbTooltip]="tipContent" triggers="manual" #tt="ngbTooltip"
        (change)="onCheckForTooltip(tt, form.get(scoringVarName))"
        (keyup)="onCheckForTooltip(tt, form.get(scoringVarName))"
        #scoringInputEl
      >
      <button
        class="btn btn-success ml-1"
        [disabled]="(scoringVarName === scoringInputEl.value || form.get(scoringVarName).errors) ? true : null"
        (click)="onEdit(scoringInputEl.value, scoringVarName)"
      >
        <i class="fa fa-save"></i>
      </button>
      <button class="btn btn-primary ml-1" (click)="onUndo(scoringVarName)">
        <i class="fa fa-undo"></i>
      </button>
      <button class="btn btn-danger ml-1" (click)="onRemove(scoringVarName)">
        <i class="fa fa-trash"></i>
      </button>
    </div>
  </div>
</form>

<div class="input-group mt-2 mb-2">
  <span class="input-group-addon">{{_(KEYS.EDITOR.ADD_SCORING_VARIABLE)}}</span>
  <button
    class="btn input-group-addon add-btn btn-success add-btn"
    (click)="onAdd()">
    <i class="fa fa-plus"></i>
  </button>
</div>
