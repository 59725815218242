import {ThemingComponent} from './components/theming.component';
import {SettingsThemeService} from './services/settings-theme.service';
import {NAVIGATION} from './settings-theming.navigation';
import {routing} from './settings-theming.routing';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LayoutService} from '@ngmedax/layout';
import {ColorPickerModule} from 'ngx-color-picker';
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from "@angular/forms";


@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ColorPickerModule,
    NgbTooltipModule,
    NgbModule,
    routing,
  ],
  declarations: [
    ThemingComponent
  ],
  exports: [],
  providers: [
    SettingsThemeService
  ]
})
export class SettingsThemingModule {
  constructor(private layoutService: LayoutService) {
    this.layoutService.addMenuEntry(NAVIGATION);
  }
}
