import * as crypto from 'crypto';

/**
 * Nonce
 */
export class Nonce {

  /**
   * Adds nonce to given object
   *
   * @param {any} data
   */
  public add(data: any): any {
    const nonce = crypto.randomBytes(12).toString('base64');
    Object.assign(data, {nonce});
    return data;
  }
}
