import {Component, Input, OnInit, Optional} from '@angular/core';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {ValueService} from '@ngmedax/value';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {TRANSLATION_EDITOR_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';
import * as sha from 'js-sha256';
import {QuestionnaireVariablesService} from '../../services/questionnaire-variables.service';


// hack to inject decorator declarations. must occur before class declaration!
export interface ScoringVariablesComponent extends Translatable {}

@Component({
  selector: 'app-qa-scoring-variables',
  templateUrl: './scoring-variables.component.html',
  styleUrls: ['./scoring-variables.component.css']
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class ScoringVariablesComponent implements OnInit {
  @Input() questionnaire: Questionnaire;
  public form: FormGroup;

  private varPath  = ['meta', 'options', 'variables'];
  private varNamePattern =  /^[a-zA-Z0-9_]{3,50}$/;
  private get formErrorMsg() {return this._(KEYS.EDITOR.INVALID_VAR_NAME_TEXT);}

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    private questionnaireVariables: QuestionnaireVariablesService,
    private formBuilder: FormBuilder,
    private value: ValueService
  ) {
  }

  /**
   * Initializes scoring variables and updates form
   */
  public ngOnInit() {
    this.initScoringVariables();
    this.updateForm();
  }

  /**
   * Adds new scoring variable. Defaults to name "scoring${x}" where ${x} is an auto generated ascending number
   */
  public onAdd() {
    const varPrefix = 'scoring';
    const scoringVariables = this.questionnaireVariables.getVariablesForScope('scoring');

    for (let i = 1; i < 1000; i++) {
      const varName = `${varPrefix}${i}`;
      if (!scoringVariables[varName]) {
        scoringVariables[varName] = {keepMe: true};
        break;
      }
    }

    this.updateForm();
  }

  /**
   * Event handler for when a scoring var is changed
   *
   * @param {string} varName
   * @param {string} previousVarName
   */
  public onEdit(varName: string, previousVarName: string) {
    this.renameVariable('scoring', varName, previousVarName);
    this.renameGdtScoringVariable(varName, previousVarName);
    this.updateForm();
  }

  /**
   * Event handler for when scoring var is reset to previous value
   *
   * @param {string} previousVarName
   */
  public onUndo(previousVarName: string) {
    this.form.get(previousVarName).reset(previousVarName);
  }

  /**
   * Event handler for when scoring var is removed
   *
   * @param {string} varName
   */
  public onRemove(varName: string) {
    const scoringVariables = this.questionnaireVariables.getVariablesForScope('scoring');
    scoringVariables[varName] && delete(scoringVariables[varName]);
    this.renameGdtScoringVariable(null, varName);
    this.updateForm();
  }

  /**
   * Shows tooltip when current form element value is invalid
   *
   * @param {NgbTooltip} tooltip
   * @param {AbstractControl} formEl
   */
  public onCheckForTooltip(tooltip: NgbTooltip, formEl: AbstractControl) {
    if (formEl.dirty && formEl.errors) {
      tooltip.open({tooltipMsg: this.formErrorMsg});
      return;
    }

    tooltip && tooltip.isOpen() && tooltip.close();
  }

  /**
   * Returns array of scoring variables by questionnaire or by form
   *
   * @param {boolean} byForm
   * @returns string[]
   */
  public getScoringVariableNames(byForm = false): string[] {
    const formControls = this.value.get(this.form, ['controls']) || {};
    return Object.keys(byForm ? formControls : this.questionnaireVariables.getVariablesForScope('scoring'));
  }

  /**
   * Renames variable
   *
   * @param {string} varName
   * @param {string} previousVarName
   */
  public renameVariable(scope: string, varName: string, previousVarName: string) {
    const variables = this.questionnaireVariables.getVariablesForScope(scope);

    if (!variables[previousVarName]) {
      return;
    }

    variables[varName] = this.value.clone(variables[previousVarName]);
    delete(variables[previousVarName]);
  }

  /**
   * Renames or deletes gdt scoring variable
   *
   * @param {string} varName
   * @param {string} previousVarName
   */
  public renameGdtScoringVariable(varName: string = null, previousVarName: string) {
    const gdtPreviousPathHash = sha.sha256(previousVarName);
    const gdtPathHash = varName ? sha.sha256(varName): '';
    const gdtKey = this.questionnaireVariables.getMappedVariableName('gdt', gdtPreviousPathHash);

    if (!gdtKey) {
      return;
    }

    const varOpts = this.value.clone(this.questionnaireVariables.getMappedVariableOptions('gdt', gdtPreviousPathHash, gdtKey));
    varName && this.questionnaireVariables.addVariableMapping('gdt', gdtKey, gdtPathHash, varOpts);
    this.questionnaireVariables.deleteVariableMapping('gdt', gdtKey, gdtPreviousPathHash, true);
  }

  /**
   * Updates scoring variables form by questionnaire scoring variables
   */
  private updateForm() {
    const varNames = this.getScoringVariableNames();
    const dynFormGroup = {};

    for (const varName of varNames) {
      dynFormGroup[varName] = this.formBuilder.control(varName, [Validators.pattern(this.varNamePattern)]);
    }

    this.form = this.formBuilder.group(dynFormGroup);
  }

  /**
   * Initializes questionnaire scoring variables in questionnaire
   */
  private initScoringVariables() {
    let current = this.questionnaire;
    [...this.varPath, 'scoring'].forEach((path) => {
      !current[path] && (current[path] = {});
      current = current[path];
    });
  }
}
