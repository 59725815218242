import {Questionnaire, Translation} from '../common-questionnaire-types';

export namespace Survey {
  export enum Status {
    created = 'created',
    assigned = 'assigned',
    submitted = 'submitted',
    signed = 'signed',
    unknown = 'unknown'
  }
}

export interface Submission {
  _id: string;
  id?: string;
  uid?: string;
  revision?: number;
  swVersion?: string;
  bucketId?: string;
  requestId?: string;
  clientId?: string;
  surveyId?: string;
  client: any;
  questionnaire: Questionnaire;
  answers: {[answerPatHash: string]: any};
  variables: {
    scoring: {[varName: string]: number}
    inline: {[varName: string]: string | boolean | number};
    container: {[varName: string]: string | boolean | number};
  };
  visibilities: {[patHash: string]: boolean};
  signDocuments: {
    [documentId: string]: {
      documentId: string,
      documentName: string,
      sourceFileName: string,
      targetFileName: string,
      sourceType: string,
      targetType: string,
      processed: boolean
    }
  };
  uploadLinks?: any;
  exports?: any;
  error?: {
    message: string,
    stack: string
  };
  status?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface Client {
  uid?: string;
  customerNr?: string | number;
  caseNr?: string | number;
  gender?: string;
  lastName?: string;
  firstName?: string;
  address?: string;
  status?: string;
  birthDate?: string;
  location?: string;
  createdAt?: string;
  updatedAt?: string;
  anonymous?: boolean;
  pseudonymous?: boolean;
  pseudoId?: string;
  questionnaires?: Questionnaire[];
}

export interface Survey {
  _id: string;
  id?: string;
  uid?: string;
  clientId: string;
  appDataId?: string;
  appDataLink?: string;
  locale?: string;
  client: Client;
  questionnaires: Questionnaire[];
  submissions: Submission[];
  additionalData?: any;
  notificationApiUri?: string;
  returnUri?: string;
  validUntil?: Date;
  signing: boolean;
  allowMail?: boolean;
  status: Survey.Status;
  createdBy?: string;
  updatedAt?: string;
  createdAt?: string;
}

export interface SurveyHistory {
  id: string;
  surveyId: string;
  status: string;
  event: string;
  data: null | SurveyHistory.Data.File | SurveyHistory.Data.Mail;
  createdAt: string;
  updatedAt: string;
}

export namespace SurveyHistory {
  export namespace Data {
    export interface File {
      fileName: string;
      fileType: string;
      submissionId: string;
      questionnaireTitle: Translation;
    }

    export interface Mail {
      from: string;
      to: string;
      subject: string;
      text: string;
      attachments: Mail.Attachment[];
    }

    export namespace Mail {
      export interface Attachment {
        filename: string;
        path: string;
        cid: string;
      }
    }
  }
}
