import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmldecode',
})
export class HtmlDecodePipe implements PipeTransform {
  /**
   * Replaces html entities with text
   *
   * @param {string} value
   * @returns {string}
   */
  transform(value: string) {
    if (value && typeof value === 'string') {

      const el = document.createElement('div');
      el.innerHTML = value;
      value = el.textContent;
    }

    return value;
  }
}
