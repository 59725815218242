/**
 * Mapping for translation keys, so we don't have to use long text in source code. Please keep in mind, that the
 * german default translations are the keys, so don't change them, if you wan't to change the text.
 * Instead change the de_DE translations!
 */
export const KEYS = {
  GRID: {
    FILTER_GROUPS: 'Fachgruppen',
    SEARCH: 'Suche',
    ID: 'Id',
    NAME: 'Name',
    RESET: 'Zurücksetzen',
    SAVE: 'Speichern',
    DELETE: 'Löschen',
    ADD: 'Hinzufügen',
    ENTRIES: 'Einträge',
    ERROR_LOADING_FILTER_GROUPS: "Beim Laden der Fachgruppen ist ein Fehler aufgetreten!",
    FILTER_GROUP_ALREADY_EXISTS: "Es existiert bereits eine Fachgruppe mit der Id:",
    ERROR_SAVING_FILTER_GROUP: "Beim Anlegen der Fachgruppe ist ein Fehler aufgetreten!",
    ERROR_UPDATING_FILTER_GROUP: "Beim Aktualisieren der Fachgruppe ist ein Fehler aufgetreten!",
    ERROR_RESETTING_FILTER_GROUP: "Beim Zurücksetzen der Fachgruppe ist ein Fehler aufgetreten!",
    ERROR_DELETING_FILTER_GROUP: "Beim Löschen der Fachgruppe ist ein Fehler aufgetreten!"
  }
};
