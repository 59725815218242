<div class="qa-grid">
  <h2 class="mb-4">{{_(KEYS.GRID.QUESTIONNAIRE_SHEETS)}}</h2>

  <div class="card mb-5">
    <div class="card-body">
      <div class="card-title">

        <div class="btn-toolbar justify-content-between">
          <div class="justify-content-between qa-lh-2p5">
            <i class="fa fa-search"></i>
            <h5 class="d-inline">{{_(KEYS.GRID.SEARCH)}}</h5>
          </div>

          <button class="btn btn-primary d-inline-block" (click)="isSearchCollapsed = !isSearchCollapsed">
            <i class="fa" [ngClass]="{'fa-arrow-down': isSearchCollapsed, 'fa-arrow-up': !isGridCollapsed}"></i>
          </button>
        </div>
      </div>
      <div class="card-text" [ngbCollapse]="isSearchCollapsed" #qaSearch>
        <div class="qa-grid-search mt-2 mb-1">
          <div class="input-group qa-grid-search-title">
            <span class="input-group-addon">{{_(KEYS.GRID.TITLE)}}:</span>
            <input class="form-control" [(ngModel)]="filter.title"
                   (paste)="onFilterChange()" (change)="onFilterChange()" (keydown)="onFilterChange()">
          </div>

          <div class="input-group qa-grid-search-filtergroup">
            <span class="input-group-addon">{{_(KEYS.GRID.FILTER_GROUPS)}}: </span>
            <select class="custom-select" data-edit multiple>
              <ng-container *ngFor="let filtergroup of filtergroupObjects">
                <option [value]="filtergroup.id">
                  {{filtergroup.name[locale]}}
                </option>
              </ng-container>
            </select>
          </div>

          <div class="input-group qa-grid-search-author">
            <span class="input-group-addon">{{_(KEYS.GRID.AUTHOR)}}:</span>
            <input class="form-control" [(ngModel)]="filter.author"
                   (paste)="onFilterChange()" (change)="onFilterChange()" (keydown)="onFilterChange()">
          </div>

          <div class="input-group qa-grid-search-status">
            <span class="input-group-addon">{{_(KEYS.GRID.STATUS)}}:</span>
            <select class="form-control custom-select" [(ngModel)]="filter.status" (change)="onFilterChange()">
              <option value="">...</option>
              <option value="draft">{{_(KEYS.GRID.DRAFT)}}</option>
              <option value="active">{{_(KEYS.GRID.APPROVED)}}</option>
            </select>
          </div>

      </div>
    </div>
  </div>
  </div>

  <div class="card">
    <div class="card-body">
      <div class="card-title">

        <div class="btn-toolbar justify-content-between">
          <div class="justify-content-between qa-lh-2p5">
            <i class="fa fa-copy"></i>
            <h5 class="d-inline">{{_(KEYS.GRID.QUESTIONNAIRES)}}</h5>
          </div>

          <button class="btn btn-primary d-inline-block" (click)="isGridCollapsed = !isGridCollapsed">
            <i class="fa" [ngClass]="{'fa-arrow-down': isGridCollapsed, 'fa-arrow-up': !isGridCollapsed}"></i>
          </button>
        </div>

      </div>
      <div class="card-text" [ngbCollapse]="isGridCollapsed">
        <table class="table">
          <thead>
          <tr>
            <th class="id-col">{{_(KEYS.GRID.ID)}}</th>
            <th>{{_(KEYS.GRID.TITLE)}}</th>
            <!--<th>Beschreibung</th>-->
            <th class="td-filtergroup">{{_(KEYS.GRID.FILTER_GROUPS)}}</th>
            <th class="td-author">{{_(KEYS.GRID.AUTHOR)}}</th>
            <th>{{_(KEYS.GRID.ACTIVE)}}</th>
            <th>{{_(KEYS.GRID.CREATED_AT)}}</th>
            <th>{{_(KEYS.GRID.UPDATED_AT)}}</th>
            <th>{{_(KEYS.GRID.OPTIONS)}}</th>
          </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let questionnaire of getQuestionnaires(); let pos = index;">
              <tr [ngClass]="{'qa-tr-odd': pos % 2 === 0}">
                <td class="id-col">
                  <span ngbTooltip="{{questionnaire.id}}">{{questionnaire.id | truncate: 5}}</span>
                </td>
                <td>
                  <span ngbTooltip="{{questionnaire.meta.title[locale] | striptags | htmldecode}}">
                    {{questionnaire.meta.title[locale] | striptags | htmldecode | truncate: 30}}
                  </span>
                </td>
                <!--
                <td>
                  <span ngbTooltip="{{questionnaire.meta.description[locale] | striptags | htmldecode}}">
                    {{questionnaire.meta.description[locale] | striptags | htmldecode | truncate: 40}}
                  </span>
                </td>
                -->
                <td class="td-filtergroup">
                  <ng-container *ngIf="questionnaire.meta.tags">
                    <ul>
                      <li *ngFor="let tag of filterTags(questionnaire.meta.tags);">{{tag}}</li>
                    </ul>
                  </ng-container>
                </td>
                <td class="td-author">
                  <span ngbTooltip="{{questionnaire.meta.author}}">
                    {{questionnaire.meta.author | truncate: 20}}
                  </span>
                </td>
                <td>
                  <ng-container *ngIf="questionnaire.status === 'active'; else qaInactive">
                    <i class="fa fa-check qa-status-active"></i>
                  </ng-container>
                  <ng-template #qaInactive>
                    <i class="fa fa-times qa-status-inactive"></i>
                  </ng-template>
                </td>
                <td>{{questionnaire.createdAt | datex:dateFormat}}</td>
                <td>{{questionnaire.updatedAt | datex:dateFormat}}</td>
                <td>
                  <div class="d-inline-block nowrap">
                    <div
                      class="btn btn-sm btn-primary d-inline-block mr-1"
                      (click)="onDownload(questionnaire)"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="{{_(KEYS.GRID.DOWNLOAD)}}"
                    >
                      <i class="fa fa-download"></i>
                    </div>
                    <button
                      class="btn btn-sm btn-primary d-inline-block mr-1"
                      (click)="onClone(questionnaire)"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="{{_(KEYS.GRID.DUPLICATE)}}"
                      [attr.disabled]="isNumQuestLimitReached ? true : null">
                      <i class="fa fa-copy"></i>
                    </button>
                    <div
                      class="btn btn-sm btn-primary d-inline-block mr-1"
                      [routerLink]="'/module/questionnaire/editor/' + questionnaire.id"
                      data-toggle="tooltip" data-placement="top"
                      title="{{_(KEYS.GRID.CHANGE)}}"
                    >
                      <i class="fa fa-edit"></i>
                    </div>
                    <div
                      class="btn btn-sm btn-danger d-inline-block mr-1"
                      (click)="onDelete(questionnaire)"
                      data-toggle="tooltip" data-placement="top"
                      title="{{_(KEYS.GRID.DELETE)}}"
                    >
                      <i class="fa fa-trash"></i>
                    </div>
                 </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>

        <div class="btn-toolbar justify-content-between mt-2 mb-2">
          <div class="justify-content-between">
            <div class="input-group">
              <div class="input-group qa-num-per-page">
                <select class="form-control custom-select" [(ngModel)]="displayPerPage" (change)="onPagingChange()">
                  <option value="2">2</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
                <span class="input-group-addon">{{_(KEYS.GRID.ENTRIES)}}</span>
              </div>
              <label class="btn btn-primary btn-file mb-0 ml-1" [class.disabled]="isProcessing || isNumQuestLimitReached">
                <i class="fa fa-upload"></i> {{_(KEYS.GRID.IMPORT)}}
                <i class="fa fa-spinner fa-pulse fa-fw" *ngIf="isProcessing"></i>
                <form #qaUploadForm>
                  <input
                    *ngIf="!isProcessing && !isNumQuestLimitReached"
                    type="file"
                    (change)="onImportQuestionnaires($event)"
                    accept=".mqt" hidden multiple/>
                </form>
              </label>

              <label class="btn btn-primary btn-file mb-0 ml-1" [class.disabled]="!questionnaires.length" (click)="onExportQuestionnaires()">
                <i class="fa fa-download"></i> {{_(KEYS.GRID.EXPORT_ALL)}}
              </label>
            </div>
          </div>
          <ngb-pagination
            [pageSize]="displayPerPage"
            [maxSize]="9"
            [rotate]="true"
            [(page)]="gridPageNumber"
            [collectionSize]="total"
            (pageChange)="onPagingChange()">
          </ngb-pagination>
        </div>

      </div>
    </div>
  </div>
</div>
