/**
 * Mapping for translation keys, so we don't have to use long text in source code. Please keep in mind, that the
 * german default translations are the keys, so don't change them, if you want to change the text.
 * Instead, change the de_DE translations!
 */
export const KEYS = {
  DEFAULT: {
    ENTER_JUMP_IN_CODE: 'Bitte geben sie ihren Code ein',
    ENTER_BIRTH_YEAR: 'Ihr Geburtsjahr',
    START_SURVEY: 'Befragung starten',
    SURVEY_EXPIRED: 'Die Befragung ist bereits abgelaufen.',
    ERROR_CHECKING_CODE: 'Beim Überprüfen des Codes ist ein Fehler aufgetreten.',
    SURVEY_NOT_FOUND: 'Zu den eingegebenen Daten konnte keine Befragung gefunden werden.',
  }
};

