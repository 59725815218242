import {Component, OnInit, Optional} from '@angular/core';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LayoutService} from '@ngmedax/layout';

import {PdfFormService} from '../../services/pdf-form.service';
import {TRANSLATION_CRUD_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';
import {PdfFormField} from '../../../../types';

import * as pdfjs from 'pdfjs-dist/build/pdf';
import * as pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;



// hack to inject decorator declarations. must occur before class declaration!
export interface PdfFormCrudComponent extends Translatable {}

@Component({
  selector: 'app-pdf-form-crud',
  templateUrl: './pdf-form-crud.component.html',
  styleUrls: ['./pdf-form-crud.component.css'],
})
@Translatable({scope: TRANSLATION_CRUD_SCOPE, keys: KEYS})
export class PdfFormCrudComponent implements OnInit {

  /**
   * Locale for pdfForms. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  public uid: string;
  public name: string;
  public file: File;
  public fileName: string;
  public forceSgn = false;
  public isEditMode = false;
  public form: PdfFormField[];

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    private layoutService: LayoutService,
    public pdfFormService: PdfFormService,
    public activeModal: NgbActiveModal
  ) {
  }

  public ngOnInit() {
    this.uid = this.generateUUID();
  }

  public canSave() {
    const canSave = !this.isEditMode && this.file && this.name;
    const canEdit = this.isEditMode && this.name;
    return canSave || canEdit;
  }

  public onFileSelect(event: Event) {
    const files: FileList = event && event.target && event.target['files'] ? event.target['files'] : null;

    if (!files[0]) {
      return;
    }

    this.file = files[0];
    this.fileName = this.file.name;
  }

  public onCancel() {
    delete this.file;
    delete this.fileName;
    this.activeModal.close();
  }

  public async onSave() {
    console.log('forceSgn:', this.forceSgn);
    this.layoutService.showPreloader();

    try {
      const method = this.isEditMode ? 'updatePdfForm' : 'createPdfForm';
      await (<any>this.pdfFormService[method])({uid: this.uid, name: this.name, file: this.file, forceSgn: this.forceSgn});

      await this.reload(this.uid);
      this.pdfFormService.onPdfFormSaved().emit();
      alert(this._(KEYS.CRUD.SUCCESSFULLY_SAVED_PDF_FORM));
    } catch (error) {
      console.error(error);
      alert(this._(KEYS.CRUD.ERROR_SAVING_FORM));
      this.onCancel();
    }

    this.layoutService.hidePreloader();
  }

  public onDownload() {
    this.pdfFormService.downloadPdfDocument(this.uid, this.fileName);
  }

  public async load(uid: string) {
    await this.reload(uid);
  }

  public async reload(uid: string) {
    const form = await this.pdfFormService.loadPdfForm(uid);

    if (!form) {
      alert(this._(KEYS.CRUD.FOUND_NO_FORM_BY_ID) + ' ' + uid);
      this.layoutService.hidePreloader();
      return;
    }

    this.uid = uid;
    this.name = form.name;
    this.fileName = form.file;
    this.isEditMode = true;
    this.form = form.form;
    this.forceSgn = form.forceSgn || false;
  }

  /**
   * Generates a uuid
   * @returns {string}
   */
  public generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  public stringifyOptions(field: PdfFormField) {
    return (field && field.options && Array.isArray(field.options)) ? field.options.join(', ') : '';
  }
}
