<div class="modal-header">
  <h4 class="modal-title">{{_(KEYS.CRUD.QUESTIONNAIRE_GROUP)}}</h4>
  <button type="button" class="close" (click)="activeModal.dismiss()">
    <i class="fa fa-close"></i>
  </button>
</div>
<div class="modal-body qa-pdf-form-crud animated fadeIn">
  <form>
    <div class="form-group" class="group-name">
      <div class="input-group">
        <span class="input-group-addon">{{_(KEYS.CRUD.NAME)}}</span>
        <input
          class="form-control" id="name"
          [placeholder]="_(KEYS.CRUD.ENTER_NAME)"
          name="name" [(ngModel)]="name">
      </div>
    </div>

    <div class="form-group" class="group-questionnaires mt-3">
      <app-questionnaire-select
        [selected]="selectedQuestionnaires"
        (selectedChange)="selectedQuestionnaires = $event">
      </app-questionnaire-select>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="onCancel()">
    <i class="fa fa-close"></i>
    {{_(KEYS.CRUD.CANCEL)}}
  </button>
  <button type="button" class="btn btn-primary" (click)="onSave()" [attr.disabled]="canSave()? null : true">
    <i class="fa fa-save"></i>
    {{_(KEYS.CRUD.SAVE)}}
  </button>
</div>
