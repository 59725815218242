import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeComponent} from './components/home.component';
import {LayoutService} from '@ngmedax/layout';
import {RouterModule} from '@angular/router';
import {DeviceModule} from '@ngmedax/device';


@NgModule({
  imports: [
    RouterModule,
    DeviceModule,
    CommonModule,
  ],
  declarations: [
    HomeComponent
  ],
  entryComponents: [
    HomeComponent
  ],
  exports: [HomeComponent],
  providers: []
})
export class HomeModule {
  public constructor(
    layoutService: LayoutService,
  ) {
    layoutService.setHomeComponent(HomeComponent);
  }
}
