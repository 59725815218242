import {RouterModule, Routes} from '@angular/router';
import {PatientCrudComponent} from './components/patient-crud.component';
import {permission} from './web-allocator.permission';

const APP_ROUTES: Routes = [
  {
    path: 'module/web-allocator/crud/:id/:authToken',
    component: PatientCrudComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: true,
      needsPermissions: [
        permission.MYMEDAX_WEBALLOCATOR_USE,
        permission.MYMEDAX_PATIENT_READ,
        permission.MYMEDAX_PATIENT_UPDATE,
        permission.MYMEDAX_PATIENT_QUESTIONNAIRE_READ,
        permission.MYMEDAX_PATIENT_QUESTIONNAIRE_ASSIGN,
        permission.MYMEDAX_PATIENT_QUESTIONNAIRE_UNASSIGN
      ]
    }
  },
  {
    path: 'module/web-allocator/crud/:authToken',
    component: PatientCrudComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: true,
      needsPermissions: [
        permission.MYMEDAX_WEBALLOCATOR_USE,
        permission.MYMEDAX_PATIENT_CREATE,
        permission.MYMEDAX_PATIENT_READ,
        permission.MYMEDAX_PATIENT_UPDATE,
        permission.MYMEDAX_PATIENT_QUESTIONNAIRE_READ,
        permission.MYMEDAX_PATIENT_QUESTIONNAIRE_ASSIGN,
        permission.MYMEDAX_PATIENT_QUESTIONNAIRE_UNASSIGN
      ]
    }
  }
];

export const routing = RouterModule.forRoot(APP_ROUTES);
