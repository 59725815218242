import {EventEmitter, Injectable, Optional} from '@angular/core';

import {Translatable, TranslationService} from '@ngmedax/translation';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {TenantService} from '@ngmedax/tenant';
import {LoginService} from '@ngmedax/login';

import {ApiService} from './api.service';
import {QuestionnaireStateService} from './questionnaire-state.service';
import {QuestionnaireFormatService} from './questionnaire-format.service';
import {Filtergroup, QuestionDisplayChangeInterface} from '../../types';
import {TRANSLATION_EDITOR_SCOPE} from '../../constants';
import {KEYS} from '../../translation-keys';


// hack to inject decorator declarations. must occur before class declaration!
export interface QuestionnaireEditorService extends Translatable {}

@Injectable()
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class QuestionnaireEditorService {
  public questionDisplayChange: EventEmitter<QuestionDisplayChangeInterface> = new EventEmitter();

  /**
   * Injects dependencies
   */
  public constructor(
    private apiService: ApiService,
    private questionnaireFormatService: QuestionnaireFormatService,
    private questionnaireStateService: QuestionnaireStateService,
    @Optional() private tenantService: TenantService,
    @Optional() private loginService: LoginService,
    @Optional() private translationService: TranslationService) {
  }

  /**
   * Initializes a new questionnaire
   *
   * @param {Questionnaire} questionnaire
   * @param pathHashMap
   */
  public initQuestionnaire(pathHashMap: any): Questionnaire {
    const questionnaire: Questionnaire = this.questionnaireFormatService.generateQuestionnaire(
      this.getTenantId(),
      this.getLoggedInUserName()
    );

    this.questionnaireStateService.setPathHashMap(pathHashMap);
    this.questionnaireStateService.setQuestionnaire(questionnaire);

    return questionnaire;
  }

  /**
   * Loads questionnaire by given id
   *
   * @param {string} questionnaireId
   * @param {any} pathHashMap
   * @returns {Promise<Questionnaire>}
   */
  public async loadQuestionnaire(questionnaireId: string, pathHashMap: any): Promise<Questionnaire> {
    // reset path hash map
    pathHashMap = {};

    const payload = await this.apiService.loadQuestionnaire(questionnaireId);

    if (!payload.questionnaire) {
      throw new Error('Invalid questionnaire format. Missing property: "questionnaire"');
    }

    // convert groups (pages) to page break elements in order to flatten the questions of the questionnaire
    const flatQuestionnaire = this.questionnaireFormatService.unpack(
      this.questionnaireFormatService.flattenGroups(payload.questionnaire)
    );

    // transform questionnaire to newest format
    this.questionnaireFormatService.transform(flatQuestionnaire);

    this.questionnaireStateService.setPathHashMap(pathHashMap);
    this.questionnaireStateService.setQuestionnaire(flatQuestionnaire);

    return flatQuestionnaire;
  }

  /**
   * Saves the questionnaire
   *
   * @param editMode
   * @returns {Promise<any>}
   */
  public async saveQuestionnaire(editMode = false): Promise<any> {
    const payload = this.questionnaireFormatService.getPayload(this.questionnaireStateService.getQuestionnaire());
    const method = (editMode) ? 'updateQuestionnaire' : 'createQuestionnaire';
    await this.apiService[method](payload);
    this.deleteMemorizedQuestionnaire();
  }

  /**
   * Memorizes given questionnaire
   *
   * @param {Questionnaire} questionnaire
   */
  public memorizeQuestionnaire(questionnaire: Questionnaire) {
    const json = questionnaire ? JSON.stringify(this.questionnaireFormatService.getPayload(questionnaire)) : '';
    localStorage && localStorage.setItem('memorizedQuestionnaire', json);
  }

  /**
   * Returns true if memorized questionnaire present
   *
   * @returns {boolean}
   */
  public hasMemorizedQuestionnaire() {
    return localStorage ? !!localStorage.getItem('memorizedQuestionnaire') : false;
  }

  /**
   * Resumes memorized questionnaire
   */
  public async resumeMemorizedQuestionnaire(): Promise<Questionnaire> {
    const json = localStorage ? localStorage.getItem('memorizedQuestionnaire') : null;

    if (!json) {
      return;
    }

    this.memorizeQuestionnaire(null);
    return this.loadQuestionnaireByJson(json, {});
  }

  /**
   * Deletes memorized questionnaire
   */
  public deleteMemorizedQuestionnaire() {
    this.memorizeQuestionnaire(null);
  }

  /**
   * Loads questionnaire by given json
   *
   * @param json
   * @returns {Promise<Questionnaire>}
   */
  public async loadQuestionnaireByJson(json: string, pathHashMap: any): Promise<Questionnaire> {
    const questionnaire = JSON.parse(json);

    if (!questionnaire || typeof questionnaire !== 'object' || !questionnaire.questionnaire) {
      throw new Error('Invalid questionnaire format. Missing property: "questionnaire"');
    }

    // convert groups (pages) to page break elements in order to flatten the questions of the questionnaire
    const flatQuestionnaire = this.questionnaireFormatService.unpack(
      this.questionnaireFormatService.flattenGroups(questionnaire.questionnaire)
    );

    this.questionnaireStateService.setPathHashMap(pathHashMap);
    this.questionnaireStateService.setQuestionnaire(flatQuestionnaire);

    return flatQuestionnaire;
  }

  /**
   * Returns filter groups
   *
   * @returns {Promise<Filtergroup[]>}
   */
  public async loadFiltergroups(): Promise<Filtergroup[]> {
    return await this.apiService.loadFiltergroups();
  }

  /**
   * Returns currently active tenant id.
   * Returns 0 when tenant service not found
   *
   * @returns {number}
   */
  public getTenantId() {
    if (!this.tenantService) {
      return 0;
    }

    return this.tenantService.getTenantId();
  }

  /**
   * Returns username of currently logged in user.
   * @returns {string}
   */
  public getLoggedInUserName() {
    const unknownUser = 'nologin@mymedax.de';

    if (!this.loginService) {
      return unknownUser;
    }

    const user = this.loginService.getUser();

    if (!user) {
      return unknownUser;
    }

    return user.getUsername();
  }

  /**
   * Opens a deletion confirmation dialog. Executes given callback if confirmation dialog is confirmed
   *
   * @param callback
   */
  public confirmDelete(callback: Function) {
    Promise
      .resolve(confirm(this._(KEYS.EDITOR.QUESTION_DELETE_ELEMENT)))
      .then(sure => sure && callback());
  }

  /**
   * Generates a uuid
   * @returns {string}
   */
  public generateUUID() {
    return this.questionnaireFormatService.generateUUID();
  }
}
