import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ConfigService} from '@ngmedax/config';
import {RegistryService} from '@ngmedax/registry';
import {AssetApiService} from '@ngmedax/asset';
import {PdfForm} from '../../../types';
import {configKeys} from '../pdf-form-select.config-keys';

@Injectable()
export class PdfFormService {
  /**
   * Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';


  public constructor(
    private registryService: RegistryService,
    private configService: ConfigService,
    private assetApi: AssetApiService,
    private http: HttpClient
  ) {
  }

  /**
   * Loads pdf forms
   *
   * @param filter: any
   * @returns {Promise<PdfForm[]>}
   */
  public loadPdfForms(filter?: PdfForm.Filter, opts?: PdfForm.Opts): Promise<{rows: PdfForm[], total: number}> {
    const query: any = {filter: JSON.stringify(filter) || '{}', opts: JSON.stringify(opts) || '{}'};
    const basePath = `${this.getPdfFormApiUrl()}?${decodeURI(new URLSearchParams(query).toString())}`;

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers: this.getAuthHeaders()})
        .subscribe((pdfForm: any) => {
            const pdfForms = pdfForm.pdfForm || pdfForm.rows || pdfForm || [];
            const total = pdfForm.total || 0;
            resolve({rows: pdfForms, total});
          },
          error => {
            reject(error);
          });
    });
  }

  /**
   * Returns api url for pdfForm
   *
   * @param {string} suffix
   * @returns {string}
   */
  private getPdfFormApiUrl(suffix: string = null): string {
    return this.buildUrl(configKeys.PDF_FORM_URI_CONFIG_KEY, suffix);
  }

  /**
   * Returns url for config key. Adds auth information to url path when api is not deprecated
   *
   * @param {string} configKey
   * @returns {any}
   */
  private buildUrl(configKey: string, suffix = null) {
    let uri = this.configService.get(configKey);

    if (suffix) {
      uri = `${uri}${suffix}`;
    }

    return uri;
  }

  /**
   * Returns auth headers by auth token and tenant id
   */
  private getAuthHeaders(): any {
    const headers: any = {};
    const authToken = this.registryService.get(configKeys.SESSION_AUTH_TOKEN);
    const tenantId = this.registryService.get(configKeys.SESSION_TENANT_ID);

    if (authToken) {
      headers['X-Api-Token'] = authToken;
    }

    if (tenantId) {
      headers['X-Api-TenantId'] = `${tenantId}`;
    }

    return headers;
  }
}
