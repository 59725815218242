/**
 * Mapping for translation keys, so we don't have to use long text in source code. Please keep in mind, that the
 * german default translations are the keys, so don't change them, if you wan't to change the text.
 * Instead change the de_DE translations!
 */
export const KEYS = {
  DEFAULT: {
    SELECTED_QUESTIONNAIRES: 'Zugeordnete Fragebögen',
    ERROR_LOADING_QUESTIONNAIRES: 'Beim Laden der Fragebögen ist ein Fehler aufgetreten',
    ERROR_LOADING_GROUPS: 'Beim Laden der Fragebogen Gruppen ist ein Fehler aufgetreten',
    QUESTIONNAIRES: 'Fragebögen',
    GROUPS: 'Gruppen'
  }
};

