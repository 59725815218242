import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ConfigService} from '@ngmedax/config';
import {RegistryService} from '@ngmedax/registry';
import {configKeys} from '../license-manager.config-keys';
import {License} from '../../../types';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {KeyPairStorageService} from './key-pair-storage.service';


@Injectable()
export class LicenseManagerService {
  /**
   * Key password to unlock private/public key for license generation
   */
  private keyPassword = null;

  /**
   * Event emitter for "on license saved" event
   */
  private onLicenseSavedEmitter: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Injects dependencies
   */
  public constructor(
    private keyPairStorageService: KeyPairStorageService,
    private registryService: RegistryService,
    private configService: ConfigService,
    private modal: NgbModal,
    private http: HttpClient
  ) {
  }

  /**
   * Loads licenses
   *
   * @param filter: any
   * @returns {{rows: License[], total: number}}
   */
  public loadLicenses(filter?: any, opts?: any): Promise<{rows: License[], total: number}> {
    const query: any = {filter: JSON.stringify(filter) || '{}', opts: JSON.stringify(opts) || '{}'};
    const basePath = `${this.getLicenseManagerApiUrl()}?${decodeURI(new URLSearchParams(query).toString())}`;

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers: this.getAuthHeaders()})
        .subscribe((result: any) => {
            const rows = result.license || result.rows || result || [];
            const total = result.total || 0;
            resolve({rows, total});
          },
          error => {
            reject(error);
          });
    });
  }

  /**
   * Loads license model
   */
  public loadLicense(licenseId: string): Promise<License> {
    const basePath = `${this.getLicenseManagerApiUrl()}/${licenseId}`;

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers: this.getAuthHeaders()})
        .subscribe((result: any) => {
            const license = result.license || result.rows || result || [];
            resolve(license);
          },
          error => {
            reject(error);
          });
    });
  }

  /**
   * Creates or updates a given license
   *
   * @param {License} license
   * @returns {Promise<License>}
   */
  public saveLicense(license: License): Promise<License> {
    if (license.id) {
      return this.updateLicense(license);
    }

    return this.createLicense(license);
  }

  /**
   * Updates the license
   *
   * @param {License} license
   * @returns {Promise<License>}
   */
  public createLicense(license: License): Promise<License> {
    const basePath = this.getLicenseManagerApiUrl();

    return new Promise((resolve, reject) => {
      this.http.post(basePath, license, {headers: this.getAuthHeaders()})
        .subscribe(
          (response: any) => resolve(<License>(response.license || response)),
          error => reject(error));
    });
  }

  /**
   * Updates the license
   *
   * @param {License} license
   * @returns {Promise<License>}
   */
  public updateLicense(license: License): Promise<License> {
    const basePath = this.getLicenseManagerApiUrl(`/${license.id}`);

    return new Promise((resolve, reject) => {
      this.http.put(basePath, license, {headers: this.getAuthHeaders()})
        .subscribe(
          () => resolve(license),
          error => reject(error));
    });
  }

  /**
   * Deletes the license
   *
   * @param {License} license
   * @returns {Promise<License>}
   */
  public deleteLicense(license: License): Promise<License> {
    const basePath = this.getLicenseManagerApiUrl(`/${license.id}`);

    return new Promise((resolve, reject) => {
      this.http.delete(basePath, {headers: this.getAuthHeaders()})
        .subscribe(
          () => resolve(license),
          error => reject(error));
    });
  }

  /**
   * Returns "on license saved" emitter
   */
  public onLicenseSaved(): EventEmitter<any> {
    return this.onLicenseSavedEmitter;
  }


  /**
   * Returns api url for license manager
   *
   * @param {string} suffix
   * @returns {string}
   */
  private getLicenseManagerApiUrl(suffix: string = null): string {
    return this.buildUrl(configKeys.LICENSE_MANAGER_API_URI_CONFIG_KEY, suffix);
  }

  /**
   * Returns url for config key. Adds auth information to url path when api is not deprecated
   *
   * @param {string} configKey
   * @returns {any}
   */
  private buildUrl(configKey: string, suffix = null) {
    let uri = this.configService.get(configKey);

    if (suffix) {
      uri = `${uri}${suffix}`;
    }

    return uri;
  }

  /**
   * Returns auth headers by auth token and tenant id
   */
  private getAuthHeaders(): any {
    const headers: any = {};
    const authToken = this.registryService.get(configKeys.SESSION_AUTH_TOKEN);
    const tenantId = this.registryService.get(configKeys.SESSION_TENANT_ID);

    if (authToken) {
      headers['X-Api-Token'] = authToken;
    }

    if (tenantId) {
      headers['X-Api-TenantId'] = `${tenantId}`;
    }

    return headers;
  }
}
