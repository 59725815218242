import {Injectable} from '@angular/core';
import {ConfigService} from '@ngmedax/config';
import {RegistryService} from '@ngmedax/registry';


@Injectable()
export class AuthUriService {

  public constructor(
    private registryService: RegistryService,
    private configService: ConfigService
  ) {
  }

  /**
   * Builds url by given config key and suffix
   *
   * @param {string} configKey
   * @returns {any}
   */
  public buildUrl(configKey: string, suffix = null) {
    let baseUri = this.configService.get(configKey);

    if (suffix) {
      baseUri = `${baseUri}${suffix}`;
    }

    return baseUri;
  }

  /**
   * Returns auth headers by auth token and tenant id
   */
  public getAuthHeaders(): any {
    const headers: any = {};
    const authToken = this.registryService.get('session.authToken');
    const tenantId = this.registryService.get('session.tenantId');

    if (authToken) {
      headers['X-Api-Token'] = authToken;
    }

    if (tenantId) {
      headers['X-Api-TenantId'] = `${tenantId}`;
    }

    return headers;
  }
}
