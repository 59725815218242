<div class="theming-crud">
  <h2 class="mb-4">{{_("Ausfüll-Oberfläche")}}</h2>

  <div class="qa-theming-settings">
    <label class="bgcolor-label" for="bgcolor">{{_("Hauptfarbe")}}</label>
    <span class="bgcolor-input">
      <input
        class="form-control" id="bgcolor"
        [colorPicker]="theming.mainColor"
        (colorPickerChange)="onMainColorChange($event)"
        [style.background]="theming.mainColor"
        [value]="theming.mainColor"
        [cpPosition]="'bottom'"
        [cpOKButtonClass]="'btn btn-primary'"
        [cpSaveClickOutside]="true"
        [cpOKButton]="true"
      />
    </span>


    <label class="font-label">{{_("Schriftart")}}</label>
    <select class="font-select form-control" [(ngModel)]="theming.font" (change)="onFontChange($event)">
      <option value="Asap">Asap</option>
      <option value="Open Sans">Open Sans</option>
      <option value="Caveat">Caveat</option>
      <option value="Exo">Exo</option>
      <option value="Roboto Condensed">Roboto Condensed</option>
    </select>

    <label class="image-label mt-3">{{_("Oberfläche Logo")}}</label>
    <div class="image-input card qa-card-inline-block">
      <div class="card-footer">
        <div class="btn-toolbar justify-content-between">
          <div class="justify-content-between">
            <button class="btn btn-primary qa-no-margin" (click)="onOpenMediaCenterModalForLogo()">
              <i class="fa fa-plus"></i>
            </button>
          </div>
          <button
            class="btn btn-danger"
            (click)="onDeleteLogo()"
            [attr.disabled]="!(theming.logo)? true: null">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
          <img
            class="card-img-top qa-canvas-image mb-3 checkerboard"
            *ngIf="theming.logo; else qaCanvasImgPlaceholder"
            [src]="theming.logo.dataUrl"
            (click)="onOpenMediaCenterModalForLogo()" />
          <ng-template #qaCanvasImgPlaceholder>
            <img
              class="card-img-top qa-canvas-image mb-3"
              src="/assets/images/placeholder.png"
              (click)="onOpenMediaCenterModalForLogo()"/>
          </ng-template>
      </div>
    </div>

    <label class="image-pdf-label mt-3">{{_("Briefkopf Logo")}}</label>
    <div class="image-pdf-input card qa-card-inline-block">
      <div class="card-footer">
        <div class="btn-toolbar justify-content-between">
          <div class="justify-content-between">
            <button class="btn btn-primary qa-no-margin" (click)="onOpenMediaCenterModalForLogoPdf()">
              <i class="fa fa-plus"></i>
            </button>
          </div>
          <button
            class="btn btn-danger"
            (click)="onDeleteLogoPdf()"
            [attr.disabled]="!(theming.logoPdf)? true: null">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <img
          class="card-img-top qa-canvas-image mb-3 checkerboard"
          *ngIf="theming.logoPdf; else qaCanvasImgPdfPlaceholder"
          [src]="theming.logoPdf.dataUrl"
          (click)="onOpenMediaCenterModalForLogoPdf()" />
        <ng-template #qaCanvasImgPdfPlaceholder>
          <img
            class="card-img-top qa-canvas-image mb-3"
            src="/assets/images/placeholder.png"
            (click)="onOpenMediaCenterModalForLogoPdf()"/>
        </ng-template>
      </div>
    </div>

    <label class="image-pdf-position-label mt-3">{{_("Briefkopf Position")}}</label>
    <div class="image-pdf-position-input">
      <div class="btn-group" role="group">
        <button type="button" class="btn"
                [ngClass]="{'btn-success': theming.logoPdfPosition === 'left'}"
                (click)="onLogoPdfPositionChange('left')">
          <i class="fa fa-arrow-left"></i> {{_("Links")}}
        </button>
        <button type="button" class="btn"
                [ngClass]="{'btn-success': theming.logoPdfPosition === 'center'}"
                (click)="onLogoPdfPositionChange('center')">
          <i class="fa fa-align-center"></i> {{_("Mittig")}}
        </button>
        <button type="button" class="btn"
                [ngClass]="{'btn-success': theming.logoPdfPosition === 'right'}"
                (click)="onLogoPdfPositionChange('right')">
          <i class="fa fa-arrow-right"></i> {{_("Rechts")}}
        </button>
      </div>
    </div>

    <label class="image-pdf-height-label mt-3">{{_("Briefkopf Höhe")}}</label>

    <div class="input-group image-pdf-height-input">
      <input class="form-control" type="number" [(ngModel)]="theming.logoPdfHeight" />
      <div class="input-group-addon">px</div>
    </div>
  </div>
  <div class="btn-footer">
    <button type="button" class="btn btn-primary" (click)="onSave()" [attr.disabled]="!(canSave())? true: null">
      <i class="fa fa-save"></i> {{_("Speichern")}}
    </button>

    <span class="vl ml-2">&nbsp;</span>

    <button
      class="btn theming-active-input ml-2"
      (click)="onToggle()" [ngClass]="{'btn-success': theming.active, 'btn-secondary': !theming.active}">
      <span class="label" *ngIf="theming.active">{{_("Theming aktiviert")}} &nbsp;</span>
      <span class="label" *ngIf="!theming.active">{{_("Theming deaktiviert")}} &nbsp;</span>
      <i class="fa" [ngClass]="{'fa-toggle-on': theming.active, 'fa-toggle-off': !theming.active}"></i>
    </button>

    <span class="vl ml-2">&nbsp;</span>

    <button class="btn btn-success ml-2" (click)="onOpenPreview(content)" ngbTooltip="{{_('Vorschau')}}" [attr.disabled]="!(canSave())? true: null">
      <i class="fa fa-eye"></i> {{_("Vorschau")}}
    </button>
  </div>
</div>

<ng-template #content let-c="close">
  <div class="modal-header">
    <h4 class="modal-title">{{_("Vorschau")}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="c('')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <iframe [src]="previewUrl" *ngIf="previewUrl"></iframe>
  </div>
</ng-template>

