export const KEYS = {
  LICENSE: {
    LICENSE: 'Lizenz',
    TYPE: 'Lizenztyp',
    LICENSEE: 'Lizenznehmer',
    VALID_UNTIL: 'Gültig bis',
    UNLIMITED: 'unbegrenzt',
    LIMITS: 'Einschränkungen',
    NUM_QUESTIONNAIRES: 'Anzahl Fragebögen',
    NUM_SURVEYS_PER_MONTH: 'Abgaben pro Monat',
    NUM_DEVICES: 'Anzahl Geräte',
    ADDONS: 'Erweiterungen',
    FEATURES: 'Eigenschaften',
    LICENSE_FEATURE_ANON_PATIENT: 'Anonyme Patienten',
    LICENSE_FEATURE_APP_LINK_OPEN: 'Direkt ausfüllen',
    LICENSE_FEATURE_APP_LINK_COPY: 'Link zum Ausfüllen kopieren',
    LICENSE_FEATURE_APP_LINK_SEND: 'Link zum Ausfüllen per Mail senden',
    LICENSE_FEATURE_APP_PATIENT_UPLOAD: 'Upload von Daten während Ausfüllung',
    LICENSE_FEATURE_APP_ALLOW_MAIL: 'Abgabe per Mail senden',
    LICENSE_FEATURE_APP_ALLOW_SAVE: 'Zwischenspeichern',
    LICENSE_FEATURE_PDF_FORMS: 'Verwendung von PDF Formularen',
    LICENSE_FEATURE_SIGNO_SIGN: 'SignoSign Unterschriften',
    LICENSE_FEATURE_EXTENDED_WYSIWYG: 'Erweiterte WYSIWYG Funktionen',
    LICENSE_FEATURE_APP_AUTO_THEMING: 'Auto Theming',
  }
};
