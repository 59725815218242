import {VersionComponent} from './components/version.component';
import {NAVIGATION} from './settings-version.navigation';
import {routing} from './settings-version.routing';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LayoutService} from '@ngmedax/layout';


@NgModule({
  imports: [
    CommonModule,
    routing
  ],
  declarations: [
    VersionComponent
  ],
  exports: [],
  providers: []
})
export class SettingsVersionModule {
  constructor(private layoutService: LayoutService) {
    layoutService.addMenuEntry(NAVIGATION);
    layoutService.setVersionLink(NAVIGATION.children[0].path);
  }
}
