import {Component, Input, OnInit, Optional} from '@angular/core';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {TRANSLATION_EDITOR_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';


// hack to inject decorator declarations. must occur before class declaration!
export interface QuestionTypeNumericComponent extends Translatable {}

@Component({
  selector: 'app-qa-question-numeric',
  templateUrl: './question-type-numeric.component.html',
  styleUrls: ['./question-type-numeric.component.css']
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class QuestionTypeNumericComponent implements OnInit {
  @Input() question: Questionnaire.Container;
  // @Input() mapping: Questionnaire.Container.ComponentMapping;

  /**
   * Locale for questionnaire. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Input steps
   * @type {string}
   */
  public steps = '1';

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService
  ) {
  }

  public ngOnInit() {
    if (!this.question.options) {
      this.question.options = {};
    }

    if (!this.question.options.unit || typeof this.question.options.unit['de_DE'] === 'undefined') {
      this.question.options.unit = {
        'de_DE': ''
      };
    }

    this.onUpdateSteps();
  }

  /**
   * Updates input steps
   */
  public onUpdateSteps() {
    this.steps = (this.question.options.steps + '')
      .replace(/0*\d/g,'0')
      .replace(/[0-9]$/, '1');
  }
}
