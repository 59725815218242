import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ConfigService} from '@ngmedax/config';
import {RegistryService} from '@ngmedax/registry';


@Injectable()
export class SettingsService {

  public constructor(
    private http: HttpClient,
    private registryService: RegistryService,
    private configService: ConfigService
  ) {
  }

  /**
   * Posts settings data
   */
  public async save(data: any): Promise<any> {
    const headers = this.getAuthHeaders();
    const apiUrl = this.getSettingsApiUrl();

    if (!data.key) {
      const keys = Object.keys(data);
      for (const key of keys) {
        const value = typeof data[key] === 'object' ? JSON.stringify(data[key]) : data[key];
        await this.save({key, value});
      }
      return;
    }

    return await new Promise((resolve, reject) => {
      this.http.post(apiUrl, data, {headers}).subscribe(value => resolve(value), reject);
    });
  }

  /**
   * Returns settings data for given key
   */
  public async get(key: string): Promise<any> {
    const data = await this.save({key});
    if (data) {
      try {
        data.value = JSON.parse(data.value);
      } catch (error) {
        // do nothing
      }
    }

    return data;
  }

  /**
   * Returns value for given key
   * @param {string} key
   */
  public async getValue(key: string): Promise<any> {
    const data = await this.get(key);
    return data ? data.value : null;
  }

  /**
   * Returns api url for settings
   *
   * @returns {string}
   */
  private getSettingsApiUrl(suffix: string = null): string {
    return this.buildUrl('apis.settings.uri', suffix);
  }

  /**
   * Returns api url for theme
   *
   * @returns {string}
   */
  private getThemeApiUrl(suffix: string = null): string {
    return this.buildUrl('apis.theme.uri', suffix);
  }

  /**
   * Builds url by value of given config key and suffix
   *
   * @param {string} configKey
   * @returns {any}
   */
  private buildUrl(configKey: string, suffix = null) {
    let baseUri = this.configService.get(configKey);

    if (suffix) {
      baseUri = `${baseUri}${suffix}`;
    }

    return baseUri;
  }

  /**
   * Returns auth headers by auth token and tenant id
   */
  private getAuthHeaders(): any {
    const headers: any = {};
    const authToken = this.registryService.get('session.authToken');
    const tenantId = this.registryService.get('session.tenantId');

    if (authToken) {
      headers['X-Api-Token'] = authToken;
    }

    if (tenantId) {
      headers['X-Api-TenantId'] = `${tenantId}`;
    }

    return headers;
  }
}
