export const KEYS = {
  SETTINGS: {
    DELETION_PERIODS: 'Löschfristen',
    SUCCESSFULLY_SAVED_SETTINGS: 'Die Einstellungen wurden erfolgreich gespeichert.',
    ERROR_SAVING_SETTINGS: 'Beim Speichern der Einstellungen ist ein Fehler aufgetreten.',
    PATIENT_DELETION_PERIODS: 'Löschfristen für Patientendaten',
    EXPORT_FILE_DELETION_PERIODS: 'Löschfristen für ausgefüllte Fragebögen und Export-Dateien',
    SURVEY_DELETION_PERIODS: 'Löschfristen für Befragungen',
    ONE_DAY: '1 Tag',
    ONE_WEEK: '1 Woche',
    ONE_MONTH: '1 Monat',
    NEVER: 'niemals',
    NUM_DAYS: '[[num]] Tage',
    NUM_WEEKS: '[[num]] Wochen',
    NUM_MONTHS: '[[num]] Monate',
    SAVE: 'Speichern'
  }
};
