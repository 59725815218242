/**
 * Mapping for translation keys, so we don't have to use long text in source code. Please keep in mind, that the
 * german default translations are the keys, so don't change them, if you wan't to change the text.
 * Instead change the de_DE translations!
 */
export const KEYS = {
  GRID: {
    CONFIG_ERROR: 'Konfigurationsfehler',
    ERROR_MISSING_MANDATORY_CONFIG: 'Die Konfiguration enthält keine API URL zum Abrufen von Fragebogen Gruppen!',
    ERROR_LOADING_GROUPS: 'Beim Laden der Fragebogen Gruppen ist ein Fehler aufgetreten',
    DELETE_GROUP_QUESTION: 'Die folgende Fragebogen Gruppe wirklich löschen?',
    SUCCESSFULLY_DELETED_GROUP: 'Die Fragebogen Gruppe wurde erfolgreich gelöscht.',
    ERROR_DELETING_GROUP: 'Beim Löschen der Fragebogen Gruppe ist ein Fehler aufgetreten.',
    QUESTIONNAIRE_GROUPS: 'Fragebogen Gruppen',
    SEARCH: 'Suche',
    NAME: 'Name',
    QUESTIONNAIRES: 'Fragebögen',
    CREATED_AT: 'Erstellt am',
    UPDATED_AT: 'Bearbeitet am',
    ENTRIES: 'Einträge',
    OPTIONS: 'Optionen',
    CHANGE: 'Bearbeiten',
    DELETE: 'Löschen',
    CREATE: 'Erstellen'
  },
  CRUD: {
    QUESTIONNAIRES: 'Fragebögen',
    QUESTIONNAIRE_GROUP: 'Fragebogen Gruppe',
    NAME: 'Name',
    ENTER_NAME: 'Name eingeben',
    SAVE: 'Speichern',
    CANCEL: 'Abbrechen',
    SUCCESSFULLY_SAVED_QUESTIONNAIRE_GROUP: 'Fragebogen Gruppe wurde erfolgreich gespeichert.',
    ERROR_SAVING_QUESTIONNAIRE_GROUP: 'Beim Speichern der Fragebogen Gruppe ist ein Fehler aufgetreten.',
    FOUND_NO_QUESTIONNAIRE_GROUP_BY_ID: 'Es wurde keine Fragebogen Gruppe mit folgender Id gefunden:'
  }
};
