import {Component, OnInit, Optional, ViewEncapsulation} from '@angular/core';
import {DATE_FORMAT_YMD_HMS, Translatable, TranslationService} from '@ngmedax/translation';
import {RegistryService} from '@ngmedax/registry';
import {LicenseGenerator, LicenseDecoder} from '@ngmedax/common-license';
const isValidDomain = require('is-valid-domain');
import * as moment from 'moment';

import {TRANSLATION_HISTORY_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';
import {License} from '../../../types';
import {DateFormatService} from '../../../../translation';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LicenseManagerService} from '../services/license-manager.service';
import {KeyPairStorageService} from '../services/key-pair-storage.service';
import {LicenseCipher} from '../services/license-cipher.service';
import {factory} from '@ngmedax/common-license';

// hack to inject decorator declarations. must occur before class declaration!
export interface LicenseManagerCrudComponent extends Translatable {}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-license-manager-crud',
  templateUrl: './license-manager-crud.component.html',
  styleUrls: ['./license-manager-crud.component.css'],
})
@Translatable({scope: TRANSLATION_HISTORY_SCOPE, keys: KEYS})
export class LicenseManagerCrudComponent implements OnInit {
  /**
   * Locale hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi lang support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * License
   * @type {License}
   */
  public license: License = null;

  /**
   * Is unlocked?
   */
  public isUnlocked: boolean = false;

  /**
   * Is public key mode?
   */
  public isPubKeyMode: boolean = false;

  /**
   * Decrypted license key
   */
  public decryptedLicenceKey: string = null;

  /**
   * Domains
   */
  public domains: string = null;

  /**
   * Valid until
   */
  public validUntil: string = null;

  /**
   * Default for new licenses
   */
  private defaultLicense: License = {
    name: '',
    nonce: '',
    licenseKey: '',
    feature: {
      extendedWysiwyg: true,
      appAllowMail: false,
      appLinkSend: false,
      appLinkCopy: false,
      appLinkOpen: true,
      anonPatient: true,
      appPatientUpload: false,
      pdfForms: false,
      signoSign: false
    },
    constraint: {}
  };

  /**
   */
  public ngOnInit() {
    this.onReset();
    this.isPubKeyMode = this.keyPairStorageService.isPubKeyMode();
    this.keyPairStorageService.isUnlocked().then(isUnlocked => this.isUnlocked = isUnlocked);
  }

  /**
   * Injects dependencies
   */
  public constructor(
    public activeModal: NgbActiveModal,
    @Optional() private translationService: TranslationService,
    @Optional() private dateFormatService: DateFormatService,
    private licenseManagerService: LicenseManagerService,
    private licenseGeneratorService: LicenseGenerator,
    private licenseDecoderService: LicenseDecoder,
    private keyPairStorageService: KeyPairStorageService,
    private registryService: RegistryService,
    private cipher: LicenseCipher
  ) {
  }

  /**
   * Loads license
   * @param licenseId
   */
  public load(licenseId: string) {
    (async () => {
      try {
        const license = await this.licenseManagerService.loadLicense(licenseId);

        if (this.isUnlocked && !this.isPubKeyMode) {
          const keyPair = await this.keyPairStorageService.getKeyPair();
          this.decryptedLicenceKey = this.cipher.decrypt(license.licenseKey, keyPair);
        }

        if (this.isPubKeyMode) {
          this.decryptedLicenceKey = license.licenseKey;
        }

        !license.constraint && (license.constraint = {});
        license.constraint && license.constraint.domains && (this.domains = license.constraint.domains.join(', '))
        license.validUntil && (this.validUntil = moment(license.validUntil).format('YYYY-MM-DD'))
        this.license = license;
      } catch (error) {
        alert('Beim Laden der Lizenz ist ein Fehler aufgetreten!');
        throw(error);
      }
    })();
  }

  /**
   * date format to use
   * @type {string}
   */
  public get dateFormat() {
    const format = DATE_FORMAT_YMD_HMS.replace(/YYYY/, 'YY');
    return this.getDateFormat(format);
  };

  /**
   * Returns true if we can save
   */
  public canSave() {
    if (this.isPubKeyMode) {
      return true;
    }

    return this.isUnlocked && this.validUntil && this.license.name;
  }

  /**
   * Saves current license
   */
  public async onSave() {
    try {
      if (this.isPubKeyMode) {
        const keyPair = await this.keyPairStorageService.getKeyPair();
        const decoder = factory.getLicenseDecoder();
        try {
          const licenseKey = this.decryptedLicenceKey.toString().trim();
          const license = Object.assign({}, {licenseKey}, <any>decoder.decode(licenseKey, <any>keyPair));
          delete(license.createdBy);
          delete(license.updatedBy);
          this.license && (this.license = Object.assign({}, this.license, license));
        } catch (error) {
          console.error(error);
          alert('Fehler: Der Lizenzschlüssel ist ungültig!');
          return;
        }
      }

      if (!this.isPubKeyMode) {
        if (this.decryptedLicenceKey) {
          const shouldOverwrite = await <any>confirm('Achtung: Diese Aktion überschreibt den vorherigen Lizenzschlüssel! Fortfahren?');
          if (!shouldOverwrite) {
            return;
          }
        }

        const isGenerated = await this.onGenerate();
        if (!isGenerated) {
          return;
        }
      }

      const license = await this.licenseManagerService.saveLicense(this.license);
      this.licenseManagerService.onLicenseSaved().emit();
      await this.load(license.id);
    } catch (error) {
      alert('Beim Speichern der Lizenz ist ein Fehler aufgetreten!');
      throw(error);
    }

    alert('Die Lizenz wurde erfolgreich gespeichert.');
  }

  public onCopyClipboard() {
    try {
      navigator.clipboard
        && navigator.clipboard.writeText
        && navigator.clipboard.writeText(this.decryptedLicenceKey);

      if (!navigator.clipboard) {
        const aux = document.createElement('textarea');
        aux.innerHTML = this.decryptedLicenceKey

        document.body.appendChild(aux);
        aux.select();

        document.execCommand('copy');
        document.body.removeChild(aux);
      }

      alert('Der Lizenzschlüssel wurde in die Zwischenablage kopiert.');
    } catch (error) {
      alert('Fehler: Lizenzschlüssel konnte nicht in die Zwischenablage kopiert werden.');
    }
  }

  onReset() {
    this.license = this.defaultLicense;
    this.decryptedLicenceKey = '';
    delete this.domains;
    delete this.validUntil;
  }

  async onGenerate(): Promise<boolean> {
    const values = this.getValues();
    const isInvalidDomains = values.constraint && values.constraint.domains && !this.isValidDomains(values.constraint.domains);

    if (isInvalidDomains) {
      const msg = 'Fehler: Lizenz konnte nicht generiert werden. Bitte gültige Domain(s) eingeben. Ohne Protokoll (http://,https://)! '
        + 'Wildcard Domains sind nicht erlaubt.';
      alert(msg);
      return false;
    }

    const keyPair = await this.keyPairStorageService.getKeyPair();
    this.decryptedLicenceKey = this.licenseGeneratorService.generate(values, keyPair);
    const decoded = await this.licenseDecoderService.decode(this.decryptedLicenceKey, keyPair);
    this.license.licenseKey = this.cipher.encrypt(this.decryptedLicenceKey, keyPair);
    this.license.validUntil = moment(decoded.validUntil, 'YYYY-MM-DD').toJSON();
    !this.license.constraint && (this.license.constraint = {});
    values.constraint && values.constraint.domains && values.constraint.domains.length
      && (this.license.constraint.domains = values.constraint.domains);
    this.license.nonce = decoded.nonce;
    return true;
  }

  /**
   * Returns license values
   */
  private getValues() {
    const validUntil = this.validUntil;
    const domains = this.domains ? this.domains.split(',').map(domain => domain.trim()) : null;
    const values = JSON.parse(JSON.stringify(this.license));

    // todo: use whitelist instead
    delete values.id;
    delete values.uid;
    delete values._id;
    delete values.__v;
    delete values.licenseKey;
    delete values.constraint.domains;
    delete values.validUntil;
    delete values.updatedAt;
    delete values.createdAt;
    delete values.partner;
    delete values.nonce;

    validUntil && (values.validUntil = validUntil);
    domains && (values.constraint.domains = domains);
    !Object.keys(values.constraint).length && delete values.constraint;
    return values;
  }

  /**
   * Returns true if format of given domains or ips is valid
   */
  private isValidDomains(domains: string[]): boolean {
    for (const domain of domains) {
      const isValidDomainName = isValidDomain(
        domain.replace(/:[0-9]*$/, ''),
        {subdomain: true, wildcard: false}
      );

      const isValidIp = this.isValidIp(domain);
      if (!isValidDomainName && !isValidIp) {
        return false;
      }
    }

    return true;
  }

  private isValidIp(ip: string): boolean {
    return !!ip.match(/^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/);
  }

  private onKeyBlur(element: HTMLElement) {
    this.decryptedLicenceKey = element.innerText;
  }
}
