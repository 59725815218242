export const configKeys = {
  /**
   * Config key for questionnaire uri
   * @type {string}
   */
  QUESTIONNAIRE_URI_CONFIG_KEY: 'apis.questionnaire.uri',

  /**
   * Config key for filter group uri
   * @type {string}
   */
  FILTER_GROUP_URI_CONFIG_KEY: 'apis.filtergroup.uri',
};
