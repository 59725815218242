<div class="modal-header">
  <h4 class="modal-title">Lizenz</h4>
  <button type="button" class="close" (click)="activeModal.dismiss()">
    <i class="fa fa-close"></i>
  </button>
</div>
<div class="modal-body container generator mt-2" *ngIf="license">
  <div class="row" *ngIf="!isPubKeyMode">
    <div class="col-6 base">
      <div class="form-group">
        <label for="name">Partner</label>
        <input type="text" style="padding: 10px;" class="form-control data" id="partner" [(ngModel)]="license.partner" aria-describedby="partnerHelp" required value="myMedax">
        <small id="PartnerHelp" class="form-text text-muted">Lizenzpartner.</small>
      </div>

      <div class="form-group">
        <label for="name">Lizenznehmer</label>
        <input type="text" style="padding: 10px;" class="form-control data" id="name" [(ngModel)]="license.name" aria-describedby="nameHelp" placeholder="Praxis Dr. Sorgenfrei" required>
        <small id="nameHelp" class="form-text text-muted">Lizenznehmer auf den die Lizenz ausgestellt werden soll.</small>
      </div>

      <div class="form-group">
        <label><b>Einschränkungen</b></label>
        <small class="form-text text-muted head-label">
          Werden global angewendet. Leer lassen = keine Beschränkung.
        </small>
        <div class="form-group mb-0p5">
          <label for="name">Erlaubte Domains</label>
          <input type="text" style="width: 259px;" class="form-control data constraint" id="domains" aria-describedby="domainsHelp"
                 placeholder="www.domain1.tld, www.domain2.tld" required [(ngModel)]="domains">
        </div>
        <div class="form-group mb-0p5">
          <label for="numQuest" style="width: 170px;">Anzahl Fragebögen</label>
          <input type="number" class="form-control inline constraint" id="numQuest" [(ngModel)]="license.constraint.numQuest">
        </div>
        <div class="form-group mb-0p5">
          <label for="numDevices" style="width: 170px;">Anzahl Geräte</label>
          <input type="number" class="form-control inline constraint" id="numDevices" [(ngModel)]="license.constraint.numDevices">
        </div>
        <div class="form-group mb-0p5">
          <label for="numSurveysPerMonth" style="width: 170px;">Abgaben pro Monat</label>
          <input type="number" class="form-control inline constraint" id="numSurveysPerMonth" [(ngModel)]="license.constraint.numSurveysPerMonth">
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label for="validUntil">Ablaufdatum</label>
        <div class="input-group">
          <input type="date" class="form-control data" id="validUntil" aria-describedby="validUntilHelp" required [(ngModel)]="validUntil">
          <div class="input-group-append">
                <span class="input-group-text btn btn-secondary" id="undoBtn" title="zurücksetzen" (click)="validUntil = ''">
                  <i class="fa fa-undo"></i>
                </span>
          </div>
        </div>
        <small id="validUntilHelp" class="form-text text-muted">Ablaufdatum der Lizenz.</small>
      </div>

      <br><label><b>Features</b></label>
      <small class="form-text text-muted head-label">
        Hiermit kann die Funktionalität der Systemverwaltung eingeschränkt werden.
      </small>
      <div class="form-check">
        <input type="checkbox" class="form-check-input feature" id="extendedWysiwyg" [(ngModel)]="license.feature.extendedWysiwyg">
        <label class="form-check-label">Erweiterte WYSIWYG Funktionen</label>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input feature" id="appAllowMail" [(ngModel)]="license.feature.appAllowMail">
        <label class="form-check-label">Mail Option nach Abgabe</label>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input feature" id="appLinkSend" [(ngModel)]="license.feature.appLinkSend">
        <label class="form-check-label">Ausfüll App Link per Mail senden</label>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input feature" id="appLinkCopy" [(ngModel)]="license.feature.appLinkCopy">
        <label class="form-check-label">Ausfüll App Link kopieren</label>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input feature" id="appLinkOpen" [(ngModel)]="license.feature.appLinkOpen">
        <label class="form-check-label">Direkt ausfüllen</label>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input feature" id="anonPatient" [(ngModel)]="license.feature.anonPatient">
        <label class="form-check-label">Anonyme Patienten</label>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input feature" id="appPatientUpload" [(ngModel)]="license.feature.appPatientUpload">
        <label class="form-check-label">Patienten Upload</label>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input feature" id="pdfForms" [(ngModel)]="license.feature.pdfForms">
        <label class="form-check-label">PDF Formulare</label>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input feature" id="signoSign" [(ngModel)]="license.feature.signoSign">
        <label class="form-check-label">SignoSign Unterschriften</label>
      </div>
    </div>
  </div>

  <div class="license" *ngIf="license && isUnlocked">
    <hr *ngIf="!isPubKeyMode">
    <label>Lizenz</label>
    <div #key [contentEditable]="isPubKeyMode" id="token" name="licenseKey" [textContent]="decryptedLicenceKey" (blur)="onKeyBlur(key)"></div>
  </div>

  <button type="button" class="btn btn-sm btn-success mt-2 mb-2" (click)="onSave()" [attr.disabled]="canSave()? null : true" *ngIf="isUnlocked">
    <i class="fa fa-save"></i>
    Speichern
  </button>
  <button type="button"
          class="btn btn-sm btn-primary ml-2 mt-2 mb-2"
          (click)="onCopyClipboard()" [attr.disabled]="(!decryptedLicenceKey) ? true : null"
          *ngIf="isUnlocked"
  >
    <i class="fa fa-clipboard"></i>
    In Zwischenablage kopieren
  </button>
</div>

