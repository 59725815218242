<div class="modal-header">
  <h4 class="modal-title">{{_(KEYS.MEDIA_CENTER.MEDIA_LIB)}}</h4>
  <button type="button" class="close" (click)="activeModal.dismiss()">
    <i class="fa fa-close"></i>
  </button>
</div>
<div class="modal-body qa-media-center animated fadeIn" *ngIf="!uploadMode">
  <div class="row">
    <div class="col-lg-5 qa-preview" [ngClass]="{'invisible': !selectedFile}">
      <ng-container *ngIf="selectedFile">
        <video *ngIf="selectedFile.type.match('^video')"
          #video class="qa-video" controls crossorigin="anonymous"
          [attr.poster]="selectedFile.options.previewImage ? selectedFile.options.previewImage : null"
          [src]="baseUri + '/' + selectedFile.name"
        >
          <source [src]="baseUri + '/' + selectedFile.name"/>
        </video>

        <audio class="mb-2" *ngIf="selectedFile.type.match('^audio')" controls>
          <source [src]="baseUri + '/' + selectedFile.name"/>
        </audio>

        <div class="qa-image-container mb-2" *ngIf="selectedFile.type.match('^image')">
          <img #previewImage
            class="qa-image"
            *ngIf="!imageEditMode && !selectedFile.options.croppedImage"
            [src]="baseUri + '/' + selectedFile.name"
          />

          <img #previewImage
               class="qa-image"
               *ngIf="!imageEditMode && selectedFile.options.croppedImage"
               [src]="selectedFile.options.croppedImage"
          />

          <image-cropper
            #imageCropper
            *ngIf="imageEditMode"
            [autoCrop]="false"
            [aspectRatio]="options.aspectRatio ? options.aspectRatio : 1"
            [maintainAspectRatio]="!!options.maintainAspectRatio"
            [imageBase64]="selectedImage"
            (imageLoaded)="onImageLoaded()"
            (imageCropped)="onImageCropped($event)"
            format="jpeg"
          ></image-cropper>

          <button class="btn btn-small btn-primary qa-image-edit-btn" *ngIf="!imageEditMode" (click)="onEditImage()">
            <i class="fa fa-edit"></i>
          </button>

          <button class="btn btn-small btn-success qa-image-edit-btn" *ngIf="imageEditMode" (click)="onEditImageDone()">
            <i class="fa fa-check"></i>
          </button>
        </div>

        <div class="qa-av-options mb-5" *ngIf="selectedFile.type.match('^video') || selectedFile.type.match('^audio')">
          <div class="input-group qa-drop-down mr-1">
            <div
              ngbDropdown [placement]="selectedFile.type.match('^audio') ? 'bottom-left': 'top-left'"
              class="d-inline-block"
            >
              <button class="btn btn-success" id="ddvOptions" ngbDropdownToggle>
                <i class="fa fa-cog"></i>
              </button>
              <div ngbDropdownMenu aria-labelledby="ddvOptions">
                  <button class="dropdown-item" (click)="selectedFile.options.autoPlay = !selectedFile.options.autoPlay">
                    <input type="checkbox" [(ngModel)]="selectedFile.options.autoPlay">
                    {{_(KEYS.MEDIA_CENTER.AUTO_PLAY)}}
                  </button>
                  <button class="dropdown-item" (click)="selectedFile.options.hideControls = !selectedFile.options.hideControls">
                    <input type="checkbox" [(ngModel)]="selectedFile.options.hideControls">
                    {{_(KEYS.MEDIA_CENTER.DEACTIVATE_CONTROLS)}}
                  </button>
              </div>
            </div>
          </div>

          <button
            type="button"
            class="btn btn-primary qa-preview-img"
            (click)="onCreateVideoPreviewImage()"
            *ngIf="selectedFile.type.match('^video')"
          >
            <i class="fa fa-image"></i>
            {{_(KEYS.MEDIA_CENTER.CREATE_PREVIEW)}}
          </button>
        </div>

        <div class="mb=2" *ngIf="!selectedFile.type.match('(^audio)|(^video)|(^image)')">
          {{_(KEYS.MEDIA_CENTER.NO_PREVIEW_FOR_THIS_FILE_TYPE)}}
        </div>
      </ng-container>
    </div>
    <div [ngClass]="{'col-lg-7': !!selectedFile, 'col-lg-12': !selectedFile}">
      <div class="row qa-search">
        <div class="col">
          <div class="qa-type input-group">
            <span class="input-group-addon"><i class="fa fa-search"></i></span>
            <input type="text" class="form-control" placeholder="..." [(ngModel)]="nameFilter"/>
          </div>
        </div>
        <div class="qa-upload" *ngIf="!options.disableUpload">
          <label class="btn btn-primary">
            <i class="fa fa-upload"></i>
            {{_(KEYS.MEDIA_CENTER.UPLOAD)}}
            <input
              type="file"
              (change)="onUpload($event)"
              [attr.accept]="options.accept ? options.accept : null"
              hidden multiple>
          </label>
        </div>
      </div>
      <div class="row qa-files">
        <div class="col">
          <div class="table-wrapper">
            <div class="table-scroll">
              <table class="table">
                <thead>
                <tr>
                  <th><span class="text">{{_(KEYS.MEDIA_CENTER.FILE)}}</span></th>
                  <th><span class="text">{{_(KEYS.MEDIA_CENTER.SIZE)}}</span></th>
                  <th><span class="text">{{_(KEYS.MEDIA_CENTER.DATE)}}</span></th>
                  <th><span class="text"></span></th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let file of getFiles(); let pos = index;">
                  <tr
                    (click)="onSelectFile(file)"
                    [ngClass]="{'qa-tr-odd': pos % 2 === 0, 'selected': !!file.options.selected}"
                  >
                    <td class="qa-name">
                      {{file.name}}
                    </td>
                    <td>
                      {{file.size / 1024 / 1024 | number:'1.0-2'}} Mb
                    </td>
                    <td>
                      {{file.date | datex:dateFormat}}
                    </td>
                    <td class="qa-file-options">
                      <div
                        class="btn btn-primary btn-sm d-inline-block mr-1"
                        (click)="onDownloadFile(file); $event.stopPropagation()"
                      >
                        <i class="fa fa-download qa-cursor-pointer"></i>
                      </div>
                      <div
                        class="btn btn-danger btn-sm d-inline-block mr-1"
                        (click)="onDeleteFile(file); $event.stopPropagation()"
                      >
                        <i class="fa fa-trash qa-cursor-pointer"></i>
                      </div>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div >
</div>
<div class="modal-body qa-media-center fixed-height animated fadeIn" *ngIf="uploadMode">
  <div class="qa-upload-overlay">
    <ng-container *ngFor="let upload of uploads">
      <span>{{upload.fileName}}</span>
      <ngb-progressbar type="primary" [value]="upload.progress | async"></ngb-progressbar>
      <br/>
    </ng-container>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-success mr-1" *ngIf="options.enableZipDownload && getFiles().length" (click)="onZipDownload()">
    <i class="fa fa-file-zip-o"></i>
    {{_(KEYS.MEDIA_CENTER.ZIP_DOWNLOAD)}}
  </button>
  <button type="button" class="btn btn-secondary" (click)="close()">
    {{_(KEYS.MEDIA_CENTER.CANCEL)}}
  </button>
  <button type="button" class="btn btn-primary" (click)="close(true)">
    {{_(KEYS.MEDIA_CENTER.OK)}}
  </button>
</div>
