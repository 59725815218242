<div class="qa-grid">
  <h2 class="mb-4">{{_(KEYS.GRID.PATIENTS)}}</h2>
  <div class="card mb-5">
    <div class="card-body">
      <div class="card-title">
        <div class="btn-toolbar justify-content-between">
          <div class="justify-content-between qa-lh-2p5">
            <i class="fa fa-search"></i>
            <h5 class="d-inline">{{_(KEYS.GRID.SEARCH)}}</h5>
          </div>
          <button class="btn btn-primary d-inline-block" (click)="isSearchCollapsed = !isSearchCollapsed">
            <i class="fa" [ngClass]="{'fa-arrow-down': isSearchCollapsed, 'fa-arrow-up': !isGridCollapsed}"></i>
          </button>
        </div>
      </div>
      <div class="card-text" [ngbCollapse]="isSearchCollapsed">
        <div class="qa-grid-search mt-2 mb-2">
          <div class="input-group qa-grid-search-first-name">
            <span class="input-group-addon">{{_(KEYS.GRID.FIRSTNAME)}}:</span>
            <input class="form-control" [(ngModel)]="filter.firstName"
                   (paste)="onFilterChange()" (change)="onFilterChange()" (keydown)="onFilterChange()">
          </div>
          <div class="input-group qa-grid-search-last-name">
            <span class="input-group-addon">{{_(KEYS.GRID.LASTNAME)}}:</span>
            <input class="form-control" [(ngModel)]="filter.lastName"
                   (paste)="onFilterChange()" (change)="onFilterChange()" (keydown)="onFilterChange()">
          </div>
          <div class="input-group qa-grid-search-address">
            <span class="input-group-addon">{{_(KEYS.GRID.ADDRESS)}}:</span>
            <input class="form-control" [(ngModel)]="filter.address"
                   (blur)="onFilterChange()" (change)="onFilterChange()">
          </div>
          <div class="input-group qa-grid-search-pseudo-id">
            <span class="input-group-addon">{{_(KEYS.GRID.PSEUDO_ID)}}:</span>
            <input class="form-control" [(ngModel)]="filter.pseudoId"
                   (blur)="onFilterChange()" (change)="onFilterChange()">
          </div>
          <div class="input-group qa-grid-search-anonymous">
            <span class="input-group-addon">{{_(KEYS.GRID.TYPE)}}:</span>
            <select class="custom-select" [(ngModel)]="filter.anonymous"
                    (blur)="onFilterChange()" (change)="onFilterChange()" (keydown)="onFilterChange()">
              <option value="" selected>{{_(KEYS.GRID.TYPE_ALL)}}</option>
              <option value="1">{{_(KEYS.GRID.TYPE_NOT_ANONYMOUS)}}</option>
              <option value="2">{{_(KEYS.GRID.TYPE_ANONYMOUS)}}</option>
              <option value="3">{{_(KEYS.GRID.TYPE_PSEUDONYMOUS)}}</option>
            </select>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="card-title">
        <div class="btn-toolbar justify-content-between">
          <div class="justify-content-between qa-lh-2p5">
            <i class="fa fa-copy"></i>
            <h5 class="d-inline">{{_(KEYS.GRID.PATIENTS)}}</h5>
          </div>
          <button class="btn btn-primary d-inline-block" (click)="isGridCollapsed = !isGridCollapsed">
            <i class="fa" [ngClass]="{'fa-arrow-down': isGridCollapsed, 'fa-arrow-up': !isGridCollapsed}"></i>
          </button>
        </div>
      </div>
      <div class="card-text" [ngbCollapse]="isGridCollapsed">
        <table class="table">
          <thead>
          <tr>
            <th class="id-col">{{_(KEYS.GRID.ID)}}</th>
            <th>{{_(KEYS.GRID.NAME)}}</th>
            <th>{{_(KEYS.GRID.ADDRESS)}}</th>
            <th>{{_(KEYS.GRID.LOCATION)}}</th>
            <th>{{_(KEYS.GRID.CREATED_AT)}}</th>
            <th>{{_(KEYS.GRID.UPDATED_BY)}}</th>
            <th>{{_(KEYS.GRID.OPTIONS)}}</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let patient of getPatients(); let pos = index;">
            <tr [ngClass]="{'qa-tr-odd': pos % 2 === 0}">
              <td class="id-col">
                <span ngbTooltip="{{patient.uid}}">{{patient.uid | truncate: 5}}</span>
              </td>
              <td>
                <ng-container *ngIf="!patient.anonymous && !patient.pseudonymous">
                  <ng-container *ngIf="patient?.pseudoId">
                    Pseudo Id - {{patient?.pseudoId}}<br>
                  </ng-container>
                  <span *ngIf="patient.gender === 'male'">{{_(KEYS.GRID.SALUTATION_MR)}}</span>
                  <span *ngIf="patient.gender === 'female'">{{_(KEYS.GRID.SALUTATION_MS)}}</span>
                  {{patient.firstName}} {{patient.lastName}}
                </ng-container>
                <ng-container *ngIf="patient.anonymous">
                  {{_(KEYS.GRID.ANONYMOUS)}}
                </ng-container>
                <ng-container *ngIf="patient.pseudonymous">
                  {{_(KEYS.GRID.PSEUDONYMOUS)}}
                  <ng-container *ngIf="patient?.pseudoId">
                    <br>Pseudo Id - {{patient?.pseudoId}}
                  </ng-container>
                </ng-container>
              </td>
              <td>
                <span ngbTooltip="{{patient.address}}">{{patient.address | truncate: 25}}</span>
              </td>
              <td>
                <span ngbTooltip="{{patient.location}}">{{patient.location | truncate: 25}}</span>
              </td>
              <td>
                {{patient.createdAt | datex:dateFormat}}
              </td>
              <td>
                {{patient.updatedBy}}
              </td>
              <td>
                <div class="d-inline-block nowrap">
                  <div
                    class="btn btn-primary ml-1"
                    [routerLink]="'/module/patient/crud/' + patient.uid"
                    data-toggle="tooltip" data-placement="top"
                    [title]="_(KEYS.GRID.CHANGE)"
                  >
                    <i class="fa fa-edit qa-cursor-pointer"></i>
                  </div>
                  <div
                    class="btn btn-primary ml-1"
                    [routerLink]="'/module/patient/assign/' + patient.uid"
                    data-toggle="tooltip" data-placement="top"
                    [title]="_(KEYS.GRID.ASSIGN_QUESTIONNAIRES)">
                    <i class="fa fa-bars qa-cursor-pointer"></i>
                  </div>
                  <div
                    class="btn btn-danger ml-1"
                    (click)="onDeletePatient(patient)"
                    data-toggle="tooltip" data-placement="top"
                    [title]="_(KEYS.GRID.DELETE)">
                    <i class="fa fa-trash qa-cursor-pointer"></i>
                  </div>

                </div>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
        <div class="btn-toolbar justify-content-between mt-2 mb-2">
          <div class="justify-content-between">
            <div class="input-group">
              <div class="input-group qa-num-per-page">
                <select class="form-control custom-select" [(ngModel)]="displayPerPage" (change)="onPagingChange()">
                  <option value="2">2</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
                <span class="input-group-addon">{{_(KEYS.GRID.ENTRIES)}}</span>
              </div>
            </div>
          </div>
          <ngb-pagination
            [pageSize]="displayPerPage"
            [maxSize]="9"
            [rotate]="true"
            [(page)]="gridPageNumber"
            [collectionSize]="total"
            (pageChange)="onPagingChange()">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
