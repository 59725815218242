<div class="modal-header">
  <h4 class="modal-title">
    {{_(KEYS.EDITOR.VARIABLES)}}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <i class="fa fa-close"></i>
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="container">
    <app-qa-variables [container]="container" [disabledScopes]="disabledScopes"></app-qa-variables>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()">
    {{_(KEYS.EDITOR.CLOSE)}}
  </button>
</div>
