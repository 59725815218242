import {AfterViewInit, Component, OnInit, Optional} from '@angular/core';
import {Translatable, TranslationService } from '@ngmedax/translation';
import {KEYS } from '../../../translation-keys';
import {TRANSLATION_DEFAULT_SCOPE } from '../../../constants';
import {SurveyService} from '../services/survey.service';
import {ActivatedRoute} from '@angular/router';

export interface SurveyCodeComponent extends Translatable {}

@Component({
  selector: 'app-survey-code',
  templateUrl: './survey-code.component.html',
  styleUrls: ['./survey-code.component.css'],
})
@Translatable({scope: TRANSLATION_DEFAULT_SCOPE, keys: KEYS})
export class SurveyCodeComponent implements OnInit, AfterViewInit {
  public locale = 'de_DE';
  public birthYear: string = '';
  public codeFields: string[] = ['', '', '', '', '', ''];

  constructor(
    @Optional() private translationService: TranslationService,
    private surveyService: SurveyService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const code = params.get('code');

      if (code && this.isValidCode(code)) {
        this.codeFields = code.split('');
        setTimeout(() => {document.getElementById('birthYear')?.focus()}, 100);
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {document.getElementById('code-0')?.focus()}, 0);
  }

  onPaste(event: ClipboardEvent, index: number) {
    const isFirstField = index === 0;
    const code = event.clipboardData?.getData('text').toUpperCase();
    const isValidCode = this.isValidCode(code);

    if (isFirstField && isValidCode) {
      this.codeFields = code.split('');
      setTimeout(() => {document.getElementById('birthYear')?.focus()}, 0);
    }

    event.preventDefault();
    return false;
  }

  onBirthYearKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.canSubmit()) {
      this.onSubmit();
      return;
    }

    if (event.key === 'Backspace' && !this.birthYear) {
      setTimeout(() => {document.getElementById(`code-5`)?.focus()}, 0);
    }
  }

  onKeyDown(event: KeyboardEvent, index: number) {
    if (event.ctrlKey || event.metaKey) {
      return;
    }

    if (event.key.length !== 1 && event.key !== 'Backspace') {
      return;
    }

    event.preventDefault();

    if (event.key === 'Backspace') {
      this.codeFields[index] && (this.codeFields[index] = '');

      setTimeout(() => {
        const pos = index <= 0 ? 0 : index - 1;
        const prevInput = document.getElementById(`code-${pos}`) as HTMLInputElement;
        prevInput?.focus();
      }, 0);
      return;
    }

    this.codeFields[index] = event.key.toUpperCase();

    if (index < 5) {
      setTimeout(() => {document.getElementById(`code-${index + 1}`)?.focus()}, 0);
      return;
    }

    setTimeout(() => {document.getElementById('birthYear')?.focus()}, 0);
  }

  isValidBirthYear() {
    const year = parseInt(this.birthYear, 10);
    const currentYear = new Date().getFullYear();
    return !isNaN(year) && year > 1900 && year <= currentYear;
  }

  public canSubmit () {
    return this.codeFields.every((field) => field !== '') && this.isValidBirthYear();
  }

  async onSubmit() {
    try {
      await this.startSurvey();
    } catch (error) {
      this.handleError(error);
    }
  }

  private async startSurvey() {
    const code = this.codeFields.join('');
    const entry = await this.surveyService.getSurveyCode(code, this.birthYear);
    entry && entry.link && (location.href = entry.link);
  }

  private handleError(error: any) {
    const status = (<any>error).status || 0;

    if (status === 410) {
      alert(this._(KEYS.DEFAULT.SURVEY_EXPIRED));
      return;
    }

    if (status === 404) {
      alert(this._(KEYS.DEFAULT.SURVEY_NOT_FOUND));
      return;
    }

    alert(this._(KEYS.DEFAULT.ERROR_CHECKING_CODE));
    console.error(error);
  }

  private isValidCode(code: string): boolean {
    return /^[2346789ABCDEFGHJKLMNPQRTUVWXYZ]{6}$/.test(code);
  }

  public _!: (text: string, variables?: any) => string;
  public getDateFormat!: (format: string, locale?: string) => string;
  public readonly KEYS = KEYS;
}
