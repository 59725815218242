export const NAVIGATION = {
  name: 'Fragebogen',
  icon: 'fa-id-card-o',
  path: '/module/questionnaire/',
  children: [{
    name: 'Gruppen',
    path: '/module/questionnaire/groups',
    icon: 'fa-group'
  }]
};
