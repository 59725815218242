import {Injectable, Optional} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import * as JSZip from 'jszip';
import * as FileSaver from 'file-saver';

import {Payload, Questionnaire} from '@ngmedax/common-questionnaire-types';
import {LoginService} from '@ngmedax/login';
import {AssetApiService} from '@ngmedax/asset';
import {LayoutService} from '@ngmedax/layout';

import {Filtergroup} from '../../../types';
import {ApiService} from './api.service';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {TRANSLATION_GRID_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';


// hack to inject decorator declarations. must occur before class declaration!
export interface QuestionnaireGridService extends Translatable {}

@Injectable()
@Translatable({scope: TRANSLATION_GRID_SCOPE, keys: KEYS})
export class QuestionnaireGridService {
  /**
   * Locale for questionnaires. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  public constructor(
    private apiService: ApiService,
    private layoutService: LayoutService,
    @Optional() private assetApi: AssetApiService,
    @Optional() private loginService: LoginService,
    @Optional() private translationService: TranslationService) {
  }

  /**
   * Method to load questionnaires.
   *
   * @returns {Promise<{rows: Questionnaire[], total: number}>}
   */
  public loadQuestionnaires(filter?: any, opts?: any): Promise<{rows: Questionnaire[], total: number}> {
    return this.apiService.loadQuestionnaires(filter, opts);
  }

  /**
   * Returns filter groups
   *
   * @returns {Promise<Filtergroup[]>}
   */
  public loadFiltergroups(): Promise<Filtergroup[]> {
    return this.apiService.loadFiltergroups();
  }

  /**
   * Generates a uuid
   * @returns {string}
   */
  public generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  /**
   * Helper method to clone given questionnaire.
   *
   * @param {Questionnaire} questionnaireMeta
   * @returns {Promise<Questionnaire>}
   */
  public cloneQuestionnaire(questionnaireMeta: Questionnaire): Promise<Questionnaire> {
    return new Promise((resolve, reject) => {
      // first, load questionnaire fully, not only metadata
      this.loadQuestionnaire(questionnaireMeta.id).then((questionnaire) => {
        const newQuestionnaire = this.clone(questionnaire);
        newQuestionnaire.id = this.generateUUID();
        newQuestionnaire.status = 'draft';

        newQuestionnaire.meta.author = this.getLoggedInUserName();
        // TODO: on i18n we should have a "copy" in locale version
        newQuestionnaire.meta.title[this.locale] = newQuestionnaire.meta.title[this.locale] + ' [Kopie]';
        newQuestionnaire.updatedAt = new Date().toJSON();
        newQuestionnaire.createdAt = new Date().toJSON();

        this.saveQuestionnaire(newQuestionnaire).then((clonedQuestionnaire) => {
          resolve(clonedQuestionnaire);
        }).catch((err) => {
          reject(err);
        });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  /**
   * Helper method to load questionnaire by id.
   *
   * @param {string} questionnaireId
   * @returns {Promise<Questionnaire>}
   */
  public loadQuestionnaire(questionnaireId: string): Promise<Questionnaire> {
    return this.apiService.loadQuestionnaire(questionnaireId);
  }

  /**
   * Saves the questionnaire
   *
   * @returns {Promise<any>}
   */
  public saveQuestionnaire(questionnaire: Questionnaire): Promise<Questionnaire> {
    return this.apiService.saveQuestionnaire(questionnaire);
  }

  /**
   * Deletes the questionnaire
   *
   * @returns {Promise<any>}
   */
  public deleteQuestionnaire(questionnaire: Questionnaire): Promise<Questionnaire> {
    return this.apiService.deleteQuestionnaire(questionnaire);
  }

  /**
   * Provides downloadable zip archive of given questionnaire id including assets
   *
   * @param {Questionnaire} gridQuestionnaire
   */
  public download(gridQuestionnaire: Questionnaire): Promise<any> {
    const dl = async (): Promise<any> => {
      try {
        this.layoutService.showPreloader();
        const questionnaire = await this.loadQuestionnaire(gridQuestionnaire.id);

        const fileName = questionnaire.meta.title.de_DE
          .replace(/([^a-z0-9 ]+)/gi, '')
          .replace(/ /gi, '_')
          .toLowerCase() + '.mqt';

        const payload = {
          $schema: 'questionnaire-schema.json',
          questionnaire: questionnaire,
        };

        if (this.assetApi) {
          const files = [{name: 'questionnaire.json', content: JSON.stringify(payload)}];
          const download = this.assetApi.downloadBucketZip(questionnaire.id, fileName, files);
          download.progress.subscribe((bytes) => {
            const mb = bytes / 1024 / 1024;
            this.layoutService.showPreloader(`${fileName}: ${mb.toFixed(2)} Mb`);
          });

          await download.promise;
        }
        // fallback for when asset module is not present
        else {
          const zip = new JSZip();
          zip.file('questionnaire.json', JSON.stringify(payload));

          const content = await zip.generateAsync({
            type: 'blob',
            mimeType: 'application/zip',
            compression: 'DEFLATE',
            comment: questionnaire.meta.title[this.locale],
            compressionOptions: {
              level: 9
            }
          });

          FileSaver.saveAs(content, fileName);
        }

        this.layoutService.hidePreloader();

      } catch (error) {
        this.layoutService.hidePreloader();
        alert(this._(KEYS.GRID.ERROR_DOWNLOADING_QUESTIONNAIRE));
        console.error(error);
      }
    };

    return dl();
  }

  /**
   * Provides import of zipped questionnaire
   *
   * @param file
   */
  public import(file): Promise<Questionnaire> {
    const imp = async (): Promise<Questionnaire> => {
      const bucketId = this.generateUUID();

      try {
        this.layoutService.showPreloader();
        let questionnaire: Questionnaire = null;

        if (this.assetApi) {
          const upload = this.assetApi.uploadBucketZip(bucketId, file);
          const qFileName = 'questionnaire.json';

          upload.progress.subscribe((percent) => {
            this.layoutService.showPreloader(`${file.name}: ${percent} %`);
            if (percent >= 100) {
              setTimeout(() => {
                this.layoutService.showPreloader(this._(KEYS.GRID.PROCESSING));
              }, 500);
            }
          });
          await upload.promise;

          const payload = <Payload>await this.assetApi.getBucketFileContent(bucketId, qFileName);

          if (typeof payload !== 'object' || typeof payload.questionnaire !== 'object') {
            await this.assetApi.removeBucket(bucketId);
            throw new Error(`Found no questionnaire in created bucket with id: ${bucketId}`);
          }

          questionnaire = payload.questionnaire;
          questionnaire.id = bucketId;

          // hotfix to import old questionnaire format with broken options type
          questionnaire.meta && Array.isArray(questionnaire.meta.options) && (questionnaire.meta.options = {});

          await this.saveQuestionnaire(questionnaire);
          await this.assetApi.removeBucketFile(bucketId, qFileName);
        }
        // fallback
        else {
          const zip = await JSZip.loadAsync(file);
          const content = await zip.file('questionnaire.json').async('string');
          const data = JSON.parse(content);

          if (typeof data.questionnaire !== 'undefined') {
            data.questionnaire.id = this.generateUUID();
            questionnaire = await this.saveQuestionnaire(data.questionnaire);
          } else {
            throw new Error('Failed to unzip!');
          }
        }

        setTimeout(() => this.layoutService.hidePreloader(), 500);
        return questionnaire;
      } catch (error) {
        this.layoutService.hidePreloader();
        await this.assetApi.removeBucket(bucketId);

        let msg = '';
        if (error instanceof HttpErrorResponse && error.error && error.error.message) {
          msg = ' Server: ' + error.error.message;
        }

        alert(this._(KEYS.GRID.ERROR_IMPORTING_QUESTIONNAIRE) + msg);
        throw error;
      }
    };
    return imp();
  }

  /**
   * Returns username of currently logged in user.
   * @returns {string}
   */
  public getLoggedInUserName() {
    const unknownUser = 'nologin@mymedax.de';

    if (!this.loginService) {
      return unknownUser;
    }

    const user = this.loginService.getUser();

    if (!user) {
      return unknownUser;
    }

    return user.getUsername();
  }

  /**
   * Returns number of questionnaires
   * @returns {Promise<number>}
   */
  public async getNumQuestionnaires(): Promise<number> {
    return this.apiService.getNumQuestionnaires();
  }

  /**
   * Clones the given object
   *
   * @param {any} obj
   * @returns {any}
   */
  private clone(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }
}
