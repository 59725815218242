import {Component, EventEmitter, Input, Optional, Output} from '@angular/core';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';

import {QuestionnaireEditorService} from '../../services/questionnaire-editor.service';
import {QuestionnaireFormatService} from '../../services/questionnaire-format.service';
import {TRANSLATION_EDITOR_SCOPE} from '../../../constants';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {KEYS} from '../../../translation-keys';


// hack to inject decorator declarations. must occur before class declaration!
export interface IoOverlayComponent extends Translatable {}

@Component({
  selector: 'app-qa-io-overlay',
  templateUrl: './io-overlay.component.html',
  styleUrls: ['./io-overlay.component.css']
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class IoOverlayComponent {
  @Input() questionnaire: Questionnaire;
  @Input() pathHashMap: any;
  @Input() visible = false;
  @Output() onHide: EventEmitter<any> = new EventEmitter();
  @Output() onImportQuestionnaire: EventEmitter<Questionnaire> = new EventEmitter();

  public constructor(
    private questionnaireEditorService: QuestionnaireEditorService,
    private questionnaireFormatService: QuestionnaireFormatService,
    @Optional() private translationService: TranslationService
  ) {
  }

  public importJSON(json: string) {
    this.questionnaireEditorService
      .loadQuestionnaireByJson(json, this.pathHashMap)
      .then(questionnaire => {
        this.onImportQuestionnaire.emit(questionnaire);
        this.onHide.emit();
      })
      .catch(error => {
        alert(this._(KEYS.EDITOR.ERROR_READING_QUESTIONNAIRE_JSON) + ' ' + error.message);
      });
  }

  public exportJSON(el: HTMLTextAreaElement | HTMLInputElement, unpacked: boolean = false) {
    const payload = this.questionnaireFormatService.getPayload(this.questionnaire, unpacked);

    payload.questionnaire.updatedAt = new Date().toJSON();
    payload.questionnaire.createdAt = new Date().toJSON();

    el.value = JSON.stringify(payload, null, 2);
  }

  public hide() {
    this.onHide.emit();
  }
}
