import {Injectable, Optional} from '@angular/core';
import {MediaCenter, MediaCenterService} from '@ngmedax/asset';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {ConfigService} from '@ngmedax/config';
import {QuestionnaireStateService} from './questionnaire-state.service';

@Injectable()
export class QuestionnaireMediaCenterService {
  /**
   * Asset base path
   */
  private readonly assetBaseUri = '/';

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private mediaCenter: MediaCenterService,
    private state: QuestionnaireStateService,
    private config: ConfigService,
  ) {
    this.assetBaseUri = this.config.get('apis.asset.cdn');
  }

  /**
   * Builds and returns container asset by given media center asset and optional container asset
   *
   * @param params
   */
  public buildContainerAsset(
    params: {
      mediaCenterAsset: MediaCenter.Asset,
      containerAsset: Questionnaire.Container.Asset,
      locale: string
    }
  ): Questionnaire.Container.Asset {
    const {mediaCenterAsset, locale} = params;
    let containerAsset = params.containerAsset;
    !mediaCenterAsset.options && (mediaCenterAsset.options = {});

    !containerAsset && (containerAsset = {title: {[locale]: ''}, filename: {[locale]: ''}});
    containerAsset.filename[locale] = mediaCenterAsset.name;
    !containerAsset.options && (containerAsset.options = {});
    containerAsset.options.autoPlay = !!mediaCenterAsset.options.autoPlay;
    containerAsset.options.allowControls = (mediaCenterAsset.options.hideControls) ? false : true;

    for (const prop of ['previewImage', 'croppedImage', 'cropperPosition']) {
      if (mediaCenterAsset.options[prop]) {
        !containerAsset.options[prop] && (containerAsset.options[prop] = {});
        containerAsset.options[prop][locale] = mediaCenterAsset.options[prop];
      } else {
        // delete property
        containerAsset.options && containerAsset.options[prop] && containerAsset.options[prop][locale]
          && delete(containerAsset.options[prop][locale]) && (!Object.keys(containerAsset.options[prop]).length)
          && delete(containerAsset.options[prop]);
      }
    }

    return containerAsset;
  }

  /**
   * Opens media center by given params
   *
   * @param params
   */
  public openMediaCenter(params: {
    questionnaireId: string,
    mediaType: 'videos' | 'images' | 'audio',
    containerAsset: Questionnaire.Container.Asset,
    callback: (selectedFile: MediaCenter.Asset) => void,
    maintainAspectRatio?: boolean,
    aspectRatio?: number,
    locale: string,
  }) {
    const options = this.createMediaCenterOptions(params);
    this.mediaCenter && this.mediaCenter.openModal(options);
  }

  /**
   * Creates and returns media center options by given params
   *
   * @param params
   */
  public createMediaCenterOptions(
    params: {
      questionnaireId: string,
      mediaType: 'videos' | 'images' | 'audio',
      containerAsset: Questionnaire.Container.Asset,
      callback: (selectedFile: MediaCenter.Asset) => void,
      maintainAspectRatio?: boolean,
      aspectRatio?: number,
      locale: string,
    }
  ): MediaCenter.Options {
    const fileTypes = {videos: 'video/mp4', audio: 'audio/mpeg', images: 'image/*'};
    const fileType = fileTypes[params.mediaType];

    const options: MediaCenter.Options = {
      maintainAspectRatio: !!params.maintainAspectRatio,
      aspectRatio: params.aspectRatio ? params.aspectRatio : 1,
      bucketId: params.questionnaireId,
      accept: fileType,
      filter: {type: fileType}
    };

    params.callback && (options.callback = params.callback);
    params.containerAsset && this.updateMediaCenterOptions(options, params.containerAsset, params.locale);
    return options;
  }

  /**
   * Updates given media center options by given container asset
   *
   * @param containerAsset
   * @param mediaCenterAsset
   * @param locale
   */
  public updateMediaCenterOptions(
    mediaCenterOptions: MediaCenter.Options,
    containerAsset: Questionnaire.Container.Asset,
    locale: string
  ): MediaCenter.Options {

    mediaCenterOptions.selectFile = {name: '', size: 0, date: '', type: '', unit: 'bytes', path: {local: '', web: ''}, options: {}};
    const selectFile = mediaCenterOptions.selectFile;

    // add file name
    containerAsset && containerAsset.filename
      && containerAsset.filename[locale]
      && (mediaCenterOptions.selectFile.name = containerAsset.filename[locale]);

    if (containerAsset && containerAsset.options) {
      // add "autoPlay" option
      containerAsset.options.autoPlay && (selectFile.options.autoPlay = containerAsset.options.autoPlay);

      // add "hideControls" option
      (typeof containerAsset.options.allowControls === 'boolean')
        && (selectFile.options.hideControls = !containerAsset.options.allowControls);

      for (const prop of ['previewImage', 'croppedImage', 'cropperPosition']) {
        containerAsset.options[prop] && containerAsset.options[prop][locale]
          && (selectFile.options[prop] = containerAsset.options[prop][locale]);
      }
    }

    return mediaCenterOptions;
  }

  /**
   * Returns asset url by given asset
   *
   * @param asset
   * @param locale
   */
  public getAssetUrl(asset: Questionnaire.Container.Asset, locale: string) {
    let fileName = asset && asset.filename && asset.filename[locale] ? asset.filename[locale] : null;
    const croppedImage = asset && asset.options && asset.options.croppedImage
      && asset.options.croppedImage[locale] ? asset.options.croppedImage[locale] : null;

    const questionnaireId = this.state.getQuestionnaire() ? this.state.getQuestionnaire().id : '';

    if (!fileName) {
      return;
    }

    if (croppedImage) {
      fileName = croppedImage;
    }

    const isDataUrl = fileName.match(/^data:image/);

    if (isDataUrl) {
      return fileName;
    }

    return `${this.assetBaseUri}/${questionnaireId}/${asset.filename[locale]}`;
  }

  /**
   * Returns preview image by given asset
   *
   * @param asset
   * @param locale
   */
  public getPreviewImage(asset: Questionnaire.Container.Asset, locale: string) {
    return asset.options && asset.options.previewImage && asset.options.previewImage[locale] ?
      asset.options.previewImage[locale] : null;
  }

  /**
   * Returns image data for given Asset
   *
   * @param file
   * @param bucketId
   */
  public async getImageData(
    file: MediaCenter.Asset,
    bucketId: string
  ): Promise<{width: number, height: number, dataUrl: string}> {
    return this.mediaCenter ? this.mediaCenter.getImageData(file, bucketId) : {width: 0, height: 0, dataUrl: ''};
  }

  /**
   * Returns true if asset module is present
   * @returns {boolean}
   */
  public hasAssetSupport(): boolean {
    return !!this.mediaCenter;
  }
}
