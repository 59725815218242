import {Component, OnInit, Optional} from '@angular/core';
import {Translatable, TranslationEventService, TranslationService} from '@ngmedax/translation';
import {LayoutService} from '@ngmedax/layout';
import {ConfigService} from '@ngmedax/config';
import {Base64 as base64} from 'js-base64';
import {TRANSLATION_VERSION_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';


// hack to inject decorator declarations. must occur before class declaration!
export interface VersionComponent extends Translatable {}

/**
 * License component
 */
@Component({
    selector: 'app-version',
    templateUrl: './version.component.html',
    styleUrls: ['./version.component.css']
})
@Translatable({scope: TRANSLATION_VERSION_SCOPE, keys: KEYS})
export class VersionComponent implements OnInit {
  /**
   * Default locale
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Version info
   */
  public versionInfo: {mymedax: {[key: string]: string}, dependencies: {[key: string]: string}};

  /**
   * Main Version
   */
  public version: string;

  /**
   * Constructor
   */
  public constructor(
    @Optional() private translationEvents: TranslationEventService,
    @Optional() private translationService: TranslationService,
    private layout: LayoutService,
    private config: ConfigService
  ) {
    const encodedVersionInfo = config.get('versions.base64');
    this.versionInfo = JSON.parse(base64.decode(encodedVersionInfo));
  }

  public ngOnInit() {
    this.version = this.layout.getVersion();
  }

  services(scope) : Array<string> {
    return Object.keys(this.versionInfo[scope]);
  }
}
