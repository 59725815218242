import {ChangeDetectorRef, Component, ElementRef, OnInit, Optional, ViewChild} from '@angular/core';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {AssetApiService} from '@ngmedax/asset';
import {LayoutService} from '@ngmedax/layout';
import {ConfigService} from '@ngmedax/config';
import {DATE_FORMAT_YMD_HM, Translatable, TranslationService} from '@ngmedax/translation';
import {QuestionnaireGridService} from '../../services/questionnaire-grid.service';
import {Filtergroup, QuestionnaireGridFilter} from '../../../../types';
import {TRANSLATION_GRID_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';
import {HttpErrorResponse} from "@angular/common/http";


// hack to inject decorator declarations. must occur before class declaration!
export interface QuestionnaireGridComponent extends Translatable {}
declare const $: any;

@Component({
  selector: 'app-qa-grid',
  templateUrl: './questionnaire-grid.component.html',
  styleUrls: ['./questionnaire-grid.component.css']
})
@Translatable({scope: TRANSLATION_GRID_SCOPE, keys: KEYS})
export class QuestionnaireGridComponent implements OnInit {
  public isSearchCollapsed = true;
  public isGridCollapsed = false;
  public gridPageNumber = 1;
  public displayPerPage = 25;
  public questionnaires: Questionnaire[] = [];
  public filtergroups: string[] = [];
  public filtergroupObjects: Filtergroup[] = [];
  public filter: QuestionnaireGridFilter = {};
  public isProcessing;
  public isNumQuestLimitReached = false;
  public total: 0;

  @ViewChild('qaSearch') searchRef: ElementRef;
  @ViewChild('qaUploadForm') uploadFormRef: ElementRef;

  /**
   * Locale for questionnaires. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Timeout for filter change event
   */
  private filterChangeTimeout: any = null;

  /**
   * Timeout for show preloader event
   */
  private showPreloaderTimeout: any = null;

  /**
   * date format to use in grid
   * @type {string}
   */
  public get dateFormat() {
    const format = DATE_FORMAT_YMD_HM.replace(/YYYY/, 'YY');
    return this.getDateFormat(format);
  };

  /**
   * Loads questionnaires and initializes search filter
   */
  public ngOnInit() {
    this.layoutService.showPreloader();
    this.loadQuestionnaires();
    this.applyConstraints();
    this.onResetSearchForm();
  }

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    @Optional() private assetApi: AssetApiService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private questionnaireGridService: QuestionnaireGridService,
    private ref: ChangeDetectorRef
  ) {
  }

  /**
   * Event handler for when paging changes. Triggers the change detection
   */
  public onPagingChange() {
    // trigger change detection
    this.ref.detectChanges();
    this.ref.markForCheck();

    this.loadQuestionnaires();
  }

  /**
   * Event handler for when filter changes. Loads patients
   */
  public onFilterChange() {
    clearTimeout(this.filterChangeTimeout);
    this.filterChangeTimeout = setTimeout(() => this.loadQuestionnaires(), 1000);
  }

  /**
   * Event handler for when we should reset the search form
   */
  public onResetSearchForm() {
    this.filter.title = '';
    this.filter.description = '';
    this.filter.author = '';
    this.filter.status = '';
  }

  /**
   * Returns questionnaires which are allowed by currently set filter
   *
   * @returns {Array}
   */
  public getQuestionnaires(): Questionnaire[] {
    return this.questionnaires;
  }

  /**
   * Filters out non existing filtergroups
   *
   * @param filtergroups
   * @returns {string[]}
   */
  public filterTags(tags: string[]): string[] {
    const filtered = [];

    for (const tag of tags) {
      if (this.filtergroups.indexOf(tag) !== -1) {
        filtered.push(tag);
      }
    }

    return filtered;
  }

  /**
   * Event handler for when we want to download a questionnaire
   *
   * @param questionnaire
   */
  public onDownload(questionnaire: Questionnaire) {
    this.questionnaireGridService.download(questionnaire);
  }

  /**
   * Event handler for when we want to clone a questionnaire
   *
   * @param questionnaire
   */
  public onClone(questionnaire: Questionnaire) {
    this.layoutService.showPreloader();

    (async() => {
      try {
        const clonedQuestionnaire = await this.questionnaireGridService.cloneQuestionnaire(questionnaire);
        this.assetApi && await this.assetApi.copyBucket(questionnaire.id, clonedQuestionnaire.id);

        setTimeout(() => {
          this.questionnaires.unshift(clonedQuestionnaire);
          this.applyConstraints();
          this.layoutService.hidePreloader();
          setTimeout(() => alert(this._(KEYS.GRID.SUCCESSFULLY_DUPLICATED_QUESTIONNAIRE)), 1000);
        }, 1000);
      } catch (error) {
        this.layoutService.hidePreloader();
        alert(this._(KEYS.GRID.ERROR_DUPLICATING_QUESTIONNAIRE));
        console.error(error);
      }
    })();
  }


  /**
   * Event handler for when we want to delete a questionnaire
   * @param questionnaire
   */
  public async onDelete(questionnaire: Questionnaire) {
    const title = questionnaire.meta.title[this.locale];
    const shouldDelete = await confirm(this._(KEYS.GRID.QUESTION_DELETE_QUESTIONNAIRE, {title}));

    if (!shouldDelete) {
      return;
    }

    const promises: Promise<any>[] = [
      this.questionnaireGridService.deleteQuestionnaire(questionnaire),
      this.assetApi && this.assetApi.removeBucket(questionnaire.id)
    ];

    try {
      await Promise.all(promises);
      alert(this._(KEYS.GRID.SUCCESSFULLY_DELETED_QUESTIONNAIRE));
      this.questionnaires.splice(this.questionnaires.indexOf(questionnaire), 1);
      this.applyConstraints();
    } catch (error) {
      alert(this._(KEYS.GRID.ERROR_DELETING_QUESTIONNAIRE));
      console.error(error);
    }
  }

  /**
   * Event handler for when we want to import questionnaires
   * @param event
   */
  public async onImportQuestionnaires(event: Event) {
    try {
      const files: FileList = event && event.target && event.target['files'] ? event.target['files'] : null;

      if (!files[0]) {
        return;
      }

      const constraints = await this.getConstraints();

      if (constraints.numAllowed && (constraints.numCreated + files.length) > constraints.numAllowed) {
        alert(this._(KEYS.GRID.ERROR_CANT_IMPORT_BECAUSE_OF_QUESTIONNAIRE_LIMIT));
        return;
      }

      for (const file of Array.from(files)) {
        this.isProcessing = true;
        const questionnaire = await this.questionnaireGridService.import(file);
        this.questionnaires.unshift(questionnaire);
      }

      setTimeout(() => {
        this.uploadFormRef.nativeElement.reset();
        this.applyConstraints();
        this.isProcessing = false;
        setTimeout(() => alert(this._(KEYS.GRID.SUCCESSFULLY_IMPORTED_QUESTIONNAIRES)), 500);
      }, 1000);
    } catch (error) {
      this.hidePreloader();
      // alert(this._(KEYS.GRID.FAILED_TO_IMPORT_QUESTIONNAIRES));
      this.uploadFormRef.nativeElement.reset();
      this.isProcessing = false;
      console.error(error);
    }
  }

  /**
   * Event handler for when we want to export all questionnaires
   */
  public async onExportQuestionnaires() {
    try {
      const sure = await confirm(this._(KEYS.GRID.QUESTION_EXPORT_ALL_QUESTIONNAIRES));

      if (!sure) {
        return;
      }

      this.layoutService.showPreloader();

      const filter = this.getQueryFilter();
      const result = await this.questionnaireGridService.loadQuestionnaires(filter);
      const questionnaires = result.rows;

      for (const questionnaire of questionnaires) {
        await this.questionnaireGridService.download(questionnaire);
      }

      alert(this._(KEYS.GRID.SUCCESSFULLY_EXPORTED_QUESTIONNAIRES));
    } catch (error) {
      this.layoutService.hidePreloader();
      alert(this._(KEYS.GRID.FAILED_TO_EXPORT_QUESTIONNAIRES));
      console.error(error);
    }
  }

  /**
   * Converts normal select html elements into dynamic select2 elements
   */
  private renderSelect2() {
    const selects = $(this.searchRef.nativeElement).find('select[data-edit]');
    const cmp = this;

    // convert selects
    selects.select2({
      width: '100%',
      placeholder: '...',
      sorter: function(data) {
        return data.sort(function (a, b) {
          if (a.id > b.id) {
            return 1;
          }
          if (a.id < b.id) {
            return -1;
          }
          return 0;
        });
      }
    });

    const updateFiltergroups = function() {
      cmp.filter.tags = $(<any>this).val();
      cmp.loadQuestionnaires();
    };

    // select2 change event > triggers angular events
    selects.on('select2:select', updateFiltergroups);
    selects.on('select2:unselect', updateFiltergroups);
  }

  private loadQuestionnaires() {
    this.showPreloader();

    const filter = this.getQueryFilter();
    const opts: any = this.getQueryOpts();
    opts.sort = '-createdAt';

    const promises: Promise<any>[] = [
      this.questionnaireGridService.loadQuestionnaires(filter, opts),
      !this.filtergroups.length ? this.questionnaireGridService.loadFiltergroups() : Promise.resolve([])
    ];

    Promise
      .all(promises)
      .then((result) => {
        this.questionnaires = result[0].rows;
        this.total = result[0].total || 0;

        if (result[1]) {
          for (const filtergroup of result[1]) {
            this.filtergroupObjects.push(filtergroup);
            this.filtergroups.push(filtergroup.id);
          }
        }

        this.renderSelect2();
        this.hidePreloader();
      })
      .catch(error => {
        alert(this._(KEYS.GRID.ERROR_LOADING_QUESTIONNAIRES));
        this.hidePreloader();
        console.log(error);
      });
  }

  /**
   * Returns questionnaire constraints
   *
   * @returns {Promise<{numAllowed: number, numCreated: number}>}
   */
  private async getConstraints(): Promise<{numAllowed: number, numCreated: number}> {
    const numAllowed = this.configService.get('constraint.numQuestionnaires');
    const numCreated = await this.questionnaireGridService.getNumQuestionnaires();
    return {numAllowed, numCreated};
  }

  /**
   * Apply configured constraints
   */
  private applyConstraints() {
    this.getConstraints().then(constraints => apply(constraints)).catch(error => console.error(error));
    const apply = (constraints) => {
      if (!constraints.numAllowed) {
        return;
      }

      this.isNumQuestLimitReached = constraints.numCreated >= constraints.numAllowed;
    }
  }

  /**
   * Returns query filter by filter object
   */
  private getQueryFilter() {
    const filter: any = {};
    const mappings = {title: 'meta.title.de_DE', author: 'meta.author', description: 'meta.description', tags: 'meta.tags', status: 'status'};
    Object.keys(this.filter).filter(key => this.filter[key])
      .forEach((key) => filter[mappings[key]] = { '$regex': this.filter[key], '$options' : 'i' });

    delete(filter[mappings.tags]);
    this.filter.tags && this.filter.tags.length && (filter[mappings.tags] = {'$in': this.filter.tags});
    return filter;
  }

  /**
   * Returns query opts
   */
  private getQueryOpts() {
    return {limit: this.displayPerPage, offset: (this.gridPageNumber - 1) * this.displayPerPage}
  }

  /**
   * Shows preloader after 200ms
   */
  private showPreloader() {
    this.showPreloaderTimeout = setTimeout(() => this.layoutService.showPreloader(), 200);
  }

  /**
   * Hides preloader
   */
  private hidePreloader() {
    clearTimeout(this.showPreloaderTimeout);
    this.layoutService.hidePreloader();
    setTimeout(() => this.layoutService.hidePreloader(), 1000);
  }
}
