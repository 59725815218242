export const NAVIGATION = {
  name: 'Fragebogen',
  icon: 'fa-id-card-o',
  path: '/module/questionnaire/',
  children: [{
    name: 'Fachgruppen',
    path: '/module/questionnaire/filtergroups',
    icon: 'fa-filter'
  }]
};
