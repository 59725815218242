import {RouterModule, Routes} from '@angular/router';
import {SettingsComponent} from './components/settings.component';

import {PrintersComponent} from './components/printers.component';
import {LicenseComponent} from './components/license.component';
import {LanguagesComponent} from './components/languages.component';
import {permission} from './settings.permission';

const requiredPermissions = [
  permission.MYMEDAX_SETTING_CREATE,
  permission.MYMEDAX_SETTING_READ,
  permission.MYMEDAX_SETTING_UPDATE,
  permission.MYMEDAX_SETTING_DELETE
];

const APP_ROUTES: Routes = [
    {
      path: 'module/settings/deletion-periods',
      component: SettingsComponent,
      canActivate: ['CoreGuard'],
      data: {
        needsLogin: true,
        needsPermissions: requiredPermissions
      }
    },
    /*
    {
      path: 'module/settings/users',
      component: UsersComponent,
      canActivate: ['CoreGuard'],
      data: {
        needsLogin: true,
        needsPermissions: requiredPermissions
      }
    },
    {
      path: 'module/settings/edituser',
      component: UsersFormComponent,
      canActivate: ['CoreGuard'],
      data: {
        needsLogin: true,
        needsPermissions: requiredPermissions
      }
    },
    {
      path: 'module/settings/printers',
      component: PrintersComponent,
      canActivate: ['CoreGuard'],
      data: {
        needsLogin: true,
        needsPermissions: requiredPermissions
      }
    },
    */
    /*
    {
      path: 'module/settings/customer',
      component: CustomerComponent,
      canActivate: ['CoreGuard'],
      data: {
        needsLogin: true,
        needsPermissions: requiredPermissions
      }
    },
    {
      path: 'module/settings/license',
      component: LicenseComponent,
      canActivate: ['CoreGuard'],
      data: {
        needsLogin: true,
        needsPermissions: requiredPermissions
      }
    },
     */
    {
      path: 'module/settings/languages',
      component: LanguagesComponent,
      canActivate: ['CoreGuard'],
      data: {
        needsLogin: true,
        needsPermissions: requiredPermissions
      }
    }
];

export const routing = RouterModule.forRoot(APP_ROUTES);
