import {Router} from '@angular/router';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {LayoutService} from '@ngmedax/layout';

import {routing} from './settings.routing';
import {NAVIGATION} from './settings.navigation';
import {SettingsComponent} from './components/settings.component';
import {SettingsService} from './services/settings.service';
import {PrintersComponent} from './components/printers.component';
import {LicenseComponent} from './components/license.component';
import {LanguagesComponent} from './components/languages.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    routing
  ],
  declarations: [
    SettingsComponent,
    PrintersComponent,
    LicenseComponent,
    LanguagesComponent,
  ],
  exports: [],
  providers: [
    SettingsService
  ]
})
export class SettingsModule {
  public constructor(
    private router: Router,
    private layoutService: LayoutService
  ) {
    layoutService.addMenuEntry(NAVIGATION);
  }
}
