import {LicenseComponent} from './components/license.component';
import {NAVIGATION} from './settings-license.navigation';
import {routing} from './settings-license.routing';
import {ApiService} from './service/api-service';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LayoutService} from '@ngmedax/layout';


@NgModule({
  imports: [
    CommonModule,
    routing
  ],
  declarations: [
    LicenseComponent
  ],
  exports: [],
  providers: [ApiService]
})
export class SettingsLicenseModule {
  constructor(private layoutService: LayoutService) {
    layoutService.addMenuEntry(NAVIGATION);
  }
}
