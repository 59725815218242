import {CommonModule} from '@angular/common';
import {NgModule, Optional} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {LayoutService} from '@ngmedax/layout';
import {routing} from './survey-code.routing';
import {NAVIGATION} from './survey-code.navigation';
import {SurveyService} from './services/survey.service';
import {SurveyCodeComponent} from './components/survey-code.component';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {TRANSLATION_DEFAULT_SCOPE} from '../../constants';
import {KEYS} from '../../translation-keys';


// hack to inject decorator declarations. must occur before class declaration!
export interface SurveyCodeModule extends Translatable {}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    routing
  ],
  declarations: [
    SurveyCodeComponent,
  ],
  exports: [],
  providers: [
    SurveyService
  ]
})
@Translatable({scope: TRANSLATION_DEFAULT_SCOPE, keys: KEYS})
export class SurveyCodeModule {
  public constructor(
    private layoutService: LayoutService,
    @Optional() private translationService: TranslationService,
  ) {
    // this.layoutService.addMenuEntry(NAVIGATION);
  }
}
