<div class="qa-canvas-options">
  <label class="bgcolor-label" for="bgcolor">{{_(KEYS.EDITOR.BACKGROUND_COLOR)}}</label>
  <input
    class="bgcolor-input form-control" id="bgcolor"
    [colorPicker]="question.options.bgColor"
    (colorPickerChange)="question.options.bgColor=$event"
    [style.background]="question.options.bgColor"
    [value]="question.options.bgColor"
    [cpPosition]="'bottom'"
    [cpOKButtonClass]="'btn btn-primary'"
    [cpSaveClickOutside]="true"
    [cpOKButton]="true"
  />

  <label class="drawcolor-label" for="drawcolor">{{_(KEYS.EDITOR.PEN_COLOR)}}</label>
  <input
    class="drawcolor-input form-control" id="drawcolor"
    [colorPicker]="question.options.drawColor"
    (colorPickerChange)="question.options.drawColor=$event"
    [style.background]="question.options.drawColor"
    [value]="question.options.drawColor"
    [cpPosition]="'bottom'"
    [cpOKButtonClass]="'btn btn-primary'"
    [cpSaveClickOutside]="true"
    [cpOKButton]="true"
  />

  <label class="height-label" for="height">{{_(KEYS.EDITOR.HEIGHT)}}</label>
  <input
    class="height-input form-control"
    id="height"
    type="number"
    [(ngModel)]="question.options.height"
  />

  <label class="image-label">{{_(KEYS.EDITOR.BACKGROUND_IMAGE)}}</label>
  <div class="image-input card qa-card-inline-block">
    <div class="card-footer">
      <div class="btn-toolbar justify-content-between">
        <div class="justify-content-between">
          <label class="btn btn-primary qa-no-margin" (click)="onOpenMediaCenterModal()">
            <i class="fa fa-plus"></i> {{_(KEYS.EDITOR.ADD_IMAGE)}}
          </label>
        </div>
        <button
          class="btn btn-danger"
          (click)="onDeleteImage()"
          [attr.disabled]="!(question.options.bgImage && question.options.bgImage[locale])? true: null">
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </div>
    <div class="card-body">
      <img
        class="card-img-top qa-canvas-image mb-3"
        *ngIf="question.options.bgImage && question.options.bgImage[locale]; else qaCanvasImgPlaceholder"
        [src]="question.options.bgImage[locale]"
        (click)="onOpenMediaCenterModal()" />
      <ng-template #qaCanvasImgPlaceholder>
        <img
          class="card-img-top qa-canvas-image mb-3"
          src="/assets/images/placeholder.png"
          (click)="onOpenMediaCenterModal()"/>
      </ng-template>
    </div>
  </div>

</div>
