import {Component, OnInit, Optional, ViewEncapsulation} from '@angular/core';
import {DATE_FORMAT_YMD_HMS, Translatable, TranslationService} from '@ngmedax/translation';
import {LayoutService} from '@ngmedax/layout';
import {ValueService} from '@ngmedax/value';

import {TRANSLATION_HISTORY_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';
import {Survey, SurveyHistory} from '../../../types';
import {DateFormatService} from '../../../../translation';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SurveyGridService} from '../services/survey-grid.service';


// hack to inject decorator declarations. must occur before class declaration!
export interface SurveyHistoryComponent extends Translatable {}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-survey-history',
  templateUrl: './survey-history.component.html',
  styleUrls: ['./survey-history.component.css'],
})
@Translatable({scope: TRANSLATION_HISTORY_SCOPE, keys: KEYS})
export class SurveyHistoryComponent implements OnInit {
  /**
   * Locale for surveys. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Survey history
   */
  public history: SurveyHistory[] = [];

  /**
   * Survey history entries found?
   */
  public noEntries: boolean = false;

  /**
   * Parent survey id (only set for resubmitted surveys)
   */
  public parentSurveyId: string = null;

  /**
   * Icon mapping for events
   */
  private iconMapping = {
    surveyCreated: 'fa-plus',
    surveyAssigned: 'fa-user',
    surveySaved: 'fa-save',
    surveyExportAdded: 'fa-wpforms',
    surveySubmitted: 'fa-send-o',
    surveyDocumentSigned: 'fa-wpforms',
    surveySigned: 'fa-edit',
    surveyDone: 'fa-check',
    surveyMailSent: 'fa-envelope-o',
    surveyExportDownloaded: 'fa-download',
    surveyCanceled: 'fa-ban',
    surveyExpired: 'fa-clock-o'
  };

  /**
   * Custom icon mapping mods for resubmitted surveys
   */
  private resubmitIconMapping = {
    ...this.iconMapping,
    surveyCreated: 'fa-copy',
    surveyAssigned: 'fa-refresh'
  };

  /**
   */
  public ngOnInit() {
  }

  /**
   * Injects dependencies
   */
  public constructor(
    public activeModal: NgbActiveModal,
    @Optional() private translationService: TranslationService,
    @Optional() private dateFormatService: DateFormatService,
    private surveyGridService: SurveyGridService,
    private value: ValueService,
    private layout: LayoutService
  ) {
  }

  /**
   * Load survey history
   * @param surveyId
   */
  public async load(surveyId: string) {
    const survey = await this.surveyGridService.loadSurvey(surveyId);
    const res = await this.surveyGridService.loadSurveyHistory(surveyId);
    (!res.rows || !res.rows.length) && (this.noEntries = true);
    this.parentSurveyId = res.parentSurveyId || null;
    this.history = res.rows;

    const now = <any>new Date().toJSON();
    const isDone = survey.status === Survey.Status.submitted || survey.status === Survey.Status.signed;
    const isExpired = survey.validUntil && survey.validUntil < now;

    // virtual history entry for expired surveys (if not done)
    if (!isDone && isExpired) {
      this.history.push({
        id: '',
        surveyId: surveyId,
        status: '',
        event: 'surveyExpired',
        data: null,
        createdAt: <any>survey.validUntil,
        updatedAt: <any>survey.validUntil
      });
    }
  }

  public getIcon(history: SurveyHistory) {
    return this.value.get(this.parentSurveyId ? this.resubmitIconMapping : this.iconMapping, [history.event]);
  }

  public getText(history: SurveyHistory) {
    const fileName = (history: SurveyHistory) => this.value.get(history, ['data', 'fileName'])
    const questTitle = (history: SurveyHistory) => this.value.get(history, ['data', 'questionnaireTitle', this.locale]);
    const tpl = (history: SurveyHistory) => {
      return `${this._('Fragebogen')}: "${questTitle(history)}", ${this._('Datei')}: "${fileName(history)}".`
    }

    switch(history.event) {
      case 'surveyCreated':
        if (this.parentSurveyId) {
          return this._('Befragung aus vorheriger erstellt') + ': ' + this.parentSurveyId;
        }
        return this._('Befragung wurde angelegt.');
        break;
      case 'surveyAssigned':
        if (this.parentSurveyId) {
          return this._('Export Dateien werden generiert.');
        }
        return this._('Befragung wurde auf das Gerät geladen.');
        break;
      case 'surveySaved':
        return this._('Befragung wurde zwischengespeichert.');
        break;
      case 'surveyExportAdded':
      case 'surveyDocumentSigned':
        return this._('Export Datei erzeugt.') + ` ${tpl(history)}`;
        break;
      case 'surveySubmitted':
        if (this.parentSurveyId) {
          return this._('Befragung erfolgreich abgeschlossen.');
        }
        return this._('Befragung erfolgreich abgegeben.');
        break;
      case 'surveySigned':
        return this._('Alle Dokumente der Befragung signiert.');
        break;
      case 'surveyDone':
        return this._('Befragung abgeschlossen.');
        break;
      case 'surveyMailSent':
        return this._('Befragung wurde per Mail an Ausfüller gesendet.');
        break;
      case 'surveyExportDownloaded':
        return this._('Export Datei heruntergeladen.') + ` ${tpl(history)}`;
        break;
      case 'surveyCanceled':
        return this._('Befragung wurde abgebrochen.');
        break;
      case 'surveyExpired':
        return this._('Befragung ist abgelaufen.');
        break;
    }
  }

  /**
   * date format to use
   * @type {string}
   */
  public get dateFormat() {
    const format = DATE_FORMAT_YMD_HMS.replace(/YYYY/, 'YY');
    return this.getDateFormat(format);
  };
}
