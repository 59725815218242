export const NAVIGATION = {
  name: 'Einstellungen',
  icon: 'fa-cog',
  path: '/module/settings/',
  children: [{
    name: 'Version',
    path: '/module/settings/version',
    icon: 'fa-code'
  }]
};
