 <div class="input-group">
  <span class="input-group-addon">{{_(KEYS.EDITOR.SOURCE)}}</span>
  <select class="form-control qa-custom-select" [(ngModel)]="sourceScope">
    <ng-container *ngFor="let scopeName of scopeNames">
      <option value="{{scopeName}}" *ngIf="isAllowedSourceScope(scopeName)">{{variableScopes[scopeName].name}}</option>
    </ng-container>
  </select>
  <select class="form-control qa-custom-select" [(ngModel)]="sourceVariable">
    <ng-container *ngIf="sourceScope && variableScopes[sourceScope]">
      <ng-container *ngIf="variableScopes[sourceScope].mappings; else noSourceMappingVar">
        <option
          value="{{variableScopes[sourceScope].mappings[key]}}"
          *ngFor="let key of getObjectKeys(variableScopes[sourceScope].mappings)"
        >
          {{key}}
        </option>
      </ng-container>
    </ng-container>


    <ng-template #noSourceMappingVar>
      <option *ngFor="let varName of getVariablesForScope(sourceScope)" value="{{varName}}">{{varName}}</option>
    </ng-template>
  </select>

  <span class="input-group-addon">=</span>

  <span class="input-group-addon">{{_(KEYS.EDITOR.TARGET)}}</span>
  <select class="form-control qa-custom-select" [(ngModel)]="targetScope">
    <ng-container *ngFor="let scopeName of scopeNames">
      <option value="{{scopeName}}" *ngIf="isAllowedTargetScope(scopeName)">{{variableScopes[scopeName].name}}</option>
    </ng-container>
  </select>
  <select class="form-control qa-custom-select" [(ngModel)]="targetVariable">
    <ng-container *ngIf="targetScope && variableScopes[targetScope]">
      <ng-container *ngIf="variableScopes[targetScope].mappings; else noTargetMappingVar">
        <option
          value="{{variableScopes[targetScope].mappings[key]}}"
          *ngFor="let key of getObjectKeys(variableScopes[targetScope].mappings)"
        >
          {{key}}
        </option>
      </ng-container>
    </ng-container>

    <ng-template #noTargetMappingVar>
      <option *ngFor="let varName of getVariablesForScope(targetScope)" value="{{varName}}">{{varName}}</option>
    </ng-template>
  </select>

  <button class="btn btn-success add-btn" [attr.disabled]="canAdd() ? null : true" (click)="onAdd()">
    <i class="fa fa-plus"></i>
  </button>
</div>


<div class="qa-mapping-grid">
  <table class="table">
    <thead>
    <tr>
      <th>{{_(KEYS.EDITOR.SOURCE)}}</th>
      <th>{{_(KEYS.EDITOR.TARGET)}}</th>
      <th>{{_(KEYS.EDITOR.OPTIONS)}}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let mapping of questionnaire.meta.options.variablesMapping; let pos = index">
      <ng-container *ngIf="isValidMapping(mapping)">
        <td>{{formatScopeName(mapping.source.scope)}} > {{formatVarName(mapping.source.variable)}}</td>
        <td>{{formatScopeName(mapping.target.scope)}} > {{formatVarName(mapping.target.variable)}}</td>
        <td>
          <button class="btn btn-danger icon-only" (click)="onDelete(pos)">
            <i class="fa fa-trash"></i>
          </button>
        </td>
      </ng-container>
    </tr>
    </tbody>
  </table>
</div>

