export const configKeys = {
  /**
   * Config key for pdf form api uri
   * @type {string}
   */
  PDF_FORM_URI_CONFIG_KEY: 'apis.pdf.form.uri',

  /**
   * Config key for session auth token
   * @type {string}
   */
  SESSION_AUTH_TOKEN: 'session.authToken',

  /**
   * Config key for session tenant id
   * @type {string}
   */
  SESSION_TENANT_ID: 'session.tenantId'
};
