import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ConfigService} from '@ngmedax/config';
import {RegistryService} from '@ngmedax/registry';
import {configKeys} from '../survey-grid.config-keys';
import {Survey, SurveyHistory} from '../../../types';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SurveyHistoryComponent} from '../components/survey-history.component';


@Injectable()
export class SurveyGridService {
  /**
   * Injects dependencies
   */
  public constructor(
    private registryService: RegistryService,
    private configService: ConfigService,
    private modal: NgbModal,
    private http: HttpClient
  ) {
  }

  /**
   * Loads surveys
   *
   * @param filter: any
   * @returns {{rows: Survey[], total: number}}
   */
  public loadSurveys(filter?: any, opts?: any): Promise<{rows: Survey[], total: number}> {
    const query: any = {filter: JSON.stringify(filter) || '{}', opts: JSON.stringify(opts) || '{}'};
    const basePath = `${this.getSurveyApiUrl()}?${decodeURI(new URLSearchParams(query).toString())}`;

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers: this.getAuthHeaders()})
        .subscribe((result: any) => {
            const surveys = result.survey || result.rows || result || [];
            const total = result.total || 0;
            resolve({rows: surveys, total});
          },
          error => {
            reject(error);
          });
    });
  }

  public loadSurvey(surveyId: string): Promise<Survey> {
    const basePath = `${this.getSurveyApiUrl()}/${surveyId}`;

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers: this.getAuthHeaders()})
        .subscribe((result: any) => {
            resolve(result.survey || result || {});
          },
          error => {
            reject(error);
          });
    });
  }

  /**
   * Loads survey history
   */
  public loadSurveyHistory(surveyId: string): Promise<{rows: SurveyHistory[], parentSurveyId?: string}> {
    const basePath = `${this.getSurveyApiUrl()}/history/${surveyId}`;

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers: this.getAuthHeaders()})
        .subscribe((result: any) => {
            const history = result.surveyHistory || result.rows || result || [];
            const parentSurveyId = result.parentSurveyId || null;
            resolve({rows: history, parentSurveyId});
          },
          error => {
            reject(error);
          });
    });
  }

  /**
   * Resubmits a survey
   */
  public resubmitSurvey(surveyId: string): Promise<void> {
    const basePath = `${this.getSurveyApiUrl()}/resubmit/${surveyId}`;

    return new Promise((resolve, reject) => {
      this.http.post(basePath, null, {headers: this.getAuthHeaders()})
        .subscribe((result: any) => {
            if (!result || typeof result !== 'object' || !result.success) {
              reject(result.message || 'Unknown error');
              return;
            }
            resolve(result);
          },
          error => {
            reject(error);
          });
    });
  }

  /**
   * Returns api url for survey
   *
   * @param {string} suffix
   * @returns {string}
   */
  private getSurveyApiUrl(suffix: string = null): string {
    return this.buildUrl(configKeys.SURVEY_API_URI_CONFIG_KEY, suffix);
  }



  /**
   * Returns url for config key. Adds auth information to url path when api is not deprecated
   *
   * @param {string} configKey
   * @returns {any}
   */
  private buildUrl(configKey: string, suffix = null) {
    let uri = this.configService.get(configKey);

    if (suffix) {
      uri = `${uri}${suffix}`;
    }

    return uri;
  }

  /**
   * Returns auth headers by auth token and tenant id
   */
  private getAuthHeaders(): any {
    const headers: any = {};
    const authToken = this.registryService.get(configKeys.SESSION_AUTH_TOKEN);
    const tenantId = this.registryService.get(configKeys.SESSION_TENANT_ID);

    if (authToken) {
      headers['X-Api-Token'] = authToken;
    }

    if (tenantId) {
      headers['X-Api-TenantId'] = `${tenantId}`;
    }

    return headers;
  }
}
