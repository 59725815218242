import {QuestionnaireEditorService} from './questionnaire-editor.service';
import {Injectable} from '@angular/core';

@Injectable()
export class QuestionnaireTabsService {

  /**
   * Injects dependencies
   *
   * @param questionnaireEditorService
   */
  public constructor(
    private questionnaireEditorService: QuestionnaireEditorService
  ) {
  }

  /**
   * Event handler for when we should expand all questionnaire questions
   */
  public onExpandQuestions() {
    this.questionnaireEditorService.questionDisplayChange.emit({expandQuestion: true});
  }

  /**
   * Event handler for when we should fold all questionnaire questions
   */
  public onFoldQuestions() {
    this.questionnaireEditorService.questionDisplayChange.emit({expandQuestion: false});
  }

  /**
   * Expands all contents
   */
  public onExpandQuestionContents() {
    this.onExpandQuestionTabs('expandContent');
  }

  /**
   * Expands all images
   */
  public onExpandQuestionImages() {
    this.onExpandQuestionTabs('expandImages');
  }

  /**
   * Expands all videos
   */
  public onExpandQuestionVideos() {
    this.onExpandQuestionTabs('expandVideos');
  }

  /**
   * Expands all audio
   */
  public onExpandQuestionAudio() {
    this.onExpandQuestionTabs('expandAudio');
  }

  /**
   * Expands all descriptions
   */
  public onExpandQuestionDescriptions() {
    this.onExpandQuestionTabs('expandDescriptions');
  }

  /**
   * Event handler for when we should expand all questionnaire questions
   * and auto open the "conditions" tab
   */
  public onExpandQuestionConditions() {
    this.onExpandQuestionTabs('expandConditions');
  }

  /**
   * Event handler for when we should expand all questionnaire questions
   * and auto open tabs by given command
   */
  public onExpandQuestionTabs(cmd: string) {
    const cmdObj = {
      expandQuestion: true,
      expandContents: false,
      expandDescriptions: false,
      expandConditions: false,
      expandImages: false,
      expandVideos: false,
      expandAudio: false
    };

    cmdObj[cmd] = true;

    this.questionnaireEditorService.questionDisplayChange.emit(cmdObj);
  }
}
