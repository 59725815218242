import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ConfigService} from '@ngmedax/config';
import {RegistryService} from '@ngmedax/registry';
import {AssetApiService} from '@ngmedax/asset';
import {PdfForm} from '../../../types';
import {configKeys} from '../pdf-form.config-keys';
import {User} from '@ngmedax/ums/types';


@Injectable()
export class PdfFormService {
  /**
   * Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Event for when pdf form was saved
   * @type {EventEmitter}
   */
  private pdfFormSavedEvent: EventEmitter<User> = new EventEmitter();

  public constructor(
    private registryService: RegistryService,
    private configService: ConfigService,
    private assetApi: AssetApiService,
    private http: HttpClient
  ) {
  }

  /**
   * Loads pdf forms
   *
   * @param filter: any
   * @returns {Promise<PdfForm[]>}
   */
  public loadPdfForms(filter?: PdfForm.Filter, opts?: PdfForm.Opts): Promise<{rows: PdfForm[], total: number}> {
    const query: any = {filter: JSON.stringify(filter) || '{}', opts: JSON.stringify(opts) || '{}'};
    const basePath = `${this.getPdfFormApiUrl()}?${decodeURI(new URLSearchParams(query).toString())}`;

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers: this.getAuthHeaders()})
        .subscribe((pdfForm: any) => {
            const pdfForms = pdfForm.pdfForm || pdfForm.rows || pdfForm || [];
            const total = pdfForm.total || 0;
            resolve({rows: pdfForms, total});
          },
          error => {
            reject(error);
          });
    });
  }

  /**
   * Helper method to load pdf form by id.
   *
   * @param {string} pdfFormId
   * @returns {Promise<PdfForm>}
   */
  public loadPdfForm(pdfFormId: string): Promise<PdfForm> {
    const basePath = this.getPdfFormApiUrl(`/${pdfFormId}`);

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers: this.getAuthHeaders()})
        .subscribe(
          (pdfForm: any) => resolve(pdfForm.pdfForm || pdfForm || []),
          error => reject(error)
        );
    });
  }


  /**
   * Creates a pdf form
   *
   * @param {PdfForm} pdfFile
   * @returns {Promise<PdfForm>}
   */
  public async createPdfForm(pdfFile: {uid: string, name: string, file: File, forceSgn: boolean}): Promise<PdfForm> {
    const bucketId = this.getBucketId(pdfFile.uid);
    await this.assetApi.createBucket(bucketId);
    const upload = await this.assetApi.uploadBucketFile(bucketId, pdfFile.file);
    await upload.promise;

    const info = await this.assetApi.getBucketFile(bucketId, upload.fileName);

    const pdfForm: PdfForm = {
      uid: pdfFile.uid,
      name: pdfFile.name,
      file: upload.fileName,
      forceSgn: pdfFile.forceSgn
    };

    info.form && (pdfForm.form = <any>info.form);
    pdfForm.numFields = Array.isArray(info.form) ? info.form.length : 0;

    const basePath = this.getPdfFormApiUrl();

    const saveFormPromise: any = new Promise((resolve, reject) => {
      this.http.post(basePath, pdfForm, {headers: this.getAuthHeaders()})
        .subscribe(
          (response: any) => resolve(<PdfForm>(response.pdfForm || response)),
          error => reject(error));
    });

    return await saveFormPromise;
  }

  /**
   * Updates the pdf form
   *
   * @param {PdfForm} pdfForm
   * @returns {Promise<PdfForm>}
   */
  public updatePdfForm(pdfForm: PdfForm): Promise<PdfForm> {
    const basePath = this.getPdfFormApiUrl(`/${pdfForm.uid}`);

    return new Promise((resolve, reject) => {
      this.http.put(basePath, pdfForm, {headers: this.getAuthHeaders()})
        .subscribe(
          () => resolve(pdfForm),
          error => reject(error));
    });
  }

  /**
   * Deletes a pdf form
   *
   * @param {PdfForm} pdfForm
   * @returns {Promise<any>}
   */
  public deletePdfForm(pdfForm: PdfForm): Promise<any> {
    const basePath = this.getPdfFormApiUrl(`/${pdfForm.uid}`);

    return new Promise<void>((resolve, reject) => {
      this.http.delete(basePath, {headers: this.getAuthHeaders()})
        .subscribe(
          () => resolve(),
          error => reject(error));
    });
  }

  /**
   * Downloads pdf document by given uid and file name
   *
   * @param uid
   * @param fileName
   */
  public downloadPdfDocument(uid: string, fileName: string): void {
    const dlUrl = this.getPdfDocumentUri(uid, fileName);
    window.open(dlUrl, '_blank');
  }

  /**
   * Returns uri for pdf document
   *
   * @param uid
   * @param fileName
   */
  public getPdfDocumentUri(uid: string, fileName: string): string {
    return encodeURI(this.assetApi.getBucketFileLink(`pdf-form-${uid}`, fileName));
  }

  /**
   * Returns event emitter for when pdf form was saved
   */
  public onPdfFormSaved(): EventEmitter<any> {
    return this.pdfFormSavedEvent;
  }

  /**
   * Returns api url for pdfForm
   *
   * @param {string} suffix
   * @returns {string}
   */
  private getPdfFormApiUrl(suffix: string = null): string {
    return this.buildUrl(configKeys.PDF_FORM_URI_CONFIG_KEY, suffix);
  }

  /**
   * Returns url for config key. Adds auth information to url path when api is not deprecated
   *
   * @param {string} configKey
   * @returns {any}
   */
  private buildUrl(configKey: string, suffix = null) {
    let uri = this.configService.get(configKey);

    if (suffix) {
      uri = `${uri}${suffix}`;
    }

    return uri;
  }

  /**
   * Returns auth headers by auth token and tenant id
   */
  private getAuthHeaders(): any {
    const headers: any = {};
    const authToken = this.registryService.get(configKeys.SESSION_AUTH_TOKEN);
    const tenantId = this.registryService.get(configKeys.SESSION_TENANT_ID);

    if (authToken) {
      headers['X-Api-Token'] = authToken;
    }

    if (tenantId) {
      headers['X-Api-TenantId'] = `${tenantId}`;
    }

    return headers;
  }

  private getBucketId(uid: string) {
    return `pdf-form-${uid}`;
  }
}
