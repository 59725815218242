import {ChangeDetectorRef, Component, OnInit, Optional} from '@angular/core';
import {DATE_FORMAT_YMD_HM, Translatable, TranslationService} from '@ngmedax/translation';
import {LayoutService} from '@ngmedax/layout';

import {DateFormatService} from '@ngmedax/translation';
import {TRANSLATION_GRID_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';
import {QuestionnaireGroup} from '../../../../types';
import {QuestionnaireGroupService} from '../../services/questionnaire-group.service';
import {QuestionnaireGroupCrudComponent} from '../crud/questionnaire-group-crud.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';


// hack to inject decorator declarations. must occur before class declaration!
export interface QuestionnaireGroupGridComponent extends Translatable {}

@Component({
  selector: 'app-questionnaire-group-grid',
  templateUrl: './questionnaire-group-grid.component.html',
  styleUrls: ['./questionnaire-group-grid.component.css'],
})
@Translatable({scope: TRANSLATION_GRID_SCOPE, keys: KEYS})
export class QuestionnaireGroupGridComponent implements OnInit {
  public isSearchCollapsed = true;
  public isGridCollapsed = false;
  public gridPageNumber = 1;
  public displayPerPage = 25;
  public groups: QuestionnaireGroup[] = [];
  public filter: {name?: string} = {name: ''};
  public total = 0;
  public KEYS: any = KEYS;

  /**
   * Locale hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Timeout for filter change event
   */
  private filterChangeTimeout: any = null;

  /**
   * Timeout for show preloader event
   */
  private showPreloaderTimeout: any = null;

  /**
   * Subscriptions
   */
  private subscriptions: Subscription[] = [];

  /**
   * date format to use in grid
   * @type {string}
   */
  public get dateFormat() {
    const format = DATE_FORMAT_YMD_HM.replace(/YYYY/, 'YY');
    return this.getDateFormat(format);
  };

  /**
   * Loads questionnaire groups
   */
  public ngOnInit() {
    this.layoutService.showPreloader();
    this.loadGroups();
  }

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    @Optional() private dateFormatService: DateFormatService,
    private layoutService: LayoutService,
    private groupService: QuestionnaireGroupService,
    private ref: ChangeDetectorRef,
    private modal: NgbModal
  ) {
    this.subscriptions.push(this.groupService.onQuestionnaireGroupSaved().subscribe(() => this.loadGroups()));
  }

  /**
   * Event handler for when paging changes. Triggers change detection and loads questionnaire groups
   */
  public onPagingChange() {
    // trigger change detection
    this.ref.detectChanges();
    this.ref.markForCheck();
    this.loadGroups();
  }

  /**
   * Event handler for when filter changes. Loads questionnaire groups
   */
  public onFilterChange() {
    clearTimeout(this.filterChangeTimeout);
    this.filterChangeTimeout = setTimeout(() => this.loadGroups(), 1000);
  }

  public onCreateGroup() {
    this.openModal();
  }

  public onEditGroup(group: QuestionnaireGroup) {
    this.openModal(group.uid);
  }

  public async onDeleteGroup(group: QuestionnaireGroup) {
    const [sure] = await Promise.all([confirm(this._(KEYS.GRID.DELETE_GROUP_QUESTION) + `: ${group.name[this.locale]}`)]);

    if (sure) {
      this.groupService
        .deleteQuestionnaireGroup(group)
        .then(() => {
          this.groups.splice(this.groups.indexOf(group), 1);
          alert(this._(KEYS.GRID.SUCCESSFULLY_DELETED_GROUP));
        })
        .catch(error => {
          console.error(error);
          alert(this._(KEYS.GRID.ERROR_DELETING_GROUP));
        });
    }
  }

  /**
   * Returns groups
   *
   * @returns {Array}
   */
  public getGroups(): QuestionnaireGroup[] {
    return this.groups;
  }

  private openModal(uid?: string) {
    const modalRef = this.modal.open(QuestionnaireGroupCrudComponent, <any>{size: 'fullscreen', backdrop: 'static'});
    uid && modalRef.componentInstance.load(uid);
    // modalRef.componentInstance.options = options;
  }

  /**
   * Loads questionnaire groups from server by current filter and opts
   * @private
   */
  private loadGroups() {
    this.showPreloader();

    const filter = this.getQueryFilter();
    const opts = this.getQueryOpts();

    this.groupService.loadQuestionnaireGroups(filter, opts)
      .then((result) => {
        this.groups = result.rows;
        this.total = result.total;
        this.hidePreloader();
      })
      .catch(error => {
        this.hidePreloader();
        alert(this._(KEYS.GRID.ERROR_LOADING_GROUPS));
        console.log(error);
      });
  }

  /**
   * Returns query filter by filter object
   */
  private getQueryFilter() {
    const filter: any = {};
    const like = (value) => ({ '$regex': value, '$options' : 'i' });

    if (this.filter.name) {
      filter['name.de_DE'] = like(this.filter.name);
    }

    return filter;
  }

  /**
   * Returns query opts
   */
  private getQueryOpts() {
    return {limit: this.displayPerPage, offset: (this.gridPageNumber - 1) * this.displayPerPage}
  }

  /**
   * Shows preloader after 200ms
   */
  private showPreloader() {
    this.showPreloaderTimeout = setTimeout(() => this.layoutService.showPreloader(), 200);
  }

  /**
   * Hides preloader
   */
  private hidePreloader() {
    clearTimeout(this.showPreloaderTimeout);
    this.layoutService.hidePreloader();
  }
}
