import * as nacl from 'tweetnacl';
import {Converter} from './converter';

export class Signer {
  public constructor(private converter: Converter) {
  }

  public sign(data: Uint8Array | string | {[key: string]: any}, privateKey: Uint8Array) {
    typeof data === 'object' && (data = JSON.stringify(data));
    const message = this.converter.toUint8Array(data);
    return nacl.sign(message, privateKey);
  }

  public open(data: Uint8Array, publicKey: Uint8Array): string {
    const message = nacl.sign.open(data, publicKey);

    if (!message) {
      throw new Error('Invalid data or public key given');
    }

    return Buffer.from(<any>message).toString();
  }
}
