import {Component, Input, OnInit, Optional} from '@angular/core';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {TRANSLATION_EDITOR_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {DomHelperService} from "../../../services/dom-helper.service";


// hack to inject decorator declarations. must occur before class declaration!
export interface QuestionTypeTextComponent extends Translatable {}

@Component({
  selector: 'app-qa-question-text',
  templateUrl: './question-type-text.component.html',
  styleUrls: ['./question-type-text.component.css', '../../../shared/reusable.css']
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class QuestionTypeTextComponent implements OnInit {
  @Input() question: Questionnaire.Container;
  // @Input() mapping: Questionnaire.Container.ComponentMapping;

  /**
   * Locale for questionnaire. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Regex string
   */
  public regex = '';

  /**
   * Regex modifier
   */
  public mod = '';

  /**
   * Validation hint
   */
  public hint = '';

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    private domHelper: DomHelperService,
  ) {
  }

  public ngOnInit() {
    !this.question.options && (this.question.options = {});
    !this.question.options.validation && (this.question.options.validation = {regex: '', hint: {'de_DE': ''}});
    !this.question.options.html && (this.question.options.html = false);

    this.regex = this.question.options.validation.regex;
    this.mod = this.question.options.validation.mod;
    this.hint = this.question.options.validation.hint[this.locale] || '';
  }

  public onSaveRegex(el: HTMLElement) {
    if (!this.regex) {
      return;
    }

    try {
      const regExp = new RegExp(this.regex, this.mod);
      this.question.options.validation.regex = this.regex;
      this.question.options.validation.mod = this.mod;
      this.flash(el);
    } catch (error) {
      const msg = `${this._(KEYS.EDITOR.ERROR)}: ${(<any>error).message || ''}`
      alert(msg);
    }
  }

  public isRegexDirty() {
    return this.question.options.validation.regex !== this.regex
      || this.question.options.validation.mod !== this.mod;
  }

  public isHintDirty() {
    return this.question.options.validation.hint[this.locale] !== this.hint;
  }

  public onResetRegex(el: HTMLElement) {
    this.regex = this.question.options.validation.regex || '';
    this.mod = this.question.options.validation.mod || '';
    this.flash(el);
  }

  public onDeleteRegex() {
    this.question.options.validation.regex = '';
    this.question.options.validation.mod = '';
    this.regex = '';
    this.mod = '';
  }

  public onSaveHint(el: HTMLElement) {
    this.question.options.validation.hint[this.locale] = this.hint;
    this.flash(el);
  }

  public onResetHint(el: HTMLElement) {
    this.hint = this.question.options.validation.hint[this.locale] || '';
    this.flash(el);
  }

  public onDeleteHint() {
    this.question.options.validation.hint[this.locale] = '';
    this.hint = '';
  }

  private flash(el: HTMLElement) {
    this.domHelper.addClasses(el, ['fadeIn', 'animated']);
    setTimeout(() => this.domHelper.removeClasses(el, ['fadeIn', 'animated']), 600);
  }
}
