<div class="qa-group mb-2" *ngIf="hasGroup()">
  <div class="d-flex justify-content-between">
    <div class="btn-group qa-operator" role="group">
      <button
        type="button"
        class="btn btn-sm btn-primary"
        [ngClass]="{'btn-primary': group.operator == '&&', 'btn-secondary': group.operator == '||'}"
        (click)="group.operator = '&&'; triggerChangeDetection()">
        {{_(KEYS.EDITOR.AND)}}
      </button>
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        [ngClass]="{'btn-primary': group.operator == '||', 'btn-secondary': group.operator == '&&'}"
        (click)="group.operator = '||'; triggerChangeDetection()">
        {{_(KEYS.EDITOR.OR)}}
      </button>
    </div>

    <div class="btn-group" role="group">
      <!-- Save button is no longer needed. Will now auto save :)
      <button
        class="btn btn-sm btn-success ml-2"
        *ngIf="question"
        (click)="onSave()"
        [disabled]="canSave() ? null: true">
        <i class="fa fa-save"></i>
      </button>
      -->


      <button type="button" class="btn btn-sm btn-success ml-2" *ngIf="question && canSave()" disabled>
        <i class="fa fa-check"></i>
      </button>

      <button type="button" class="btn btn-sm btn-danger ml-2" *ngIf="question && !canSave()" disabled>
        <i class="fa fa-times"></i>
      </button>

      <button type="button" class="btn btn-sm btn-primary" (click)="onAddGroup()">
        <i class="fa fa-plus"></i>
        {{_(KEYS.EDITOR.GROUP)}}
      </button>
      <button type="button" class="btn btn-sm btn-primary" (click)="onAddExpression()">
        <i class="fa fa-plus"></i>
        {{_(KEYS.EDITOR.CONDITION)}}
      </button>
      <button class="btn btn-sm btn-danger" (click)="onClickDelete()" *ngIf="!question">
        <i class="fa fa-trash"></i>
      </button>
    </div>
  </div>

  <div class="qa-elements mt-2" *ngIf="group.elements && group.elements.length">
    <ng-container *ngFor="let element of group.elements;let pos = index;">
      <div style="position:relative" *ngIf="element.type === Ast.TYPE.EXPRESSION">
        <app-qa-conditions-editor-expression
          [expression]="element"
          (onDelete)="onElementDelete(pos)"
        >
        </app-qa-conditions-editor-expression>
      </div>

      <div style="position:relative" *ngIf="element.type === Ast.TYPE.GROUP">
        <app-qa-conditions-editor-group
          [group]="element"
          (onDelete)="onElementDelete(pos)"
        >
        </app-qa-conditions-editor-group>
      </div>
    </ng-container>
  </div>
</div>
