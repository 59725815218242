import {NgModule, Optional} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {Router} from '@angular/router';

import {LayoutService} from '@ngmedax/layout';
import {GlobalStyleComponent} from './components/global-style/global-style.component';
import {LocaleSelectComponent} from './components/translate/locale-select.component';
import {LoginComponent} from './components/login/login.component';
import {LoginService} from './service/login.service';
import {AuthUriService} from './service/auth-uri.service';
import {LoginConfigService} from './service/login.config.service';
import {AuthenticationGuard} from './guard/authentication.guard';
import {routing} from './login.routing';
import {RegistryGuard} from './guard/registry.guard';
import {Select2Module} from '@ngmedax/select2';
import {LoginEventService} from './service/login-event.service';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {TRANSLATION_LOGIN_SCOPE} from '../constants';
import {KEYS} from '../translation-keys';


declare const $: any;
export interface LoginModule extends Translatable {};

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    Select2Module,
    routing
  ],
  declarations: [
    GlobalStyleComponent,
    LocaleSelectComponent,
    LoginComponent
  ],
  exports: [LoginComponent],
  providers: [
    LoginEventService,
    LoginService,
    AuthUriService,
    LoginConfigService,
    AuthenticationGuard,
    RegistryGuard
  ]
})
@Translatable({scope: TRANSLATION_LOGIN_SCOPE, keys: KEYS})
export class LoginModule {
  private loginCheckRunning = false;
  constructor(
    @Optional() private translationService: TranslationService,
    private authenticationGuard: AuthenticationGuard,
    private registryGuard: RegistryGuard,
    private layoutService: LayoutService,
    private loginService: LoginService,
    private loginEvents: LoginEventService,
    private router: Router,
  ) {
    layoutService.addUserMenuEntry( {
      name: 'Logout',
      path: '/module/login/out',
      icon: 'fa-power-off'
    });

    layoutService.registerGuard(authenticationGuard, 100);
    layoutService.registerGuard(registryGuard, 100);

    if (!$ || !$.heartbeat) {
      console.log('heartbeat fn not available. skipping periodic session checks.');
      return;
    }

    // only register heartbeat for normal login. token login is currently only used for web allocator
    this.loginEvents.onAfterLogin().subscribe((opts) => !opts.tokenLogin && this.registerLoginCheck());
    this.loginService.isLoggedIn() && this.registerLoginCheck();
  }

  /**
   * Registers login check
   */
  public registerLoginCheck() {
    if (this.loginCheckRunning) {
      return;
    }

    const loginCheck = () => {
      this.loginService.heartbeat()
        .then((isLoggedIn) => {
          if (!isLoggedIn) {
            this.loginService.logout().catch(error => console.error(error));
            this.router.navigate(['module', 'login']).catch(error => console.error(error));
            alert(this._(KEYS.LOGIN.SESSION_EXPIRED));
          }
        })
        .catch(error => console.error(error));
    };

    const opts = {click: true, keypress: true, mousemove: true, timeout: 10000};
    setTimeout(() => {$.heartbeat(opts, () => loginCheck())}, 10000);
    this.loginCheckRunning = true;
  }
}
