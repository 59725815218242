import {Component} from '@angular/core';
import {QuestionComponent} from '../question/question.component';
import {TRANSLATION_EDITOR_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';
import {Translatable} from '@ngmedax/translation';


@Component({
  selector: 'app-qa-page-break',
  templateUrl: './page-break.component.html',
  styleUrls: [
    '../question/question.component.css',
    '../../shared/reusable.css'
  ]
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class PageBreakComponent extends QuestionComponent {
}
