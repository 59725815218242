<app-select2
  cmpCssClasses="qa-add-question mb-3"
  btnCssClasses="btn-success add-btn"
  label="{{_(KEYS.EDITOR.ADD_ELEMENT)}}"
  buttonFaIcon="fa-plus"
  (cmpElement)="cmpEl = $event"
  (submit)="onAddElement($event)"
  [options]="options"
  [hideLabel]="hideLabel"
>
  <option value="">...</option>
  <optgroup *ngFor="let group of elementGroups" label="{{group.title}}">
    <option *ngFor="let element of group.elements" value="{{element.value}}">
      {{element.title}}
    </option>
  </optgroup>
</app-select2>
