<div class="modal-header">
  <h4 class="modal-title">{{_(KEYS.CRUD.PDF_FORM)}}</h4>
  <button type="button" class="close" (click)="activeModal.dismiss()">
    <i class="fa fa-close"></i>
  </button>
</div>
<div class="modal-body qa-pdf-form-crud animated fadeIn">
  <form>
    <div class="form-group" class="group-name">
      <label for="name">{{_(KEYS.CRUD.NAME)}}</label>
      <input
        class="form-control" id="name"
        [placeholder]="_(KEYS.CRUD.ENTER_NAME)"
        name="name" [(ngModel)]="name">
    </div>

    <div class="form-group group-file">
      <label>{{_(KEYS.CRUD.FILE)}}</label><br>

      <div class="input-group">
        <label class="input-group-addon btn btn-file" *ngIf="!isEditMode">
          <i class="fa fa-upload"></i>
          <input
            type="file"
            (change)="onFileSelect($event)"
            *ngIf="!isEditMode"
            accept=".pdf"
            hidden
          />
        </label>
        <button class="input-group-addon btn btn-file btn-primary" *ngIf="isEditMode" (click)="onDownload()">
          <i class="fa fa-download"></i>
        </button>
        <input class="form-control" id="fileName" name="fileName" [(ngModel)]="fileName" readonly>
      </div>
    </div>

    <div class="qa-grid-form-header" *ngIf="isEditMode">
      {{_(KEYS.CRUD.FORM_FIELDS)}} ({{form ? form.length : 0}})
    </div>
    <div class="form-group qa-grid-form qa-form-fields" *ngIf="isEditMode">
      <table *ngIf="form" class="table">
        <ng-container *ngFor="let field of form; let pos = index;">
          <tr>
            <td class="qa-name qa-nowrap">
              <i class="fa fa-check-square" *ngIf="field.type && field.type == 'choice'"></i>
              <i class="fa fa-pencil-square" *ngIf="field.type && field.type == 'text'"></i>
              {{field.name}}
            </td>
            <td class="qa-nowrap">
              <ng-container *ngIf="field.options">
                {{stringifyOptions(field)}}
              </ng-container>
            </td>
          </tr>

        </ng-container>
      </table>
    </div>

    <div class="qa-sgn-show-opt">

      <div class="form-check">
        <input
          type="checkbox"
          [ngModelOptions]="{standalone: true}"
          [(ngModel)]="forceSgn"
        >
        <label class="form-check-label">{{_(KEYS.CRUD.SHOW_IN_SGN)}}</label>
      </div>

    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="onCancel()">
    <i class="fa fa-close"></i>
    {{_(KEYS.CRUD.CANCEL)}}
  </button>
  <button type="button" class="btn btn-primary" (click)="onSave()" [attr.disabled]="canSave()? null : true">
    <i class="fa fa-save"></i>
    {{_(KEYS.CRUD.SAVE)}}
  </button>
</div>
