import {Injectable} from '@angular/core';
import {RegistryService} from '@ngmedax/registry';
import {HttpHeaders} from '@angular/common/http';


@Injectable()
export class AuthService {

  /**
   * Injects dependencies
   * @param registryService
   */
  public constructor(
    private registryService: RegistryService
  ) {
  }

  /**
   * Returns auth headers by auth token and tenant id
   */
  public getAuthHeaders(): {'X-Api-Token': string, 'X-Api-TenantId': string} {
    const headers: any = {};
    const authToken = this.registryService.get('session.authToken');
    const tenantId = this.registryService.get('session.tenantId');

    if (authToken) {
      headers['X-Api-Token'] = authToken;
    }

    if (tenantId) {
      headers['X-Api-TenantId'] = `${tenantId}`;
    }

    return headers;
  }

  /**
   * Converts auth headers to http headers object
   *
   * @param authHeaders
   */
  public toHttpHeaders(authHeaders: any): HttpHeaders {
    return new HttpHeaders(authHeaders);
  }
}
