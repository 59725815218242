import {ChangeDetectorRef, Component, Input, Optional} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as sha from 'js-sha256';

import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {MediaCenter} from '@ngmedax/asset';

import {QuestionnaireEditorService} from '../../../services/questionnaire-editor.service';
import {QuestionnaireStateService} from '../../../services/questionnaire-state.service';
import {VariablesModalComponent} from '../variables/modal/variables-modal.component';
import {ScoringModalComponent} from '../scoring/modal/scoring-modal.component';
import {QuestionnaireVariablesService} from '../../../services/questionnaire-variables.service';
import {QuestionnaireMediaCenterService} from '../../../services/questionnaire-media-center.service';
import {TRANSLATION_EDITOR_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';
import {FROALA_LICENSE_KEY} from '../../../licences/froala';
import {preventMultilineText} from '../../../froala/prevent-multiline-text';


// hack to inject decorator declarations. must occur before class declaration!
export interface QuestionTypeChoiceComponent extends Translatable {}

@Component({
  selector: 'app-qa-question-choice',
  templateUrl: './question-type-choice.component.html',
  styleUrls: ['./question-type-choice.component.css']
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class QuestionTypeChoiceComponent {
  @Input() question: Questionnaire.Container;

  public answerEditorOptions: any = {
    key: FROALA_LICENSE_KEY,
    events : {
      'paste.beforeCleanup': preventMultilineText,
      'contentChanged': () => this.updateElement()
    },
    pluginsEnabled: ['wordPaste', 'emoticons'],
    toolbarButtons: ['emoticons'],
    toolbarVisibleWithoutSelection: true,
    placeholderText: '...',
    emoticonsUseImage: false,
    quickInsertButtons: [],
    wordPasteModal: false,
    pastePlain: true,
    quickInsertTags: [],
    charCounterCount: false,
    toolbarInline: true,
    multiLine: false,
    language: 'de',
  };

  /**
   * Locale for questionnaire. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    private questionnaireEditor: QuestionnaireEditorService,
    private questionnaireState: QuestionnaireStateService,
    private questionnaireVariables: QuestionnaireVariablesService,
    private mediaCenter: QuestionnaireMediaCenterService,
    private ref: ChangeDetectorRef,
    private modal: NgbModal
  ) {
  }

  /**
   * Opens modal for questionnaire variables for current container, identified by position
   *
   * @param {number} position
   */
  public onVariablesModal(position: number) {
    const container = this.question.elements[position];
    const modalRef = this.modal.open(VariablesModalComponent, {size: 'lg'});
    modalRef.componentInstance.container = container;
    modalRef.componentInstance.disabledScopes = {upload: true};
  }

  /**
   * Opens modal for scoring value
   *
   * @param {number} position
   */
  public onScoringModal(position: number) {
    const container = this.question.elements[position];
    const modalRef = this.modal.open(ScoringModalComponent, {size: 'lg'});
    modalRef.componentInstance.container = container;
  }

  /**
   * Adds an answer to this question
   */
  public onAddAnswer() {
    // add elements if we don't have them right now
    // usually this should not happen, but just in case...
    if (!this.question.elements) {
      this.question.elements = [];
    }

    // generate id for new answer element
    const id = this.questionnaireEditor.generateUUID();

    // build new answer
    const answer = {
      id,
      pathHash: sha.sha256(`${this.question.id}.${id}`),
      title: {
        'de_DE': ''
      }
    };

    // push new answer element to question
    this.question.elements.push(answer);

    // update element (updates path hashes)
    this.updateElement();
  }

  /**
   * Deletes an answer from this question
   *
   * @param {number} position
   */
  public onDeleteAnswer(position: number) {
    this.questionnaireEditor.confirmDelete(() => {
      const pathHash = this.question.elements[position].pathHash;
      this.question.elements.splice(position, 1);

      this.questionnaireState.deletePathHash(pathHash);
      this.questionnaireVariables.deleteVariableMappings(pathHash);
      this.updateElement();
      this.triggerChangeDetection();
    });
  }

  /**
   * Triggers the "question answer position change" event in order to push an answer
   * to the given position
   *
   * @param {number} currentPosition
   * @param {number} newPosition
   */
  public onPositionChange(currentPosition: number, newPosition: number) {
    // get answers
    const answers = this.question.elements;

    // early bailout when changing positions does not make sense
    if (currentPosition >= (answers.length) || newPosition < 0) {
      return;
    }

    // reorder answers by current and new position
    answers.splice(newPosition, 0, answers.splice(currentPosition, 1)[0]);
  }

  /**
   * Opens media center modal
   *
   * @param mediaType
   * @param answerOption
   */
  public onOpenMediaCenterModal(
    mediaType: 'videos' | 'images' | 'audio',
    answerOption: Questionnaire.Container = null
  ) {
    !answerOption.assets && (answerOption.assets = {});
    !answerOption.assets[mediaType] && (answerOption.assets[mediaType] = []);

    const containerAsset = answerOption.assets[mediaType] && answerOption.assets[mediaType].length ?
      answerOption.assets[mediaType][0] : null;

    const locale = this.locale;
    const questionnaireId =  this.questionnaireState.getQuestionnaire().id;
    const buildAsset = this.mediaCenter.buildContainerAsset;

    const callback = (mediaCenterAsset: MediaCenter.Asset) => {
      const asset = buildAsset({mediaCenterAsset, containerAsset, locale});
      answerOption.assets[mediaType] = [asset];
      this.triggerChangeDetection();
    };

    this.mediaCenter.openMediaCenter({
      questionnaireId, mediaType, locale, containerAsset, callback
    });
  }

  /**
   * Deletes media from given answer option by given media type
   *
   * @param mediaType
   * @param answerOption
   */
  public onDeleteAnswerMedia(
    mediaType: 'videos' | 'images' | 'audio',
    answerOption: Questionnaire.Container = null
  ) {
    answerOption.assets && answerOption.assets[mediaType] && (answerOption.assets[mediaType] = []);
  }

  /**
   * Toggles the mark expression for given answer option
   *
   * @param {Questionnaire.Container} answer
   */
  public onToggleMark(answer: Questionnaire.Container) {
    !answer.conditions && (answer.conditions = {mark: ''});

    if (!answer.conditions.mark) {
      answer.conditions.mark = `(container.x${answer.pathHash} == true)`;
      return;
    }

    delete answer.conditions.mark;
  }

  /**
   * Updates element in state service
   */
  public updateElement() {
    // update element (updates path hashes, triggers events)
    this.questionnaireState.updateElement(this.question, -1);
  }

  /**
   * Triggers change detection
   */
  public triggerChangeDetection() {
    this.ref.detectChanges();
    this.ref.markForCheck();
  }
}
