<form [formGroup]="form" class="mt-1">
  <ng-template #tipContent let-tooltipMsg="tooltipMsg">{{tooltipMsg}}</ng-template>
  <div class="btn-toolbar justify-content-between" *ngFor="let formulaVarName of getFormulaVariableNames(false)">
    <div class="input-group full-width mt-1 mb-1" [ngClass]="{'dirty-entry': form.get(formulaVarName).dirty}" padding>

      <div class="input-group">
        <span class="input-group-addon">Name</span>
        <input
          [value]="formulaVarName"
          id="{{formulaVarName}}"
          name="{{formulaVarName}}"
          [formControlName]='formulaVarName'
          [ngbTooltip]="tipContent" triggers="manual" #tt="ngbTooltip"
          (change)="onCheckForTooltip(tt, form.get(formulaVarName))"
          (keyup)="onCheckForTooltip(tt, form.get(formulaVarName))"
          class="form-control"
          #formulaInputEl
        >
      </div>

      <div class="input-group ml-2 full-width">
        <span class="input-group-addon">{{_(KEYS.EDITOR.FORMULA)}}</span>
        <input
          [formControlName]='formulaVarName + "Expression"'
          [ngbTooltip]="tipContent" triggers="manual" #ttd="ngbTooltip"
          (change)="onCheckForTooltip(ttd, form.get(formulaVarName + 'Expression'))"
          (keyup)="onCheckForTooltip(ttd, form.get(formulaVarName + 'Expression'))"
          class="form-control expression-value"
        >
      </div>

      <button
        class="btn btn-success ml-2"
        [disabled]="(form.get(formulaVarName).errors || form.get(formulaVarName + 'Expression').errors || !form.dirty)  ? true : null"
        (click)="onEdit(formulaInputEl.value, formulaVarName)"
      >
        <i class="fa fa-save"></i>
      </button>
      <button class="btn btn-primary ml-1" (click)="onUndo(formulaVarName)">
        <i class="fa fa-undo"></i>
      </button>
      <button class="btn btn-danger ml-1 mr-3" (click)="onRemove(formulaVarName)">
        <i class="fa fa-trash"></i>
      </button>
    </div>
  </div>
</form>

<div class="input-group mt-2 mb-2">
  <span class="input-group-addon">{{_(KEYS.EDITOR.ADD_FORMULA_VARIABLE)}}</span>
  <button
    class="btn input-group-addon add-btn btn-success add-btn"
    (click)="onAdd()">
    <i class="fa fa-plus"></i>
  </button>
</div>
