/**
 * Mapping for translation keys, so we don't have to use long text in source code. Please keep in mind, that the
 * german default translations are the keys, so don't change them, if you want to change the text.
 * Instead, change the de_DE translations!
 */
export const KEYS = {
  LOGIN: {
    LOGIN: 'Login',
    USERNAME: 'Benutzername (E-Mail)',
    PASSWORD: 'Passwort',
    ENTER_VALID_USERNAME: 'Bitte geben Sie einen gültigen Benutzername ein.',
    ENTER_VALID_PASSWORD: 'Bitte geben Sie ein gültiges Passwort ein.',
    STAY_LOGGED_IN: 'Auf diesem Gerät angemeldet bleiben.',
    CONFIG_ERROR: 'Konfigurationsfehler',
    ERROR_UMS_API_URI_UNCONFIGURED: 'Login ist nicht möglich, da keine UMS Login Api Url konfiguriert wurde.',
    INVALID_CREDENTIALS: 'Die eingegebenen Zugangsdaten sind nicht korrekt',
    INSUFFICIENT_PERMISSIONS: 'Sie haben nicht die erforderlichen Rechte, um sich an diesem Mandanten anzumelden.',
    ERROR_UMS_API_UNREACHABLE: 'Fehler: Es konnte keine Verbindung zum UMS Server hergestellt werden.',
    ERROR_UMS_API_ANSWER: 'UMS Server Antwort',
    INITIAL_PW_CHANGE_MSG: 'Sie verwenden derzeit noch das Standardpasswort. Bitte Passwort ändern.',
    FORGOT_PASSWORD: 'Passwort zurücksetzen',
    CAPTCHA: 'Captcha',
    FORGOT_PASSWORD_SUBMIT: 'Passwort zurücksetzen',
    FORGOT_PASSWORD_HINT: 'Passwort vergessen?',
    BACK_TO_LOGIN: 'Zurück zum Login',
    ENTER_VALID_CAPTCHA: 'Bitte geben Sie einen gültigen Captcha Code ein.',
    RESET_PASSWORD_MAIL_SUCCESS: 'Eine E-Mail zum Zurücksetzen Ihres Passworts wurde an Ihre Adresse gesendet.',
    RESET_PASSWORD_DEFAULT_USER_NO_MAIL_ERROR: 'Das Passwort des Standard Admin-Benutzers kann nicht zurückgesetzt werden.',
    ERROR_ACCEPTING_TOS: 'Beim Akzeptieren der Nutzungsbedingungen ist ein Fehler aufgetreten.',
    TOS_HEADLINE: 'Nutzungsbedingungen von myMedax',
    SESSION_EXPIRED: 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
    ACCEPT: 'Akzeptieren',
    DECLINE: 'Ablehnen'
  }
};
