import {Component, Input, OnInit, Optional} from '@angular/core';
import {PermissionService} from '@ngmedax/permission';
import {LayoutService} from '@ngmedax/layout';
import {Translatable, TranslationService} from '@ngmedax/translation';

import {UmsService} from '../../services/ums.service';
import {UserService} from '../../services/user.service';
import {FilterGroup, User, UserFilterGroup} from '../../../../types';
import {permission} from '../../ums.permission';
import {TRANSLATION_CRUD_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';

// hack to inject decorator declarations. must occur before class declaration!
export interface UserFilterGroupComponent extends Translatable {}

/**
 * Component to assign/unassign filter groups to/from a user
 */
@Component({
  selector: 'app-user-filter-group',
  templateUrl: './user-filter-group.component.html',
  styleUrls: ['./user-filter-group.component.css']
})
@Translatable({scope: TRANSLATION_CRUD_SCOPE, keys: KEYS})
export class UserFilterGroupComponent implements OnInit {
  /**
   * User
   * @type {User}
   */
  @Input() public user: User;

  /**
   * filter groups
   * @type {FilterGroup[]}
   */
  public filterGroups: FilterGroup[] = [];

  /**
   * Selected user filter groups
   * @type {{}}
   */
  public selectedUserFilterGroups: any = {};

  /**
   * Should we only allow readonly access to user filter groups?
   * @type {boolean}
   */
  public isReadonly = true;

  /**
   * Default locale
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Injects dependencies
   */
  public constructor(
    private umsService: UmsService,
    private userService: UserService,
    private layoutService: LayoutService,
    @Optional() private permissionService: PermissionService,
    @Optional() private translationService: TranslationService
  ) {
  }

  /**
   * Loads filter groups, user assignments to filter groups and builds selected filter groups object.
   * Also sets readonly flag by user permissions. Users are only allowed to change filter groups, if
   * they have the permission "UMS_USER_UPDATE"!
   */
  public ngOnInit() {
    // early bailout if no user is set
    if (!this.user) {
      return;
    }

    const promises: Promise<any>[] = [
      this.umsService.loadFilterGroups(),
      this.userService.loadUserFilterGroups(this.user.userId)
    ];

    // allow changing user filter group assignments, if user is allowed to do so
    if (this.permissionService) {
      this.isReadonly = !this.permissionService.isAllowed(permission.UMS_USER_UPDATE);
    }

    // load filter groups and user assignments to filter groups
    Promise
      .all(promises)
      .then((result) => {
        const filterGroups: FilterGroup[] = result[0];
        const userFilterGroups: UserFilterGroup[] = result[1];

        // init "selected filter groups" object
        for (const filterGroup of filterGroups) {
          this.selectedUserFilterGroups[filterGroup.id] = false;
        }

        if (userFilterGroups && Array.isArray(userFilterGroups)) {
          // update "selected filter groups" object by assigned filter groups
          for (const userFilterGroup of userFilterGroups) {
            this.selectedUserFilterGroups[userFilterGroup.filterGroupId] = true;
          }
        }

        this.filterGroups = filterGroups;
      })
      .catch(error => {
        alert(this._(KEYS.CRUD.ERROR_LOADING_FILTER_GROUPS));
        console.log(error);
      });
  }

  /**
   * Action for when a user filter group is changed
   *
   * @param {FilterGroup} filterGroup
   */
  public onUpdateUserFilterGroup(filterGroup: FilterGroup) {
    // early bailout if user is not allowed to update filter group assignments
    if (this.isReadonly) {
      return;
    }

    const add = this.selectedUserFilterGroups[filterGroup.id];
    const method = add ? 'assignFilterGroups' : 'unassignFilterGroups';

    this.layoutService.showPreloader();

    setTimeout(() => {
      this.userService[method](this.user, [filterGroup])
        .then(() => this.layoutService.hidePreloader())
        .catch(error => {
          this.layoutService.hidePreloader();
          alert(this._(KEYS.CRUD.ERROR_SETTING_FILTER_GROUP));
          console.log(error);
        });
    }, 50);
  }
}
