<ng-template #content let-c="close">
  <div class="modal-header">
    <h4 class="modal-title">{{_(KEYS.EDITOR.QUESTIONNAIRE_PREVIEW)}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="c('')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <iframe [src]="previewUrl" *ngIf="previewUrl"></iframe>
  </div>
</ng-template>

<button class="btn btn-primary" (click)="open(content)" ngbTooltip="{{_(KEYS.EDITOR.QUESTIONNAIRE_PREVIEW)}}">
  <i class="fa fa-eye"></i>
</button>
