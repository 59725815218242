import {Component, Input, OnInit, Optional} from '@angular/core';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {ValueService} from '@ngmedax/value';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {TRANSLATION_EDITOR_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';
import * as sha from 'js-sha256';
import {QuestionnaireVariablesService} from '../../services/questionnaire-variables.service';


// hack to inject decorator declarations. must occur before class declaration!
export interface ExternVariablesComponent extends Translatable {}

@Component({
  selector: 'app-qa-extern-variables',
  templateUrl: './extern-variables.component.html',
  styleUrls: ['./extern-variables.component.css']
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class ExternVariablesComponent implements OnInit {
  @Input() questionnaire: Questionnaire;
  public form: FormGroup;

  private varPath  = ['meta', 'options', 'variables'];
  private varNamePattern =  /^[a-zA-Z0-9_]{3,50}$/;
  private errors = {
    'pattern': this._(KEYS.EDITOR.INVALID_VAR_NAME_TEXT),
    'invalidNumber': this._(KEYS.EDITOR.INVALID_NUMBER_TEXT)
  }

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    private questionnaireVariables: QuestionnaireVariablesService,
    private formBuilder: FormBuilder,
    private value: ValueService
  ) {
  }

  /**
   * Initializes extern variables and updates form
   */
  public ngOnInit() {
    this.initExternVariables();
    this.updateForm();
  }

  /**
   * Adds new extern variable
   */
  public onAdd() {
    const varPrefix = 'extern';
    const externVariables = this.questionnaireVariables.getVariablesForScope('extern');

    for (let i = 1; i < 1000; i++) {
      const varName = `${varPrefix}${i}`;
      if (!externVariables[varName]) {
        externVariables[varName] = {format: 'text'};
        break;
      }
    }

    this.updateForm();
  }

  /**
   * Event handler for when a extern var is changed
   *
   * @param {string} varName
   * @param {string} previousVarName
   */
  public onEdit(varName: string, previousVarName: string) {
    const format = this.form.get(previousVarName + 'Format')?.value;
    const defaultValue = this.form.get(previousVarName + 'Default')?.value;
    this.renameVariable('extern', varName, format, defaultValue, previousVarName);
    this.updateForm();
  }

  /**
   * Event handler for when extern var is reset to previous value
   *
   * @param {string} previousVarName
   */
  public onUndo(previousVarName: string) {
    this.form.get(previousVarName).reset(previousVarName);
  }

  /**
   * Event handler for when extern var is removed
   *
   * @param {string} varName
   */
  public onRemove(varName: string) {
    const externVariables = this.questionnaireVariables.getVariablesForScope('extern');
    externVariables[varName] && delete(externVariables[varName]);
    this.updateForm();
  }

  public onResetDefault(varName: string) {
    const format = this.form.get(varName + 'Format')?.value;
    this.form.get(varName + 'Default')?.reset(format === 'numeric' ? 0 : '');
  }

  /**
   * Shows tooltip when current form element value is invalid
   *
   * @param {NgbTooltip} tooltip
   * @param {AbstractControl} formEl
   */
  public onCheckForTooltip(tooltip: NgbTooltip, formEl: AbstractControl) {
    if (formEl.dirty && formEl.errors) {
      formEl.errors.pattern && tooltip.open({tooltipMsg: this.errors.pattern});
      formEl.errors.invalidNumber && tooltip.open({tooltipMsg: this.errors.invalidNumber});
      return;
    }

    tooltip && tooltip.isOpen() && tooltip.close();
  }

  /**
   * Returns array of extern variables by questionnaire or by form
   *
   * @param {boolean} byForm
   * @returns string[]
   */
  public getExternVariableNames(byForm = false): string[] {
    const formControls = this.value.get(this.form, ['controls']) || {};
    return Object.keys(byForm ? formControls : this.questionnaireVariables.getVariablesForScope('extern'));
  }

  /**
   * Returns variable data
   *
   * @param {string} varName
   * @returns {{type: string}}
   */
  public getVariableData(varName: string): {format: string, default: string | number} {
    return <{format: string, default: string | number}>
      this.questionnaireVariables.getVariablesForScope('extern')[varName] || {format: 'text', default: ''};
  }

  /**
   * Returns html input type by variable format
   * @param {string} varName
   * @returns string
   */
  public getInputType(varName): string {
    const format = this.form.get(varName + 'Format')?.value;
    return (format === 'numeric') ? 'number': format;
  }

  /**
   * Renames variable
   *
   * @param {string} varName
   * @param {string} previousVarName
   */
  public renameVariable(scope: string, varName: string, format: string, defaultValue: string | number, previousVarName: string) {
    const variables = this.questionnaireVariables.getVariablesForScope(scope);

    if (!variables[previousVarName]) {
      return;
    }

    variables[varName] = this.value.clone(variables[previousVarName]);
    variables[varName].format = format;
    format === 'numeric' && (defaultValue = parseInt(<any>defaultValue));
    defaultValue !== '' && (variables[varName].default = defaultValue);
    previousVarName !== varName && delete(variables[previousVarName]);
  }

  /**
   * Updates extern variables form by questionnaire extern variables
   */
  private updateForm() {
    const varNames = this.getExternVariableNames();
    const dynFormGroup = {};

    for (const varName of varNames) {
      dynFormGroup[varName] = this.formBuilder.control(varName, [Validators.pattern(this.varNamePattern)]);

      const format = this.getVariableData(varName).format;
      const defaultValue = this.getVariableData(varName).default || (format === 'numeric' ? 0 : '');
      dynFormGroup[varName + 'Format'] = this.formBuilder.control(format);
      dynFormGroup[varName + 'Default'] = this.formBuilder.control(defaultValue, (control: AbstractControl) => {
        const format = dynFormGroup[varName + 'Format'].value;
        if (format === 'numeric' && isNaN(control.value)) {
          return {invalidNumber: true};
        }

        return null;
      });
    }

    this.form = this.formBuilder.group(dynFormGroup);
  }

  /**
   * Initializes questionnaire extern variables in questionnaire
   */
  private initExternVariables() {
    let current = this.questionnaire;
    [...this.varPath, 'extern'].forEach((path) => {
      !current[path] && (current[path] = {});
      current = current[path];
    });
  }
}
