import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LayoutService} from '@ngmedax/layout';
import {Translatable} from '@ngmedax/translation';

import {NAVIGATION} from './pdf-form.navigation';
import {PdfFormService} from './services/pdf-form.service';
import {PdfFormCrudService} from './services/pdf-form-crud.service';
import {PdfFormGridComponent} from './components/grid/pdf-form-grid.component';
import {PdfFormCrudComponent} from './components/crud/pdf-form-crud.component';
import {PdfFormSignatureComponent} from './components/signature/pdf-form-signature.component';
import {TRANSLATION_DEFAULT_SCOPE} from '../../constants';
import {KEYS} from '../../translation-keys';
import {TruncatePipe} from './pipes/truncate.pipe';
import {DatexPipe} from './pipes/datex.pipe';
import {routing} from './pdf-form.routing';
import {Select2Module} from '@ngmedax/select2';
import {DragulaModule} from 'ng2-dragula';
import {ImageCropperModule} from '@ngmedax/image-cropper';

export interface PdfFormModule extends Translatable {};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    Select2Module,
    DragulaModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModule,
    ImageCropperModule,
    routing
  ],
  declarations: [
    PdfFormGridComponent,
    PdfFormCrudComponent,
    PdfFormSignatureComponent,
    TruncatePipe,
    DatexPipe
  ],
  exports: [
    PdfFormGridComponent,
    PdfFormCrudComponent,
    PdfFormSignatureComponent
  ],
  entryComponents: [
    PdfFormCrudComponent,
    PdfFormSignatureComponent
  ],
  providers: [
    PdfFormCrudService,
    PdfFormService
  ]
})

@Translatable({scope: TRANSLATION_DEFAULT_SCOPE, keys: KEYS})
export class PdfFormModule {
  constructor(layoutService: LayoutService) {
    layoutService.addMenuEntry(NAVIGATION);
  }
}
