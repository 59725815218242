import {Component, OnInit, Optional, ViewChild} from '@angular/core';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LayoutService} from '@ngmedax/layout';
import {PdfFormService} from '../../services/pdf-form.service';
import {TRANSLATION_CRUD_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';
import * as pdfJs from 'pdfjs-dist/build/pdf';
import * as pdfJsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import {PdfForm} from '../../../../types';
pdfJs.GlobalWorkerOptions.workerSrc = pdfJsWorker;


// hack to inject decorator declarations. must occur before class declaration!
export interface PdfFormSignatureComponent extends Translatable {}

@Component({
  selector: 'app-pdf-form-signature-crud',
  templateUrl: './pdf-form-signature.component.html',
  styleUrls: ['./pdf-form-signature.component.css'],
})
@Translatable({scope: TRANSLATION_CRUD_SCOPE, keys: KEYS})
export class PdfFormSignatureComponent implements OnInit {
  @ViewChild('imageCropper') imageCropper: any;
  public pageImg: string;
  public pageScale: number = 0.78;
  public pageNumber: number = 0;
  public pageCount: number = 0;
  public pdfForm: PdfForm;
  public document: any;
  public editMode: boolean = false;
  public signatureTitle: string;
  public locale: string = 'de_DE';
  private signaturePos: number = -1;
  private defaultCropperPosition = {x1: 0, x2: 182, y1: 0, y2: 34};


  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    public pdfFormService: PdfFormService,
    public activeModal: NgbActiveModal,
    public layoutService: LayoutService
  ) {
  }

  public ngOnInit() {
  }

  public onCancel() {
    this.activeModal.dismiss();
  }

  public async onSave() {
    this.layoutService.showPreloader();

    try {
      await this.pdfFormService.updatePdfForm(this.pdfForm);
      alert(this._(KEYS.CRUD.SUCCESSFULLY_SAVED_PDF_FORM));
    } catch (error) {
      alert(this._(KEYS.CRUD.ERROR_SAVING_FORM));
    }

    this.layoutService.hidePreloader();
  }

  public onDone() {
    this.imageCropper && this.imageCropper.crop();
  }

  public onAdd() {
    this.isNewSignature(true);
    this.editMode = true;
  }

  public onEdit(pos: number) {
    this.signaturePos = pos;
    this.editMode = true;
  }

  public onDelete () {
    !this.isNewSignature() && this.pdfForm.signatures.splice(this.signaturePos, 1);
    this.signatureTitle = '';
    this.editMode = false;
  }

  public onImageLoaded() {
    if (!this.imageCropper) {
      return;
    }

    this.imageCropper.cropper = this.isNewSignature() ? this.defaultCropperPosition : this.pdfForm.signatures[this.signaturePos];
    const hasTitle = !this.isNewSignature() && this.pdfForm.signatures[this.signaturePos].title && this.pdfForm.signatures[this.signaturePos].title[this.locale];
    !this.isNewSignature() && hasTitle && (this.signatureTitle = this.pdfForm.signatures[this.signaturePos].title[this.locale]);
  }

  public onImageCropped(event: any) {
    const signature: PdfForm.Signature = {
      mandatory :true,
      scale: this.pageScale,
      page: this.pageNumber,
      x1: event.cropperPosition.x1,
      x2: event.cropperPosition.x2,
      y1: event.cropperPosition.y1,
      y2: event.cropperPosition.y2,
      title: {[this.locale]: this.signatureTitle}
    };

    this.isNewSignature() ?
      this.pdfForm.signatures.push(signature) :
      this.pdfForm.signatures[this.signaturePos] = signature;

    this.signatureTitle = '';
    this.editMode = false;
  }

  public async load(uid: string) {
    this.pdfForm = await this.pdfFormService.loadPdfForm(uid);
    !this.pdfForm.signatures && (this.pdfForm.signatures = []);

    const pdfUri = await this.pdfFormService.getPdfDocumentUri(this.pdfForm.uid, this.pdfForm.file);
    this.document = await pdfJs.getDocument(pdfUri).promise;
    this.pageCount = this.document.numPages;
    await this.loadPage(1);
  }

  public async loadPage(pageNumber: number) {
    this.editMode = false;
    const page = await this.document.getPage(pageNumber);
    const viewport = page.getViewport({scale: this.pageScale});
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    await page.render({canvasContext: context, viewport: viewport}).promise;
    this.pageImg = canvas.toDataURL('image/jpeg');
    this.pageNumber = pageNumber;
  }

  public isNewSignature(isNewSignature?: boolean): boolean {
    isNewSignature === true && (this.signaturePos = -1);
    return this.signaturePos === -1;
  }

  public getBoxStyle(signature: PdfForm.Signature): any {
    return {
      position: 'absolute',
      top: `${signature.y1}px`,
      left: `${signature.x1}px`,
      width: `${Math.abs(signature.x2 - signature.x1)}px`,
      height: `${Math.abs(signature.y1 - signature.y2)}px`
    };
  }
}
