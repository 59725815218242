/**
 * Mapping for translation keys, so we don't have to use long text in source code. Please keep in mind, that the
 * german default translations are the keys, so don't change them, if you wan't to change the text.
 * Instead change the de_DE translations!
 */
export const KEYS = {
  HOME: {
    SALUTATION_MR: 'Herr',
    SALUTATION_MS: 'Frau',
    MYMEDAX_ADMIN: 'myMedax Verwaltung',
    USER_GUIDE: 'Anleitung ([[lang]])',
    WELCOME_USERNAME: 'Herzlich willkommen [[userName]]',
    NEW_PATIENT: 'Patient anlegen',
    NORMAL: 'Normal',
    ANONYMOUS: 'Anonym',
    PSEUDONYMOUS: 'Pseudonym',
    QUESTIONNAIRE_EDITOR: 'Fragebogen Editor',
    FILLED_QUESTIONNAIRES: 'Ausgefüllte Fragebögen',
    FILLED_QUESTIONNAIRE_TEXT: 'Die bereits ausgefüllten Fragebögen findest du hier',
    NEW_QUESTIONNAIRE_TEXT: 'Um einen neuen Fragebogen anzulegen, klicke hier',
    NEW_PATIENT_TEXT: 'Neue Patienten kannst du hier anlegen',
    ALLOCATOR_APP_DOWNLOAD_TEXT: 'Die aktuelle Zuweiser App für die Anbindung an Praxisverwaltungssysteme per GDT-Import kannst du hier downloaden',
    USER_GUIDE_DOWNLOAD_TEXT: 'Die myMedax Benutzer-Anleitung findest du unter',
    INIT_DEVICE_TEXT: 'Möchtest du ein neues Gerät mit deinem myMedax verbinden? Lade die App, verbinde dich mit deinem WLAN und scanne dann den QR-Code',
    SURVEY_LIST_TEXT: 'Um eine Übersicht der Befragungen zu erhalten, klicke hier',
    SURVEY_LIST: 'Befragungen',
  }
};

