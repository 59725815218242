export const NAVIGATION = {
  name: 'Fragebogen',
  icon: 'fa-id-card-o',
  path: '/module/questionnaire/',
  children: [{
    name: 'PDF Formulare',
    path: '/module/pdf-form/grid',
    icon: 'fa-file-pdf-o'
  }]
};
