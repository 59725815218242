<div class="version-crud">
  <h2 class="mb-4">{{_(KEYS.VERSION.VERSION)}}</h2>

  <table class="table" *ngIf="version">
    <tbody>
    <tr>
      <th scope="col"><i class="fa fa-info-circle"></i></th>
      <th scope="col">{{_(KEYS.VERSION.SOFTWARE)}}</th>
      <td>&nbsp;</td>
      <td>myMedax 4</td>
    </tr>
    <tr>
      <th scope="col"><i class="fa fa-code"></i></th>
      <th scope="col">{{_(KEYS.VERSION.MAIN_VERSION)}}</th>
      <td>&nbsp;</td>
      <td>{{version}}</td>
    </tr>
    </tbody>
  </table>

  <ng-container *ngIf="versionInfo && versionInfo.mymedax">
    <br><h4 class="mb-4"><i class="fa fa-sitemap"></i> &nbsp;&nbsp; {{_(KEYS.VERSION.SERVICES)}}</h4>

    <table class="table">
      <tbody>
      <tr *ngFor="let service of services('mymedax');">
        <th scope="col">{{service}}</th>
        <td>&nbsp;</td>
        <td>{{versionInfo.mymedax[service] || ''}}</td>
      </tr>
      </tbody>
    </table>
  </ng-container>

  <ng-container *ngIf="versionInfo && versionInfo.dependencies">
    <br><h4 class="mb-4"><i class="fa fa-code-fork"></i> &nbsp;&nbsp; {{_(KEYS.VERSION.DEPENDENCIES)}}</h4>

    <table class="table">
      <tbody>
      <tr *ngFor="let service of services('dependencies');">
        <th scope="col">{{service}}</th>
        <td>&nbsp;</td>
        <td>{{versionInfo.dependencies[service]}}</td>
      </tr>
      </tbody>
    </table>
  </ng-container>

</div>
