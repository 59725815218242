import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {QuestionnaireSelectComponent} from './components/questionnaire-select.component';
import {QuestionnaireSelectService} from './services/questionnaire-select.service';
import {Select2Module} from '@ngmedax/select2';
import {DragulaModule} from 'ng2-dragula';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    Select2Module,
    DragulaModule.forRoot()
  ],
  declarations: [
    QuestionnaireSelectComponent
  ],
  exports: [
    QuestionnaireSelectComponent
  ],
  providers: [
    QuestionnaireSelectService
  ]
})
export class QuestionnaireSelectModule {
}
