import {RouterModule, Routes} from '@angular/router';
import {permission} from './license-manager.permission';
import {LicenseManagerGridComponent} from './components/license-manager-grid.component';

// TODO: add permissions
const APP_ROUTES: Routes = [
  {
    path: 'module/license-manager/grid',
    component: LicenseManagerGridComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: true,
      needsPermissions: []
    }
  }
];

export const routing = RouterModule.forRoot(APP_ROUTES);
