import {Injectable, EventEmitter} from '@angular/core';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import * as sha from 'js-sha256';

import {QuestionnaireVariablesService} from './questionnaire-variables.service';
import {ElementPositionChange, ElementChange} from '../../types';
import {SCHEMA_VERSION} from '../../../schema-version';


@Injectable()
export class QuestionnaireStateService {
  private questionnaire: Questionnaire;
  private pathHashMap: any;
  public onAddElement: EventEmitter<ElementChange> = new EventEmitter();
  public onUpdateElement: EventEmitter<ElementChange> = new EventEmitter();
  public onDeleteElement: EventEmitter<ElementChange> = new EventEmitter();
  public onElementPositionChange: EventEmitter<ElementPositionChange> = new EventEmitter();
  public onSetQuestionnaire: EventEmitter<Questionnaire> = new EventEmitter();

  /**
   * Injects dependencies
   */
  public constructor(
    private questionnaireVariables: QuestionnaireVariablesService
  ) {
  }

  /**
   * Sets the questionnaire
   *
   * @param {Questionnaire} questionnaire
   */
  public setQuestionnaire(questionnaire: Questionnaire) {
    this.questionnaire = questionnaire;

    if (this.questionnaire.questions) {
      for (const question of this.questionnaire.questions) {
        this.addPathHashes(question);
      }
    }

    this.questionnaireVariables.setQuestionnaire(this.questionnaire);
    this.onSetQuestionnaire.emit(questionnaire);
  }

  /**
   * Returns the questionnaire
   *
   * @returns {Questionnaire}
   */
  public getQuestionnaire() {
    // always update schema version
    this.questionnaire.softwareVersion = SCHEMA_VERSION;
    return this.questionnaire;
  }

  /**
   * Sets the questionnaire path hash map
   *
   * @param {any} pathHashes
   */
  public setPathHashMap(pathHashMap: any) {
    this.pathHashMap = pathHashMap;
  }

  /**
   * Returns the questionnaire path hash map
   *
   * @returns {any}
   */
  public getPathHashMap() {
    return this.pathHashMap;
  }

  /**
   * Deletes a path hash from the hash map
   *
   * @param {string} pathHash
   */
  public deletePathHash(pathHash: string) {
    if (typeof this.pathHashMap[pathHash] !== 'undefined') {
      delete(this.pathHashMap[pathHash]);
    }
  }

  /**
   * Adds an element to the questionnaire
   *
   * @param {Questionnaire.Container} element
   * @param {number} position
   */
  public addElement(element: Questionnaire.Container, position: number = null) {
    // if position found
    if (position !== null) {
      // reorder position. adds the element somewhere between
      this.questionnaire.questions.splice((position + 1) , 0, element);
    } else {
      // add new element to questionnaire
      this.questionnaire.questions.push(element);
    }

    // update path hashes in path hash map
    this.addPathHashes(element);

    // emit "add" event
    this.onAddElement.emit({
      position,
      element,
    });
  }

  /**
   * Returns element by its path hash
   *
   * @param pathHash
   */
  public getElement(pathHash: string) {
    return this.pathHashMap[pathHash];
  }

  /**
   * Updates an element of the questionnaire. This will only trigger the "element change" event.
   *
   * @param {Questionnaire.Container} element
   * @param {number} position
   */
  public updateElement(element: Questionnaire.Container, position: number) {
    // update path hashes in path hash map
    this.addPathHashes(element);

    // emit "change" event
    this.onUpdateElement.emit({
      element,
      position
    });
  }

  /**
   * Deletes an element from the questionnaire
   *
   * @param {number} position
   */
  public deleteElement(position: number) {
    // early bailout if no element on position
    if (typeof this.questionnaire.questions[position] === 'undefined') {
      return;
    }

    // get element by position
    const element = this.questionnaire.questions[position];

    // delete all variable mappings of container element
    this.questionnaireVariables.deleteVariableMappings(element);

    // remove path hashes of this element from path hash map
    this.removePathHashes(element);

    // remove element from questionnaire
    this.questionnaire.questions.splice(position, 1);

    // emit "delete" event
    this.onDeleteElement.emit({
      position,
      element
    });
  }

  /**
   * Changes the position of an element from given current position to new position
   *
   * @param {number} currentPosition
   * @param {number} newPosition
   */
  public changeElementPosition(currentPosition: number, newPosition: number) {
    // get questionnaire questions
    const questions = this.questionnaire.questions;

    // early bailout when changing positions does not make sense
    if (currentPosition > (questions.length) || newPosition < 0) {
      return;
    }

    if (currentPosition !== newPosition) {
      // reorder element by current and new position
      questions.splice(newPosition, 0, questions.splice(currentPosition, 1)[0]);
    }

    // emit "position change" event
    this.onElementPositionChange.emit({
      currentPosition,
      newPosition
    });
  }

  /**
   * Returns true if given position of an element is the last one
   *
   * @param position
   */
  public isOnLastPosition(position: number): boolean {
    return (position + 1) === this.questionnaire.questions.length;
  }

  /**
   * Recursively creates and adds path hash to element. Also recursively adds element to path hash map
   *
   * @param {Questionnaire.Container} element
   */
  public addPathHashes(element: Questionnaire.Container, path: string = null) {
    // build new path
    path = path ? `${path}.${element.id}` : element.id;

    // build and add path hash
    element.pathHash = sha.sha256(path);

    // for debugging only
    element.path = path;

    // add element to path hash map
    this.pathHashMap[element.pathHash] = element;

    if (element.elements) {
      for (const subElement of element.elements) {
        // elements with "omitContainer: true" don't get a path hash. in this case we use the parent hash!
        subElement.parentHash = element.pathHash || element.parentHash;
        this.addPathHashes(subElement, path);

        // yes/no/misc json default structure contains condition.show, which contains an uuid of an element
        if (subElement.conditions && subElement.conditions.show && typeof subElement.conditions.show === 'string') {
          // we will search for the element and map it by its path hash
          for (const matchingElement of element.elements) {
            if (matchingElement.id === subElement.conditions.show) {
              // maybe the matching element does not yet contain a path hash, so lets add it
              this.addPathHashes(matchingElement, path);
              // map sub element. fill out app will show element if matching element was answered
              subElement.conditions.show = [matchingElement.pathHash];
            }
          }
        }
      }
    }
  }

  /**
   * Searches for path hashes in given element and removes them from the given hash map
   *
   * @param {Questionnaire.Container} element
   */
  private removePathHashes(element: Questionnaire.Container) {
    if (element.pathHash && typeof this.pathHashMap[element.pathHash] !== 'undefined') {
      delete(this.pathHashMap[element.pathHash]);
    }

    if (element.elements) {
      for (const subElement of element.elements) {
        this.removePathHashes(subElement);
      }
    }
  }
}
