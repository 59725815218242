import {RouterModule, Routes} from '@angular/router';
import {VersionComponent} from './components/version.component';

const APP_ROUTES: Routes = [
  {
    path: 'module/settings/version',
    component: VersionComponent,
    canActivate: ['CoreGuard'],
    data: {needsLogin: false}
  }
];

export const routing = RouterModule.forRoot(APP_ROUTES);
