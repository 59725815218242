<div class="variables-form" *ngIf="variablesForm && scopeNames.length; else notConfigured">
  <form [formGroup]="variablesForm">
    <table>
      <tr>
        <th>{{_(KEYS.EDITOR.SCOPE)}}</th>
        <th colspan="2">{{_(KEYS.EDITOR.VARIABLE_NAME)}}</th>
        <th>{{_(KEYS.EDITOR.OPTIONS)}}</th>
      </tr>
      <ng-template #tipContent let-tooltipMsg="tooltipMsg">{{tooltipMsg}}</ng-template>

      <ng-container *ngFor="let scope of scopeNames">
        <ng-container *ngIf="!isDisabledScope(scope) && variableScopes[scope].hr">
          <tr class="variablesHr"><td colspan="4"><hr></td></tr>
        </ng-container>
        <tr #tr *ngIf="!isDisabledScope(scope)">
          <td>
            <button
              class="btn btn-success qa-var-scope mr-1"
              [ngbTooltip]="_(variableScopes[scope]?.tooltip)"
            >
              <i *ngIf="variableScopes[scope].icon" class="fa {{variableScopes[scope].icon}}"></i>
              {{variableScopes[scope]?.name || scope}}
            </button>
          </td>
          <td
            [attr.colspan]="variableScopes[scope]?.allowReturnValue ? 1 : 2"
          >
            <ng-container *ngIf="variableScopes[scope]?.mappings; else freeInput">
              <select
                class="form-control"
                [ngClass]="{'max-width-180': variableScopes[scope]?.allowReturnValue ? 1 : 2}"
                [formControlName]="scope"
                [ngbTooltip]="tipContent" triggers="manual" #tt="ngbTooltip"
                (change)="onCheckForTooltip(tt, variablesForm.get(scope), scope)"
              >
                <ng-container *ngFor="let mappedVarName of getObjectKeys(variableScopes[scope].mappings)">
                  <option value="{{variableScopes[scope]?.mappings[mappedVarName]}}">
                    {{_(mappedVarName)}}
                  </option>
                </ng-container>
              </select>
            </ng-container>

            <ng-template #freeInput>
              <input
                class="form-control"
                [ngbTooltip]="tipContent" triggers="manual" #tt="ngbTooltip"
                [formControlName]="scope"
                (keyup)="onDirty(tr);onCheckForTooltip(tt, variablesForm.get(scope), scope)"
                (blur)="onCheckForTooltip(tt, variablesForm.get(scope), scope)"
              >
            </ng-template>
          </td>
          <ng-container *ngIf="variableScopes[scope]?.allowReturnValue">
            <td>
              <input
                class="form-control"
                [formControlName]="scope + '-return-value'"
                placeholder="{{_(KEYS.EDITOR.RETURN_VALUE)}}"
                (keyup)="onDirty(tr, variablesForm.get(scope));">
            </td>
          </ng-container>
          <td>
            <button
              class="btn btn-success ml-1"
              ngbTooltip="{{_(KEYS.EDITOR.SAVE_VARIABLE)}}" #st="ngbTooltip"
              (click)="onSave(tr, variablesForm.get(scope), st, scope)"
              [disabled]="!variablesForm.valid || !variablesForm.get(scope).dirty || !variablesForm.get(scope).value"
            >
              <i class="fa fa-save"></i>
            </button>
            <button
              class="btn btn-primary ml-1"
              ngbTooltip="{{_(KEYS.EDITOR.RESET_VARIABLE)}}"
              (click)="onReset(tr, variablesForm.get(scope), scope)"
            >
              <i class="fa fa-undo"></i>
            </button>
            <button
              [disabled]="variablesForm.get(scope).dirty || !variablesForm.get(scope).value ? true : null"
              class="btn btn-danger ml-1"
              ngbTooltip="{{_(KEYS.EDITOR.DELETE_VARIABLE)}}" #dt="ngbTooltip"
              (click)="dt.close(); onDelete(tr, variablesForm.get(scope), scope)"
            >
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </tr>
      </ng-container>
    </table>
  </form>
</div>
<ng-template #notConfigured>
  <div class="variables-form">
    {{_(KEYS.EDITOR.NO_VARIABLES_CONFIGURED)}}
  </div>
</ng-template>
