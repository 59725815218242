import {factory} from '@ngmedax/common-license';
import {FormControl} from '@angular/forms';


export const configGeneratorValidators = {
  /**
   * Returns regex validator by given field name and list of regular expressions
   *
   * @param fieldName
   * @param regExps
   */
  regexMatchesValidator: (fieldName: string, regExps: RegExp[]): (control: FormControl) => any => {
    return (control: FormControl) => {
      const value = (control.value || '') + '';
      let validationResult = null;
      for (const regExp of regExps) {
        if (regExp && !value.match(regExp)) {
          validationResult = {[fieldName]: true};
          break;
        }
      }

      return validationResult;
    };
  },

  validLicenseValidator: (fieldName: string): (control: FormControl) => any => {
    const licenseValidator = factory.getLicenseValidator();

    return (control: FormControl) => {
      const value = (control.value || '') + '';
      let validationResult = null;

      if (!licenseValidator.isValid(value)) {
        validationResult = {[fieldName]: true};
      }

      return validationResult;
    };
  }
};
