import {RouterModule, Routes} from '@angular/router';
import {SurveyCodeComponent} from './components/survey-code.component';

const APP_ROUTES: Routes = [
  {
    path: 'code',
    data: { redirectTo: 'module/survey/code'},
    component: SurveyCodeComponent,
    canActivate: ['RedirectGuard'],
    pathMatch: 'full'
  },
  {
    path: 'code/:code',
    redirectTo: 'module/survey/code/:code',
    pathMatch: 'full'
  },
  {
    path: 'module/survey/code',
    component: SurveyCodeComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: false,
    }
  },
  {
    path: 'module/survey/code/:code',
    component: SurveyCodeComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: false,
    }
  }
];

export const routing = RouterModule.forRoot(APP_ROUTES);
