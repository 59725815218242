import {AfterViewInit, Component, EventEmitter, Input, Optional, ViewChild} from '@angular/core';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {PatientCrudComponent} from './patient-crud.component';
import {TRANSLATION_CRUD_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";


// hack to inject decorator declarations. must occur before class declaration!
export interface PatientCrudOverlayComponent extends Translatable {}

@Component({
  selector: 'app-patient-crud-overlay',
  templateUrl: './patient-crud-overlay.component.html',
  styleUrls: ['./patient-crud-overlay.component.css']
})
@Translatable({scope: TRANSLATION_CRUD_SCOPE, keys: KEYS})
export class PatientCrudOverlayComponent implements AfterViewInit {
  @ViewChild('patientCrud') patientCrudComponent: PatientCrudComponent;
  @Input() patientId: string;

  public constructor(
    public activeModal: NgbActiveModal,
    @Optional() private translationService: TranslationService
  ) {
  }

  public ngAfterViewInit() {
    if (this.patientId) {
      this.patientCrudComponent.overlayMode = true;
      this.patientCrudComponent.loadPatient(this.patientId, this.patientCrudComponent).catch();
    }
  }

  public close() {
    this.activeModal.dismiss();
  }
}
