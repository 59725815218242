<div class="qa-question-conditions mt-3" #qaCondition>
  <div class="input-group mb-2">
    <div class="input-group-addon">
      {{_(KEYS.EDITOR.SHOW_THIS_QUESTION_IF)}}
    </div>
  </div>

  <ng-container *ngFor="let condition of conditionElements;let i = index;">
    <div class="btn-toolbar justify-content-between mb-1">
      <div class="input-group" [ngClass]="{'draw-or': (i > 0)}" [attr.data-operator]="_(KEYS.EDITOR.OR)">
        <button class="btn btn-success qa-input-position mr-1">{{i + 1}}</button>

        <ng-container *ngIf="!condition.editMode; else editMode;">
          <select class="custom-select" disabled>
            <option *ngIf="condition.selectedQuestion" selected>
              {{condition.selectedQuestion.title[locale] | striptags | htmldecode}}
            </option>
          </select>
          <span class="input-group-addon">=</span>
          <select class="custom-select" disabled>
            <option *ngIf="condition.selectedAnswer" selected>
              {{condition.selectedAnswer.title[locale] | striptags | htmldecode}}
            </option>
          </select>
        </ng-container>

        <ng-template #editMode>
          <select
            class="custom-select qa-question-select"
            (change)="onQuestionSelect(qaQuestion.value, condition)"
            data-edit data-type="question"
            [attr.data-id]="i" #qaQuestion
          >
            <option value="">...</option>
            <ng-container *ngFor="let question of questions">
              <option
                [attr.selected]="isSameElement(condition.selectedQuestion, question)"
                *ngIf="isAllowedForCondition(question) && question.title[locale]"
                [value]="question.pathHash"
              >
                {{question.title[locale] | striptags | htmldecode}}
              </option>
            </ng-container>
          </select>
          <span class="input-group-addon">=</span>
          <select
            class="custom-select qa-answer-select"
            (change)="onAnswerSelect(qaAnswer.value, condition)"
            data-edit data-type="answer"
            [attr.data-id]="i" #qaAnswer
          >
            <option value="">...</option>
            <ng-container *ngIf="condition.selectedQuestion">
              <ng-container *ngFor="let answer of getAnswers(condition.selectedQuestion)">
                <option
                  [attr.selected]="isSameElement(condition.selectedAnswer, answer)"
                  *ngIf="answer.title[locale]"
                  [value]="answer.pathHash"
                >
                  {{answer.title[locale] | striptags | htmldecode}}
                </option>
              </ng-container>
            </ng-container>
          </select>
        </ng-template>

        <button class="btn btn-success ml-1" (click)="onToggleEditMode(condition)">
          <i class="fa fa-edit"></i>
        </button>
        <button class="btn btn-danger ml-1" (click)="onConditionRemove(i)">
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </div>
  </ng-container>

  <div class="btn-group mt-2">
    <button class="btn btn-sm btn-success" (click)="onConditionAdd()">
      <i class="fa fa-plus"></i> {{_(KEYS.EDITOR.ADD_CONDITION)}}
    </button>
  </div>
</div>
