import {ConfigService} from '@ngmedax/config';
import {configKeys} from '../license-guard.config-keys';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LicenseResponse} from '../../../types';


@Injectable()
export class ApiService {

  /**
   * Constructor
   *
   * @param {ConfigService} config
   * @param {HttpClient} http
   */
  public constructor(
    private config: ConfigService,
    private http: HttpClient
  ) {
  }

  /**
   * Fetches license info
   *
   * @returns {Promise<LicenseResponse>}
   */
  public async getLicense(): Promise<LicenseResponse> {
    const url = this.config.get(configKeys.LICENSE_INFO_URI_CONFIG_KEY);

    return new Promise<LicenseResponse>((resolve, reject) => {
      this.http.get(url).subscribe(
        (licenseInfo: any) => {
          if (typeof licenseInfo !== 'object') {
            throw new Error('Unable to get license info. Response does not contain object');
          }

          if (!licenseInfo.success || !licenseInfo.license) {
            throw new Error('Fetching license was unsuccessful');
          }

          resolve(<any>licenseInfo);
        },
        error => reject(error)
      );
    });
  }
}
