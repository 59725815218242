import {ConfigService} from '@ngmedax/config';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RegistryService} from '@ngmedax/registry';
import {configKeys} from '../settings-license.config-keys';


@Injectable()
export class ApiService {

  /**
   * Constructor
   *
   * @param {ConfigService} config
   * @param {HttpClient} http
   */
  public constructor(
    private config: ConfigService,
    private registry: RegistryService,
    private http: HttpClient
  ) {
  }

  /**
   * Fetches number of questionnaires
   *
   * @returns {Promise<number>}
   */
  public async getNumQuestionnaires(): Promise<number> {
    const url = this.config.get(configKeys.QUESTIONNAIRE_URI_CONFIG_KEY) + '/count';

    return new Promise<number>((resolve, reject) => {
      this.http.get(url, {headers: this.getAuthHeaders()}).subscribe(
        (response: {count: number}) => resolve(response.count || 0),
        error => reject(error)
      );
    });
  }

  /**
   * Fetches number of devices
   *
   * @returns {Promise<number>}
   */
  public async getNumDevices(): Promise<number> {
    const url = this.config.get(configKeys.DEVICE_URI_CONFIG_KEY) + '/count';

    return new Promise<number>((resolve, reject) => {
      this.http.get(url, {headers: this.getAuthHeaders()}).subscribe(
        (response: {count: number}) => resolve(response.count || 0),
        error => reject(error)
      );
    });
  }

  /**
   * Fetches number of submitted surveys for current month
   *
   * @returs {Promise<number>}
   */
  public async getNumSubmittedSurveysForCurrentMonth(): Promise<number> {
    const url = this.config.get(configKeys.PATIENT_URI_CONFIG_KEY) + '/survey/submitted/count';

    return new Promise<number>((resolve, reject) => {
      this.http.get(url, {headers: this.getAuthHeaders()}).subscribe(
        (response: {count: number}) => resolve(response.count || 0),
        error => reject(error)
      );
    });
  }

  /**
   * Returns auth headers by auth token and tenant id
   */
  private getAuthHeaders(): any {
    const headers: any = {};
    const authToken = this.registry.get(configKeys.SESSION_AUTH_TOKEN);
    const tenantId = this.registry.get(configKeys.SESSION_TENANT_ID);

    if (authToken) {
      headers['X-Api-Token'] = authToken;
    }

    if (tenantId) {
      headers['X-Api-TenantId'] = `${tenantId}`;
    }

    return headers;
  }
}
