import {ChangeDetectorRef, Component, OnDestroy, OnInit, Optional} from '@angular/core';
import {DATE_FORMAT_YMD_HM, Translatable, TranslationService} from '@ngmedax/translation';
import {LayoutService} from '@ngmedax/layout';

import {PdfFormService} from '../../services/pdf-form.service';
import {TRANSLATION_GRID_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';
import {PdfForm} from '../../../../types';
import {PdfFormCrudService} from '../../services/pdf-form-crud.service';
import {Subscription} from 'rxjs';


// hack to inject decorator declarations. must occur before class declaration!
export interface PdfFormGridComponent extends Translatable {}

@Component({
  selector: 'app-pdf-form-grid',
  templateUrl: './pdf-form-grid.component.html',
  styleUrls: ['./pdf-form-grid.component.css'],
})
@Translatable({scope: TRANSLATION_GRID_SCOPE, keys: KEYS})
export class PdfFormGridComponent implements OnInit, OnDestroy {
  public isSearchCollapsed = true;
  public isGridCollapsed = false;
  public gridPageNumber = 1;
  public displayPerPage = 25;
  public pdfForms: PdfForm[] = [];
  public filter: PdfForm.Filter = {};
  public total = 0;

  /**
   * Locale for pdfForms. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Timeout for filter change event
   */
  private filterChangeTimeout: any = null;

  /**
   * Timeout for show preloader event
   */
  private showPreloaderTimeout: any = null;

  /**
   * Subscriptions
   */
  private subscriptions: Subscription[] = [];

  /**
   * date format to use in grid
   * @type {string}
   */
  /**
   * date format to use in grid
   * @type {string}
   */
  public get dateFormat() {
    const format = DATE_FORMAT_YMD_HM.replace(/YYYY/, 'YY');
    return this.getDateFormat(format);
  };

  /**
   * Loads pdfForms and initializes search filter
   */
  public ngOnInit() {
    this.layoutService.showPreloader();
    this.loadPdfForms();
  }

  /**
   * Unsubscribes and deletes subscriptions on destroy event
   */
  public ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }

    this.subscriptions = [];
  }

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    private layoutService: LayoutService,
    private pdfFormService: PdfFormService,
    private crudService: PdfFormCrudService,
    private ref: ChangeDetectorRef
  ) {
    this.subscriptions.push(this.pdfFormService.onPdfFormSaved().subscribe(() => this.loadPdfForms()));
  }

  public async onCreatePdfForm() {
    this.crudService.openModal();
  }

  public async onEditPdfForm(pdfForm: PdfForm) {
    this.crudService.openModal(pdfForm.uid);
  }

  public async onDeletePdfForm(pdfForm: PdfForm) {
    const sure = await confirm(this._(KEYS.GRID.DELETE_PDF_FORM_QUESTION) + `: ${pdfForm.name}`);

    if (sure) {
      this.pdfFormService
        .deletePdfForm(pdfForm)
        .then(() => {
          this.pdfForms.splice(this.pdfForms.indexOf(pdfForm), 1);
          alert(this._(KEYS.GRID.SUCCESSFULLY_DELETED_PDF_FORM));
        })
        .catch(error => {
          console.error(error);
          alert(this._(KEYS.GRID.ERROR_DELETING_PDF_FORM));
        });
    }
  }

  public async onManageSignatures(pdfForm: PdfForm) {
    this.crudService.openSignatureModal(pdfForm.uid);
  }

  /**
   * Event handler for when paging changes. Triggers change detection and loads pdf forms
   */
  public onPagingChange() {
    // trigger change detection
    this.ref.detectChanges();
    this.ref.markForCheck();

    this.loadPdfForms();
  }

  /**
   * Event handler for when filter changes. Loads pdfForms
   */
  public onFilterChange() {
    clearTimeout(this.filterChangeTimeout);
    this.filterChangeTimeout = setTimeout(() => this.loadPdfForms(), 1000);
  }

  /**
   * Returns pdfForms which are allowed by currently set filter
   *
   * @returns {Array}
   */
  public getPdfForms(): PdfForm[] {
    return this.pdfForms;
  }

  private loadPdfForms() {
    this.showPreloader();

    const filter = this.getQueryFilter();
    const opts = this.getQueryOpts();

    this.pdfFormService.loadPdfForms(filter, opts)
      .then((result) => {
        this.pdfForms = result.rows;
        this.total = result.total;
        this.hidePreloader();
      })
      .catch(error => {
        this.hidePreloader();
        alert(this._(KEYS.GRID.ERROR_LOADING_PDF_FORMS));
        console.log(error);
      });
  }

  /**
   * Returns query filter by filter object
   */
  private getQueryFilter() {
    const filter: any = {};
    Object.keys(this.filter).forEach((key) => filter[key] = { '$regex' : this.filter[key], '$options' : 'i' });
    return filter;
  }

  /**
   * Returns query opts
   */
  private getQueryOpts() {
    return {limit: this.displayPerPage, offset: (this.gridPageNumber - 1) * this.displayPerPage}
  }

  /**
   * Shows preloader after 200ms
   */
  private showPreloader() {
    this.showPreloaderTimeout = setTimeout(() => this.layoutService.showPreloader(), 200);
  }

  /**
   * Hides preloader
   */
  private hidePreloader() {
    clearTimeout(this.showPreloaderTimeout);
    this.layoutService.hidePreloader();
  }
}
