import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCropperComponent } from './image-cropper.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ImageCropperComponent
    ],
    exports: [
        ImageCropperComponent
    ]
})
export class ImageCropperModule {
    public constructor() {
        // ie11 inline polyfill for "toBlob" method
        if (!HTMLCanvasElement.prototype.toBlob) {
            Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
                value: function (callback: any, type: any, quality: any) {
                    const dataURL = (<HTMLCanvasElement>this).toDataURL(type, quality).split(',')[1];
                    setTimeout(function() {
                        const binStr = atob( dataURL ), len = binStr.length, arr = new Uint8Array(len);

                        for (let i = 0; i < len; i++ ) {
                            arr[i] = binStr.charCodeAt(i);
                        }

                        callback( new Blob( [arr], {type: type || 'image/png'} ) );
                    });
                }
            });
        }
    }
}
