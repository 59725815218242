import {Injectable, Renderer2, RendererFactory2} from '@angular/core';

@Injectable()
export class DomHelperService {
  private renderer: Renderer2;

  /**
   * Sets renderer by renderer factory
   *
   * @param rendererFactory
   */
  public constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Removes given css classes from given element
   *
   * @param el
   * @param classes
   */
  public removeClasses(el: HTMLElement, classes: string[]) {
    for (const cls of classes) {
      this.renderer.removeClass(el, cls);
    }
  }

  /**
   * Adds given css classes to given element
   *
   * @param el
   * @param classes
   */
  public addClasses(el: HTMLElement, classes: string[]) {
    for (const cls of classes) {
      this.renderer.addClass(el, cls);
    }
  }

  /**
   * Toggles classes on given element(s).
   *
   * @param el
   * @param cls
   * @param additionalEls
   */
  public toggleCls(el: HTMLElement, cls: string, additionalEls: HTMLElement[] = []) {
    additionalEls.push(el);

    if (el.classList.contains(cls)) {
      for (const additionalEl of additionalEls) {
        this.renderer.removeClass(additionalEl, cls);
      }
      return;
    }

    for (const additionalEl of additionalEls) {
      this.renderer.addClass(additionalEl, cls);
    }
  }

  /**
   * Animates given element
   *
   * @param el
   * @param classes
   * @param duration
   */
  public animate(el: HTMLElement, classes: string[], duration = 600) {
    this.addClasses(el, classes);
    setTimeout(() => this.removeClasses(el, classes), duration);
  }
}
