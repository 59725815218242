<div class="qa-question mt-2 mb-2 qa-padding qa-bg-gray" id="{{question.id}}" #qaQuestion>
  <div class="qa-question-bar qa-upper">
    <div class="btn-group btn-group-custom">
      <button class="btn btn-success qa-position">
        {{position + 1}}
      </button>
      <button class="btn btn-primary qa-question-type">
        <i class="fa fa-file-text-o"></i>
        {{_(KEYS.EDITOR.PAGE_BREAK)}}
      </button>
    </div>
  </div>
  <div class="qa-question-buttons">
    <button class="btn btn-danger ml-1" (click)="onDelete()">
      <i class="fa fa-trash"></i>
    </button>
  </div>
  <div class="qa-question-hr"></div>
  <div class="qa-question-handle">
    <div class="btn-group-vertical">
      <button class="btn btn-primary qa-handle-control">
        <i class="fa fa-arrows qa-handle-control"></i>
      </button>
      <button class="btn btn-secondary qa-show-on-expanded" (click)="onPositionChange(position + 1)">
        <i class="fa fa-arrow-up"></i>
      </button>
      <button class="btn btn-secondary qa-btn-brighten qa-show-on-expanded">
        <input [value]="position + 1" (change)="onPositionChange(position); qaPosition.value = (position + 1) + '';" #qaPosition>
      </button>
      <button class="btn btn-secondary qa-show-on-expanded" (click)="onPositionChange(position - 1)">
        <i class="fa fa-arrow-down"></i>
      </button>
      <button class="btn btn-success qa-show-on-expanded">
        <i class="fa fa-plus"></i>
      </button>
    </div>
  </div>
  <div class="qa-question-title">
    <input class="form-control" placeholder="{{_(KEYS.EDITOR.ENTER_TITLE)}}" [(ngModel)]="question.title[locale]" >
  </div>
</div>
