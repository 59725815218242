import {Injectable} from '@angular/core';

@Injectable()
export class ClipboardService {
  /**
   * Copies given text to clipboard
   *
   * @param {string} text
   */
  public copy(text: string) {
    const aux = document.createElement('textarea');
    aux.innerHTML = text;

    document.body.appendChild(aux);
    aux.select();

    document.execCommand('copy');
    document.body.removeChild(aux);

  }
}
