import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as JSZip from 'jszip';
import * as yaml from 'js-yaml';
import * as handlebars from 'handlebars';
import {Pack} from '../../../types';
import {ConfigService} from '@ngmedax/config';
import {ValueService} from '@ngmedax/value';
import {QueryStringService} from './query-string.service';

@Injectable()
export class ConfigGeneratorService {
  private readonly templateUrl;
  private readonly repos = '';
  private readonly srcUrl = '';
  private channel = '';

  /**
   * Injects dependencies
   *
   * @param http
   */
  public constructor(
    public http: HttpClient,
    private queryString: QueryStringService,
    private config: ConfigService,
    private value: ValueService
  ) {
    this.srcUrl = this.queryString.getParam('src')
      || this.config.get('configGenerator.src')
      || 'https://repos.mymedax.dev';

    this.repos =
      this.queryString.getParam('repos')
      || this.config.get('configGenerator.repos')
      || 'docker-compose';

    this.channel = this.queryString.getParam('channel')
      || this.config.get('configGenerator.channel')
      || '';

    console.log({srcUrl: this.srcUrl, repos: this.repos, channel: this.channel});

    this.templateUrl = `${this.srcUrl}/${this.repos}/${this.channel}`;

    handlebars.registerHelper({
      eq: (v1, v2) => v1 === v2,
      ne: (v1, v2) => v1 !== v2,
      lt: (v1, v2) => v1 < v2,
      gt: (v1, v2) => v1 > v2,
      lte: (v1, v2) => v1 <= v2,
      gte: (v1, v2) => v1 >= v2,
      and: function () {return Array.prototype.slice.call(arguments).every(Boolean);},
      or: function () {return Array.prototype.slice.call(arguments, 0, -1).some(Boolean);},
      not: (v1) => !v1,
      removePort: function(url) {return url.replace(/:\d+/, '');},
      toKubernetesResourceName: function(str) {
        if (typeof str !== 'string') {
          return str;
        }
        // replace dots with -
        let secretName = str.replace(/\./g, '-');
        // remove any non-allowed characters
        secretName = secretName.replace(/[^a-z0-9-]/gi, '');
        // make sure the first and last characters are alphanumeric
        secretName = secretName.replace(/(^-|-$)/g, '');
        // make the secret name lowercase
        secretName = secretName.toLowerCase();
        // truncate the secret name to a maximum of 253 characters
        secretName = secretName.slice(0, 253);
        return secretName;
      },
      curlyBraces: function(str) {
        return `{{${str}}}`;
      },
      isIp: function(ip) {
        if (typeof ip !== 'string') {
          return false;
        }
        const ipRegex = /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])$/;
        return ipRegex.test(ip);
      }
    });
  }

  /**
   * Returns template url
   *
   * @returns {string}
   */
  public getTemplateUrl(): string {
    return this.templateUrl;
  }

  /**
   * Sets channel
   *
   * @param {string} channel
   */
  public setChannel(channel: string) {
    this.channel = channel;
  }

  /**
   * Returns channel
   *
   * @returns {string}
   */
  public getChannel(): string {
    return this.channel;
  }

  /**
   * Fetches available versions
   *
   * @returns {Promise<string[]>}
   */
  public async getVersions(): Promise<string[]> {
    const indexUrl = `${this.getTemplateUrl()}/index.json?t=${Date.now()}`;

    const request = new Promise((resolve, reject) => {
      this.http.get(indexUrl, {responseType: 'json'})
        .subscribe((response: any) => resolve(response), error => reject(error));
    });

    const response = await request;
    const versions = this.value.get(response, ['versions']) || [];
    return this.value.isArray(versions) ? versions : [];
  }

  /**
   * Fetches and returns template pack by given url
   *
   * @param {string} version
   */
  public async fetch(version: string): Promise<Pack> {
    const templateUrl = `${this.getTemplateUrl()}/${version}.zip?t=${Date.now()}`;

    const request = new Promise((resolve, reject) => {
      this.http.get(templateUrl, {responseType: 'arraybuffer'})
        .subscribe((response: any) => resolve(response), error => reject(error));
    });

    const zipContent = await request;
    const zip = await JSZip.loadAsync(<any>zipContent);
    const variablesContent = await zip.file('variables.yaml').async('string');
    const pack: Pack = yaml.load(variablesContent);

    if (pack.template.wizard) {
      try {
        const baseFile = 'questionnaire';
        const questionnaireZipContent = await zip.file(`${baseFile}.mqt`).async('arraybuffer');
        const questionnaireZip = await JSZip.loadAsync(questionnaireZipContent)
        const questionnaire = await questionnaireZip.file(`${baseFile}.json`).async('string');
        pack.template.wizard.payload = JSON.parse(questionnaire);
      } catch (error) {
        console.error(error);
        throw new Error(`Der Fragebogen für den Wizard konnte nicht geladen werden.`);
      }
    }

    if (!pack || !pack.template || !pack.template.name) {
      throw new Error('Unable to continue. Invalid variables format');
    }

    pack.template.content = await zip.file(pack.template.name).async('string');
    return pack;
  }

  /**
   * Renders template by given variables and template string
   *
   * @param variables
   * @param template
   */
  public render(variables: any, template: string): string {
    Object.keys(variables).map(varName => {
      const regExp = new RegExp(`\\\$\\{${varName}\\}`, 'g');
      const value = this.sanitize(variables[varName] === null ? '' : variables[varName]);
      template = template.replace(regExp, value);
    });

    // handlebars template ?
    if (template.match(/([^\$]){{.*?}}/)) {
      try {
        template = handlebars.compile(template)(variables);
      } catch (error) {
        console.error(error);
      }
    }

    return template.replace(/[\r\n]{2,}/g, '\n');
  }

  public toDataUrl(content: string, mimeType: string) {
    return `data:${mimeType},${encodeURIComponent(content)}`;
  }

  /**
   * Sanitizes value
   *
   * @param value
   */
  private sanitize(value: any): any {
    return value && typeof value === 'string' ? value.replace(/\$/g, '$$$$') : value;
  }
}
