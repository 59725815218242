import {Component, ElementRef, Input, OnInit, Optional, ViewChild, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {saveAs} from 'file-saver';

import {LayoutService} from '@ngmedax/layout';
import {ConfigService} from '@ngmedax/config';
import {DATE_FORMAT_YMD_HM, Translatable, TranslationService} from '@ngmedax/translation';

import {AssetApiService} from '../../services/asset-api.service';
import {ImageService} from '../../services/image.service';
import {TRANSLATION_MEDIA_CENTER_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';
import {Asset} from '../../../../types';


// hack to inject decorator declarations. must occur before class declaration!
export interface MediaCenterComponent extends Translatable {}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-qa-media-center-modal',
  templateUrl: './media-center.component.html',
  styleUrls: ['./media-center.component.css']
})
@Translatable({scope: TRANSLATION_MEDIA_CENTER_SCOPE, keys: KEYS})
export class MediaCenterComponent implements OnInit {
  @Input() options: Asset.MediaCenter.Options;
  @ViewChild('imageCropper') imageCropper: any;
  @ViewChild('previewImage') previewImageEl: ElementRef;
  @ViewChild('video') videoEl: ElementRef;

  /**
   * Files from asset server
   */
  public files: Asset.MediaCenter.GridFile[] = [];

  /**
   * Currently uploaded files
   */
  public uploads: Asset.Bucket.File.Upload[] = [];

  /**
   * Currently selected file
   */
  public selectedFile: Asset.MediaCenter.GridFile;

  /**
   * Data url of selected image
   */
  public selectedImage: string;

  /**
   * Base uri for assets
   */
  public baseUri: string;

  /**
   * date format to use in grid
   * @type {string}
   */
  public get dateFormat() {
    const format = DATE_FORMAT_YMD_HM.replace(/YYYY/, 'YY');
    return this.getDateFormat(format);
  };

  /**
   * Name filter string
   *
   */
  public nameFilter: string;

  /**
   * Upload mode?
   */
  public uploadMode = false;

  /**
   * Image edit mode?
   */
  public imageEditMode = false;

  /**
   * Injects dependencies
   */
  constructor(
    private config: ConfigService,
    private image: ImageService,
    private assetApi: AssetApiService,
    private layout: LayoutService,
    @Optional() private translationService: TranslationService,
    public activeModal: NgbActiveModal,
  ) {
  }

  /**
   * Loads files and sets base uri for assets cdn
   */
  public ngOnInit() {
    this.loadFiles().catch(error => {
      alert(this._(KEYS.MEDIA_CENTER.ERROR_LOADING_ASSETS));
      console.error(error);
    });

    this.baseUri = `${this.config.get('apis.asset.cdn') || ''}/${this.options.bucketId || ''}`;
  }

  /**
   * Loads list of files via asset server api. Also tries to select desired file, when given by options
   */
  public async loadFiles() {
    this.files = await this.assetApi.getBucketFiles(this.options.bucketId, this.options.filter || null);
    !Array.isArray(this.files) && (this.files = []);

    for (const file of this.files) {
      !file.type && (file.type = '');
      file.options = {};
    }

    // if we should select a file
    if (this.options.selectFile) {
      // find file by name
      const selectFile = this.options.selectFile;
      const foundFile = this.getFileByName(selectFile.name);

      if (!foundFile) {
        return;
      }

      // pass options to found file
      if (selectFile.options) {
        for (const optKey of Object.keys(selectFile.options)) {
          foundFile.options[optKey] = selectFile.options[optKey];
        }
      }

      // select found file
      this.onSelectFile(foundFile);
    }
  }

  /**
   * Downloads selected file from the asset server
   *
   * @param file
   */
  public async onDownloadFile(file: Asset.MediaCenter.GridFile) {
    saveAs(`${this.baseUri}/${file.name}`, file.name);
  }

  /**
   * Deletes a selected file from the asset server
   *
   * @param file
   */
  public async onDeleteFile(file: Asset.MediaCenter.GridFile) {
    const sure = await confirm(this._(KEYS.MEDIA_CENTER.QUESTION_DELETE_FILE));
    if (sure) {
      delete(this.selectedFile);
      delete(this.options.selectFile);
      this.assetApi.removeBucketFile(this.options.bucketId, file.name)
        .then(() => {
          this.loadFiles().catch(error => {
            alert(this._(KEYS.MEDIA_CENTER.ERROR_UPDATING_ASSETS));
            console.error(error);
          });
        })
        .catch(error => {
          alert(this._(KEYS.MEDIA_CENTER.ERROR_DELETING_ASSET));
        });
    }
  }

  /**
   * Selects the given file
   *
   * @param selectedFile
   */
  public onSelectFile(selectedFile: Asset.MediaCenter.GridFile) {
    this.imageEditMode = false;

    this.selectedFile = selectedFile;
    this.videoEl && this.videoEl.nativeElement && this.videoEl.nativeElement.load();

    for (const file of this.files) {
      if (file.name === selectedFile.name) {
        file.options.selected = true;
        continue;
      }
      file.options.selected = false;
    }

    if (this.options.maintainAspectRatio) {
      this.onEditImage();
    }
  }

  /**
   * Creates a video preview image by currently selected video file
   */
  public onCreateVideoPreviewImage() {
    if (!this.selectedFile || !this.videoEl) {
      return;
    }

    const video = this.videoEl.nativeElement;
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    const previewImage = canvas.toDataURL('image/jpeg');
    this.selectedFile.options.previewImage = previewImage;
    video.currentTime = 0;
    video.load();
  }

  public onEditImage() {
    const editImage = async() => {
      try {
        const imageData = await this.image.getImageData(this.selectedFile, this.options.bucketId);

        if (!imageData.dataUrl) {
          throw new Error('Received empty data url!');
        }

        this.selectedImage = imageData.dataUrl;
        this.imageEditMode = true;
      } catch (error) {
        alert(this._(KEYS.MEDIA_CENTER.ERROR_STARTING_EDIT_MODE));
        console.error(error);
      }
    };

    editImage().then().catch();
  }

  /**
   * Hack to set cropper position by selected file
   */
  public onImageLoaded() {
    if (!this.selectedFile.options.cropperPosition || !this.imageCropper) {
      return;
    }

    this.imageCropper.cropper = this.selectedFile.options.cropperPosition;
  }

  public onEditImageDone() {
    (this.imageCropper) && this.imageCropper.crop();
  }

  /**
   * Sets cropped image on crop event
   *
   * @param event
   */
  public onImageCropped(event: any) {
    if (this.selectedFile) {
      this.selectedFile.options.croppedImage = event.base64;
      this.selectedFile.options.cropperPosition = this.imageCropper.cropper;
    }
    this.imageEditMode = false;
  }

  /**
   * Handles file upload
   *
   * @param event
   */
  public onUpload(event: Event) {
    const files: FileList = event && event.target && event.target['files'] ? event.target['files'] : null;

    if (!files) {
      return;
    }

    if (this.options.accept) {
      for (let i = 0; i < files.length; i++) {
        const file: File = files[i];
        if (!this.isFileTypeAllowed(file.type, this.options.accept)) {
          alert(this._(KEYS.MEDIA_CENTER.INVALID_FILE_TYPE_SELECTED));
          return;
        }
      }
    }

    this.uploadMode = true;

    const uploads = this.assetApi.uploadBucketFiles(this.options.bucketId, files);
    const promises: Promise<void>[] = [];

    for (const fileName of Object.keys(uploads)) {
      const upload = uploads[fileName];
      upload.progress.subscribe((percent) => {
        if (percent !== 100) {
          return;
        }

        setTimeout(() => {
          const position = this.uploads.indexOf(upload);
          (position !== -1) && this.uploads.splice(position, 1);
        }, 500);
      });

      this.uploads.push(upload);
      promises.push(upload.promise);
    }

    Promise.all(promises)
      .then(() => {
        this.uploads = [];
        this.loadFiles()
          .then(() => this.uploadMode = false)
          .catch(error => {
            this.uploadMode = false;
            alert(this._(KEYS.MEDIA_CENTER.ERROR_UPDATING_ASSETS));
            console.error(error);
          });

      })
      .catch(error => {
        alert(this._(KEYS.MEDIA_CENTER.ERROR_UPLOADING_ASSET));
        console.error(error);
      });
  }

  /**
   * Handles zip download of bucket
   */
  public async onZipDownload() {
    this.layout.showPreloader();

    try {
      const download = this.assetApi.downloadBucketZip(this.options.bucketId, `${this.options.bucketId}.zip`);
      await download.promise;
      this.layout.hidePreloader();
    } catch (error) {
      alert(this._(KEYS.MEDIA_CENTER.ERROR_DOWNLOADING_ASSETS));
      console.error(error);
      this.layout.hidePreloader();
    }
  }

  /**
   * Returns grid files by filter
   */
  public getFiles(): Asset.MediaCenter.GridFile[] {
    const filtered: Asset.MediaCenter.GridFile[] = [];

    if (!this.files) {
      return [];
    }

    const notIndexFile = file => file.name !== 'index.json';

    if (!this.nameFilter) {
      return this.files.filter(notIndexFile);
    }

    const nameRegex = new RegExp(this.nameFilter, 'i');

    for (const file of this.files.filter(notIndexFile)) {
      file.name.match(nameRegex) && filtered.push(file);
    }

    return filtered;
  }

  /**
   * Closes the media center modal. Executes callback with selected file
   * and preview image when "whitSelectedFile" is set to true
   *
   * @param withSelectedFile
   */
  public close(withSelectedFile = false) {
    if (this.selectedFile && this.imageEditMode) {
      this.onEditImageDone();
    }

    setTimeout(() => {
      if (withSelectedFile && this.selectedFile && typeof this.options.callback === 'function') {
        this.options.callback(this.selectedFile);
      }

      this.activeModal.dismiss();
    }, 100);
  }

  /**
   * Returns file by name
   *
   * @param fileName
   */
  private getFileByName(fileName: string): Asset.MediaCenter.GridFile {
    for (const file of this.files) {
      if (file.name === fileName) {
        return file;
      }
    }
  }

  /**
   * Returns true if file type is allowed by accept attribute
   *
   * @param {string} fileType the file type. e.g. image/jpeg
   * @param {string} acceptAttr the accept attribute. e.g. image/*,video/*
   * @returns {boolean} true if file type is allowed
   */
  private isFileTypeAllowed(fileType: string, acceptAttr: string) {
    const acceptRegex = acceptAttr.replace(/\*/g, '.*');
    const pattern = new RegExp('^(' + acceptRegex.split(',').join('|') + ')$', 'i');
    return pattern.test(fileType);
  }
}
