import {Injectable} from '@angular/core';
import {Subject, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';

interface EventData {
  type: string;
  payload?: any;
}

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private eventSubject = new Subject<EventData>();

  /**
   * Methode to emit events
   */
  emitEvent(event: EventData) {
    this.eventSubject.next(event);
  }

  /**
   * Methode to listen to events
   */
  onEvent(eventType: string): Observable<any> {
    return this.eventSubject.asObservable().pipe(
      filter(event => event.type === eventType),
      map(event => event.payload)
    );
  }
}
