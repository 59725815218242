import {QuestionnaireRevision, QuestionnaireTitle, Submission, SubmissionQuery} from '../../../types';

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {RegistryService} from '@ngmedax/registry';
import {ConfigService} from '@ngmedax/config';


@Injectable()
export class SubmissionService {
  /**
   * Locale for submissions. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Base uri for assets download
   */
  private assetsBaseUri: string;

  /**
   * Injects dependencies
   *
   * @param registryService
   * @param configService
   * @param http
   */
  public constructor(
    private registryService: RegistryService,
    private configService: ConfigService,
    private http: HttpClient,
  ) {
    this.assetsBaseUri = this.configService.get('apis.submission.assets.cdn');
    console.log('apis.submission.assets.cdn:', this.assetsBaseUri);
  }

  /**
   * Fetches and returns submissions
   *
   * @returns {Promise<Submission[]>}
   */
  public getSubmissions(filter?: any, opts?: any): Promise<{rows: Submission[], total: number}> {
    const baseUri = this.getSubmissionsApiUrl();
    const headers = this.getAuthHeaders();

    const query: any = {filter: JSON.stringify(filter) || '{}', opts: JSON.stringify(opts) || '{}'};
    const basePath = `${baseUri}?${decodeURI(new URLSearchParams(query).toString())}`;

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers})
        .subscribe((submission: any) => {
            const submissions = submission.submission || submission.rows || submission || [];

            if (Object.prototype.toString.call(submissions) === '[object Array]') {
              submissions.sort((a , b) => (a.receivedDate < b.receivedDate) ? 1 : -1);
            }

            resolve({rows: submissions, total: submission.total || 0});
          },
          error => {
            reject(error);
          });
    });
  }

  /**
   * Fetches and returns submission by given submission id
   *
   * @returns {Promise<Submission>}
   */
  public getSubmission(id: string): Promise<Submission> {
    const basePath = this.getSubmissionsApiUrl(`/${id}`);
    const headers = this.getAuthHeaders();

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers})
        .subscribe((result: any) => {
            const submission = result.submission || result || {};
            resolve(submission);
          },
          error => {
            reject(error);
          });
    });
  }

  /**
   * Fetches and returns questionnaire titles by submissions
   *
   * @returns {Promise<QuestionnaireTitle[]>}
   */
  public getQuestionnaireTitles(): Promise<QuestionnaireTitle[]> {
    const basePath = this.getSubmissionsApiUrl('/questionnaire-title');
    const headers = this.getAuthHeaders();

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers})
        .subscribe((result: any) => {
            const titles = result.title || result.rows || result || [];
            resolve(titles);
          },
          error => {
            reject(error);
          });
    });
  }

  /**
   * Fetches and returns questionnaire titles by submissions
   *
   * @param {string} id questionnaire id
   * @returns {Promise<QuestionnaireRevision[]>}
   */
  public getQuestionnaireRevisions(id: string): Promise<QuestionnaireRevision[]> {
    const basePath = this.getSubmissionsApiUrl(`/questionnaire-revision/${id}`);
    const headers = this.getAuthHeaders();

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers})
        .subscribe((result: any) => {
            const revisions = result.revision || result.rows || result || [];
            resolve(revisions);
          },
          error => {
            reject(error);
          });
    });
  }

  /**
   * Fetches and returns submission count by given query
   *
   * @param {SubmissionQuery} query
   * @returns {Promise<number>}
   */
  public async getCount(query: SubmissionQuery): Promise<number> {
    if (query.rev === 'all') {
      const revs = await this.getQuestionnaireRevisions(query.id);

      let count = 0;
      for (const rev of revs) {
        query.rev = rev.revision;
        count += await this.getCount(query);
      }

      return count;
    }

    const queryString = `questionnaireId=${query.id}&questionnaireRevision=${query.rev}&fromDate=${query.fromDate}&toDate=${query.toDate}`;
    const basePath = this.getSubmissionsApiUrl(`/count`, queryString);
    const headers = this.getAuthHeaders();

    return new Promise<number>((resolve, reject) => {
      this.http.get(basePath, {headers})
        .subscribe((result: any) => {
            const count = result.count || 0;
            resolve(count);
          },
          error => {
            reject(error);
          });
    });
  }

  /**
   * Fetches and returns submission id's by given query
   *
   * @param {SubmissionQuery} query
   * @returns {Promise<string[]>}
   */
  public getIds(query: SubmissionQuery): Promise<string[]> {
    const queryString = `questionnaireId=${query.id}&questionnaireRevision=${query.rev}&fromDate=${query.fromDate}&toDate=${query.toDate}`;
    const basePath = this.getSubmissionsApiUrl(`/ids`, queryString);
    const headers = this.getAuthHeaders();

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers})
        .subscribe((result: any) => {
            resolve(result.submission || result.rows || []);
          },
          error => {
            reject(error);
          });
    });
  }

  /**
   * Returns URI to export file name
   *
   * @param fileName
   */
  public getDownloadLink(fileName: string): string {
    console.log(`${this.assetsBaseUri}/${fileName}`);
    return `${this.assetsBaseUri}/${fileName}`;
  }

  /**
   * Returns api url for submissions
   *
   * @param {string} suffix
   * @param {string} queryString
   * @returns {string}
   */
  private getSubmissionsApiUrl(suffix: string = null, queryString: string = null): string {
    return this.buildUrl('apis.submission.uri', suffix, queryString);
  }

  /**
   * Builds url by value of given config key, suffix and optional query string
   *
   * @param {string} configKey
   * @param {string} suffix
   * @param {string} queryString
   * @returns {string}
   */
  private buildUrl(configKey: string, suffix: string = null, queryString: string = null): string {
    let baseUri = this.configService.get(configKey);

    if (suffix) {
      baseUri = `${baseUri}${suffix}`;
    }

    if (queryString) {
      baseUri += `?${queryString}`;
    }

    return baseUri;
  }

  /**
   * Returns auth headers by auth token and tenant id
   */
  private getAuthHeaders(): any {
    const headers: any = {};
    const authToken = this.registryService.get('session.authToken');
    const tenantId = this.registryService.get('session.tenantId');

    if (authToken) {
      headers['X-Api-Token'] = authToken;
    }

    if (tenantId) {
      headers['X-Api-TenantId'] = `${tenantId}`;
    }

    return headers;
  }
}
