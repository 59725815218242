<div class="qa-grid">
  <h2 class="mb-4">{{_(KEYS.GRID.FILTER_GROUPS)}}</h2>

  <div class="card mb-5">
    <div class="card-body">
      <div class="card-title">

        <div class="btn-toolbar justify-content-between">
          <div class="justify-content-between qa-lh-2p5">
            <i class="fa fa-search"></i>
            <h5 class="d-inline">{{_(KEYS.GRID.SEARCH)}}</h5>
          </div>

          <button class="btn btn-primary d-inline-block" (click)="isSearchCollapsed = !isSearchCollapsed">
            <i class="fa" [ngClass]="{'fa-arrow-down': isSearchCollapsed, 'fa-arrow-up': !isGridCollapsed}"></i>
          </button>
        </div>
      </div>
      <div class="card-text" [ngbCollapse]="isSearchCollapsed">
        <div class="qa-grid-search mt-2">
          <div class="input-group qa-grid-search-id">
            <span class="input-group-addon">{{_(KEYS.GRID.ID)}}:</span>
            <input class="form-control" [(ngModel)]="filter.id">
          </div>

          <div class="input-group qa-grid-search-name">
            <span class="input-group-addon">{{_(KEYS.GRID.NAME)}}:</span>
            <input class="form-control" [(ngModel)]="filter.name">
          </div>


          <div class="qa-grid-search-buttons">
            <button class="btn btn-primary" (click)="onResetSearchForm()">
              <i class="fa fa-eraser"></i>
              {{_(KEYS.GRID.RESET)}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <div class="card-title">

        <div class="btn-toolbar justify-content-between">
          <div class="justify-content-between qa-lh-2p5">
            <i class="fa fa-copy"></i>
            <h5 class="d-inline">{{_(KEYS.GRID.FILTER_GROUPS)}}</h5>
          </div>

          <button class="btn btn-primary d-inline-block" (click)="isGridCollapsed = !isGridCollapsed">
            <i class="fa" [ngClass]="{'fa-arrow-down': isGridCollapsed, 'fa-arrow-up': !isGridCollapsed}"></i>
          </button>
        </div>

      </div>
      <div class="card-text" [ngbCollapse]="isGridCollapsed">
        <table class="table">
          <thead>
          <tr>
            <th class="id-col">{{_(KEYS.GRID.ID)}}</th>
            <th>{{_(KEYS.GRID.NAME)}}</th>
          </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let filtergroup of getFiltergroups(); let pos = index;">
              <tr [ngClass]="{'qa-tr-odd': pos % 2 === 0}" *ngIf="isOnCurrentPage(pos)" #tr>
                <td class="id-col">
                  <input class="form-control" [(ngModel)]="filtergroup.id" readonly>
                </td>
                <td>
                  <input class="form-control" [(ngModel)]="filtergroup.name[locale]" (keyup)="onDirty(tr)">
                </td>

                <td class="options-col">
                  <div class="btn btn-success d-inline-block mr-1" ngbTooltip="{{_(KEYS.GRID.SAVE)}}" (click)="onUpdate(filtergroup, tr)">
                    <i class="fa fa-save"></i>
                  </div>
                  <div class="btn btn-primary d-inline-block mr-1" ngbTooltip="{{_(KEYS.GRID.RESET)}}" (click)="onReset(filtergroup, tr)">
                    <i class="fa fa-undo"></i>
                  </div>
                  <div class="btn btn-danger d-inline-block mr-1" ngbTooltip="{{_(KEYS.GRID.DELETE)}}" (click)="onDelete(pos)">
                    <i class="fa fa-trash"></i>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>

        <div class="btn-toolbar justify-content-between mb-2">
          <table class="table">
            <tbody>
              <tr>
                <td class="id-col">
                  <input class="form-control" [(ngModel)]="newgroup.id" (keyup)="sanitize(newgroup)">
                </td>
                <td>
                  <input class="form-control" [(ngModel)]="newgroup.name[locale]">
                </td>
                <td class="options-col">
                  <div class="btn btn-success d-inline-block mr-1" ngbTooltip="{{_(KEYS.GRID.ADD)}}" (click)="onAdd()">
                    <i class="fa fa-plus"></i>
                  </div>
                </td>
            </tbody>
          </table>
        </div>

        <div class="btn-toolbar justify-content-between mt-2 mb-2">
          <div class="justify-content-between">
            <div class="input-group qa-num-per-page">
              <select class="form-control custom-select" [(ngModel)]="displayPerPage" (change)="onPagingChange()">
                <option value="2">2</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
              <span class="input-group-addon">{{_(KEYS.GRID.ENTRIES)}}</span>
            </div>
          </div>

          <ngb-pagination
            [pageSize]="displayPerPage"
            [maxSize]="9"
            [rotate]="true"
            [(page)]="gridPageNumber"
            [collectionSize]="getFiltergroups().length"
            (pageChange)="onPagingChange()">
          </ngb-pagination>
        </div>

      </div>
    </div>
  </div>
</div>
