export interface Token {
  type: 'GROUP_OPEN' | 'GROUP_CLOSE' | 'VARIABLE' | 'OPERATOR' | 'VALUE';
  raw: string | number | boolean;
}

export namespace Token {
  export const TYPE: {GROUP_OPEN: 'GROUP_OPEN', GROUP_CLOSE: 'GROUP_CLOSE', VARIABLE: 'VARIABLE', OPERATOR: 'OPERATOR', VALUE: 'VALUE'} = {
    GROUP_OPEN: 'GROUP_OPEN',
    GROUP_CLOSE: 'GROUP_CLOSE',
    VARIABLE: 'VARIABLE',
    OPERATOR: 'OPERATOR',
    VALUE: 'VALUE'
  };
}

export interface TokenRule {
  matches: (token: string) => boolean;
  action: (token: string) => Token;
}

export namespace Ast {
  export const TYPE: {GROUP: 'group', EXPRESSION: 'expression'} = {
    GROUP: 'group',
    EXPRESSION: 'expression'
  };

  export type OPERATOR = '||' | '&&' | '!=' | '==' | '>' | '<' | '>=' | '<=';

  export interface OperatorAware {
    operator: '||' | '&&' | '!=' | '==' | '>' | '<' | '>=' | '<=';
  }

  export interface Group extends OperatorAware {
    type: 'group';
    elements: (Group | Expression)[];
    parent?: Group;
  }

  export interface Expression extends OperatorAware {
    type: 'expression';
    variable: string;
    value: string | number | boolean;
  }
}
