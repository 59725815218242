import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ConfigService} from '@ngmedax/config';
import {RegistryService} from '@ngmedax/registry';
import {AssetApiService} from '@ngmedax/asset';
import {QuestionnaireGroup, Translation} from '../../../types';
import {configKeys} from '../questionnaire-group.config-keys';
import {User} from '@ngmedax/ums/types';


@Injectable()
export class QuestionnaireGroupService {
  /**
   * Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Event for when pdf form was saved
   * @type {EventEmitter}
   */
  private questionnaireGroupSavedEvent: EventEmitter<User> = new EventEmitter();

  public constructor(
    private registryService: RegistryService,
    private configService: ConfigService,
    private assetApi: AssetApiService,
    private http: HttpClient
  ) {
  }

  /**
   * Loads questionnaire groups
   *
   * @param filter: any
   * @returns {Promise<QuestionnaireGroup[]>}
   */
  public loadQuestionnaireGroups(filter?: QuestionnaireGroup.Filter, opts?: QuestionnaireGroup.Opts): Promise<{rows: QuestionnaireGroup[], total: number}> {
    const query: any = {filter: JSON.stringify(filter) || '{}', opts: JSON.stringify(opts) || '{}'};
    const basePath = `${this.getQuestionnaireGroupApiUrl()}?${decodeURI(new URLSearchParams(query).toString())}`;

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers: this.getAuthHeaders()})
        .subscribe((group: any) => {
            const rows = group.group || group.rows || group || [];
            const total = group.total || 0;
            resolve({rows, total});
          },
          error => {
            reject(error);
          });
    });
  }

  /**
   * Loads questionnaire group by id
   *
   * @param {string} pdfFormId
   * @returns {Promise<PdfForm>}
   */
  public loadQuestionnaireGroup(uid: string): Promise<QuestionnaireGroup> {
    const basePath = this.getQuestionnaireGroupApiUrl(`/${uid}`);

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers: this.getAuthHeaders()})
        .subscribe(
          (group: any) => resolve(group.group || group || []),
          error => reject(error)
        );
    });
  }


  /**
   * Creates a questionnaire group
   *
   * @param {QuestionnaireGroup} questionnaireGroup
   * @returns {Promise<QuestionnaireGroup>}
   */
  public async createQuestionnaireGroup(questionnaireGroup: {uid: string, name: Translation, questionnaires: any[]}): Promise<QuestionnaireGroup> {
    const basePath = this.getQuestionnaireGroupApiUrl();

    return new Promise((resolve, reject) => {
      this.http.post(basePath, questionnaireGroup, {headers: this.getAuthHeaders()})
        .subscribe(
          (response: any) => resolve(<QuestionnaireGroup>(response.group || response)),
          error => reject(error));
    });
  }

  /**
   * Updates the questionnaire group
   *
   * @param {QuestionnaireGroup} group
   * @returns {Promise<QuestionnaireGroup>}
   */
  public updateQuestionnaireGroup(group: QuestionnaireGroup): Promise<QuestionnaireGroup> {
    const basePath = this.getQuestionnaireGroupApiUrl(`/${group.uid}`);

    return new Promise((resolve, reject) => {
      this.http.put(basePath, group, {headers: this.getAuthHeaders()})
        .subscribe(
          () => resolve(group),
          error => reject(error));
    });
  }

  /**
   * Deletes a questionnaire group
   *
   * @param {QuestionnaireGroup} group
   * @returns {Promise<any>}
   */
  public deleteQuestionnaireGroup(group: QuestionnaireGroup): Promise<any> {
    const basePath = this.getQuestionnaireGroupApiUrl(`/${group.uid}`);

    return new Promise<void>((resolve, reject) => {
      this.http.delete(basePath, {headers: this.getAuthHeaders()})
        .subscribe(
          () => resolve(),
          error => reject(error));
    });
  }


  /**
   * Returns event emitter for when questionnaire group was saved
   */
  public onQuestionnaireGroupSaved(): EventEmitter<any> {
    return this.questionnaireGroupSavedEvent;
  }

  /**
   * Returns api url for questionnaire groups
   *
   * @param {string} suffix
   * @returns {string}
   */
  private getQuestionnaireGroupApiUrl(suffix: string = null): string {
    return this.buildUrl(configKeys.QUESTIONNAIRE_GROUP_URI_CONFIG_KEY, suffix);
  }

  /**
   * Returns url for config key. Adds auth information to url path when api is not deprecated
   *
   * @param {string} configKey
   * @returns {any}
   */
  private buildUrl(configKey: string, suffix = null) {
    let uri = this.configService.get(configKey);

    if (suffix) {
      uri = `${uri}${suffix}`;
    }

    return uri;
  }

  /**
   * Returns auth headers by auth token and tenant id
   */
  private getAuthHeaders(): any {
    const headers: any = {};
    const authToken = this.registryService.get(configKeys.SESSION_AUTH_TOKEN);
    const tenantId = this.registryService.get(configKeys.SESSION_TENANT_ID);

    if (authToken) {
      headers['X-Api-Token'] = authToken;
    }

    if (tenantId) {
      headers['X-Api-TenantId'] = `${tenantId}`;
    }

    return headers;
  }
}
