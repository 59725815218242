<div class="qa-patient-crud-overlay">
  <div class="card bg-light mb-3">
    <div class="card-header">
      <i class="fa fa-user-o"></i>
      <button class="close" (click)="close()">
        <i class="fa fa-close"></i>
      </button>
    </div>
    <div class="card-body">
      <app-patient-crud #patientCrud></app-patient-crud>
    </div>
  </div>
</div>
