<div class="qa-settings">
  <h2 class="mb-4">{{_(KEYS.SETTINGS.DELETION_PERIODS)}}</h2>

  <form>
      <div class="form-group">
          <label for="deleteClientData">{{_(KEYS.SETTINGS.PATIENT_DELETION_PERIODS)}}</label>
          <select class="form-control" id="deleteClientData" [(ngModel)]="settings.deleteClientsInDb" [ngModelOptions]="{standalone: true}">
              <option *ngFor="let period of timePeriods" value="{{period.value}}">
                {{_(period.key, period.variables)}}
              </option>
          </select>
      </div>
      <div class="form-group">
          <label for="deletePDF">{{_(KEYS.SETTINGS.EXPORT_FILE_DELETION_PERIODS)}}</label>
          <select class="form-control" id="deletePDF" [(ngModel)]="settings.deleteClientsOnDisk" [ngModelOptions]="{standalone: true}">
            <option *ngFor="let period of timePeriods" value="{{period.value}}">
              {{_(period.key, period.variables)}}
            </option>
          </select>
      </div>
    <div class="form-group">
      <label for="deleteSurveys">{{_(KEYS.SETTINGS.SURVEY_DELETION_PERIODS)}}</label>
      <select class="form-control" id="deleteSurveys" [(ngModel)]="settings.deleteSurveys" [ngModelOptions]="{standalone: true}">
        <option *ngFor="let period of surveyTimePeriods" value="{{period.value}}">
          {{_(period.key, period.variables)}}
        </option>
      </select>
    </div>
      <button type="button" class="btn btn-primary" (click)="saveSettings()">
        {{_(KEYS.SETTINGS.SAVE)}}
      </button>
  </form>
</div>
