import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Optional, Output, ViewEncapsulation} from '@angular/core';
import {Translatable, TranslationEventService, TranslationService} from '@ngmedax/translation';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';

import {QuestionnaireVariablesService} from '../../services/questionnaire-variables.service';
import {QuestionnaireEditorService} from '../../services/questionnaire-editor.service';
import {QuestionnaireStateService} from '../../services/questionnaire-state.service';
import {DomHelperService} from '../../services/dom-helper.service';
import {TEMPLATE_TO_ICON_MAPPING} from '../../../mappings';
import {TRANSLATION_EDITOR_SCOPE} from '../../../constants';
import {Question} from '../../../structure';
import {KEYS} from '../../../translation-keys';


// hack to inject decorator declarations. must occur before class declaration!
export interface AddQuestionComponent extends Translatable {}
declare const $: any;

@Component({
  selector: 'app-qa-add-question',
  templateUrl: './add-question.component.html',
  styleUrls: ['./add-question.component.css'],
  encapsulation: ViewEncapsulation.None
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class AddQuestionComponent implements AfterViewInit {
  @Output() done: EventEmitter<any> = new EventEmitter();
  @Input() hideLabel = false;
  @Input() position = null;
  @Input() cmpEl: ElementRef;
  @Input() question?: Questionnaire.Container;

  /**
   * Default structures for question types
   * @type {Question.Structure}
   */
  public questionStructure = Question.Structure;

  /**
   * Select2 options
   * @type {any}
   */
  public options: any = {};

  /**
   * This is used to render the select2 options
   */
  public elementGroups: any[] = [];

  /**
   * Element format to icon mapping
   */
  public iconMapping = TEMPLATE_TO_ICON_MAPPING;

  /**
   * Injects dependencies
   */
  public constructor(
    private editor: QuestionnaireEditorService,
    private state: QuestionnaireStateService,
    private variables: QuestionnaireVariablesService,
    private domHelper: DomHelperService,
    @Optional() private translationService: TranslationService,
    @Optional() private translationEvents: TranslationEventService
  ) {
    // adds matching icon on left side of element text
    const iconRenderer = (state) => {
      if (state.id && this.iconMapping[state.id]) {
        return $(`<span><i class="fa fa-fw ${this.iconMapping[state.id]}"></i> ${state.text.trim()}</span>`);
      }

      return state.text;
    };

    this.options = {
      width: '100%',
      placeholder: '...',
      templateResult: iconRenderer,
      templateSelection: iconRenderer
    };

    this.updateElements();
    this.translationEvents && this.translationEvents.onLocaleChanged().subscribe(() => this.updateElements());
  }

  /**
   * Adds element to copy question
   */
  public ngAfterViewInit() {
    if (this.question) {
      this.elementGroups.unshift({
        title: this._(KEYS.EDITOR.DYNAMIC),
        elements: [{
          title: this._(KEYS.EDITOR.COPY_QUESTION),
          value: 'COPY'
        }]
      });

      this.questionStructure['COPY'] = JSON.parse(JSON.stringify(this.question));
    }
  }

  /**
   * Sadly, NgbPopover does not support auto hide on click outside, so we have to implement this.
   * When Ngb is updated to a version >= 3.0.0, we can use the "autoClose" option.
   *
   * @param targetElement
   */
  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    const popover = $('ngb-popover-window');
    const select2Dropdown = $('.select2-dropdown');
    const clickInside = popover.find(targetElement).length || select2Dropdown.find(targetElement).length;
    (!clickInside) && $('ngb-popover-window').hide();
  }

  /**
   * Event for when we should add a questionnaire element.
   *
   * @param {Questionnaire.Container} question
   */
  public onAddElement(templateName: string) {
    this.done.emit();

    const template = templateName ? this.questionStructure[templateName] : '';

    if (!template) {
      return;
    }

    // clone question object
    const question = JSON.parse(JSON.stringify(template));

    // add uuid to question
    question.id = this.editor.generateUUID();

    if (question.elements) {
      for (const element of question.elements) {
        if (element.id) {
          continue;
        }

        element.id = this.editor.generateUUID();
      }
    }

    // if we have a question to copy
    if (this.question) {
      this.preservePathHashes(question);
      this.copyVariables(question, 'scoring');
      this.copyVariables(question, 'gdt');
      this.removePreservedPathHashes(question);
    }

    // add element to questionnaire
    this.state.addElement(question, this.position);

    // animate component to signalize that an element was added to the questionnaire
    (this.cmpEl) && this.domHelper.animate(this.cmpEl.nativeElement, ['fadeIn', 'animated']);
  }

  /**
   * Updates available elements
   */
  public updateElements() {
    this.elementGroups = [{
      title: this._(KEYS.EDITOR.STATIC),
      elements: [{
        title: this._(KEYS.EDITOR.INFO_TEXT),
        value: 'INFO'
      }, {
        title: this._(KEYS.EDITOR.NET_DIAGRAM),
        value: 'NET_DIAGRAM'
      }, {
        title: this._(KEYS.EDITOR.PAGE_BREAK),
        value: 'PAGE_BREAK'
      }]
    }, {
      title: this._(KEYS.EDITOR.INPUT),
      elements: [{
        title: this._(KEYS.EDITOR.NUMBER),
        value: 'NUMERIC'
      }, {
        title: this._(KEYS.EDITOR.DATE),
        value: 'DATE'
      }, {
        title: this._(KEYS.EDITOR.FREE_TEXT),
        value: 'TEXT'
      }, {
        title: this._(KEYS.EDITOR.TEXT_MULTILINE),
        value: 'TEXT_MULTILINE'
      }]
    }, {
      title: this._(KEYS.EDITOR.CHOICE),
      elements: [{
        title: this._(KEYS.EDITOR.SINGLE_CHOICE),
        value: 'SINGLE_CHOICE'
      }, {
        title: this._(KEYS.EDITOR.MULTIPLE_CHOICE),
        value: 'MULTIPLE_CHOICE'
      }, {
        title: this._(KEYS.EDITOR.QUESTION_MATRIX),
        value: 'MATRIX'
      }, {
        title: this._(KEYS.EDITOR.SCALE),
        value: 'SCALE'
      }]
    }, {
      title: this._(KEYS.EDITOR.MEDIA),
      elements: [{
        title: this._(KEYS.EDITOR.TAKE_PICTURE),
        value: 'PICTURE'
      }, {
        title: this._(KEYS.EDITOR.DRAWING),
        value: 'CANVAS'
      }, {
        title: this._(KEYS.EDITOR.SIGNATURE),
        value: 'SIGNATURE'
      }]
    }, {
      title: this._(KEYS.EDITOR.TEMPLATES),
      elements: [{
        title: this._(KEYS.EDITOR.YES_NO),
        value: 'YES_NO'
      }, {
        title: this._(KEYS.EDITOR.YES_NO_MISC),
        value: 'YES_NO_MISC'
      }]
    }];
  }

  /**
   * Copies scoring from template question to new question
   *
   * @param question
   * @private
   */
  private copyVariables(question: Questionnaire.Container, scope: string) {

    const walk = (container: Questionnaire.Container) => {
      if (container['oldPathHash']) {
        const mappedVarName = this.variables.getMappedVariableName(scope, container['oldPathHash']);

        if (mappedVarName) {
          const opts = this.variables.getMappedVariableOptions(scope, container['oldPathHash'], mappedVarName);
          opts && this.variables.addVariableMapping(scope, mappedVarName, container.pathHash, opts);
        }
      }

      container.elements && container.elements.forEach(element => walk(element));
    }

    // add path hashes
    this.state.addPathHashes(question);

    // add variables
    walk(question);
  }

  /**
   * Preserves old path hashes (if any)
   *
   * @param element
   * @private
   */
  private preservePathHashes(element: Questionnaire.Container) {
    const walk = (container: Questionnaire.Container) => {
      container.pathHash && (container['oldPathHash'] = container.pathHash);
      container.elements && container.elements.forEach(element => walk(element));
    }

    walk(element);
  }

  /**
   * Deletes old path hashes (if any)
   *
   * @param element
   * @private
   */
  private removePreservedPathHashes(element: Questionnaire.Container) {
    const walk = (container: Questionnaire.Container) => {
      container['oldPathHash'] && delete(container['oldPathHash']);
      container.elements && container.elements.forEach(element => walk(element));
    }

    walk(element);
  }
}
