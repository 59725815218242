import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Filtergroup} from '../../types';
import {configKeys} from '../questionnaire-editor.config-keys';
import {AuthUriService} from './auth-uri.service';

import {ConfigService} from '@ngmedax/config';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';


@Injectable()
export class ApiService {

  public constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private authUriService: AuthUriService) {
  }

  /**
   * Returns true if questionnaire exists
   *
   * @param {string} questionnaireId
   */
  public async hasQuestionnaire(questionnaireId: string) {
    try {
      const questionnaire = await this.loadQuestionnaire(questionnaireId);
      return !!questionnaire.questionnaire;
    } catch (error) {
      return false;
    }
  }

  /**
   * Loads questionnaire by given id
   *
   * @param {string} questionnaireId
   * @returns {Promise<{$schema: string, questionnaire: Questionnaire}>}
   */
  public loadQuestionnaire(questionnaireId: string): Promise<{$schema: string, questionnaire: Questionnaire}> {
    const basePath = this.authUriService.buildUrl(configKeys.QUESTIONNAIRE_URI_CONFIG_KEY, `/${questionnaireId}`);
    const headers = this.authUriService.getAuthHeaders();

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers})
        .subscribe(
          (questionnaire: {$schema: string; questionnaire: any}) => {

            if (!questionnaire.questionnaire) {
              reject(new Error('Invalid questionnaire format. Missing property: "questionnaire"'));
            }

            resolve(questionnaire);
          },
          error => {
            reject(error);
          });
    });
  }

  /**
   * Saves a questionnaire
   *
   * @returns {Promise<any>}
   */
  public createQuestionnaire(payload: {$schema: string, questionnaire: Questionnaire}): Promise<any> {
    payload.questionnaire.updatedAt = new Date().toJSON();

    if (!payload.questionnaire.createdAt) {
      payload.questionnaire.createdAt = new Date().toJSON();
    }

    const basePath = this.authUriService.buildUrl(configKeys.QUESTIONNAIRE_URI_CONFIG_KEY);
    const headers = this.authUriService.getAuthHeaders();

    return new Promise<void>((resolve, reject) => {
      this.http.post(basePath, payload, {headers})
        .subscribe(
          () => resolve(),
          error => reject(error));
    });
  }

  /**
   * Updates a questionnaire
   *
   * @returns {Promise<any>}
   */
  public updateQuestionnaire(payload: {$schema: string, questionnaire: Questionnaire}): Promise<any> {
    const uid = payload.questionnaire.id;

    payload.questionnaire.updatedAt = new Date().toJSON();

    if (!payload.questionnaire.createdAt) {
      payload.questionnaire.createdAt = new Date().toJSON();
    }

    const basePath = this.authUriService.buildUrl(configKeys.QUESTIONNAIRE_URI_CONFIG_KEY, `/${uid}`);
    const headers = this.authUriService.getAuthHeaders();

    return new Promise<void>((resolve, reject) => {
      this.http.put(basePath, payload, {headers})
        .subscribe(
          () => resolve(),
          error => reject(error));
    });
  }

  /**
   * Fetches number of questionnaires
   *
   * @returns {Promise<number>}
   */
  public async getNumQuestionnaires(): Promise<number> {
    const basePath = this.authUriService.buildUrl(configKeys.QUESTIONNAIRE_URI_CONFIG_KEY, '/count');
    const headers = this.authUriService.getAuthHeaders();

    return new Promise<number>((resolve, reject) => {
      this.http.get(basePath, {headers}).subscribe(
        (response: {count: number}) => resolve(response.count || 0),
        error => reject(error)
      );
    });
  }

  /**
   * Returns filter groups
   *
   * @returns {Promise<Filtergroup[]>}
   */
  public loadFiltergroups(): Promise<Filtergroup[]> {
    const basePath = this.authUriService.buildUrl(configKeys.FILTER_GROUP_URI_CONFIG_KEY, '/?filterByUser=true');
    const headers = this.authUriService.getAuthHeaders();

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers})
        .subscribe((filtergroups: any) => {
            resolve(filtergroups.filterGroup || filtergroups.filtergroup || filtergroups || []);
          },
          error => {
            reject(error);
          });
    });
  }
}
