export const NAVIGATION = {
  name: 'Lizenz Manager',
  icon: 'fa-lock',
  path: '/module/license-manager/',
  children: [{
    name: 'Liste',
    path: '/module/license-manager/grid',
    icon: 'fa-table'
  }]
};
