import {RouterModule, Routes} from '@angular/router';
import {QuestionnaireEditorComponent} from './questionnaire-editor.component';
import {permission} from './questionnaire-editor.permission';

const APP_ROUTES: Routes = [
  {
    path: 'module/questionnaire/editor',
    component: QuestionnaireEditorComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: true,
      needsPermissions: [
        permission.MYMEDAX_QUESTIONNAIRE_CREATE,
        permission.MYMEDAX_QUESTIONNAIRE_UPDATE
      ]
    }
  },
  {
    path: 'module/questionnaire/editor/:id',
    component: QuestionnaireEditorComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: true,
      needsPermissions: [
        permission.MYMEDAX_QUESTIONNAIRE_UPDATE
      ]
    }
  }
];

export const routing = RouterModule.forRoot(APP_ROUTES);
