import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Filtergroup} from '../../../types';
import {AuthUriService} from './auth-uri.service';
import {configKeys} from '../filtergroup-grid.config-keys';


@Injectable()
export class FiltergroupGridService {

  /**
   * Injects dependencies
   */
  public constructor(
    private http: HttpClient,
    private authUriService: AuthUriService) {
  }

  /**
   * Returns filter groups
   *
   * @returns {Promise<Filtergroup[]>}
   */
  public getFiltergroups(): Promise<Filtergroup[]> {
    const basePath = this.authUriService.buildUrl(configKeys.FILTER_GROUP_URI_CONFIG_KEY);

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers: this.authUriService.getAuthHeaders()})
        .subscribe((response: any) => {
            resolve(response.filterGroup || response.filtergroup || response || []);
          },
          error => {
            reject(error);
          });
    });
  }

  /**
   * Returns filter group
   *
   * @returns {Promise<Filtergroup>}
   */
  public getFiltergroup(filtergroup: Filtergroup): Promise<Filtergroup> {
    const basePath = this.authUriService.buildUrl(
      configKeys.FILTER_GROUP_URI_CONFIG_KEY,
      `/${filtergroup.id}`
    );

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers: this.authUriService.getAuthHeaders()})
        .subscribe(
          (r: any) => resolve(r.filterGroup || r.filtergroup || r || []),
          error => reject(error)
        );
    });

  }

  /**
   * Creates the given filter group
   *
   * @returns {Promise<any>}
   */
  public createFiltergroup(filtergroup: Filtergroup): Promise<Filtergroup> {
    const basePath = this.authUriService.buildUrl(configKeys.FILTER_GROUP_URI_CONFIG_KEY);

    return new Promise((resolve, reject) => {
      this.http.post(basePath, filtergroup, {headers: this.authUriService.getAuthHeaders()})
        .subscribe(
          () => resolve(filtergroup),
          error => reject(error)
        );
    });
  }

  /**
   * Updates the given filter group
   *
   * @returns {Promise<any>}
   */
  public updateFiltergroup(filtergroup: Filtergroup): Promise<Filtergroup> {
    const basePath = this.authUriService.buildUrl(
      configKeys.FILTER_GROUP_URI_CONFIG_KEY,
      `/${filtergroup.id}`
    );

    return new Promise((resolve, reject) => {
      this.http.put(basePath, filtergroup, {headers: this.authUriService.getAuthHeaders()})
        .subscribe(
          () => resolve(filtergroup),
          error => reject(error)
        );
    });
  }

  /**
   * Deletes the given filter group
   *
   * @returns {Promise<any>}
   */
  public deleteFiltergroup(filtergroup: Filtergroup): Promise<Filtergroup>  {
    const basePath = this.authUriService.buildUrl(
      configKeys.FILTER_GROUP_URI_CONFIG_KEY,
      `/${filtergroup.id}`
    );

    return new Promise((resolve, reject) => {
      this.http.delete(basePath, {headers: this.authUriService.getAuthHeaders()})
        .subscribe(
          () => resolve(filtergroup),
          error => reject(error)
        );
    });
  }
}
