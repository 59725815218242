/**
 * Converter
 */
export class Converter {

  /**
   * Converts given data to Uint8Array
   *
   * @param {Buffer | Uint8Array | string} data
   * @returns {Buffer | Uint8Array | string}
   */
  public toUint8Array(data: Buffer | Uint8Array | string) {
    if (typeof data === 'object' && data.constructor.name === 'Uint8Array') {
      return data;
    }

    if (typeof data === 'object' && data.constructor.name === 'Buffer') {
      return new Uint8Array(data);
    }

    if (this.isBase64(data)) {
      data = this.base64ToBuffer(<any>data);
      return new Uint8Array(data);
    }

    if (typeof data === 'string') {
      return new Uint8Array(Buffer.from(data));
    }

    throw new Error('Unable to convert. Invalid type given');
  }

  /**
   * Converts given data to base64
   *
   * @param {Buffer | Uint8Array | string} data
   * @returns string
   */
  public toBase64(data: Buffer | Uint8Array | string): string {
    return Buffer.from(<any>data).toString('base64');
  }

  /**
   * Converts given base64 string to buffer
   *
   * @param {string} base64
   * @returns {Buffer}
   */
  public base64ToBuffer(base64: string) {
    return Buffer.from(base64, 'base64');
  }

  /**
   * Checks if given data is base64
   * @param {any} data
   * @returns {boolean}
   */
  private isBase64(data: any) {
    return typeof data === 'string' && Buffer.from(data, 'base64').toString('base64') === data;
  }
}
