import {RouterModule, Routes} from '@angular/router';
import {ConfigGeneratorCrudComponent} from './components/config-generator-crud.component';

const APP_ROUTES: Routes = [
  {
    path: 'module/config-generator',
    component: ConfigGeneratorCrudComponent,
    canActivate: ['CoreGuard'],
    data: {needsLogin: false},
  },
  {
    path: 'module/config-generator/full-screen',
    component: ConfigGeneratorCrudComponent,
    canActivate: ['CoreGuard'],
    data: {needsLogin: false},
  },
  {
    path: 'module/config-generator/portainer',
    component: ConfigGeneratorCrudComponent,
    canActivate: ['CoreGuard'],
    data: {needsLogin: false},
  },
  {
    path: 'module/config-generator/portainer/full-screen',
    component: ConfigGeneratorCrudComponent,
    canActivate: ['CoreGuard'],
    data: {needsLogin: false},
  },
];

export const routing = RouterModule.forRoot(APP_ROUTES);
