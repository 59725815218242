import {Component, Optional} from '@angular/core';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {DeviceService} from '../services/device.service';
import {TRANSLATION_CRUD_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';
import {ConfigService} from '../../../../config';


// hack to inject decorator declarations. must occur before class declaration!
export interface DeviceFormComponent extends Translatable {}

@Component({
  selector: 'app-device-form',
  templateUrl: './device-form.component.html',
  styleUrls: ['./device-form.component.css']
})
@Translatable({scope: TRANSLATION_CRUD_SCOPE, keys: KEYS})
export class DeviceFormComponent {
  /**
   * Data for which we want a qr code image
   */
  public qrData: string;

  /**
   * Link to ios app
   */
  public iosAppLink: string;

  /**
   * Link to android app
   */
  public androidAppLink: string;

  /**
   * Android app store link
   */
  public androidAppStoreLink: string;

  /**
   * Injects dependencies and initializes qr code url
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    private config: ConfigService,
    private ds: DeviceService
  ) {
    this.qrData = ds.getQrUrl();
    this.iosAppLink = this.config.get('downloadLinks.fillApp.ios');
    this.androidAppLink = this.config.get('downloadLinks.fillApp.android');
    this.androidAppStoreLink = this.config.get('downloadLinks.fillApp.androidStore');
  }

  /**
   * Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';
}
