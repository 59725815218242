<div class="qa-grid">
  <h2 class="mb-4">{{_(KEYS.GRID.QUESTIONNAIRE_GROUPS)}}</h2>
  <div class="card mb-5">
    <div class="card-body">
      <div class="card-title">
        <div class="btn-toolbar justify-content-between">
          <div class="justify-content-between qa-lh-2p5">
            <i class="fa fa-search"></i>
            <h5 class="d-inline">{{_(KEYS.GRID.SEARCH)}}</h5>
          </div>
          <button class="btn btn-primary d-inline-block" (click)="isSearchCollapsed = !isSearchCollapsed">
            <i class="fa" [ngClass]="{'fa-arrow-down': isSearchCollapsed, 'fa-arrow-up': !isGridCollapsed}"></i>
          </button>
        </div>
      </div>
      <div class="card-text" [ngbCollapse]="isSearchCollapsed">
        <div class="qa-grid-search mt-2 mb-2">
          <div class="input-group qa-grid-search-patient">
            <span class="input-group-addon">{{_(KEYS.GRID.NAME)}}:</span>
            <input class="form-control" [(ngModel)]="filter.name"
                   (paste)="onFilterChange()" (change)="onFilterChange()" (keydown)="onFilterChange()">
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="card-title">
        <div class="btn-toolbar justify-content-between">
          <div class="justify-content-between qa-lh-2p5">
            <i class="fa fa-copy"></i>
            <h5 class="d-inline">{{_(KEYS.GRID.QUESTIONNAIRE_GROUPS)}}</h5>
          </div>
          <button class="btn btn-primary d-inline-block" (click)="isGridCollapsed = !isGridCollapsed">
            <i class="fa" [ngClass]="{'fa-arrow-down': isGridCollapsed, 'fa-arrow-up': !isGridCollapsed}"></i>
          </button>
        </div>
      </div>
      <div class="card-text" [ngbCollapse]="isGridCollapsed">
        <table class="table">
          <thead>
          <tr>
            <th class="id-col">{{_(KEYS.GRID.ID)}}</th>
            <th>{{_(KEYS.GRID.NAME)}}</th>
            <th>{{_(KEYS.GRID.QUESTIONNAIRES)}}</th>
            <th>{{_(KEYS.GRID.CREATED_AT)}}</th>
            <th>{{_(KEYS.GRID.UPDATED_AT)}}</th>
            <th>{{_(KEYS.GRID.OPTIONS)}}</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let group of groups; let pos = index;">
            <tr [ngClass]="{'qa-tr-odd': pos % 2 === 0}">
              <td class="id-col">
                <span ngbTooltip="{{group.uid}}">{{group.uid | truncate: 5}}</span>
              </td>
              <td>
                <ng-container *ngIf="group?.name && group?.name[locale]">
                  {{group.name[locale]}}
                </ng-container>
              </td>
              <td>
                <ng-container *ngIf="group.questionnaires">
                  <ul>
                    <ng-container *ngFor="let questionnaire of group.questionnaires">
                      <li *ngIf="questionnaire?.meta?.title && questionnaire?.meta?.title[locale]">
                        {{questionnaire.meta.title[locale]}}
                      </li>
                    </ng-container>
                  </ul>
                </ng-container>
              </td>
              <td>
                {{group.createdAt | datex:dateFormat}}
              </td>
              <td>
                {{group.updatedAt | datex:dateFormat}}
              </td>
              <td>
                <div class="d-inline-block nowrap">
                  <div
                    class="btn btn-primary mr-1"
                    (click)="onEditGroup(group)"
                    data-toggle="tooltip" data-placement="top"
                    [title]="_(KEYS.GRID.CHANGE)"
                  >
                    <i class="fa fa-edit qa-cursor-pointer"></i>
                  </div>
                  <div
                    class="btn btn-danger"
                    (click)="onDeleteGroup(group)"
                    data-toggle="tooltip" data-placement="top"
                    [title]="_(KEYS.GRID.DELETE)">
                    <i class="fa fa-trash qa-cursor-pointer"></i>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
        <div class="btn-toolbar justify-content-between mt-2 mb-2">
          <div class="justify-content-between">
            <div class="input-group">
              <div class="input-group qa-num-per-page">
                <select class="form-control custom-select" [(ngModel)]="displayPerPage" (change)="onPagingChange()">
                  <option value="2">2</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
                <span class="input-group-addon">{{_(KEYS.GRID.ENTRIES)}}</span>
              </div>
              <button
                class="btn btn-primary ml-1"
                (click)="onCreateGroup()"
                data-toggle="tooltip"
                data-placement="top"
                title="{{_(KEYS.GRID.CREATE)}}">
                <i class="fa fa-plus"></i>
              </button>
            </div>
          </div>
          <ngb-pagination
            [pageSize]="displayPerPage"
            [maxSize]="9"
            [rotate]="true"
            [(page)]="gridPageNumber"
            [collectionSize]="total"
            (pageChange)="onPagingChange()">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
