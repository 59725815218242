<div class="qa-grid">
  <h2 class="mb-4">{{_(KEYS.LICENSE_MANAGER.GRID.LICENSES)}}</h2>
  <div class="card mb-5">
    <div class="card-body">
      <div class="card-title">
        <div class="btn-toolbar justify-content-between">
          <div class="justify-content-between qa-lh-2p5">
            <i class="fa fa-search"></i>
            <h5 class="d-inline">{{_(KEYS.LICENSE_MANAGER.GRID.SEARCH)}}</h5>
          </div>
          <button class="btn btn-primary d-inline-block" (click)="isSearchCollapsed = !isSearchCollapsed">
            <i class="fa" [ngClass]="{'fa-arrow-down': isSearchCollapsed, 'fa-arrow-up': !isGridCollapsed}"></i>
          </button>
        </div>
      </div>
      <div class="card-text" [ngbCollapse]="isSearchCollapsed">
        <div class="qa-grid-search mt-2 mb-2">
          <div class="input-group qa-grid-search-name">
            <span class="input-group-addon">{{_(KEYS.DEFAULT.LICENSEE)}}:</span>
            <input class="form-control" [(ngModel)]="filter.name"
                   (paste)="onFilterChange()" (change)="onFilterChange()" (keydown)="onFilterChange()">
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="card-title">
        <div class="btn-toolbar justify-content-between">
          <div class="justify-content-between qa-lh-2p5">
            <i class="fa fa-copy"></i>
            <h5 class="d-inline">{{_(KEYS.LICENSE_MANAGER.GRID.LICENSES)}}</h5>
          </div>
          <button class="btn btn-primary d-inline-block" (click)="isGridCollapsed = !isGridCollapsed">
            <i class="fa" [ngClass]="{'fa-arrow-down': isGridCollapsed, 'fa-arrow-up': !isGridCollapsed}"></i>
          </button>
        </div>
      </div>
      <div class="card-text" [ngbCollapse]="isGridCollapsed">
        <table class="table">
          <thead>
          <tr>
            <th class="id-col">{{_(KEYS.LICENSE_MANAGER.GRID.ID)}}</th>
            <th>{{_(KEYS.DEFAULT.LICENSEE)}}</th>
            <th>{{_(KEYS.DEFAULT.PARTNER)}}</th>
            <th>{{_(KEYS.LICENSE_MANAGER.GRID.OPTIONS)}}</th>
            <th>{{_(KEYS.LICENSE_MANAGER.GRID.VALID_UNTIL)}}</th>
            <th>{{_(KEYS.LICENSE_MANAGER.GRID.CREATED_BY)}}</th>
            <th>{{_(KEYS.LICENSE_MANAGER.GRID.UPDATED_BY)}}</th>
            <th>{{_(KEYS.LICENSE_MANAGER.GRID.CREATED_AT)}}</th>
            <th>{{_(KEYS.LICENSE_MANAGER.GRID.UPDATED_AT)}}</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let license of licenses; let pos = index;">
            <tr [ngClass]="{'qa-tr-odd': pos % 2 === 0}">
              <td class="id-col">
                <span ngbTooltip="{{license.id}}">{{license.id | truncate: 5}}</span>
              </td>
              <td>
                {{license.name}}
              </td>
              <td>
                {{license.partner || 'myMedax'}}
              </td>
              <td>
                <button class="btn btn-sm btn-primary" (click)="onOpenLicense(license)" *ngIf="!isUnlocked">
                  <i class="fa fa-info-circle"></i>
                </button>

                <button class="btn btn-sm btn-primary" (click)="onOpenLicense(license)" *ngIf="isUnlocked">
                  <i class="fa fa-edit"></i>
                </button>

                <button class="btn btn-sm btn-danger ml-1" (click)="onDeleteLicense(license)" *ngIf="isUnlocked">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
              <td style="min-width: 135px;">
                {{license.validUntil | datex:dateFormatYMD}}
              </td>
              <td>
                {{license.createdBy || '?'}}
              </td>
              <td>
                {{license.updatedBy || '?'}}
              </td>
              <td style="min-width: 135px;">
                {{license.createdAt | datex:dateFormat}}
              </td>
              <td style="min-width: 135px;">
                {{license.updatedAt | datex:dateFormat}}
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
        <div class="btn-toolbar justify-content-between mt-2 mb-2">
          <div class="justify-content-between">
            <div class="input-group">
              <div class="input-group qa-num-per-page">
                <select class="form-control custom-select" [(ngModel)]="displayPerPage" (change)="onPagingChange()">
                  <option value="2">2</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
                <span class="input-group-addon">{{_(KEYS.LICENSE_MANAGER.GRID.ENTRIES)}}</span>
              </div>

              <button
                class="btn btn-primary ml-1"
                (click)="onCreateLicense()"
                data-toggle="tooltip"
                data-placement="top"
                title="Lizenz erstellen"
                [attr.disabled]="isUnlocked ? null: true">
                <i class="fa fa-plus"></i>
              </button>
            </div>
          </div>
          <ngb-pagination
            [pageSize]="displayPerPage"
            [maxSize]="9"
            [rotate]="true"
            [(page)]="gridPageNumber"
            [collectionSize]="total"
            (pageChange)="onPagingChange()">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>

  <br>
  <div *ngIf="!isPubKeyMode">
    <div class="input-group" style="width: 250px;" *ngIf="!isUnlocked">
      <input type="password" class="form-control" placeholder="Entsperren..." [(ngModel)]="keyPassword">
      <button class="btn btn-primary" type="button" (click)="onUnlock()">
        <i class="fa fa-lock"></i>
      </button>
    </div>

    <div class="input-group" style="width: 250px;" *ngIf="isUnlocked">
      <button class="btn btn-primary" type="button" (click)="onLock()">
        Entsperrt
        <i class="fa fa-unlock"></i>
      </button>
    </div>
  </div>
</div>
