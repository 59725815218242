export const TRANSLATION_SCOPE = 'settingsThemingModule';
export const TRANSLATION_THEMING_SCOPE = [TRANSLATION_SCOPE, 'theming'];
export const DEMO_QUESTIONNAIRE = {
  "$schema": "questionnaire-schema.json",
  "questionnaire": {
    "meta": {
      "tags": [],
      "author": "admin@mymedax.de",
      "title": {
        "de_DE": "Demo"
      },
      "description": {
        "de_DE": ""
      },
      "type": "default",
      "options": {
        "gdt": {
          "addQuestionnaireTitle": true
        },
        "fill": {
          "skipList": true
        },
        "mail": {
          "user": {
            "internalPdf": true,
            "filledPdf": true
          },
          "filler": {
            "internalPdf": false,
            "filledPdf": true
          }
        },
        "excel": {
          "omitPatientData": false
        },
        "variables": {
          "gdt": {
            "6304": {
              "5f2d9b633f39dfe73ceb0da2804ed14c2b293aa5949376f81279d816049d8b53": {
                "keepMe": true
              }
            }
          },
          "upload": {},
          "inline": {},
          "fhir": {},
          "client": {}
        },
        "variablesMapping": [],
        "pdf": {
          "forms": []
        }
      },
      "asset": {}
    },
    "status": "draft",
    "revision": 1,
    "softwareVersion": "1.6.0",
    "tenantId": 11,
    "questions": [
      {
        "id": "dd7f9fc7-a7b4-4432-b391-5218d93a7d28",
        "dataType": "none",
        "displayType": "group",
        "omitContainer": true,
        "options": {
          "mandatory": false,
          "active": true
        },
        "title": {
          "de_DE": ""
        },
        "description": {
          "type": "html",
          "text": {
            "de_DE": ""
          }
        },
        "elements": [
          {
            "id": "53d8c63c-1fbc-4602-bcc2-3a7b47ddba0a",
            "format": "info",
            "dataType": "none",
            "displayType": "none",
            "title": {
              "de_DE": "<p>Demo</p>"
            },
            "description": {
              "type": "html",
              "text": {
                "de_DE": "<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore&nbsp;</p>"
              }
            },
            "options": {
              "mandatory": false,
              "active": true,
              "validation": {
                "regex": "",
                "hint": {
                  "de_DE": ""
                }
              }
            }
          },
          {
            "id": "278a208c-690e-43dd-92ae-6599b0e104a8",
            "format": "singleChoice",
            "extra": "yesNoMisc",
            "dataType": "boolean",
            "displayType": "radio",
            "title": {
              "de_DE": "<p>Single Choice</p>"
            },
            "description": {
              "type": "html",
              "text": {
                "de_DE": ""
              }
            },
            "elements": [
              {
                "id": "ce18f028-8dd3-7ea8-ebcd-2f40fc1974a6",
                "title": {
                  "de_DE": "Ja"
                }
              },
              {
                "id": "8c4e38b0-2f14-e4cf-f97d-4a4832ff8eef",
                "title": {
                  "de_DE": "Nein"
                }
              },
              {
                "id": "6d93e432-a38f-32ea-b542-2c690145a2c4",
                "undeletable": true,
                "title": {
                  "de_DE": "Sonstiges"
                }
              },
              {
                "id": "7d93e432-a38f-32ea-b542-2c690145a2c4",
                "dataType": "string",
                "displayType": "text",
                "undeletable": true,
                "title": {
                  "de_DE": "Bitte beschreiben"
                },
                "conditions": {
                  "show": [
                    "2f2bf84ae335f2c5143e2cc28ddf5bf743212fb59ef62b0cb01a3b68632b1850"
                  ]
                }
              }
            ],
            "options": {
              "mandatory": false,
              "active": true
            }
          },
          {
            "id": "832bafe2-e078-4223-a07a-33b4d13d916a",
            "format": "multipleChoice",
            "dataType": "boolean",
            "displayType": "checkbox",
            "title": {
              "de_DE": "<p>Multiple Choice</p>"
            },
            "description": {
              "type": "html",
              "text": {
                "de_DE": ""
              }
            },
            "elements": [
              {
                "id": "eb337633-ad3f-4232-bd49-adcbd13e20bc",
                "title": {
                  "de_DE": "Choice 1"
                }
              },
              {
                "id": "4e003d18-ffc1-4803-84bb-7dda8c56e931",
                "title": {
                  "de_DE": "Choice 2"
                }
              }
            ],
            "options": {
              "mandatory": false,
              "active": true
            }
          }
        ]
      },
      {
        "id": "2b0dea70-42a8-4943-b4fe-40b2863ccca3",
        "dataType": "none",
        "displayType": "group",
        "omitContainer": true,
        "options": {
          "mandatory": false,
          "active": true
        },
        "title": {
          "de_DE": ""
        },
        "description": {
          "type": "html",
          "text": {
            "de_DE": ""
          }
        },
        "elements": [
          {
            "id": "d14f672e-03c1-438e-ae34-e927e1e98d46",
            "format": "matrix",
            "dataType": "boolean",
            "displayType": "radio",
            "title": {
              "de_DE": "<p>Matrix</p>"
            },
            "description": {
              "type": "html",
              "text": {
                "de_DE": ""
              }
            },
            "options": {
              "multipleAnswers": false,
              "invertAxes": false,
              "mandatory": false,
              "active": true
            },
            "elements": [
              {
                "omitContainer": true,
                "title": [
                  {
                    "de_DE": "row 1",
                    "id": "3198d38d-87e0-4691-aa8e-2702303e0e12"
                  },
                  {
                    "de_DE": "row 2",
                    "id": "0ab06d04-4089-436f-9a60-cbfee1cb097c"
                  }
                ],
                "elements": [
                  {
                    "id": "b7ff6e0d-b76e-4c38-ad67-66e3912b8399",
                    "title": {
                      "de_DE": "col 1"
                    }
                  },
                  {
                    "id": "50aebad2-c617-4833-9424-bf145b13a627",
                    "title": {
                      "de_DE": "col 2"
                    }
                  }
                ],
                "appendix": [
                  {
                    "de_DE": "",
                    "id": "3198d38d-87e0-4691-aa8e-2702303e0e12"
                  },
                  {
                    "de_DE": "",
                    "id": "0ab06d04-4089-436f-9a60-cbfee1cb097c"
                  }
                ]
              }
            ]
          },
          {
            "id": "a6b86e54-42f4-4516-b4e4-12ff4748c139",
            "format": "numeric",
            "dataType": "number",
            "displayType": "text",
            "title": {
              "de_DE": "<p>Number</p>"
            },
            "description": {
              "type": "html",
              "text": {
                "de_DE": ""
              }
            },
            "options": {
              "min": 0,
              "max": 300,
              "steps": 1,
              "unit": {
                "de_DE": "Units"
              },
              "mandatory": false,
              "active": true
            }
          },
          {
            "id": "2c1edd2b-c0a8-486e-a0ad-bb589e35b3f6",
            "format": "date",
            "dataType": "string",
            "displayType": "text",
            "title": {
              "de_DE": "<p>Date</p>"
            },
            "description": {
              "type": "html",
              "text": {
                "de_DE": ""
              }
            },
            "options": {
              "mandatory": false,
              "active": true,
              "fullDate": true
            }
          },
          {
            "id": "86a5f28e-f8e1-4602-ba22-5c6a26186413",
            "format": "text",
            "dataType": "string",
            "displayType": "text",
            "title": {
              "de_DE": "<p>Text</p>"
            },
            "description": {
              "type": "html",
              "text": {
                "de_DE": ""
              }
            },
            "assets": {
              "images": []
            },
            "options": {
              "validation": {
                "regex": "",
                "mod": "",
                "hint": {
                  "de_DE": ""
                }
              },
              "mandatory": false,
              "active": true
            }
          }
        ]
      }
    ],
    "updatedAt": "2024-05-03T15:36:10.327Z",
    "createdAt": "2024-05-03T15:36:10.327Z",
    "id": "77838450-f0d5-4494-bc62-15f1a9f1f13c"
  }
};
