export const configKeys = {
  /**
   * Config key for patient api uri
   * @type {string}
   */
  PATIENT_URI_CONFIG_KEY: 'apis.patient.uri',

  /**
   * Config key for mobile api uri
   * @type {string}
   */
  MOBILE_URI_CONFIG_KEY: 'apis.mobile.uri',

  /**
   * Config key for mobile frontend uri
   * @type {string}
   */
  MOBILE_FRONTEND_URI_CONFIG_KEY: 'mobile.frontend.uri',

  /**
   * Config key for questionnaire api uri
   * @type {string}
   */
  QUESTIONNAIRE_URI_CONFIG_KEY: 'apis.questionnaire.uri',

  /**
   * Config key for survey mail api uri
   * @type {string}
   */
  SURVEY_MAIL_URI_CONFIG_KEY: 'apis.survey.mail.uri',

  /**
   * Config key for survey uri
   * @type {string}
   */
  SURVEY_API_URI_CONFIG_KEY: 'apis.survey.uri',

  /**
   * Config key for session auth token
   * @type {string}
   */
  SESSION_AUTH_TOKEN: 'session.authToken',

  /**
   * Config key for session tenant id
   * @type {string}
   */
  SESSION_TENANT_ID: 'session.tenantId'
};
