import {Injectable} from '@angular/core';
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
import {ConfigService} from '@ngmedax/config';
import {Asset} from '../../../types';
import {AuthService} from './auth.service';
import {Subject} from 'rxjs';


@Injectable()
export class AssetApiService {
  /**
   * Asset server api base uri
   */
  private readonly apiBaseUri: string;

  /**
   * Asset server cdn base uri
   */
  private readonly cdnBaseUri: string;

  /**
   * Injects dependencies and initializes base uri
   *
   * @param config
   */
  public constructor(
    private config: ConfigService,
    private auth: AuthService,
    private http: HttpClient
  ) {
    this.apiBaseUri = this.config.get('apis.asset.uri') || '';
    this.cdnBaseUri = this.config.get('apis.asset.cdn') || '';
    console.log('asset: using api uri:', this.apiBaseUri);
    console.log('asset: using cdn uri:', this.cdnBaseUri);
  }

  /**
   * Returns list of bucket id's
   *
   * @param bucketId
   */
  public async getBuckets(): Promise<string[]> {
    const url = `${this.apiBaseUri}/bucket/`;
    const response = <Asset.Response>await this.http.get(url, {headers: this.auth.getAuthHeaders()}).toPromise();
    return this.handleResponse(response, 'bucket');
  }


  /**
   * Returns bucket by given bucket id and optional filter
   *
   * @param bucketId
   * @param filter
   */
  public async getBucket(bucketId: string, filter: Asset.Request.Filter = null): Promise<Asset.Bucket> {
    const url = `${this.apiBaseUri}/bucket/${bucketId}` + (filter ? `?filter=${JSON.stringify(filter)}` : '');
    const response = <Asset.Response>await this.http.get(url, {headers: this.auth.getAuthHeaders()}).toPromise();
    return this.handleResponse(response, 'bucket');
  }

  /**
   * Creates a bucket by given bucket id
   *
   * @param bucketId
   */
  public async createBucket(bucketId: string): Promise<Asset.Bucket> {
    const url = `${this.apiBaseUri}/bucket/${bucketId}`;
    const response = <Asset.Response>await this.http.put(url, {}, {headers: this.auth.getAuthHeaders()}).toPromise();
    return this.handleResponse(response, 'bucket');
  }

  /**
   * Removes a bucket by given bucket id
   *
   * @param bucketId
   */
  public async removeBucket(bucketId: string): Promise<Asset.Bucket> {
    const url = `${this.apiBaseUri}/bucket/${bucketId}`;
    const response = <Asset.Response>await this.http.delete(url, {headers: this.auth.getAuthHeaders()}).toPromise();
    return this.handleResponse(response, 'bucket');
  }

  /**
   * Copies a bucket from source to target bucket id
   *
   * @param {string} bucketId
   * @param {string} targetBucketId
   */
  public async copyBucket(bucketId: string, targetBucketId: string): Promise<Asset.Bucket> {
    const url = `${this.apiBaseUri}/bucket/${bucketId}/copy/${targetBucketId}`;
    const response = <Asset.Response>await this.http.put(url, null, {headers: this.auth.getAuthHeaders()}).toPromise();
    return this.handleResponse(response, 'bucket');
  }

  /**
   * Moves a bucket from source to target bucket id
   *
   * @param {string} bucketId
   * @param {string} targetBucketId
   */
  public async moveBucket(bucketId: string, targetBucketId: string): Promise<Asset.Bucket> {
    const url = `${this.apiBaseUri}/bucket/${bucketId}/move/${targetBucketId}`;
    const response = <Asset.Response>await this.http.put(url, null, {headers: this.auth.getAuthHeaders()}).toPromise();
    return this.handleResponse(response, 'bucket');
  }

  /**
   * Returns bucket files by given bucket id and optional filter
   *
   * @param bucketId
   * @param filter
   */
  public async getBucketFiles(bucketId, filter: Asset.Request.Filter = null): Promise<Asset.Bucket.File[]> {
    const bucket = await this.getBucket(bucketId, filter);
    return bucket.files || [];
  }

  /**
   * Returns file meta info by given bucket id and file name
   *
   * @param bucketId
   * @param fileName
   */
  public async getBucketFile(bucketId: string, fileName: string): Promise<Asset.Bucket.File> {
    const url = `${this.apiBaseUri}/bucket/${bucketId}/${fileName}`;
    const response = <Asset.Response>await this.http.get(url, {headers: this.auth.getAuthHeaders()}).toPromise();
    return this.handleResponse(response, 'file');
  }

  /**
   * Returns file content by given bucket id and file name
   *
   * @param bucketId
   * @param fileName
   */
  public async getBucketFileContent(bucketId: string, fileName: string): Promise<any> {
    const url = `${this.cdnBaseUri}/${bucketId}/${fileName}`;
    return await this.http.get(url, {headers: this.auth.getAuthHeaders()}).toPromise();
  }
  /**
   * Removes bucket file by given bucket id and file name
   *
   * @param bucketId
   * @param fileName
   */
  public async removeBucketFile(bucketId: string, fileName: string): Promise<Asset.Bucket.File> {
    const url = `${this.apiBaseUri}/bucket/${bucketId}/${fileName}`;
    const response = <Asset.Response>await this.http.delete(url, {headers: this.auth.getAuthHeaders()}).toPromise();
    return this.handleResponse(response, 'file');
  }

  /**
   * Uploads a bucket file by given bucket id and file
   *
   * @param bucketId
   * @param file
   */
  public uploadBucketFile(bucketId: string, file: File, url: string = null): Asset.Bucket.File.Upload {
    url = url || `${this.apiBaseUri}/bucket/${bucketId}/`;

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    const headers = {reportProgress: true, headers: this.auth.toHttpHeaders(this.auth.getAuthHeaders())};
    const request = new HttpRequest('POST', url, formData, headers);
    const progress = new Subject<number>();

    const promise: Promise<void> = new Promise((resolve, reject) => {
      this.http.request(request).subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round(100 * event.loaded / event.total);
            progress.next(percentDone);
          }

          if (event instanceof HttpResponse) {
            progress.complete();

            const response = <Asset.Response>(typeof event.body === 'object' ? event.body : {});

            try {
              this.handleResponse(response, 'bucket');
              resolve();
            } catch (error) {
              reject(error);
            }
          }
        },
        error => reject(error));
    });

    return {progress: progress.asObservable(), promise, fileName: file.name};
  }

  /**
   * Uploads bucket files by given bucket id and set of files
   *
   * @param bucketId
   * @param files
   */
  public uploadBucketFiles(bucketId: string, files: FileList): Asset.Bucket.File.Uploads {
    const status = {};

    for (let i = 0; i < files.length; i++) {
      const file: File = files[i];
      status[file.name] = this.uploadBucketFile(bucketId, file);
    }

    return status;
  }


  /**
   * Uploads and unpacks a zip file in a bucket by given bucket id and file
   *
   * @param bucketId
   * @param file
   */
  public uploadBucketZip(bucketId: string, file: File): Asset.Bucket.File.Upload {
    const url = `${this.apiBaseUri}/bucket/${bucketId}/zip`;
    return this.uploadBucketFile(bucketId, file, url);
  }

  /**
   * Downloads bucket zip
   *
   * @param bucketId
   * @param fileName
   * @param files
   */
  public downloadBucketZip(
    bucketId: string,
    fileName: string,
    files: {name: string, content: string}[] = []
  ): Asset.Bucket.File.Download {
    const url = `${this.apiBaseUri}/bucket/${bucketId}/zip/download`;
    const body = {files};
    const responseType = 'blob' as 'json';
    const init = {reportProgress: true, headers: this.auth.toHttpHeaders(this.auth.getAuthHeaders()), responseType};
    const request = new HttpRequest('POST', url, body, init);
    const progress = new Subject<number>();

    const promise: Promise<void> = new Promise((resolve, reject) => {
      this.http.request(request).subscribe(
        event => {
          if (event.type === HttpEventType.DownloadProgress) {
            progress.next(event.loaded);
          }

          if (event instanceof HttpResponse) {
            resolve();
            const response: Blob = <Blob><any>event.body;
            progress.complete();

            if ((<any>navigator).msSaveBlob) {
              // IE 10+
              (<any>navigator).msSaveBlob(response, fileName);
            } else {
              const downloadLink = document.createElement('a');
              downloadLink.href = window.URL.createObjectURL(response);

              (fileName) && downloadLink.setAttribute('download', fileName);
              document.body.appendChild(downloadLink);
              downloadLink.click();

              window.URL.revokeObjectURL(url);
              downloadLink.remove();
            }
          }
        },
        error => reject(error));
    });

    return {progress: progress.asObservable(), promise, fileName};
  }

  /**
   * Returns link to download bucket file by given bucket id and file name
   *
   * @param bucketId
   * @param fileName
   */
  public getBucketFileLink(bucketId: string, fileName: string): string {
    return `${this.cdnBaseUri}/${bucketId}/${fileName}`;
  }

  /**
   * Handles response
   *
   * @param response
   * @param property
   */
  private handleResponse(response: Asset.Response, property: string): any {
    if (response.success) {
      return response[property] || null;
    }

    throw new Error(`Request failed. Message from server was: ${response.message || 'None received!'}`);
  }
}
