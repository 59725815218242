import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy,
  OnInit, Optional, ViewChild
} from '@angular/core';
import {Subscription} from 'rxjs';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {QuestionnaireStateService} from '../../../services/questionnaire-state.service';
import {TRANSLATION_EDITOR_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';

declare const $: any;


interface ConditionElement {
  selectedQuestion: Questionnaire.Container;
  selectedAnswer: Questionnaire.Container;
  editMode: boolean;
}

// hack to inject decorator declarations. must occur before class declaration!
export interface ConditionsComponent extends Translatable {}

@Component({
  selector: 'app-qa-conditions',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './conditions.component.html',
  styleUrls: ['./conditions.component.css']
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class ConditionsComponent implements OnInit, OnDestroy {
  @Input() question: Questionnaire.Container;
  @ViewChild('qaCondition') conditionRef: ElementRef;

  public questions: Questionnaire.Container[];
  public conditionElements: ConditionElement[] = [];
  private subscriptions: Subscription[] = [];
  private pathHashMap: any;

  /**
   * Locale for questionnaire. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    private questionnaireStateService: QuestionnaireStateService,
    private ref: ChangeDetectorRef) {
  }

  /**
   * Initializes conditions
   */
  public ngOnInit() {
    // subscribe to events which should trigger change detection
    this.subscriptions = [
      this.questionnaireStateService.onAddElement.subscribe(() => this.updateConditions()),
      this.questionnaireStateService.onDeleteElement.subscribe(() => this.updateConditions()),
      this.questionnaireStateService.onUpdateElement.subscribe(() => this.updateConditions())
    ];

    // get questions of questionnaire (we need this to render the dependency dropdowns)
    this.questions = this.questionnaireStateService.getQuestionnaire().questions;

    // get path hash map by state service
    this.pathHashMap = this.questionnaireStateService.getPathHashMap();

    // early bailout if this question has no condition
    if (!this.question.conditions || !this.question.conditions.show) {
      return;
    }

    // conditions is a string? convert it to an array
    if (typeof this.question.conditions.show === 'string') {
      this.question.conditions.show = [<string>this.question.conditions.show];
    }

    for (const pathHash of this.question.conditions.show) {
      // remove path hash from conditions and skip if element not found by path hash
      if (!this.pathHashMap[pathHash]) {
        this.question.conditions.show.splice(this.question.conditions.show.indexOf(pathHash), 1);

        console.warn(
          'condition removed! no element found by path hash: ' + pathHash,
          '\nsource:', this.question
        );

        continue;
      }

      // get question path hash (outermost element with a path hash)
      const questionPathHash = this.getQuestionPathHash(pathHash);

      // skip if no question element found by path hash
      if (!questionPathHash || !this.pathHashMap[questionPathHash] || questionPathHash === pathHash) {
        console.warn('no parent found by path hash: ' + questionPathHash, 'source:', this.question);
        continue;
      }

      const condition: ConditionElement = {
        selectedQuestion: null,
        selectedAnswer: null,
        editMode: false
      };

      // add new empty condition
      this.conditionElements.push(condition);

      // trigger question and answer select events
      this.onQuestionSelect(questionPathHash, condition);
      this.onAnswerSelect(pathHash, condition, false);
    }
  }

  /**
   * Unsubscribe from all subscriptions
   */
  public ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  /**
   * Checks if a given element is suitable for dependency usage
   *
   * @param {Questionnaire.Container} element
   * @returns {boolean}
   */
  public isAllowedForCondition(element: Questionnaire.Container): boolean {
    const isSingleChoice = (element.format === Questionnaire.Container.Format.SINGLE_CHOICE);
    const isMultipleChoice = (element.format === Questionnaire.Container.Format.MULTIPLE_CHOICE);
    const isMatrix = (element.format === Questionnaire.Container.Format.MATRIX);

    // only single/multiple choice and matrix allowed for now
    return (isSingleChoice || isMultipleChoice || isMatrix);
  }

  /**
   * Checks if the two given elements are the same (true if both contain the same path hash)
   *
   * @param {Questionnaire.Container} element1
   * @param {Questionnaire.Container} element2
   * @returns {true | null}
   */
  public isSameElement(element1: Questionnaire.Container, element2: Questionnaire.Container): true | null {
    // early bailout if an element is unset
    if (!element1 || !element2) {
      return null;
    }

    // returns true if the elements contain the same path hash and null otherwise
    // this is necessary when setting attributes in the template
    return (element1.pathHash === element2.pathHash) ? true : null;
  }

  public getAnswers(
    element: Questionnaire.Container,
    answerTitle: string = null,
    answers: Questionnaire.Container[] = []
  ):  Questionnaire.Container[] {
    if (!element) {
      return [];
    }

    if (!element.omitContainer) {
      answerTitle = answerTitle ? answerTitle + ' > ' + element.title[this.locale] : element.title[this.locale];
    }

    if (element.format) {
      answerTitle = '';
    }

    if (element.elements) {
      for (const subElement of element.elements) {
        this.getAnswers(subElement, answerTitle, answers);
      }
    } else {
      answers.push({
        id: element.id,
        title: { [this.locale]: answerTitle },
        pathHash: element.pathHash
      });
    }

    return answers;
  }

  /**
   * Adds a new condition to the question
   */
  public onConditionAdd() {
    this.conditionElements.push({
      selectedQuestion: null,
      selectedAnswer: null,
      editMode: true
    });

    this.triggerChangeDetection();
  }

  /**
   * Removes the condition from the question
   */
  public onConditionRemove(position: number) {
    // remove path hash from conditions
    if (this.conditionElements[position]
      && this.conditionElements[position].selectedAnswer
      && this.conditionElements[position].selectedAnswer.pathHash
    ) {
      const index = (<string[]>this.question.conditions.show).indexOf(
        this.conditionElements[position].selectedAnswer.pathHash
      );

      (<string[]>this.question.conditions.show).splice(index, 1);
    }

    // remove condition element
    this.conditionElements.splice(position, 1);

    // update element in state service (updates path hashes)
    this.questionnaireStateService.updateElement(this.question, -1);
    this.triggerChangeDetection();
  }

  /**
   * Selects a question in the given condition. Resolves the element by given path
   * hash and adds it as selected question. Also resets the selected answer on
   * the condition
   *
   * @param {string} pathHash
   * @param {ConditionElement} condition
   */
  public onQuestionSelect(pathHash: string, condition: ConditionElement) {
    // early bailout if path hash empty or no element found by path hash
    if (!pathHash || !this.pathHashMap[pathHash]) {
      // invalid path hash = reset selected question and answer
      condition.selectedQuestion = null;
      condition.selectedAnswer = null;
      return;
    }

    // fetch element by path hash and set it as selected question
    condition.selectedQuestion = this.pathHashMap[pathHash];
    condition.selectedAnswer = null;

    this.triggerChangeDetection();
  }

  /**
   * Selects an answer in the given condition. Resolves the answer element by given path
   * hash and adds it as selected answer.
   *
   * @param {string} pathHash
   * @param {ConditionElement} condition
   * @param {boolean} updateJson
   */
  public onAnswerSelect(pathHash: string, condition: ConditionElement, updateJson: boolean = true) {
    // early bailout if path hash empty or no element found by path hash
    if (!pathHash || !this.pathHashMap[pathHash]) {
      // invalid path hash = reset selected answer
      condition.selectedAnswer = null;
      return;
    }

    // recursively resolves nested elements into a flat map
    const answers = this.getAnswers(condition.selectedQuestion);

    // search for selected answer and set it
    for (const answer of answers) {
      if (answer.pathHash === pathHash) {
        condition.selectedAnswer = answer;
      }
    }

    if (updateJson) {
      // update conditions of this question by selected answer
      this.question.conditions = this.question.conditions ? this.question.conditions : { show: []};

      if ((<string[]>this.question.conditions.show).indexOf(pathHash) === -1) {
        (<string[]>this.question.conditions.show).push(pathHash);
      }
    }

    this.triggerChangeDetection();
  }

  /**
   * Toggles the edit mode on the given condition
   *
   * @param {ConditionElement} condition
   */
  public onToggleEditMode(condition: ConditionElement) {
    // negate edit mode
    condition.editMode = !condition.editMode;

    this.triggerChangeDetection();
  }

  /**
   * Auto updates conditions. Removes invalid ones if the related elements no longer exist.
   */
  private updateConditions() {
    const removeCondition = (pathHash) => {
      // remove path hash from conditions
      const index = (<string[]>this.question.conditions.show).indexOf(pathHash);
      (<string[]>this.question.conditions.show).splice(index, 1);
    };

    for (let i = 0; i < this.conditionElements.length; i++) {
      const condition = this.conditionElements[i];
      const hashQuestion = condition.selectedQuestion || null;
      const hashAnswer = condition.selectedAnswer || null;

      if (hashQuestion && typeof this.pathHashMap[condition.selectedQuestion.pathHash] === 'undefined') {
        this.conditionElements.splice(i, 1);

        // remove current path hash from conditions
        removeCondition(condition.selectedAnswer.pathHash);
        continue;
      }

      if (hashAnswer && typeof this.pathHashMap[condition.selectedAnswer.pathHash] === 'undefined') {
        condition.selectedAnswer = null;

        // remove current path hash from conditions
        removeCondition(condition.selectedAnswer.pathHash);
      }
    }

    this.triggerChangeDetection();
  }

  /**
   * Triggers change detection on this component
   */
  private triggerChangeDetection() {
    // removes all dynamic select2 elements
    this.removeSelect2();

    // trigger change detection
    this.ref.detectChanges();
    this.ref.markForCheck();

    // converts select into select2
    this.renderSelect2();
  }

  /**
   * Converts normal select html elements into dynamic select2 elements
   */
  private renderSelect2() {
    const cmp = this;
    const selects = $(this.conditionRef.nativeElement).find('select[data-edit]');

    // convert selects
    selects.select2();

    // select2 change event > triggers angular events
    selects.on('select2:select', function() {
      // get current path hash, condition id and type from el
      const pathHash = $(this).val();
      const conditionId = $(this).attr('data-id');
      const type = $(this).attr('data-type');

      // early bailout when no condition found
      if (!cmp.conditionElements[conditionId]) {
        return;
      }

      // get condition by id
      const condition = cmp.conditionElements[conditionId];

      // execute event by type
      (type === 'question') ?
        cmp.onQuestionSelect(pathHash, condition) :
        cmp.onAnswerSelect(pathHash, condition);
    });
  }

  /**
   * Removes all dynamic select2 elements
   */
  private removeSelect2() {
    if (!this.conditionRef || this.conditionRef.nativeElement) {
      return;
    }

    $(this.conditionRef.nativeElement).find('select').each((index, el) => {
      if ($(el).data('select2')) {
        $(el).select2('destroy');
      }
    });
  }

  /**
   * Recursively searches for question path hash by given path hash.
   * Given path hash is an answer path hash, so it searches from inside
   * to outside until it finds the outermost element with a hash, which is
   * always the question element.
   *
   * @param {string} pathHash
   */
  private getQuestionPathHash(pathHash: string): string {

    while (true) {
      // no parent for current path hash? seems like we found the question :)
      if (!this.pathHashMap[pathHash] || !this.pathHashMap[pathHash].parentHash) {
        break;
      }

      // we found a parent so let's override the path hash with it's parent path hash
      pathHash = this.pathHashMap[pathHash].parentHash;
    }

    // return found path hash
    return pathHash;
  }
}
