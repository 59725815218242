<div class="dv-grid">
  <h2 class="mb-4">{{_(KEYS.GRID.DEVICES)}}</h2>

  <div class="card mb-5">
    <div class="card-body">
      <div class="card-title">
        <div class="btn-toolbar justify-content-between">
          <div class="justify-content-between qa-lh-2p5">
            <i class="fa fa-search"></i>
            <h5 class="d-inline">{{_(KEYS.GRID.SEARCH)}}</h5>
          </div>
          <button class="btn btn-primary d-inline-block" (click)="isSearchCollapsed = !isSearchCollapsed">
            <i class="fa" [ngClass]="{'fa-arrow-down': isSearchCollapsed, 'fa-arrow-up': !isGridCollapsed}"></i>
          </button>
        </div>
      </div>
      <div class="card-text" [ngbCollapse]="isSearchCollapsed">
        <div class="qa-grid-search mt-2 mb-2">
          <div class="input-group qa-grid-search-device-name">
            <span class="input-group-addon">{{_(KEYS.GRID.DEVICE_NAME)}}:</span>
            <input class="form-control" [(ngModel)]="filter.deviceName"
                   (paste)="onFilterChange()" (change)="onFilterChange()" (keydown)="onFilterChange()">
          </div>
          <div class="input-group qa-grid-search-device-id">
            <span class="input-group-addon">{{_(KEYS.GRID.DEVICE_ID)}}:</span>
            <input class="form-control" [(ngModel)]="filter._id"
                   (paste)="onFilterChange()" (change)="onFilterChange()" (keydown)="onFilterChange()">
          </div>

          <div class="input-group qa-grid-search-device-client">
            <app-select2
              label="{{_(KEYS.GRID.PATIENTS)}}: &nbsp;&nbsp;&nbsp;&nbsp;"
              [hideButton]="true"
              [multiple]="true"
              [options]="{width: '74.8%',placeholder: '...'}"
              [(selected)]="filter.client"
              (selectedChange)="onFilterChange()"
            >
              <option *ngFor="let client of clients" value="{{client._id}}">
                {{shortId(client._id)}} {{client.firstName}} {{client.lastName}}
              </option>
            </app-select2>
          </div>
          <div class="input-group qa-grid-search-device-type">
            <span class="input-group-addon">{{_(KEYS.GRID.TYP)}}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <select class="custom-select" [(ngModel)]="filter.isBrowser"
                    (blur)="onFilterChange()" (change)="onFilterChange()" (keydown)="onFilterChange()">
              <option value="3">{{_(KEYS.GRID.TYPE_ALL)}}</option>
              <option value="1">{{_(KEYS.GRID.TYPE_BROWSER)}}</option>
              <option value="0">{{_(KEYS.GRID.TYPE_DEVICE)}}</option>
            </select>
          </div>

        </div>
      </div>
    </div>
  </div>


  <div class="card">
    <div class="card-body">
      <div class="card-title">
        <div class="btn-toolbar justify-content-between">
          <div class="justify-content-between qa-lh-2p5">
            <i class="fa fa-copy"></i>
            <h5 class="d-inline">{{_(KEYS.GRID.DEVICES)}}</h5>
          </div>
          <button class="btn btn-primary d-inline-block" (click)="isGridCollapsed = !isGridCollapsed">
            <i class="fa" [ngClass]="{'fa-arrow-down': isGridCollapsed, 'fa-arrow-up': !isGridCollapsed}"></i>
          </button>
        </div>
      </div>
      <div class="card-text" [ngbCollapse]="isGridCollapsed">
        <table class="table">
          <thead>
          <tr>
            <th>{{_(KEYS.GRID.DEVICE_NAME)}}</th>
            <th>{{_(KEYS.GRID.DEVICE_ID)}}</th>
            <th>{{_(KEYS.GRID.TYP)}}</th>
            <th>{{_(KEYS.GRID.PATIENT)}}</th>
            <th>{{_(KEYS.GRID.OPTIONS)}}</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let device of devices; let pos = index;">
            <tr [ngClass]="{'qa-tr-odd': pos % 2 === 0}" *ngIf="isOnCurrentPage(pos)">
              <td>
                {{device.deviceName}}
              </td>
              <td>
                {{device.uid}}
              </td>
              <td>
                <ng-container *ngIf="device.isBrowser">
                  <i class="fa fa-globe" [ngbTooltip]="_(KEYS.GRID.BROWSER)"></i>
                </ng-container>
                <ng-container *ngIf="!device.isBrowser">
                  <i class="fa fa-tablet" [ngbTooltip]="_(KEYS.GRID.DEVICE)"></i>
                </ng-container>
              </td>
              <td>
                <ng-container *ngIf="device.client">
                  {{shortId(device.client._id)}} {{device.client.firstName}} {{device.client.lastName}}
                </ng-container>
                <ng-container *ngIf="!device.client">
                  <i class="fa fa-times"></i>
                </ng-container>
              </td>
              <td>
                <button
                  class="btn btn-danger d-inline-block mr-1"
                  (click)="removeDevice(device)"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="{{_(KEYS.GRID.DELETE_DEVICE)}}">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
        <div class="btn-toolbar justify-content-between mt-2 mb-2">
          <div class="justify-content-between">
            <div class="input-group">
              <div class="input-group qa-num-per-page">
                <select class="form-control custom-select" [(ngModel)]="displayPerPage" (change)="onPagingChange()">
                  <option value="2">2</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
                <span class="input-group-addon">{{_(KEYS.GRID.ENTRIES)}}</span>
              </div>
            </div>
          </div>
          <ngb-pagination
            [pageSize]="displayPerPage"
            [maxSize]="9"
            [rotate]="true"
            [(page)]="gridPageNumber"
            [collectionSize]="devices.length"
            (pageChange)="onPagingChange()">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
