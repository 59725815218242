<div class="qa-date-options">
  <label class="display-label" for="dateFormat">Format:</label>
  <select class="display-input form-control" id="dateFormat"
          [(ngModel)]="selectedDateFormat"
          (change)="setDateFormatBySelectedOption()">
    <option value="fullDateTime">{{_(KEYS.EDITOR.DATE_YMD_HM_TEXT)}}</option>
    <option value="fullDate">{{_(KEYS.EDITOR.DATE_YMD_TEXT)}}</option>
    <option value="monthDate">{{_(KEYS.EDITOR.DATE_YM_TEXT)}}</option>
    <option value="yearDate">{{_(KEYS.EDITOR.DATE_Y_TEXT)}}</option>
  </select>
</div>
