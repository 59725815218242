import {ChangeDetectorRef, Component, ElementRef, OnInit, Optional, Renderer2} from '@angular/core';
import {LayoutService} from '@ngmedax/layout';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {FiltergroupGridService} from '../../services/filtergroup-grid.service';
import {TRANSLATION_GRID_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';
import {Filtergroup} from '../../../../types';


// hack to inject decorator declarations. must occur before class declaration!
export interface FiltergroupGridComponent extends Translatable {}

@Component({
  selector: 'app-filtergroup-grid',
  templateUrl: './filtergroup-grid.component.html',
  styleUrls: ['./filtergroup-grid.component.css']
})
@Translatable({scope: TRANSLATION_GRID_SCOPE, keys: KEYS})
export class FiltergroupGridComponent implements OnInit {
  public isSearchCollapsed = true;
  public isGridCollapsed = false;
  public gridPageNumber = 1;
  public displayPerPage = 25;
  public filtergroups: Filtergroup[] = [];
  public filter: Filtergroup.Filter = {};

  /**
   * Locale for filtergroups. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  public newgroup: Filtergroup = {
    id: '',
    name: {
      [this.locale]: ''
    }
  };

  /**
   * Loads filtergroups and initializes search filter
   */
  public ngOnInit() {
    this.layoutService.showPreloader();

    this.filtergroupGridService.getFiltergroups()
      .then((filtergroups) => {
        this.filtergroups = filtergroups;
        this.layoutService.hidePreloader();
      })
      .catch(error => {
        this.layoutService.hidePreloader();
        alert(this._(KEYS.GRID.ERROR_LOADING_FILTER_GROUPS));
        console.log(error);
      });
  }

  /**
   * Injects dependencies
   */
  public constructor(
    private renderer: Renderer2,
    private layoutService: LayoutService,
    private filtergroupGridService: FiltergroupGridService,
    private ref: ChangeDetectorRef,
    @Optional() private translationService: TranslationService) {
  }

  /**
   * Event handler for when paging changes. Triggers the change detection
   */
  public onPagingChange() {
    // trigger change detection
    this.ref.detectChanges();
    this.ref.markForCheck();
  }

  /**
   * Event handler for when search form reset was triggered
   */
  public onResetSearchForm() {
    this.filter.id = '';
    this.filter.name = '';
  }

  /**
   * Event handler for when a new element should be added
   *
   * @returns {Promise<any>}
   */
  public onAdd() {
    for (const fg of this.filtergroups) {
      if (fg.id === this.newgroup.id) {
        alert(this._(KEYS.GRID.FILTER_GROUP_ALREADY_EXISTS) + ' ' + this.newgroup.id);
        return;
      }
    }

    return new Promise<void>((resolve, reject) => {
      this.filtergroupGridService
        .createFiltergroup(this.newgroup)
        .then(() => {
          this.filtergroups.push({
            id: this.newgroup.id,
            name: this.newgroup.name
          });

          this.newgroup.id = '';
          this.newgroup.name = {[this.locale]: ''};

          resolve();
        })
        .catch(error => {
          console.log(error);
          reject(error);
          alert(this._(KEYS.GRID.ERROR_SAVING_FILTER_GROUP));
        });
    });
  }

  /**
   * Event handler for when a filtergroup change was triggered
   *
   * @param {Filtergroup} filtergroup
   * @param {HTMLElement} el
   * @returns {Promise<any>}
   */
  public onUpdate(filtergroup: Filtergroup, el: HTMLElement = null): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.filtergroupGridService
        .updateFiltergroup(filtergroup)
        .then(() => {
          if (el) {
            this.onClean(el);
          }
          resolve();
        })
        .catch(error => {
          console.log(error);
          reject(error);
          alert(this._(KEYS.GRID.ERROR_UPDATING_FILTER_GROUP));
        });
    });
  }

  /**
   * Event handler for when a filtergroup reset was triggered
   *
   * @param {Filtergroup} filtergroup
   * @param {HTMLElement} el
   */
  public onReset(filtergroup: Filtergroup, el: HTMLElement = null) {
    this.filtergroupGridService
      .getFiltergroup(filtergroup)
      .then((fetchedFg) => {
        filtergroup.id = fetchedFg.id;
        filtergroup.name = fetchedFg.name;
        this.onClean(el);
      })
      .catch(error => {
        console.log(error);
        alert(this._(KEYS.GRID.ERROR_RESETTING_FILTER_GROUP));
      });
  }

  /**
   * Event handler for when a filtergroup delete was triggered
   *
   * @param position
   */
  public onDelete(position) {
    this.filtergroupGridService
      .deleteFiltergroup(this.filtergroups[position])
      .then(() => this.filtergroups.splice(position, 1))
      .catch(error => {
        console.log(error);
        alert(this._(KEYS.GRID.ERROR_DELETING_FILTER_GROUP));
      });
  }

  /**
   * Event handler for when an element should be flagged as dirty
   *
   * @param {HTMLElement} el
   */
  public onDirty(el: HTMLElement) {
    this.addClasses(el, ['qa-tr-dirty']);
  }

  /**
   * Event handler for when an element should be flagged as clean
   *
   * @param {HTMLElement} el
   */
  public onClean(el: HTMLElement) {
    this.removeClasses(el, ['qa-tr-dirty']);
    this.addClasses(el, ['qa-tr-clean', 'fadeIn', 'animated']);
    setTimeout(() => this.removeClasses(el, ['qa-tr-clean', 'fadeIn', 'animated']), 600);
  }

  /**
   * Returns filtergroups which are allowed by currently set filter
   *
   * @returns {Array}
   */
  public getFiltergroups(): Filtergroup[] {
    const filtered = [];

    for (const filtergroup of this.filtergroups) {
      if (this.isAllowedByFilter(filtergroup)) {
        filtered.push(filtergroup);
      }
    }

    return filtered;
  }

  /**
   * Checks if the given filtergroup is allowed by the filter object
   *
   * @param {Filtergroup} filtergroup
   * @returns {boolean}
   */
  public isAllowedByFilter(filtergroup: Filtergroup) {
    const f = this.filter;
    const q = filtergroup;

    const isAllowedId = f.id && q.id ?
      q.id.match(new RegExp(f.id, 'i')) : true;

    const isAllowedName = f.name && q.name && q.name[this.locale] ?
      q.name[this.locale].match(new RegExp(f.name, 'i')) : true;

    return isAllowedId && isAllowedName;
  }

  /**
   * Checks if the given position of a filtergroup should be displayed according to the currently selected page
   *
   * @param pos
   * @returns {boolean}
   */
  public isOnCurrentPage(pos) {
    const displayPerPage = this.displayPerPage;
    const maxPos = (displayPerPage * this.gridPageNumber) - 1;
    const minPos = (maxPos - displayPerPage) + 1;
    return pos >= minPos && pos <= maxPos;
  }

  /**
   * Sanitizes filtergroup id by:
   * - converting it to upper case
   * - removing all none alpha num chars
   * - decreasing length to a max of 10 chars
   *
   * @param {Filtergroup} filtergroup
   */
  public sanitize(filtergroup: Filtergroup) {
    filtergroup.id = filtergroup.id
      .toUpperCase()
      .replace(/[^A-Z0-9]*/g, '')
      .substr(0, 10);
  }

  /**
   * Removes given css classes from given element ref
   *
   * @param {ElementRef} el
   * @param {any[]} classes
   */
  private removeClasses(el: HTMLElement, classes: any[]) {
    for (const cls of classes) {
      this.renderer.removeClass(el, cls);
    }
  }

  /**
   * Adds given css classes to given element ref
   *
   * @param {HTMLElement} el
   * @param {any[]} classes
   */
  private addClasses(el: HTMLElement, classes: any[]) {
    for (const cls of classes) {
      this.renderer.addClass(el, cls);
    }
  }
}
