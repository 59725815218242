/**
 * Mapping for translation keys, so we don't have to use long text in source code. Please keep in mind, that the
 * german default translations are the keys, so don't change them, if you wan't to change the text.
 * Instead change the de_DE translations!
 */
export const KEYS = {
  DEFAULT: {
    ERROR_LOADING_PDF_FORMS: 'Beim Laden der PDF Formulare ist ein Fehler aufgetreten',
  }
};

