import {RouterModule, Routes} from '@angular/router';
import {permission} from './survey-grid.permission';
import {SurveyGridComponent} from './components/survey-grid.component';

const APP_ROUTES: Routes = [
  {
    path: 'module/survey/grid',
    component: SurveyGridComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: true,
      needsPermissions: [
        permission.MYMEDAX_PATIENT_QUESTIONNAIRE_ASSIGN,
        permission.MYMEDAX_PATIENT_QUESTIONNAIRE_UNASSIGN
      ]
    }
  }
];

export const routing = RouterModule.forRoot(APP_ROUTES);
