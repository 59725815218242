import {Component, OnInit, Optional, ViewChild} from '@angular/core';
import {NgbDateStruct, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';

import {Translatable, TranslationEventService, TranslationService} from '@ngmedax/translation';
import {LayoutService} from '@ngmedax/layout';

import {TableExportService} from '../../services/table-export.service';
import {SubmissionService} from '../../services/submission.service';
import {TRANSLATION_TABLE_EXPORT_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';
import {SubmissionQuery} from '../../../../types';


// hack to inject decorator declarations. must occur before class declaration!
export interface QuestionnaireSubmissionTableExportComponent extends Translatable {}

@Component({
  selector: 'app-questionnaire-submission-table-export',
  templateUrl: './questionnaire-submission-table-export.component.html',
  styleUrls: ['./questionnaire-submission-table-export.component.css'],
  providers: [
    {provide: 'TRANSLATION_SCOPE', useValue: TRANSLATION_TABLE_EXPORT_SCOPE},
  ]
})
@Translatable({scope: TRANSLATION_TABLE_EXPORT_SCOPE, keys: KEYS})
export class QuestionnaireSubmissionTableExportComponent implements OnInit {
  public isTableSearchCollapsed = true;
  public questionnaireTitles = [];
  public questionnaireRevisions = [];
  public toDateMin: any = {};
  public exportCount = 0;
  public omitPatientData = '0';

  @ViewChild('fromDate') fromDateRef: NgbInputDatepicker;
  @ViewChild('toDate') toDateRef: NgbInputDatepicker;

  public set selectedQuestionnaireId(value: string) {
    this._selectedQuestionnaireId = value;
    this.updateExportCount();
  }

  public get selectedQuestionnaireId(): string {
    return this._selectedQuestionnaireId;
  }

  public set selectedRevision(value: string | number) {
    this._selectedRevision = value;
    this.updateExportCount();
  }

  public get selectedRevision(): string | number {
    return this._selectedRevision;
  }

  public set selectedFromDate(value: NgbDateStruct) {
    this.toDateMin = value;
    delete(this.selectedToDate);
    this._selectedFromDate = value;
    this.updateExportCount();
  }

  public get selectedFromDate(): NgbDateStruct {
    return this._selectedFromDate;
  }

  public set selectedToDate(value: NgbDateStruct) {
    this._selectedToDate = value;
    this.updateExportCount();
  }

  public get selectedToDate(): NgbDateStruct {
    return this._selectedToDate;
  }

  /**
   * Hidden member for selected questionnaire id
   */
  private _selectedQuestionnaireId;

  /**
   * Hidden member for selected revision
   */
  private _selectedRevision: string | number = 0;

  /**
   * Hidden member for selected "from date"
   */
  private _selectedFromDate: any;

  /**
   * Hidden member for selected "to date"
   */
  private _selectedToDate: any;

  /**
   * Default locale
   */
  public locale = 'de_DE';

  /**
   * Options for questionnaire title select
   */
  public selectOptions = {
    width: '100%',
    placeholder: '...'
  };

  /**
   * Inject dependencies
   */
  public constructor(
    private layout: LayoutService,
    private submission: SubmissionService,
    private tableExport: TableExportService,
    @Optional() private translationService: TranslationService,
    @Optional() private translationEvents: TranslationEventService
  ) {
  }

  /**
   * Initializes questionnaire titles
   */
  public async ngOnInit() {
    this.questionnaireTitles = await this.submission.getQuestionnaireTitles();

    // auto translates selected from/to date on locale changed event
    this.translationEvents && this.translationEvents.onLocaleChanged().subscribe(() => {
      if (!this.selectedFromDate || !this.selectedToDate || !this.fromDateRef || !this.toDateRef) {
        return;
      }

      const fromDate = this.toDate(this.selectedFromDate);
      const toDate = this.toDate(this.selectedToDate);
      this.fromDateRef.writeValue({year: fromDate.getFullYear(), month: fromDate.getMonth() + 1, day: fromDate.getDate()});
      this.toDateRef.writeValue({year: toDate.getFullYear(), month: toDate.getMonth() + 1, day: toDate.getDate()});
    });
  }

  /**
   * Event handler for when questionnaire was selected.
   * Updates revisions and selected revision
   *
   * @param {string} id
   */
  public async onQuestionnaireSelect(id: string) {
    this.selectedQuestionnaireId = id;
    this.selectedRevision = '';

    if (!id) {
      this.questionnaireRevisions = [];
      return;
    }

    this.questionnaireRevisions = await this.submission.getQuestionnaireRevisions(id);

    if (this.questionnaireRevisions
      && this.questionnaireRevisions[0]
      && typeof this.questionnaireRevisions[0] === 'object') {
      this.selectedRevision = this.questionnaireRevisions[0].revision;
    }
  }

  /**
   * Returns true if we can export (all mandatory fields set)
   *
   * @return {boolean}
   */
  public canExport(): boolean {
    return !!this.selectedQuestionnaireId
      && !!this.selectedRevision
      && !!this.selectedFromDate
      && !!this.selectedToDate;
  }

  /**
   * Event handler for when export button was clicked
   */
  public async onExport() {
    const id = this.selectedQuestionnaireId;
    const rev = this.selectedRevision;
    const fromDate = this.toDate(this.selectedFromDate).toJSON();
    const toDate = this.toDate(this.selectedToDate, 23, 59, 59).toJSON();
    const anonym = this.omitPatientData == '1';

    this.layout.showPreloader();

    if (rev === 'all') {
      let workBook = null;
      const revs = this.questionnaireRevisions.map(rev => rev.revision);
      const revMap = [];

      // we first need to build a map with rev and related submission id's
      // no submissions found = skip current rev
      for (let i = 0; i < revs.length; i++) {
        const currentRev = revs[i];
        const query = {id, rev: currentRev, fromDate, toDate};
        const submissionIds = await this.submission.getIds(query);

        if (!submissionIds.length) {
          continue;
        }

        revMap.push({currentRev, submissionIds});
      }


      // then we can build and append an xls sheet for every map entry and write an xls file
      for (let i = 0; i < revMap.length; i++) {
        const currentRev = revMap[i].currentRev;
        const submissionIds = revMap[i].submissionIds;
        const isLastEntry = (i+1) === revMap.length;
        const skipWrite = !isLastEntry;

        workBook = await this.tableExport.export({submissionIds, rev: currentRev, workBook, skipWrite, anonym});
      }

      this.layout.hidePreloader();
      return;
    }

    const query = {id, rev, fromDate, toDate};
    const submissionIds = await this.submission.getIds(query);
    await this.tableExport.export({submissionIds, rev, anonym});
    this.layout.hidePreloader();
  }

  /**
   * Updates estimated export count by form values
   */
  private updateExportCount() {
    if (!this.canExport()) {
      this.exportCount = 0;
      return;
    }

    const update = async () => {
      const query = this.getTableExportQuery();
      this.exportCount = await this.submission.getCount(query);
    };

    update().catch(error => console.error(error));
  }

  /**
   * Returns table export query by form values
   *
   * @returns {SubmissionQuery}
   */
  private getTableExportQuery(): SubmissionQuery {
    const id = this.selectedQuestionnaireId;
    const rev = this.selectedRevision;
    const fromDate = this.toDate(this.selectedFromDate).toJSON();
    const toDate = this.toDate(this.selectedToDate, 23, 59, 59).toJSON();
    return {id, rev, fromDate, toDate};
  }

  /**
   * Returns date object by given ngb date object
   *
   * @param date
   */
  private toDate(date: NgbDateStruct, hours = 0, minutes = 0, seconds = 0): Date {
    const year = date.year;
    const month = this.pad(date.month);
    const day = this.pad(date.day);

    const jsonDate = `${year}-${month}-${day}T${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}.000Z`;
    return new Date(jsonDate);
  }

  /**
   * Adds zero padding
   *
   * @param {number} value
   * @returns string
   */
  private pad(value: number): string {
    return `0${value}`.slice(-2);
  }
}
