import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';

import {Filtergroup} from '../../../types';
import {configKeys} from '../questionnaire-grid.config-keys';
import {AuthUriService} from './auth-uri.service';


@Injectable()
export class ApiService {

  public constructor(
    private http: HttpClient,
    private authUriService: AuthUriService) {
  }

  /**
   * Method to load questionnaires
   */
  public loadQuestionnaires(filter?: any, opts?: any): Promise<{rows: Questionnaire[], total: number}> {
    const baseUri = this.authUriService.buildUrl(configKeys.QUESTIONNAIRE_URI_CONFIG_KEY);
    const headers = this.authUriService.getAuthHeaders();

    const query: any = {filter: JSON.stringify(filter) || '{}', opts: JSON.stringify(opts) || '{}'};
    const basePath = `${baseUri}?${decodeURI(new URLSearchParams(query).toString())}`;

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers})
        .subscribe((response: any) => {
            const questionnaires = response.questionnaire || response.rows || response;
            resolve({rows: questionnaires, total: response.total || 0});
          },
          error => {
            reject(error);
          });
    });
  }

  /**
   * Helper method to load questionnaire by id.
   *
   * @param {string} questionnaireId
   * @returns {Promise<Questionnaire>}
   */
  public loadQuestionnaire(questionnaireId: string): Promise<Questionnaire> {
    const basePath = this.authUriService.buildUrl(configKeys.QUESTIONNAIRE_URI_CONFIG_KEY, `/${questionnaireId}`);
    const headers = this.authUriService.getAuthHeaders();

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers})
        .subscribe(
          (questionnaire: { schema: string; questionnaire: any }) => {
            if (!questionnaire.questionnaire) {
              reject(new Error('Invalid questionnaire format. Missing property: "questionnaire"'));
            }
            resolve(questionnaire.questionnaire);
          },
          error => {
            reject(error);
          });
    });
  }

  /**
   * Saves the questionnaire
   *
   * @returns {Promise<any>}
   */
  public saveQuestionnaire(questionnaire: Questionnaire): Promise<Questionnaire> {
    const payload = {
      $schema: 'questionnaire-schema.json',
      questionnaire: questionnaire,
    };

    payload.questionnaire.updatedAt = new Date().toJSON();

    if (!payload.questionnaire.createdAt) {
      payload.questionnaire.createdAt = new Date().toJSON();
    }

    const basePath = this.authUriService.buildUrl(configKeys.QUESTIONNAIRE_URI_CONFIG_KEY);
    const headers = this.authUriService.getAuthHeaders();

    return new Promise((resolve, reject) => {
      this.http.post(basePath, payload, {headers})
        .subscribe(
          () => resolve(questionnaire),
          error => reject(error));
    });
  }

  /**
   * Deletes the questionnaire
   *
   * @returns {Promise<any>}
   */
  public deleteQuestionnaire(questionnaire: Questionnaire): Promise<Questionnaire> {
    const basePath = this.authUriService.buildUrl(configKeys.QUESTIONNAIRE_URI_CONFIG_KEY, `/${questionnaire.id}`);
    const headers = this.authUriService.getAuthHeaders();

    return new Promise((resolve, reject) => {
      this.http.delete(basePath, {headers})
        .subscribe(
          () => resolve(questionnaire),
          error => reject(error));
    });
  }

  /**
   * Returns filter groups
   *
   * @returns {Promise<Filtergroup[]>}
   */
  public loadFiltergroups(): Promise<Filtergroup[]> {
    const basePath = this.authUriService.buildUrl(configKeys.FILTER_GROUP_URI_CONFIG_KEY);
    const headers = this.authUriService.getAuthHeaders();

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers})
        .subscribe((filtergroups: any) => {
            resolve(filtergroups.filterGroup || filtergroups.filtergroup || filtergroups || []);
          },
          error => {
            reject(error);
          });
    });
  }

  /**
   * Fetches number of questionnaires
   *
   * @returns {Promise<number>}
   */
  public async getNumQuestionnaires(): Promise<number> {
    const basePath = this.authUriService.buildUrl(configKeys.QUESTIONNAIRE_URI_CONFIG_KEY, '/count');
    const headers = this.authUriService.getAuthHeaders();

    return new Promise<number>((resolve, reject) => {
      this.http.get(basePath, {headers}).subscribe(
        (response: {count: number}) => resolve(response.count || 0),
        error => reject(error)
      );
    });
  }
}
