import {Component, OnInit, Optional} from '@angular/core';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LayoutService} from '@ngmedax/layout';
import {QuestionnaireGroupService} from '../../services/questionnaire-group.service';
import {QuestionnaireGroup, Translation} from '../../../../types';
import {TRANSLATION_CRUD_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';

// hack to inject decorator declarations. must occur before class declaration!
export interface QuestionnaireGroupCrudComponent extends Translatable {}

@Component({
  selector: 'app-questionnaire-group-crud',
  templateUrl: './questionnaire-group-crud.component.html',
  styleUrls: ['./questionnaire-group-crud.component.css'],
})
@Translatable({scope: TRANSLATION_CRUD_SCOPE, keys: KEYS})
export class QuestionnaireGroupCrudComponent implements OnInit {

  /**
   * Locale hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  public uid: string;
  public name: string;
  public questionnaires: any[] = [];
  public selectedQuestionnaires: string[] = [];
  public group: QuestionnaireGroup[];
  public isEditMode = false;

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    private layoutService: LayoutService,
    public groupService: QuestionnaireGroupService,
    public activeModal: NgbActiveModal
  ) {
  }

  public ngOnInit() {
    this.uid = this.generateUUID();
  }

  public canSave() {
    const canSave = !this.isEditMode && this.name;
    const canEdit = this.isEditMode && this.name;
    return canSave || canEdit;
  }

  public onCancel() {
    this.activeModal.close();
  }

  public async onSave() {
    this.layoutService.showPreloader();

    try {
      const name: Translation = {[this.locale]: this.name};
      const method = this.isEditMode ? 'updateQuestionnaireGroup' : 'createQuestionnaireGroup';
      await (<any>this.groupService[method])({uid: this.uid, name, questionnaires: this.selectedQuestionnaires});

      await this.reload(this.uid);
      this.groupService.onQuestionnaireGroupSaved().emit();
      alert(this._(KEYS.CRUD.SUCCESSFULLY_SAVED_QUESTIONNAIRE_GROUP));
    } catch (error) {
      console.error(error);
      alert(this._(KEYS.CRUD.ERROR_SAVING_QUESTIONNAIRE_GROUP));
      this.onCancel();
    }

    this.layoutService.hidePreloader();
  }

  public async load(uid: string) {
    await this.reload(uid);
  }

  public async reload(uid: string) {
    const form = await this.groupService.loadQuestionnaireGroup(uid);

    if (!form) {
      alert(this._(KEYS.CRUD.FOUND_NO_QUESTIONNAIRE_GROUP_BY_ID) + ' ' + uid);
      this.layoutService.hidePreloader();
      return;
    }

    this.uid = uid;
    this.name = form.name.de_DE;
    this.questionnaires = form.questionnaires;
    this.isEditMode = true;

    if (Array.isArray(this.questionnaires)) {
      this.selectedQuestionnaires = this.questionnaires.map(q => q.id);
    }
  }

  /**
   * Generates a uuid
   * @returns {string}
   */
  public generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
