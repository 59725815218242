export const configKeys = {
  /**
   * Config key for questionnaire api uri
   * @type {string}
   */
  QUESTIONNAIRE_URI_CONFIG_KEY: 'apis.questionnaire.uri',

  /**
   * Config key for questionnaire group api uri
   * @type {string}
   */
  QUESTIONNAIRE_GROUP_URI_CONFIG_KEY: 'apis.questionnaire.group.uri',

  /**
   * Config key for session auth token
   * @type {string}
   */
  SESSION_AUTH_TOKEN: 'session.authToken',

  /**
   * Config key for session tenant id
   * @type {string}
   */
  SESSION_TENANT_ID: 'session.tenantId'
};
