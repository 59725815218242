import {Component, OnInit} from '@angular/core';
import {Settings} from '../../../types';

@Component({
    selector: 'app-users',
    templateUrl: './languages.component.html',
    styleUrls: ['./languages.component.css']
})

export class LanguagesComponent implements OnInit {
    public isGridCollapsed = false;
    public settings: Settings[] = [];

    /**
     * Locale for questionnaires. Hardcoded to "de_DE" for now.
     * We need to change this, when we implement multi language support
     * @type {string}
     */
    public locale = 'de_DE';
    /**
     * Loads client
     */
    public ngOnInit() {


    }

}
