<div class="home">
  <h2 class="mb-4">{{_(KEYS.HOME.MYMEDAX_ADMIN)}}</h2>
  <p *ngIf="userName">{{_(KEYS.HOME.WELCOME_USERNAME, {userName: userName})}}</p>
  <div class="grid">
    <div class="tile" *ngIf="canOpen('/module/questionnaire/submission/grid')">
      <div class="image">
        <button class="btn btn-lg btn-success">
          <i class="fa fa-check"></i>
        </button>
      </div>
      <div class="text">
        {{_(KEYS.HOME.FILLED_QUESTIONNAIRE_TEXT)}}: <br>
        <a routerLink="/module/questionnaire/submission/grid" routerLinkActive="active">
          {{_(KEYS.HOME.FILLED_QUESTIONNAIRES)}}
        </a>
      </div>
    </div>

    <div class="tile" *ngIf="canOpen('/module/questionnaire/editor')">
      <div class="image">
        <button class="btn btn-lg btn-success">
          <i class="fa fa-pencil-square-o"></i>
        </button>
      </div>
      <div class="text">
        {{_(KEYS.HOME.NEW_QUESTIONNAIRE_TEXT)}}: <br>
        <a routerLink="/module/questionnaire/editor" routerLinkActive="active">
          {{_(KEYS.HOME.QUESTIONNAIRE_EDITOR)}}
        </a>
      </div>
    </div>

    <div class="tile" *ngIf="canOpen('/module/patient/crud/new')">
      <div class="image">
        <button class="btn btn-lg btn-success">
          <i class="fa fa-bars"></i>
        </button>
      </div>
      <div class="text">
        {{_(KEYS.HOME.NEW_PATIENT_TEXT)}}: <br>
        <a routerLink="/module/patient/crud/new" routerLinkActive="active" *ngIf="!disablePatientNewLink">
          <i class="fa fa-star"></i>
          {{_(KEYS.HOME.NORMAL)}}
        </a>
        <a routerLink="/module/patient/crud/new/anonymous" routerLinkActive="active">
          <i class="fa fa-user-secret"></i>
          {{_(KEYS.HOME.ANONYMOUS)}}
        </a>
        <a routerLink="/module/patient/crud/new/pseudonymous" routerLinkActive="active">
          <i class="fa fa-user-o"></i>
          {{_(KEYS.HOME.PSEUDONYMOUS)}}
        </a>
      </div>
    </div>

    <div class="tile" *ngIf="canOpen('/module/survey/grid')">
      <div class="image">
        <button class="btn btn-lg btn-success">
          <i class="fa fa-table"></i>
        </button>
      </div>
      <div class="text">
        {{_(KEYS.HOME.SURVEY_LIST_TEXT)}}: <br>
        <a routerLink="/module/survey/grid" routerLinkActive="active">
          {{_(KEYS.HOME.SURVEY_LIST)}}
        </a>
      </div>
    </div>

    <div class="tile" *ngIf="downloadLinks?.allocatorApp?.windows && !isCustomTranslationScope">
      <div class="image">
        <button class="btn btn-lg btn-success">
          <i class="fa fa-download"></i>
        </button>
      </div>
      <div class="text">
        {{_(KEYS.HOME.ALLOCATOR_APP_DOWNLOAD_TEXT)}}: <br>
        <a *ngIf="downloadLinks?.allocatorApp?.windows" [href]="downloadLinks.allocatorApp.windows" class="mr-1">
          <i class="fa fa-windows"></i>
          windows
        </a>
        <a *ngIf="downloadLinks?.allocatorApp?.mac" [href]="downloadLinks.allocatorApp.mac">
          <i class="fa fa-apple"></i>
          mac
        </a>
        <a *ngIf="downloadLinks?.allocatorApp?.linux" [href]="downloadLinks.allocatorApp.linux">
          <i class="fa fa-linux"></i>
          linux
        </a>
      </div>
    </div>

    <div class="tile" *ngIf="downloadLinks?.userGuide && downloadLinks?.userGuide[lang]">
      <div class="image">
        <button class="btn btn-lg btn-success">
          <i class="fa fa-question-circle-o"></i>
        </button>
      </div>
      <div class="text">
        {{_(KEYS.HOME.USER_GUIDE_DOWNLOAD_TEXT)}}:
        <a [href]="downloadLinks.userGuide[lang]">{{_(KEYS.HOME.USER_GUIDE, {lang: lang})}}</a>
      </div>
    </div>

    <div class="tile" *ngIf="canOpen('/module/device/create')">
      <div class="image">
        <button class="btn btn-lg btn-success">
          <i class="fa fa-tablet"></i>
        </button>
      </div>
      <div class="text flex">
        <div class="fixed-width">
          {{_(KEYS.HOME.INIT_DEVICE_TEXT)}}:
        </div>
        <div class="qr-code">
          <app-init-device-qr-code></app-init-device-qr-code>
        </div>
      </div>
    </div>
  </div>
</div>
