import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter,
  Input, OnDestroy, OnInit, Optional, Output, ViewEncapsulation} from '@angular/core';

import {Translatable, TranslationEventService, TranslationService} from '@ngmedax/translation';
import {DATE_FORMAT_YMD_HM, DATE_FORMAT_YMD, DATE_FORMAT_YM, DATE_FORMAT_Y} from '@ngmedax/common-translation';
import {QuestionnaireConditionService} from '../../../../../services/questionnaire-condition.service';
import {QuestionnaireVariablesService} from '../../../../../services/questionnaire-variables.service';
import {QuestionnaireStateService} from '../../../../../services/questionnaire-state.service';
import {TRANSLATION_EDITOR_SCOPE} from '../../../../../../constants';
import {KEYS} from '../../../../../../translation-keys';
import {Subscription} from 'rxjs';
import IMask from 'imask/esm/imask';
import * as moment from 'moment';

// hack to inject decorator declarations. must occur before class declaration!
export interface ConditionsEditorExpressionComponent extends Translatable {}

@Component({
  selector: 'app-qa-conditions-editor-expression',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './conditions-editor-expression.component.html',
  styleUrls: ['./conditions-editor-expression.component.css'],
  encapsulation: ViewEncapsulation.None
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class ConditionsEditorExpressionComponent implements OnInit, OnDestroy {
  /**
   * Determines dropdowns to show. question/answer dropdowns for type 'question'
   * and variable dropdown + input field for type 'variables'
   */
  public variableType: 'question' | 'variable'  = 'variable';

  /**
   * Input type of selected variable. used to render matching input type
   */
  public inputType: 'boolean' | 'numeric' | 'text' | 'date' = 'text';

  /**
   * Data to populate question/answer or variable/value dropdowns
   */
  public selectData: any = {questions: [], variables: []};

  /**
   * Default option for select2 component
   */
  public select2Opts = {width: '200px', placeholder: '...'};

  /**
   * Path hash of selected question, so it can be marked as selected in the dropdown
   */
  public selectedQuestion: string;

  /**
   * Default locale. Hardcoded to 'de_DE' for now
   */
  public locale = 'de_DE';

  /**
   * Date format to display when date input field is visible
   */
  public dateFormat = '';

  /**
   * Date format regular expression to parse date
   */
  private dateFormatRegExp = new RegExp('');

  /**
   * Default options for date input field
   */
  public iMaskOptions: any = {};

  /**
   * Variable to contain date when date mask was completed with valid date
   */
  public acceptedDate: string = '';

  /**
   * Subscription for when we have to change date format map, because locale was changed
   */
  private dateChangedSub: Subscription;

  /**
   * Subscription for when select data was updated (e.g: new question/variable added)
   */
  private selectUpdatedSub: Subscription;

  /**
   * Default date format map for date question date types
   */
  private dateFormatMap: {[dateType: string]: string} = {
    fullDateTime: 'MM/DD/YY HH:mm',
    fullDate: 'MM/DD/YYYY',
    monthDate: 'MM/YYYY',
    yearDate: 'YYYY'
  }

  /**
   * Map from date question date types to regular expressions for json date parsing
   */
  private dateFormatRegExpMap: any = {
    fullDate: /T.*$/,
    monthDate: /-[0-9]*?T.*$/,
    yearDate: /-[0-9]*?-[0-9]*?T.*$/,
  };

  /**
   * Expression for this component
   */
  @Input() expression: any = {type: 'expression', variable: null, operator: '>', value: ''};

  /**
   * Event for when expression is deleted
   */
  @Output() onDelete: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Reference for timeout. Used to delay change detection
   */
  private changeDetectionTimeoutRef: any;

  public constructor(
    @Optional() private translationService: TranslationService,
    @Optional() private translationEvents: TranslationEventService,
    private conditions: QuestionnaireConditionService,
    private variables: QuestionnaireVariablesService,
    private state: QuestionnaireStateService,
    private ref: ChangeDetectorRef
  ) {
    // early bailout when no translation service found
    if (!this.translationService) {
      return;
    }

    // function to set date format map by currently active locale
    const setDateFormatMap = () => {
      this.dateFormatMap = {
        fullDateTime: this.translationService.getDateFormat(DATE_FORMAT_YMD_HM),
        fullDate: this.translationService.getDateFormat(DATE_FORMAT_YMD),
        monthDate: this.translationService.getDateFormat(DATE_FORMAT_YM),
        yearDate: this.translationService.getDateFormat(DATE_FORMAT_Y)
      };
    };

    // initial date format map
    setDateFormatMap();

    // update date format map and date format when locale changed!
    this.dateChangedSub = this.translationEvents.onLocaleChanged().subscribe(() => {
      // update date format map by currently active locale
      setDateFormatMap();

      // this little trick actually hides the date input field for a very sort time and then rerenders it
      this.dateFormat = null;

      this.ref.markForCheck();
      this.ref.detectChanges();

      this.initDateFormat();

      this.ref.markForCheck();
      this.ref.detectChanges();
    });
  }

  public ngOnInit() {
    // get select data to populate dropdowns
    this.selectData = this.conditions.getSelectData();

    // get newest select data when updated
    this.selectUpdatedSub = this.conditions.onUpdateSelect.subscribe(() => {
      this.selectData = this.conditions.getSelectData();
      this.checkForDelete();
      this.triggerChangeDetection();
    });

    // variable contains path hash = this is a question container
    const pathHash = this.getPathHashByVariableName();

    // set variable type to 'question' so we get question/answer dropdowns
    pathHash && (this.variableType = 'question');

    // set path hash of selected question in order to select correct question in dropdown
    pathHash && this.selectData.answerToQuestionMap[pathHash]
      && (this.selectedQuestion = this.selectData.answerToQuestionMap[pathHash]);

    // question may no longer exist, so check for delete and update view
    this.checkForDelete();
    this.triggerChangeDetection();
  }

  /**
   * Unsubscribes from subscriptions when view is destroyed
   */
  public ngOnDestroy() {
    this.selectUpdatedSub.unsubscribe();
    this.dateChangedSub.unsubscribe();
  }

  /**
   * Triggers condition on change event in order to inform other groups/expressions of change.
   * Also triggers change detection to update view
   */
  public onTriggerChange(opts?: {timeout: number}) {
    if (opts && opts.timeout) {
      clearTimeout(this.changeDetectionTimeoutRef);
      this.changeDetectionTimeoutRef = setTimeout(() => this.conditions.onChange.emit(), opts.timeout);
      return;
    }

    this.conditions.onChange.emit();
    this.triggerChangeDetection();
  }

  /**
   * Deletes this expression by emitting on delete event.
   * Underlying group will then delete this expression
   */
  public onClickDelete() {
    this.onDelete.emit();
  }

  /**
   * Event for when variable type was changed. Type 'question' renders question and answer dropdowns, while
   * type 'variable' renders a variable dropdown and an input field with matching type for variable type
   */
  public onChangeVariableType() {
    this.variableType === 'question' && (this.expression.operator = '==') && (this.expression.value = true);
    this.triggerChangeDetection();
  }

  /**
   * Event for when variable was changed in the variable dropdown.
   * Triggers change detection and deletes value, when previous
   * variable type does not match new variable type
   * (e.g: switch from number to date type)
   */
  public onChangeVariable() {
    const prevInputType = this.inputType;
    this.onTriggerChange();

    // reset value when input type changed
    if (prevInputType != this.inputType) {
      this.expression.value = '';
    }
  }

  /**
   * Event handler for when date input was completed
   */
  public onDateComplete(value?: string) {
    console.log('onDateComplete:', value);

    this.expression.value = value || this.acceptedDate;
    this.onTriggerChange();
  }

  /**
   * Returns formatted date from expression value. Used to populate date input field
   */
  public getDisplayDate() {
    return this.expression.value ? moment(this.expression.value).format(this.dateFormat): '';
  }

  /**
   * Returns true if browser supports input date format
   * and also has an ui for it
   *
   * @returns {boolean}
   */
  public supportsInputDate(): boolean {
    const input = document.createElement('input');
    input.setAttribute('type','date');

    var notADateValue = 'not-a-date';
    input.setAttribute('value', notADateValue);

    return (input.value !== notADateValue);
  }

  /**
   * Triggers change detection. Also updates variable input type
   * and inits date format/date mask when variable input type is date
   */
  public triggerChangeDetection() {
    try {
      const container = this.conditions.getContainerForVariable(this.expression.variable);
      this.inputType = <any>(container && container.format ? container.format: 'text');
      this.inputType === 'date' && this.initDateFormat();
      this.ref.detectChanges();
      this.ref.markForCheck();
    } catch (error) {
      // we can ignore this
    }
  }

  /**
   * Triggers on delete event when this expression is
   * invalid, because question/variable no longer exists
   */
  private checkForDelete() {
    const pathHash = this.getPathHashByVariableName();
    pathHash && !this.selectData.answerToQuestionMap[pathHash] && this.onDelete.emit();
    !pathHash && !!this.expression.variable && this.selectData.variables.indexOf(this.expression.variable) === -1 && this.onDelete.emit();
  }

  /**
   * Returns path hash by variable name when scope is "container"
   * e.g: container.xabcde returns abcde
   */
  private getPathHashByVariableName(): string {
    return (`${this.expression.variable}`.match(/^container\.x(.*$)/) || [])
      .reduce((all, curr, index) => index > 0 ? all += curr : '', '');
  }

  /**
   * Inits date format and date mask by selected variable
   */
  private initDateFormat() {
    // get container and container options mapped to variable
    const container = this.conditions.getContainerForVariable(this.expression.variable);
    const options = container && container.options || {};

    // default values for date format and date format regular expression
    this.dateFormat = this.dateFormatMap.fullDate;
    this.dateFormatRegExp = this.dateFormatRegExpMap.fullDate;

    // set date format and date format regular expression by container options (if any)
    for (const dateOption of Object.keys(this.dateFormatMap)) {
      options[dateOption] && (this.dateFormat = this.dateFormatMap[dateOption]);
      options[dateOption] && this.dateFormatRegExpMap[dateOption] && (this.dateFormatRegExp = this.dateFormatRegExpMap[dateOption]);
    }

    this.initImask();
  }

  /**
   * Initializes date mask by current date format and date format regular expression
   */
  private initImask() {
    this.iMaskOptions = {
      mask: Date,
      pattern: this.dateFormat,
      lazy: false,
      min: new Date(1900, 0, 1),
      max: new Date(2038, 0, 19),

      format: (date) => moment(date).format(this.dateFormat),
      parse: (str) => {
        const m = moment(str, this.dateFormat);
        this.acceptedDate = m.toISOString(true).replace(this.dateFormatRegExp, '');
        return m;
      },
      blocks: {
        YYYY: {
          mask: IMask.MaskedRange,
          placeholderChar: 'Y',
          from: 1900,
          to: 2038
        },
        MM: {
          mask: IMask.MaskedRange,
          placeholderChar: 'M',
          from: 1,
          to: 12
        },
        DD: {
          mask: IMask.MaskedRange,
          placeholderChar: 'D',
          from: 1,
          to: 31
        },
        HH: {
          mask: IMask.MaskedRange,
          placeholderChar: 'H',
          from: 0,
          to: 23
        },
        mm: {
          mask: IMask.MaskedRange,
          placeholderChar: 'm',
          from: 0,
          to: 59
        }
      }
    };
  }
}
