/**
 * Mapping for translation keys, so we don't have to use long text in source code. Please keep in mind, that the
 * german default translations are the keys, so don't change them, if you wan't to change the text.
 * Instead change the de_DE translations!
 */
export const KEYS = {
  MEDIA_CENTER: {
    MEDIA_LIB: 'Mediathek',
    AUTO_PLAY: 'Automatisch abspielen',
    DEACTIVATE_CONTROLS: 'Steuerelemente deaktivieren',
    CREATE_PREVIEW: 'Vorschaubild aus aktueller Position',
    NO_PREVIEW_FOR_THIS_FILE_TYPE: 'Für diesen Dateityp ist keine Vorschau verfügbar.',
    UPLOAD: 'Hochladen',
    FILE: 'Datei',
    SIZE: 'Größe',
    DATE: 'Datum',
    ZIP_DOWNLOAD: 'Zip Download',
    CANCEL: 'Abbrechen',
    OK: 'Ok',
    INVALID_FILE_TYPE_SELECTED: 'Warnung: Ungültiger Dateityp ausgewählt. Der Upload wurde abgebrochen.',
    ERROR_LOADING_ASSETS: 'Beim Laden der Assets ist ein Fehler aufgetreten',
    QUESTION_DELETE_FILE: 'Sind sie sicher, dass sie diese Datei vom Server entfernen möchten?',
    ERROR_UPDATING_ASSETS: 'Beim Aktualisieren der Assets ist ein Fehler aufgetreten',
    ERROR_DELETING_ASSET: 'Beim Entfernen der Datei ist ein Fehler aufgetreten!',
    ERROR_STARTING_EDIT_MODE: 'Starten des Edit-Modus fehlgeschlagen',
    ERROR_UPLOADING_ASSET: 'Beim Hochladen der Dateien ist ein Fehler aufgetreten!',
    ERROR_DOWNLOADING_ASSETS: 'Beim Download der Assets ist ein Fehler aufgetreten!'
  }
};
