export const NAVIGATION = {
  name: 'Fragebogen',
  icon: 'fa-id-card-o',
  path: '/module/questionnaire/',
  children: [{
    name: 'Vorlagen',
    path: '/module/questionnaire/grid',
    icon: 'fa-table'
  }]
};
