import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {FormControl} from '@angular/forms';


export const duplicateVariableNameValidator = (scope, pathHash: string, variables: Questionnaire.Options.Variables) => {
  return (formEl: FormControl) => {
    const variableName = formEl.value;

    if (variableName && variables[scope] && variables[scope][variableName] && !variables[scope][variableName][pathHash]) {
      return {
        duplicateVariableName: true
      };
    }

    return null;
  };
};
