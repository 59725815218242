import * as moment from 'moment';
import {CommonModule} from '@angular/common';
import {NgModule, Optional} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LayoutService} from '@ngmedax/layout';
import {ToastService} from '@ngmedax/toast';
import {LoginEventService} from '@ngmedax/login';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {LicenseGuard} from './guard/license.guard';
import {ApiService} from './service/api.service';
import {TRANSLATION_DEFAULT_SCOPE} from '../../constants';
import {KEYS} from '../../translation-keys';
import {License} from '../../types';

// hack to inject decorator declarations. must occur before class declaration!
export interface LicenseGuardModule extends Translatable {}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
  ],
  declarations: [],
  exports: [],
  providers: [
    LicenseGuard,
    ApiService
  ]
})
@Translatable({scope: TRANSLATION_DEFAULT_SCOPE, keys: KEYS})
export class LicenseGuardModule {
  public constructor(
    layoutService: LayoutService,
    licenseGuard: LicenseGuard,
    licenseService: ApiService,
    loginEvents: LoginEventService,
    @Optional() private translationService: TranslationService,
    @Optional() private toastService: ToastService,
  ) {
    layoutService.registerGuard(licenseGuard, 50);
    loginEvents.onAfterLogin().subscribe(() => {
      licenseService.getLicense()
        .then(response => this.showWarningIfExpiresSoon(response.license))
        .catch(error => console.error(error));
    });
  }

  /**
   * Shows warning message, if license valid for less or equal then 14 days
   *
   * @param license
   */
  public showWarningIfExpiresSoon(license: License) {
    if (!license || !license.validUntil) {
      return;
    }

    const warning = (msg) => !!this.toastService ? this.toastService.warning(msg, null, {timeOut: 15000}) : alert(msg);

    const numDaysLeft = moment(license.validUntil, 'YYYY-MM-DD').diff(new Date(), 'days');
    const isExpiringSoon = numDaysLeft > 0 && numDaysLeft <= 14;
    const canNotify = !!this.translationService;

    if (isExpiringSoon && canNotify) {
      const msg = this._(KEYS.DEFAULT.WARNING_LICENSE_EXPIRES_SOON, {numDaysLeft});
      warning(msg);
    }
  }
}
