<ng-container *ngIf="questionnaire && questionnaire.questions">
  <app-select2
    cmpCssClasses="qa-question-index mb-3"
    selectCssClasses="question-index"
    btnCssClasses="btn-success add-btn"
    label="{{_(KEYS.EDITOR.JUMP_TO_ELEMENT)}}"
    buttonFaIcon="fa-forward"
    (submit)="onJumpToElement($event)"
    [options]="options"
    [hideLabel]="hideLabel"
  >
    <ng-container *ngFor="let question of questionnaire.questions; let pos = index;">
      <option
        *ngIf="question.title && question.title[locale]"
        value="{{question.id}}" [attr.data-format]="question.format"
      >
        {{pos+1}}: {{question.title[locale] | striptags | htmldecode | truncate: truncateAfter}}
      </option>
    </ng-container>
  </app-select2>
</ng-container>
