import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MediaCenterComponent} from '../components/media-center/media-center.component';
import {Asset, MediaCenter} from '../../../types';
import {ImageService} from './image.service';


@Injectable()
export class MediaCenterService {
  /**
   * Injects dependencies
   *
   * @param modal
   */
  public constructor(
    private image: ImageService,
    private modal: NgbModal
  ) {
  }

  public openModal(options: MediaCenter.Options) {
    const modalRef = this.modal.open(MediaCenterComponent, <any>{size: 'fullscreen', backdrop: 'static'});
    modalRef.componentInstance.options = options;
  }

  /**
   * Returns image data for given Asset
   *
   * @param file
   * @param bucketId: string
   */
  public getImageData(
    file: Asset.MediaCenter.GridFile,
    bucketId: string,
    transparentBackground: boolean = false
  ): Promise<{width: number, height: number, dataUrl: string}> {
    return this.image.getImageData(file, bucketId, transparentBackground);
  }
}
