import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ConfigService} from '@ngmedax/config';
import {RegistryService} from '@ngmedax/registry';

import {Settings, User} from '../../../types';


@Injectable()
export class SettingsService {

  public constructor(
    private http: HttpClient,
    private registryService: RegistryService,
    private configService: ConfigService
  ) {
  }

  public getUsers(filterCriteria ?: User) {
    return new Promise((resolve, reject) => {
      const exampleData: User[] = [
        {
          id: 1,
          username: 'mmustermann',
          password: '1234',
          lastName: 'Mustermann',
          firstName: 'Martin',
          groups: ['ALLG']
        },
        {
          id: 2,
          username: 'emusterfrau',
          password: '1234',
          lastName: 'Musterfrau',
          firstName: 'Erika',
          groups: ['ALLG']
        }

      ];
      resolve(exampleData);
    });
  }

  public async getSettings(): Promise<Settings> {
    const settings: Settings = {
      deleteClientsInDb: 0,
      deleteClientsOnDisk: 0,
      deleteSurveys: 40
    };

    for (const key of Object.keys(settings)) {
      const dataObj = await this.getData(key);
      let value = dataObj && dataObj.value ? parseInt(dataObj.value, 10) : 0;

      if (value !== -1 && key === 'deleteSurveys' && value < 40) {
        value = 40;
      }

      settings[key] = value;
    }

    return settings;
  }

  /**
   * Saves settings
   */
  public async saveSettings(settings: Settings): Promise<any> {
    for (const key of Object.keys(settings)) {
      const value = settings[key];
      await this.postData({key, value});
    }
  }

  /**
   * Posts settings data
   */
  private async postData(data: any): Promise<any> {
    const headers = this.getAuthHeaders();
    const apiUrl = this.getSettingsApiUrl();

    return await new Promise((resolve, reject) => {
      this.http.post(apiUrl, data, {headers}).subscribe(value => resolve(value), reject);
    });
  }

  /**
   * Returns settings data for given key
   */
  private async getData(key: string): Promise<any> {
    return this.postData({key});
  }

  /**
   * Returns api url for settings
   *
   * @returns {string}
   */
  private getSettingsApiUrl(suffix: string = null): string {
    return this.buildUrl('apis.settings.uri', suffix);
  }

  /**
   * Builds url by value of given config key and suffix
   *
   * @param {string} configKey
   * @returns {any}
   */
  private buildUrl(configKey: string, suffix = null) {
    let baseUri = this.configService.get(configKey);

    if (suffix) {
      baseUri = `${baseUri}${suffix}`;
    }

    return baseUri;
  }

  /**
   * Returns auth headers by auth token and tenant id
   */
  private getAuthHeaders(): any {
    const headers: any = {};
    const authToken = this.registryService.get('session.authToken');
    const tenantId = this.registryService.get('session.tenantId');

    if (authToken) {
      headers['X-Api-Token'] = authToken;
    }

    if (tenantId) {
      headers['X-Api-TenantId'] = `${tenantId}`;
    }

    return headers;
  }
}
