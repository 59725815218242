export const NAVIGATION = {
    name: 'Einstellungen',
    icon: 'fa-cog',
    path: '/module/settings/',
    children: [
        /*
        {
            name: 'Drucker',
            path: '/module/settings/printers',
            icon: 'fa-print'
        },
        {
            name: 'Praxisdaten',
            path: '/module/settings/customer',
            icon: 'fa-user-md'
        },

        {
            name: 'Sicherheit / Lizenz',
            path: '/module/settings/license',
            icon: 'fa-lock'
        },

        {
            name: 'Sprachen',
            path: '/module/settings/languages',
            icon: 'fa-globe'
        },
        */
        {
            name: 'Löschfristen',
            path: '/module/settings/deletion-periods',
            icon: 'fa-trash'
        },
    ]
};
