import {CommonModule} from '@angular/common';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {TranslationEventService} from './services/translation-event.service';
import {TranslationService} from './services/translation.service';
import {BaseTranslationService} from './services/base-translation.service';
import {LocaleSelectComponent} from './components/locale-select.component';
import {LocaleDownloaderService} from './services/locale-downloader.service';
import {DateFormatService} from './services/date-format.service';
import {LocalesLoader} from './loaders/locales.loader';
import {ConfigService} from '@ngmedax/config';
import {Select2Module} from '@ngmedax/select2';


@NgModule({
  imports: [
    CommonModule,
    Select2Module
  ],
  declarations: [
    LocaleSelectComponent
  ],
  exports: [
    LocaleSelectComponent
  ],
  providers: [
    LocaleDownloaderService,
    TranslationEventService,
    BaseTranslationService,
    DateFormatService,
    TranslationService,
    {
      provide: APP_INITIALIZER,
      useFactory: LocalesLoader,
      deps: [TranslationService, ConfigService],
      multi: true
    }
  ]
})
export class TranslationModule {
}
