/**
 * Mapping for translation keys, so we don't have to use long text in source code. Please keep in mind, that the
 * german default translations are the keys, so don't change them, if you wan't to change the text.
 * Instead change the de_DE translations!
 */
export const KEYS = {
  GRID: {
    DEVICES: "Geräte",
    DEVICE_NAME: "Gerätename",
    DEVICE_ID: "Geräte-ID",
    PATIENT: "Patient",
    PATIENTS: "Patienten",
    OPTIONS: "Optionen",
    DELETE_DEVICE: "Gerät löschen",
    ENTRIES: "Einträge",
    TYP: 'Typ',
    BROWSER: 'Browser',
    DEVICE: 'Gerät',
    QUESTION_DELETE_DEVICE: "Möchten Sie das Gerät wirklich löschen?",
    SUCCESSFULLY_DELETED_DEVICE: "Das Gerät wurde erfolgreich gelöscht.",
    ERROR_DELETING_DEVICE: "Beim Löschen des Geräts ist ein Fehler aufgetreten.",
    ERROR_LOADING_DEVICE_LIST: "Beim Laden der Geräteliste ist ein Fehler aufgetreten.",
    SEARCH: 'Suche',
    TYPE_BROWSER: 'Browser',
    TYPE_DEVICE: 'Gerät',
    TYPE_ALL: 'Alle'
  },
  CRUD: {
    INIT_DEVICE: "Gerät initialisieren",
    INIT_DEVICE_HELP_TEXT: "Bitte scannen sie folgenden QR-Code mit der App, um ein neues Gerät hinzuzufügen.",
    INSTALL_APP_HELP_TEXT: "Die App können sie bequem auf einem Gerät installieren, indem sie den jeweils für die Platform passenden QR-Code damit scannen."
  }
};
