<div class="qa-net-diagram-options">
  {{_(KEYS.EDITOR.DATASET_TITLE)}}:
  <div class="custom-card mb-3">
    <input class="form-control" type="text" [(ngModel)]="question.options.netDiagram[0].title[locale]" [placeholder]="_(KEYS.EDITOR.DISTRIBUTION)">
  </div>

  {{_(KEYS.EDITOR.NUMBER_RANGE)}}:
  <div class="qa-number-options custom-card mb-3">
    <label class="min-label" for="min">{{_(KEYS.EDITOR.MIN)}}:</label>
    <input class="min-input form-control" type="number" id="min" [(ngModel)]="question.options.min">

    <label class="max-label" for="max">{{_(KEYS.EDITOR.MAX)}}:</label>
    <input class="max-input form-control" type="number" id="max" [(ngModel)]="question.options.max">

    <label class="steps-label" for="steps">{{_(KEYS.EDITOR.STEPS)}}:</label>

    <input
      class="steps-input form-control"
      type="number" id="steps"
      (change)="onUpdateSteps()"
      (keyup)="onUpdateSteps()"
      [step]="steps"
      [(ngModel)]="question.options.steps"
    >
  </div>

  {{_(KEYS.EDITOR.ENTRIES)}}:
  <div *ngFor="let entry of question.options.netDiagram[0].entries; let position = index;" class="entries">
    <div class="input-group mb-1">
      <button class="btn btn-success qa-input-position mr-1">
        {{position + 1}}
      </button>

      <div class="btn-group ml-1 mr-2">
        <button class="btn btn-primary" (click)="onPositionChange(position, position - 1)">
          <i class="fa fa-arrow-up"></i>
        </button>

        <button class="btn btn-primary" (click)="onPositionChange(position, position + 1)">
          <i class="fa fa-arrow-down"></i>
        </button>
      </div>

      <input type="text" class="form-control entry-label" [(ngModel)]="entry.label[locale]">

      <app-select2
        cmpCssClasses="select-fix ml-2 entry-variable"
        [options]="select2Opts"
        [(selected)]="entry.variable"
        [hideButton]="true"
        [hideLabel]="true"
      >
        <option *ngFor="let variable of variables">
          {{variable}}
        </option>
      </app-select2>

      <button
        class="btn btn-danger ml-2"
        (click)="onDeleteEntry(position)"
      >
        <i class="fa fa-trash"></i>
      </button>
    </div>
  </div>
  <div class="btn-group mt-2">
    <button class="btn btn-sm btn-success" (click)="onAddEntry()">
      <i class="fa fa-plus"></i> {{_(KEYS.EDITOR.ADD_ENTRY)}}
    </button>
  </div>
</div>
