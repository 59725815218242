export const configKeys = {
  /**
   * Config key for questionnaire api uri
   * @type {string}
   */
  QUESTIONNAIRE_URI_CONFIG_KEY: 'apis.questionnaire.uri',

  /**
   * Config key for patient api url
   * @type {string}
   */
  PATIENT_URI_CONFIG_KEY: 'apis.patient.uri',

  /**
   * Config key for device api uri
   * @type {string}
   */
  DEVICE_URI_CONFIG_KEY: 'apis.device.uri',

  /**
   * Config key for session auth token
   * @type {string}
   */
  SESSION_AUTH_TOKEN: 'session.authToken',

  /**
   * Config key for session tenant id
   * @type {string}
   */
  SESSION_TENANT_ID: 'session.tenantId'
};
