import {Injectable} from '@angular/core';
import {Ast, Token, TokenRule} from '../../../types';
import {ExpressionTokenizerService} from './expression-tokenizer.service';

/**
 * Expression statement parser
 */
@Injectable()
export class ExpressionParserService {
  /**
   * Injects dependencies
   */
  public constructor(private tokenizer: ExpressionTokenizerService) {
  }

  /**
   * Parses given expression statement into ast
   *
   * @param {string} stmt
   * @param {TokenRule[]} rules
   * @returns {Ast.Group}
   */
  public parse(stmt: string, rules: TokenRule[] = null): Ast.Group {
    const expected = (exp: string, t: Token): string => `Expected ${exp} but got type: "${t.type}", value: ${t.raw}`;
    const tokens = this.tokenizer.tokenize(stmt, rules ? rules : this.tokenizer.getTokenRules());

    const walk = (tokens: Token[], current: Ast.Group = null, root: Ast.Group = null): Ast.Group => {
      const token = tokens.shift();

      if (!token) {
        return root;
      }

      // we found a group close
      if (token.type === Token.TYPE.GROUP_CLOSE && current) {
        current.parent && (current = current.parent);
        return walk(tokens, current, root);
      }

      // we found a group open
      if (token.type === Token.TYPE.GROUP_OPEN) {
        const group: Ast.Group = {type: Ast.TYPE.GROUP, operator: null, elements: []};
        current && current.elements.push(group) && (group.parent = current) && (current = group);

        !root && (root = group);
        !current && (current = group);

        return walk(tokens, current, root);
      }

      // we found an expression
      if (token.type === Token.TYPE.VARIABLE && current) {
        const operator = tokens.shift();
        const value = tokens.shift();

        if (operator.type !== Token.TYPE.OPERATOR) {
          throw new SyntaxError(expected('operator after variable', operator));
        }

        if (value.type !== Token.TYPE.VALUE) {
          throw new SyntaxError(expected('value after operator', value));
        }

        const expr: Ast.Expression = {
          type: Ast.TYPE.EXPRESSION,
          operator: <Ast.OPERATOR>operator.raw,
          variable: `${token.raw}`,
          value: value.raw
        };

        current.elements.push(expr);
        return walk(tokens, current, root);
      }

      // we found the group operator
      if (token.type === Token.TYPE.OPERATOR && current) {
        if (['&&', '||'].indexOf(<string>token.raw) === -1) {
          throw new SyntaxError(expected(`logical group operator: "&&" or "||"`, token));
        }

        if (current.operator && token.raw !== current.operator) {
          throw new SyntaxError(expected(`same logical operator: "${current.operator}" as it can not change in a group`, token));
        }

        current.operator = <Ast.OPERATOR>token.raw;
        return walk(tokens, current, root);
      }

      if (!current) {
        throw new SyntaxError(expected('group start: "("', token));
      }

      throw new SyntaxError(expected('group start/end, group operator or expression starting with a variable', token));
    };

    const clean = (ast: Ast.Group) =>
      delete(ast.parent) && ast.elements.forEach(el => el.type === Ast.TYPE.GROUP && clean(<Ast.Group>el));

    const ast = walk(tokens);
    clean(ast);
    return ast;
  }
}
