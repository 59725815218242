import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PdfFormCrudComponent} from '../components/crud/pdf-form-crud.component';
import {PdfFormSignatureComponent} from '../components/signature/pdf-form-signature.component';


@Injectable()
export class PdfFormCrudService {
  /**
   * Injects dependencies
   *
   * @param modal
   */
  public constructor(
    private modal: NgbModal
  ) {
  }

  public openModal(uid?: string) {
    const modalRef = this.modal.open(PdfFormCrudComponent, <any>{size: 'fullscreen', backdrop: 'static'});
    uid && modalRef.componentInstance.load(uid);
    // modalRef.componentInstance.options = options;
  }

  public openSignatureModal(uid: string) {
    const modalRef = this.modal.open(PdfFormSignatureComponent, <any>{size: 'fullscreen', backdrop: 'static'});
    modalRef.componentInstance.load(uid);
  }
}
