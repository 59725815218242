import {Injectable} from '@angular/core';
import {KeyPairGenerator, KeyPair, PUBLIC_KEY} from '@ngmedax/common-license';
import {RegistryService} from '@ngmedax/registry';
import {ConfigService} from '@ngmedax/config';
import * as crypto from 'crypto-js';

@Injectable()
export class KeyPairStorageService {
  private encrypted =
    'XwJY0lHabQpp7f+8qRLVhXMzVAzXpXvM99/uqJZl8JnvPb' +
    'rhgxwcnPnITbrEYGjWzbRlHz2sS4pNF3GFX/TVlhyDlp//' +
    'ptm48G4yEbAHr64uDJN6AyPO4kWs9gq5eZPRaz58ZNMes5' +
    'vSqUGzAwlV24GHRllwOZSgvzzfKf4VuTZUTrEyv8sYBX2j' +
    'v8/SQaqHm/Tz6KcB1NGWS1JI5TbiAQ==';

  private iv = '6182666dd2fea9e5';

  /**
   * Config key for license generator pw
   */
  private configKeyPw = 'license.generator.password';


  /**
   * Config key for license generator pubkey mode
   */
  private configKeyPubKeyMode = 'license.generator.pubkey.mode';

  /**
   * Constructor
   */
  public constructor(
    public keyPairGenerator: KeyPairGenerator,
    public registry: RegistryService,
    public config: ConfigService,
  ) {
  }

  /**
   * Unlocks key pair
   */
  public async unlock(password: string): Promise<boolean> {
    const isValidPw = await this.isValidPassword(password);

    if (!isValidPw) {
      return false;
    }

    this.registry.set(this.configKeyPw, password);
    return true;
  }

  /**
   * Locks key pair
   */
  public lock() {
    this.registry.set(this.configKeyPw, null);
  }

  /**
   * Returns true if key pair is unlocked
   */
  public async isUnlocked(): Promise<boolean> {
    if (this.isPubKeyMode()) {
      return true;
    }

    const pw = this.registry.get(this.configKeyPw);
    return this.isValidPassword(pw);
  }

  /**
   * Returns key pair
   */
  public async getKeyPair(password?: string): Promise<KeyPair> {
    if (this.isPubKeyMode()) {
      return new KeyPair(null, PUBLIC_KEY);
    }

    const pw = password || this.registry.get(this.configKeyPw)
    const key = crypto.enc.Hex.parse(crypto.MD5(pw).toString());
    const mode = {keySize: 256, iv: crypto.enc.Hex.parse(this.iv), mode: crypto.mode.CBC};
    const mnemonic = crypto.AES.decrypt(this.encrypted, key, mode).toString(crypto.enc.Utf8);

    if (!mnemonic) {
      throw new Error('Invalid password given');
    }

    return await this.keyPairGenerator.byMnemonic(mnemonic);
  }

  /**
   * Checks if password is valid
   */
  public async isValidPassword(password: string): Promise<boolean> {
    if (this.isPubKeyMode()) {
      return true;
    }

    try {
      await this.getKeyPair(password);
    } catch (error) {
      return false;
    }

    return true;
  }

  public isPubKeyMode(): boolean {
    return this.config.get(this.configKeyPubKeyMode) === true;
  }
}
