export const NAVIGATION = {
  name: 'Geräte',
  icon: 'fa-tablet',
  path: '/module/device/',
  children: [
    {
      name: 'Liste',
      path: '/module/device/grid',
      icon: 'fa-table'
    },
    {
      name: 'Neu anlegen',
      path: '/module/device/create',
      icon: 'fa-asterisk'
    },
  ]
};
