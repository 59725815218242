import {NgModule} from '@angular/core';
import {ExpressionParserService} from './services/expression-parser.service';
import {ExpressionTokenizerService} from './services/expression-tokenizer.service';
import {ExpressionCompilerService} from './services/expression-compiler.service';


@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    ExpressionParserService,
    ExpressionTokenizerService,
    ExpressionCompilerService
  ]
})
export class ExpressionCompilerModule {
}
