<app-select2
  cmpCssClasses="qa-add-pdf-form"
  [hideLabel]="true"
  [hideButton]="false"
  [multiple]="false"
  [options]="options"
  (submit)="onAddPdfForm($event)"
  [(selected)]="choice"
>
  <option
    *ngFor="let form of forms"
    value="{{form.uid}}"
    [disabled]="selected.indexOf(form.uid) !== -1 ? true : null"
  >
    {{form.name}}
  </option>
</app-select2>

<div class="qa-selected-pdf-forms mt-2">
  <table>
    <tbody dragula='pdf-form-list-bag' [dragulaModel]="selected">
    <ng-container *ngFor="let id of selected; let pos = index">
      <tr *ngIf="formTitles[id]">
        <td class="first-entry">
          <button class="btn btn-sm btn-success qa-input-position">{{pos + 1}}</button>
          &nbsp; {{formTitles[id]}}
        </td>
        <td class="options">
          <button class="btn btn-sm btn-primary qa-handle-control">
            <i class="fa fa-arrows qa-handle-control"></i>
          </button>

          <div class="btn-group ml-1 mr-1">
            <button class="btn btn-sm btn-primary" (click)="onPositionChange(pos, pos - 1)">
              <i class="fa fa-arrow-up"></i>
            </button>
            <button class="btn btn-sm btn-primary" (click)="onPositionChange(pos, pos + 1)">
              <i class="fa fa-arrow-down"></i>
            </button>
          </div>

          <button class="btn btn-sm btn-danger" (click)="onDelete(pos)">
            <i class="fa fa-trash"></i>
          </button>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
