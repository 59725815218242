import {RouterModule, Routes} from '@angular/router';
import {QuestionnaireSubmissionGridComponent} from './components/grid/questionnaire-submission-grid.component';
import {permission} from './questionnaire-submission.permission';

const APP_ROUTES: Routes = [
  {
    path: 'module/questionnaire/submission/grid',
    component: QuestionnaireSubmissionGridComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: true,
      needsPermissions: [
        permission.MYMEDAX_QUESTIONNAIRE_SUBMISSION_READ
      ]
    }
  },
];

export const routing = RouterModule.forRoot(APP_ROUTES);
