import {Component, Input, OnInit, Optional} from '@angular/core';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {TRANSLATION_EDITOR_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';


// hack to inject decorator declarations. must occur before class declaration!
export interface QuestionTypeDateComponent extends Translatable {}

@Component({
  selector: 'app-qa-question-date',
  templateUrl: './question-type-date.component.html',
  styleUrls: ['./question-type-date.component.css'],
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class QuestionTypeDateComponent implements OnInit {
  @Input() question: Questionnaire.Container;
  // @Input() mapping: Questionnaire.Container.ComponentMapping;

  /**
   * Date format type. Can be:
   * - fullDateTime
   * - fullDate
   * - monthDate
   * - yearDate
   *
   * @type {string}
   */
  public selectedDateFormat = '';

  /**
   * Locale for questionnaire. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';


  /**
   * Injects dependencies
   */
  public constructor(@Optional() private translationService: TranslationService) {
  }

  /**
   * Initializes date format option. Will be set to default "fullDate" if not already set
   */
  public ngOnInit() {
    if (!this.question.options) {
      this.question.options = {};
    }

    // default is full date
    this.selectedDateFormat = 'fullDate';

    if (this.question.options.fullDateTime) {
      this.selectedDateFormat = 'fullDateTime';
    }

    if (this.question.options.monthDate) {
      this.selectedDateFormat = 'monthDate';
    }

    if (this.question.options.yearDate) {
      this.selectedDateFormat = 'yearDate';
    }

    this.setDateFormatBySelectedOption();
  }

  /**
   * Sets the date format by selected option
   */
  public setDateFormatBySelectedOption() {
    if (!this.selectedDateFormat) {
      return;
    }

    delete(this.question.options.fullDate);
    delete(this.question.options.monthDate);
    delete(this.question.options.yearDate);

    this.question.options[this.selectedDateFormat] = true;
  }
}
